import React, { ReactElement, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { IconFileDatabase } from '@tabler/icons-react';
import { DataElementsQueryService } from '../../../../../api/services/query.services';
import KeyValueCardComponent from '../../../../components/cards/keyvalue.cards';
import DependencieSummaryWidget from '../components/dataElement.components.summary.dependencies';

type DataBlocksType = {
  key: string,
  value: string
}

type Dependencies = {
  type: string,
  entityRef: {
      entityType: string,
      entityId: string,
      name?: string | undefined
  },
  available: boolean,
  dataElementIds: string[],
  lastModified: string,
  featureId: string,
  needAvailableRecalc: boolean
}

function DataElementSummary(): ReactElement {
  const dataElementId = window.location.pathname.split('/')[4];
  const dataElementService = new DataElementsQueryService({ entityType: 'deecCompany' });
  const [dataBlocksValue, setDataBlocksValue] = useState<DataBlocksType[]>([]);
  const [dependenciesId, setDependenciesId] = useState<Dependencies[]>([]);

  useEffect(() => {
    dataElementService.findInAllEntities({ _id: dataElementId })
      .then((res: any) => {
        if (res.data) {
          const tmpDataBlocks = [];
          for (const dataBlock of res.data[0].provides.dataBlocks) {
            tmpDataBlocks.push({ key: dataBlock.path, value: dataBlock.type });
          }
          setDataBlocksValue(tmpDataBlocks);
          setDependenciesId(res.data[0].dependencies);
        }
      });
  
    // setDataBlocksValue([{key: 'test', value: 'test'}, {key: 'test', value: 'test'}, {key: 'test', value: 'test'} ])
  }, []);

  return (
    <Grid container spacing={4} py={2} pr={2}>
      <Grid item>
        <DependencieSummaryWidget dependencies={dependenciesId} />
      </Grid>
      {dataBlocksValue.length > 0 &&
        <Grid item>
          <KeyValueCardComponent
            grid
            icon={IconFileDatabase}
            title='Provides - DataBlocks'
            cardParts={
              {
                values: dataBlocksValue
              }
            }
          />
        </Grid>
      }
    </Grid>
  );
}

export default DataElementSummary;