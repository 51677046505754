import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { IconCoin } from '@tabler/icons-react';
import { CompanyEntity } from '@deecision/dna-interfaces';
import KeyValueCardComponent from '../cards/keyvalue.cards';
import { CompanyGetter } from '../../utils/getter';

function FinancialWidget(props: { data: CompanyEntity }): ReactElement {
  const { t } = useTranslation();
  const companyGetter = new CompanyGetter({
    data: props.data,
    errorMsg: t('common.utils.unknown')
  });

  return (
    <KeyValueCardComponent
      grid
      icon={IconCoin}
      title={t('common.financial.label')}
      cardParts={
        {
          mainValue:
            {
              key: t('common.financial.capital.label'),
              value: companyGetter.getCapital()
            },
          values: [
            {
              key: t('common.financial.turnover.label'),
              value: companyGetter.getTurnover(),
              align: 'right'
            },
            {
              key: t('common.financial.ebitda.label'),
              value: companyGetter.getEbitda(),
              align: 'right'
            }
          ]
        }
      }
    />
  );
}

export default FinancialWidget;
