import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import { CompanyGetter, PersonGetter } from '../../utils/getter';

export interface VeegilenzGetterProps<T, U> {
  entity: T,
  linkedEntities: U[],
  yes: string,
  no: string
}

export abstract class VeegilenzGetter<T, U> {
  entity: T;

  linkedEntities: U[];

  yes: string;

  no: string;

  protected constructor(props: VeegilenzGetterProps<T, U>) {
    this.entity = props.entity;
    this.linkedEntities = props.linkedEntities;
    this.yes = props.yes;
    this.no = props.no;
  }

  abstract getMandatesCurrent(errorMsg?: string): { value: string, score: number };

  abstract getMandatesCurrentAsBO(errorMsg?: string): { value: string, score: number };

  abstract getMandatesPast(errorMsg?: string): { value: string, score: number };

  abstract getMandatesCurrentWithAML(errorMsg?: string): { value: string, score: number };

  abstract getMandatesPastWithAML(errorMsg?: string): { value: string, score: number };

  abstract getHoldingCompany(errorMsg?: string): { value: string, score: number };

  abstract getMandatesCurrentWithListedCompanies(errorMsg?: string): { value: string, score: number };

  abstract getMandatesPastWithListedCompanies(errorMsg?: string): { value: string, score: number };

  abstract getDirectScore(errorMsg?: string): number;

  getScore(scores: number[]) {
    return scores.filter(score => score === 3).length > 2 ? 4 :
      scores.find(score => score === 3) ||
      scores.filter(score => score === 2).length > 4 ? 3 :
        scores.find(score => score === 2) ||
      scores.find(score => score === 1) ||
      0;
  }
}

export class VeegilenzPersonGetter extends VeegilenzGetter<PersonEntity, CompanyEntity> {
  constructor(props: VeegilenzGetterProps<PersonEntity, CompanyEntity>) {
    super(props);
  }

  getDirectScore(errorMsg?: string) {
    const scores = [
      this.getMandatesCurrent().score,
      this.getMandatesCurrentAsBO().score,
      this.getMandatesPast().score,
      this.getMandatesCurrentWithAML().score,
      this.getMandatesPastWithAML().score,
      this.getHoldingCompany().score,
      this.getMandatesCurrentWithListedCompanies().score,
      this.getMandatesPastWithListedCompanies().score
    ];

    return this.getScore(scores);
  }

  getCorporateScore(errorMsg?: string) {
    return undefined;
  }

  getIndividualScore(errorMsg?: string) {
    return undefined;
  }

  getMandatesCurrent(errorMsg?: string) {
    const nb = this.linkedEntities.filter(company => company.dataViews?.data?.company?.state?.state === 'open').length;

    return { value: nb > 0 ? nb.toString() : errorMsg || '', score: nb > 0 ? nb > 1 ? 1 : 2 : 3 };
  }

  getMandatesCurrentAsBO(errorMsg?: string) {
    const nb = this.entity.dataViews?.data?.companies?.stats?.active?.companiesInfo?.filter(ci => ci.activePositionTypes.includes('sha') || ci.activePositionTypes.includes('inv')).length;

    return { value: nb && nb > 0 ? nb.toString() : errorMsg || '', score: nb && nb > 0 ? nb > 1 ? 1 : 2 : 3 };
  }

  getMandatesPast(errorMsg?: string) {
    const nb = this.linkedEntities.filter(company => company.dataViews?.data?.company?.state?.state !== 'open').length;
    const alterNb = this.linkedEntities.filter(company => company.dataViews?.data?.company?.state?.state === 'open').length;

    return { value: nb > 0 ? nb.toString() : errorMsg || '', score: nb === 0 ? 0 : nb < alterNb ? 2 : nb === alterNb ? 2 : 3 };
  }

  getMandatesCurrentWithAML(errorMsg?: string) {
    const nb = this.linkedEntities.filter(company => company.dataViews?.data?.company?.state?.state === 'open')
      .filter(company => company.tags?.find(tag => tag.family === 'riskType' && tag.value === 'antiMoneyLaundering')).length;

    return { value: nb > 0 ? nb.toString() : errorMsg || '', score: nb > 0 ? 2 : 1 };
  }

  getMandatesPastWithAML(errorMsg?: string) {
    const nb = this.linkedEntities.filter(company => company.dataViews?.data?.company?.state?.state !== 'open')
      .filter(company => company.tags?.find(tag => tag.family === 'riskType' && tag.value === 'antiMoneyLaundering')).length;

    return { value: nb > 0 ? nb.toString() : errorMsg || '', score: nb > 0 ? 2 : 1 };
  }

  getHoldingCompany(errorMsg?: string) {
    const nb = this.linkedEntities.filter(company => company.tags?.find(tag => tag.family === 'companyType' && tag.value === 'holding')).length;

    return { value: nb > 0 ? `${this.yes} (${nb})` : errorMsg || '', score: nb > 0 ? 2 : 1 };
  }

  getMandatesCurrentWithListedCompanies(errorMsg?: string) {
    const nb = this.linkedEntities.filter(company => company.dataViews?.data?.company?.state?.state === 'open')
      .filter(company => company.tags?.find(tag => tag.family === 'companyType' && tag.value === 'listedCompany')).length;

    return { value: nb > 0 ? nb.toString() : errorMsg || '', score: nb > 0 ? 3 : 1 };
  }

  getMandatesPastWithListedCompanies(errorMsg?: string) {
    const nb = this.linkedEntities.filter(company => company.dataViews?.data?.company?.state?.state !== 'open')
      .filter(company => company.tags?.find(tag => tag.family === 'companyType' && tag.value === 'listedCompany')).length;

    return { value: nb > 0 ? nb.toString() : errorMsg || '', score: nb > 0 ? 3 : 1 };
  }

  // not common getter

  getNationalities(errorMsg?: string) {
    const nationalities = new PersonGetter({ data: this.entity, errorMsg }).getNationalities();
    const nationalitiesCodes = new PersonGetter({ data: this.entity, errorMsg }).getNationalitiesCodes();

    return { tooltip: nationalities.join(', '), value: nationalitiesCodes, score: nationalitiesCodes.includes('FRA') && nationalitiesCodes.length < 2 ? 1 : nationalitiesCodes.includes('FRA') ? 2 : 3 };
  }

  getCountries(errorMsg?: string) {
    const countries = new PersonGetter({ data: this.entity, errorMsg }).getCountries();
    const countriesCodes = new PersonGetter({ data: this.entity, errorMsg }).getCountriesCodes();

    return { tooltip: countries.join(', '), value: countriesCodes, score: countriesCodes.includes('FRA') && countriesCodes.length < 2 ? 1 : countriesCodes.includes('FRA') ? 2 : 3 };
  }
}

export class VeegilenzCompanyGetter extends VeegilenzGetter<CompanyEntity, PersonEntity> {
  constructor(props: VeegilenzGetterProps<CompanyEntity, PersonEntity>) {
    super(props);
  }

  getDirectScore(errorMsg?: string) {
    const scores = [
      this.getMandatesCurrent().score,
      this.getMandatesPast().score,
      this.getMandatesCurrentWithAML().score,
      this.getHoldingCompany().score,
      this.getMandatesCurrentWithListedCompanies().score
    ];

    return this.getScore(scores);
  }

  getCorporateScore(errorMsg?: string) {
    return undefined;
  }

  getIndividualScore(errorMsg?: string) {
    return undefined;
  }

  getMandatesCurrent(errorMsg?: string) {
    if (this.entity.dataViews?.data?.company?.state?.state !== 'open') {
      return { value: '', score: 3 };
    }

    return { value: this.yes || errorMsg || '', score: 1 };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getMandatesCurrentAsBO(errorMsg?: string) {
    return { value: '', score: 5 };
  }

  getMandatesPast(errorMsg?: string) {
    if (this.entity.dataViews?.data?.company?.state?.state === 'open') {
      return { value: this.no, score: 5 };
    }

    return { value: this.yes || errorMsg || '', score: 5 };
  }

  getMandatesCurrentWithAML(errorMsg?: string) {
    if (this.entity.dataViews?.data?.company?.state?.state !== 'open') {
      return { value: '', score: 3 };
    }

    const aml = this.entity.tags?.some(tag => tag.family === 'riskType' && tag.value === 'antiMoneyLaundering');

    return { value: aml ? this.yes : this.no || errorMsg || '', score: aml ? 2 : 1 };
  }

  getMandatesPastWithAML(errorMsg?: string) {
    if (this.entity.dataViews?.data?.company?.state?.state === 'open') {
      return { value: '', score: 3 };
    }

    const aml = this.entity.tags?.some(tag => tag.family === 'riskType' && tag.value === 'antiMoneyLaundering');

    return { value: aml ? this.yes : this.no || errorMsg || '', score: aml ? 2 : 1 };
  }

  getHoldingCompany(errorMsg?: string) {
    const holding = this.entity.tags?.find(tag => tag.family === 'companyType' && tag.value === 'holding');

    return { value: holding ? this.yes : this.no || errorMsg || '', score: holding ? 2 : 1 };
  }

  getMandatesCurrentWithListedCompanies(errorMsg?: string) {
    if (this.entity.dataViews?.data?.company?.state?.state !== 'open') {
      return { value: '', score: 3 };
    }
    const listed = this.entity.tags?.find(tag => tag.family === 'companyType' && tag.value === 'listedCompany');

    return { value: listed ? this.yes : this.no || errorMsg || '', score: listed ? 3 : 1 };
  }

  getMandatesPastWithListedCompanies(errorMsg?: string) {
    if (this.entity.dataViews?.data?.company?.state?.state === 'open') {
      return { value: '', score: 3 };
    }
    const listed = this.entity.tags?.find(tag => tag.family === 'companyType' && tag.value === 'listedCompany');

    return { value: listed ? this.yes : this.no || errorMsg || '', score: listed ? 3 : 1 };
  }

  // not common getter

  getCountry(errorMsg?: string) {
    const country = new CompanyGetter({ data: this.entity, errorMsg }).getCountry();
    const countryCodes = new CompanyGetter({ data: this.entity, errorMsg }).getCountry();

    return { tooltip: country, value: [countryCodes || ''], score: countryCodes.includes('FRA') ? 1 : 2 };
  }
}
