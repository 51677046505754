import React from "react";

const typeOrder = ['inv', 'sha', 'co-sha', 'exe', 'co-exe', 'boa', ''];

const getValueByCriteria = (obj: any, criteria: any) => {
  switch (criteria) {
  case 'Alphabet':
    return obj.entity2.name.toLowerCase();
  case 'Shares':
    return obj.details?.sharesPercentage ?? 0;
  case 'Proxemee':
    return obj.links[0]?.details?.prxmee?.score ?? 0;
  case 'Type':
    return obj.links[0]?.details?.positionTypes ?? '';
  default:
    return null;
  }
};

const getMostImportantType = (types: string[]) => {
  if (!types || types.length === 0) return null;

  let minIndex = Infinity;
  let mostImportantType = '';

  types.forEach((type) => {
    const index = typeOrder.indexOf(type);
    if (index !== -1 && index < minIndex) {
      minIndex = index;
      mostImportantType = type;
    }
  });

  return mostImportantType || null;
};

const compareFunction = (a: any, b: any, criteria: any, asc: boolean) => {
  for (const criterion of criteria) {
    const valueA = getValueByCriteria(a, criterion);
    const valueB = getValueByCriteria(b, criterion);

    if (criterion === 'Shares' || criterion === 'Proxemee') {
      if (asc) {
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
      } else {
        if (valueA > valueB) return -1;
        if (valueA < valueB) return 1;
      }
    } else if (criterion === 'Type') {
      const mostImportantA = getMostImportantType(valueA);
      const mostImportantB = getMostImportantType(valueB);

      if (mostImportantA === null || mostImportantB === null) {
        // alphabetical order if either has no position types
        const nameA = a.entity2.name.toLowerCase();
        const nameB = b.entity2.name.toLowerCase();

        if (nameA < nameB) return asc ? -1 : 1;
        if (nameA > nameB) return asc ? 1 : -1;
      } else {
        const indexA = typeOrder.indexOf(mostImportantA);
        const indexB = typeOrder.indexOf(mostImportantB);

        if (asc) {
          if (indexA < indexB) return -1;
          if (indexA > indexB) return 1;
        } else {
          if (indexA > indexB) return -1;
          if (indexA < indexB) return 1;
        }
      }
    } else {
      if (valueA < valueB) return asc ? -1 : 1;
      if (valueA > valueB) return asc ? 1 : -1;
    }
  }

  return 0;
};

export const test = 100;

export const SortNetworkArrayByCriteria = (array: any, criteria: any, asc: boolean) => array.sort((a: any, b:any) => compareFunction(a, b, criteria, asc));
