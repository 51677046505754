import React, { ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useTheme } from '@mui/material/styles';

function BiasChips<T>(props: { data: T[], path: string[], dispZero?: true, onClick?: (bias: string) => void, selected?: string }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const biases = [
    { label: t(`common.bias.positive`), value: 'positive', color: 'success' as const, bgColor: theme.palette.success.main },
    { label: t(`common.bias.neutral`), value: 'neutral', color: 'warning' as const, bgColor: theme.palette.warning.main },
    { label: t(`common.bias.negative`), value: 'negative', color: 'error' as const, bgColor: theme.palette.error.main }
  ];

  return (
    <>
      {props.data.length === 0 &&
        <Chip label={props.data.length} sx={{ fontWeight: 600 }} size='small' />
      }
      {biases.map(bias =>
        props.data.filter(d => props.path.some(path => get(d, path) === bias.value)).length > 0 &&
        <Tooltip key={bias.value} title={bias.label} placement='top' arrow>
          <Chip
            label={props.data.filter(d => props.path.some(path => get(d, path) === bias.value)).length}
            sx={{
              opacity: props.selected && props.selected !== bias.value ? 0.4 : 1,
              fontWeight: 600,
              bgcolor: props.selected === bias.value ? `${bias.bgColor} !important` : undefined,
              color: props.selected === bias.value ? theme.palette.primary.contrastText : undefined,
              cursor: props.onClick ? 'pointer' : undefined,
              '&:hover': {
                color: props.onClick ? theme.palette.primary.contrastText : undefined
              }
            }}
            onClick={props.onClick ? ((e) => {
              e.stopPropagation();
              e.preventDefault();
              props.onClick ? props.onClick(bias.value) : undefined;
            }) : undefined}
            color={props.selected === bias.value ? undefined : bias.color}
            size='small'
          />
        </Tooltip>
      )}
    </>
  );
}

export default BiasChips;
