import React, { ReactElement, ReactNode } from 'react';
import { Step, StepConnector, stepConnectorClasses, StepIconProps, StepLabel, Stepper } from '@mui/material';
import { styled } from '@mui/material/styles';

interface CustomizedSteppersProps {
  activeStep: number,
  icons: { [index: string]: ReactNode },
  steps: { label: string, desc: string }[],
  status?: ('active' | 'done' | 'inactive' | 'next' | undefined)[],
  unknownNbSteps?: boolean
}

const ColorLibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 18
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.success.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.grey['500'],
    borderRadius: 1,
    width: 'calc(100% - 16px)',
    marginLeft: '8px',
    marginRight: '8px'
  }
}));

const ColorLibConnectorDashedLine = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 18
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.success.main
    }
  },
  [`&.${stepConnectorClasses.disabled}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      background: `linear-gradient(to right, ${theme.palette.grey['500']} 15%, transparent 15%, transparent 85%, ${theme.palette.grey['500']} 85%), linear-gradient(to right, transparent 50%, ${theme.palette.grey['500']} 50%)`,
      backgroundSize: '100% 2px, 12px 2px',
      borderRadius: 1,
      width: 'calc(100% - 16px)',
      marginLeft: '8px',
      marginRight: '8px'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.grey['500'],
    borderRadius: 1,
    width: 'calc(100% - 16px)',
    marginLeft: '8px',
    marginRight: '8px'
  }
}));

const ColorLibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean, active?: boolean }
}>(({ theme, ownerState }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.grey['500'],
  zIndex: 1,
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  border: 'solid 1.5px',
  borderColor: theme.palette.grey['500'],
  alignItems: 'center',
  ...(ownerState.active && {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main
  }),
  ...(ownerState.completed && {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main
  })
}));

function ColorLibStepIcon(props: Pick<CustomizedSteppersProps, 'icons'> & StepIconProps) {
  const { active, completed, className } = props;

  return (
    <ColorLibStepIconRoot ownerState={{ completed, active }} className={className}>
      {props.icons[String(props.icon)]}
    </ColorLibStepIconRoot>
  );
}

function CustomizedSteppers(props: CustomizedSteppersProps): ReactElement {

  return (
    <Stepper
      sx={{ marginTop: '44px' }}
      alternativeLabel
      activeStep={props.activeStep}
      connector={props.unknownNbSteps ? <ColorLibConnectorDashedLine /> : <ColorLibConnector />}
    >
      {props.steps.map((label, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Step
          key={label.label}
          sx={{
            '& .MuiStepLabel-labelContainer': {
              marginTop: '-104px'
            },
            '& .Mui-completed': {
              color: 'success.main'
            },
            '& .Mui-active': {
              color: 'primary.main'
            }
          }}
          completed={props.status?.[index] === 'done'}
          active={props.status?.[index] === 'active'}
          disabled={props.status && props.status[index] === undefined}
        >
          <StepLabel
            StepIconComponent={p => ColorLibStepIcon({ ...p, icons: props.icons })}
            optional={label.desc.length > 26 ? label.desc.slice(0, 26).concat('...') : label.desc}
            color={props.activeStep === index ? 'primary' : undefined}
          >
            {label.label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default CustomizedSteppers;
