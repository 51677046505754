import React, { ReactElement, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import { IconBinaryTree, IconBinaryTree2, IconChartArcs, IconUserDown, IconUserUp } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import { ReactFlowProvider } from 'reactflow';
import Grid from '@mui/system/Unstable_Grid';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Chip, Stack, Tooltip, Typography } from '@mui/material';
import LinksTrees from '../../../../containers/relations/charts/tree.charts.relations';
import DotGraphChartsRelations from '../../../../containers/relations/charts/dotgraph.charts.relations';
import RelationsTree from '../../../../components/charts/relationsTree.charts';
import { DataElementChipType } from '../../../../components/charts/types.relations.charts';

type NetworkType = {
  id: number,
  icon: JSX.Element,
  label: string,
  value: string
}

function DataElementChipComponent(props: { dataElementChips: DataElementChipType }) {
  return (
    <Stack direction='row' width='88px' ml='8px'>
      <Tooltip arrow title={`Parent DataElement: ${props.dataElementChips?.parent.dataInfo ?
        props.dataElementChips?.parent.dataInfo === 'missing' ? 'data can be missing and will be updated' :
          props.dataElementChips?.parent.dataInfo === 'incomplete' ? 'Ran into issues when identifying represented/representatives' :
            props.dataElementChips?.parent.dataInfo : ' Due to nature of entity, parents are not scheduled to be fetched'}
              ${props.dataElementChips?.parent.scheduling || ''}`}
      >
        <Chip
          sx={{
            width: 45,
            height: 24
          }}
          color={
            props.dataElementChips?.parent.dataInfo ?
              props.dataElementChips?.parent.dataInfo === 'available' ?
                'success' :
                props.dataElementChips?.parent.dataInfo === 'incomplete' ? 'error' : 'warning' :
              'warning'
          }
          label={
            <Stack style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
              <IconUserUp size={20} />
            </Stack>
          }
        />
      </Tooltip>
      <Tooltip arrow title={`Subsidiary DataElement: ${props.dataElementChips?.children.dataInfo ?
        props.dataElementChips?.children.dataInfo === 'missing' ? 'data can be missing and will be updated' :
          props.dataElementChips?.children.dataInfo === 'incomplete' ? 'Ran into issues when identifying represented/representatives' :
            props.dataElementChips?.children.dataInfo : ' Due to nature of entity, subsidiary are not scheduled to be fetched'}
              ${props.dataElementChips?.children.scheduling || ''}`}
      >
        <Chip
          color={
            props.dataElementChips?.children.dataInfo ?
              props.dataElementChips?.children.dataInfo === 'available' ?
                'success' :
                props.dataElementChips?.children.dataInfo === 'incomplete' ? 'error' : 'warning' :
              'warning'
          }
          label={
            <Stack style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
              <IconUserDown size={20} />
            </Stack>
          }
          style={{
            marginLeft: '8px',
            width: 45,
            height: 24
          }}
        />
      </Tooltip>
    </Stack>
  );
}

function NetworkTabsComponents<T extends CompanyEntity | PersonEntity>(): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const entity = useOutletContext() as { data: T };
  const { id } = useParams();
  const networkTypes = [
    {
      id: 0,
      icon: <IconBinaryTree size={25} style={{ transform: 'rotate(-90deg)' }}/>,
      label: 'Network Chart - Tree View',
      value: 'Tree View'
    },
    {
      id: 1,
      icon: <IconBinaryTree2 size={25} style={{ transform: 'rotate(-90deg)' }}/>,
      label: 'Network Chart - Tree Chart View',
      value: 'Tree Chart'
    },
    {
      id: 2,
      icon: <IconChartArcs size={25}/>,
      label: 'Network Chart - Circles View',
      value: 'Circles Chart'
    }
  ];
  const [selected, setSelected] = useState<NetworkType>(networkTypes[0]);
  const [dataElementChips, setDataElementChips] = useState<DataElementChipType>();

  return (
    <>
      <Grid container display='flex' alignItems='center' justifyContent='space-between' pb={2}>
        <Grid container xs={10} display='flex' flexDirection='row' alignItems='center'>
          {selected.icon}
          <Typography ml={2} fontWeight={500} fontSize={18}>
            {selected.label}
          </Typography>
          {dataElementChips && selected.value === 'Tree Chart' &&
            <Grid xs display='flex' height='49px' alignItems='center'>
              <DataElementChipComponent dataElementChips={dataElementChips} />
            </Grid>
          }
        </Grid>
        <Grid container alignItems='center'>
          <Paper sx={{ borderRadius: 24, p: '2px', backgroundColor: 'transparent', cursor: 'pointer' }}>
            <Grid container alignItems='center'>
              <Grid
                p={2}
                borderRadius={15}
                bgcolor={selected.value === 'Tree View' ? theme.palette.primary.main : 'transparent'}
                height={35}
                onClick={() => setSelected(networkTypes[0])}
                sx={{
                  aspectRatio: '1 / 1',
                  '&:hover': selected.value !== 'Tree View' ?
                    {
                      backgroundColor: theme.palette.grey[300]
                    } : undefined
                }}
              >
                <IconBinaryTree size={20} color={selected.value === 'Tree View' ? 'white' : 'grey'} style={{ transform: 'rotate(-90deg)' }}/>
              </Grid>
              <Grid
                p={2}
                ml='2px'
                borderRadius={15}
                bgcolor={selected.value === 'Tree Chart' ? theme.palette.primary.main : 'transparent'}
                height={35}
                onClick={() => setSelected(networkTypes[1])}
                sx={{
                  aspectRatio: '1 / 1',
                  '&:hover': selected.value !== 'Tree Chart' ?
                    {
                      backgroundColor: theme.palette.grey[300]
                    } : undefined
                }}
              >
                <IconBinaryTree2 size={20} color={selected.value === 'Tree Chart' ? 'white' : 'grey'} style={{ transform: 'rotate(-90deg)' }}/>
              </Grid>
              <Grid
                p={2}
                ml='2px'
                borderRadius={15}
                bgcolor={selected.value === 'Circles Chart' ? theme.palette.primary.main : 'transparent'}
                height={35}
                onClick={() => setSelected(networkTypes[2])}
                sx={{
                  aspectRatio: '1 / 1',
                  '&:hover': selected.value !== 'Circles Chart' ?
                    {
                      backgroundColor: theme.palette.grey[300]
                    } : undefined
                }}
              >
                <IconChartArcs size={20} color={selected.value === 'Circles Chart' ? 'white' : 'grey'}/>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {id && selected.value === 'Tree View' &&
        <LinksTrees entity={entity.data} id={id} />
      }
      {id && selected.value === 'Tree Chart' &&
        <ReactFlowProvider>
          <RelationsTree entity={entity.data} id={id} setDataElementsChips={setDataElementChips}/>
        </ReactFlowProvider>
      }
      {id && selected.value === 'Circles Chart' &&
        <DotGraphChartsRelations entity={entity.data} id={id} />
      }
    </>
  );
}

export default NetworkTabsComponents;
