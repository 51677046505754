import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { BaseGroup, GroupDesc } from '@deecision/dna-interfaces';
import Grid from '@mui/material/Unstable_Grid2';
import { Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import {
  IconAlertTriangle,
  IconDatabaseImport, IconHome, IconId,
  IconReport,
  IconReportAnalytics,
  IconTimelineEventPlus, IconTransactionDollar, IconUsers
} from '@tabler/icons-react';
import VEEGILENZ from '../../../../../../assets/VEEGILENZ.svg';
import GroupsServices from '../../services/portfolios.services';
import QuickDashboardContainers from '../../../../../containers/dashboard/quick/quick.dashboard.containers';
import { DashboardConfig } from '../../../../../containers/dashboard/quick/types.quick.dashboard.containers';
import dnaConfig from '../../../../../../config/dna.config.json';

const dashboardConfig: DashboardConfig = {
  sections: [
    {
      id: 'entry',
      icon: IconDatabaseImport,
      type: 'compact',
      groups: [
        {
          subGroupId: 'persons',
          subGroups: [
            {
              augmented: true,
              subGroupId: 'persons.withCompanies'
            }
          ]
        },
        {
          subGroupId: 'companies'
        }
      ]
    },
    {
      id: 'indicators',
      icon: IconReportAnalytics,
      type: 'full',
      groups: [
        {
          subGroupId: 'persons.withCompanies',
          subGroups: [
            {
              subGroupId: 'persons.companies'
            },
            {
              subGroupId: 'persons.network',
              subGroups: [
                {
                  subGroupId: 'persons.network.inBaseGroup'
                },
                {
                  subGroupId: 'persons.network.notInBaseGroup'
                },
                {
                  subGroupId: 'persons.network.personal',
                  subGroups: [
                    {
                      subGroupId: 'persons.network.personal.client'
                    },
                    {
                      subGroupId: 'persons.network.personal.notClient',
                      subGroups: [
                        {
                          subGroupId: 'persons.network.personal.notClient.sameFamily'
                        }
                      ]
                    }
                  ]
                },
                {
                  subGroupId: 'persons.network.business',
                  subGroups: [
                    {
                      subGroupId: 'persons.network.business.client'
                    },
                    {
                      subGroupId: 'persons.network.business.notClient',
                      subGroups: [
                        {
                          subGroupId: 'persons.network.business.notClient.sameFamily'
                        }
                      ]
                    }
                  ]
                },
                {
                  subGroupId: 'persons.network.extended',
                  subGroups: [
                    {
                      subGroupId: 'persons.network.extended.client'
                    },
                    {
                      subGroupId: 'persons.network.extended.notClient',
                      subGroups: [
                        {
                          subGroupId: 'persons.network.extended.notClient.sameFamily'
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          subGroupId: 'companies'
        },
        {
          subGroupId: 'patrimonialComplexity',
          type: 'custom'
        }
      ]
    },
    {
      id: 'alerts',
      type: 'full',
      icon: IconAlertTriangle,
      groups: [
        {
          subGroupId: 'persons.veegilenz',
          type: 'segmentation',
          icon: IconUsers,
          img: VEEGILENZ,
          description: true,
          segmentationCriterias: [
            {
              id: 'person_vigilanceLevel_all',
              filterId: 'person_vigilanceLevel_all',
              on: 'person1',
              type: 'segmentationCriteria'
            }
          ],
          subGroups: [
            {
              subGroupId: 'persons.veegilenz.nationality',
              type: 'segmentation',
              icon: IconId,
              segmentationCriterias: [
                {
                  id: 'person_vigilanceLevel_nationality',
                  filterId: 'person_vigilanceLevel_nationality',
                  on: 'person1',
                  type: 'segmentationCriteria'
                }
              ]
            },
            {
              subGroupId: 'persons.veegilenz.residence',
              type: 'segmentation',
              icon: IconHome,
              segmentationCriterias: [
                {
                  id: 'person_vigilanceLevel_residence',
                  filterId: 'person_vigilanceLevel_residence',
                  on: 'person1',
                  type: 'segmentationCriteria'
                }
              ]
            }
          ]
        },
        {
          subGroupId: 'legalProcedures',
          type: 'select',
          icon: IconReport,
          options: ['1months', '3months', '6months', '12months'],
          subGroups: [
            {
              subGroupId: 'persons.withCompaniesWithAlerts'
            },
            {
              subGroupId: 'companies.withAlerts'
            }
          ]
        },
        {
          subGroupId: 'companiesCreated',
          type: 'select',
          icon: IconTimelineEventPlus,
          options: ['12months', '24months'],
          subGroups: [
            {
              subGroupId: 'persons.withRecentlyCreatedCompanies'
            },
            {
              subGroupId: 'companies.recentlyCreated'
            }
          ]
        },
        {
          subGroupId: 'transfer',
          type: 'select',
          icon: IconTransactionDollar,
          options: ['1months', '3months', '6months', '12months'],
          subGroups: [
            {
              subGroupId: 'persons.withCompaniesWithSoldCompanies'
            },
            {
              subGroupId: 'companies.withSoldCompanies'
            }
          ]
        }
      ]
    }
  ]
};

function DashboardTabsPortfolios() {
  const theme = useTheme();
  const data = useOutletContext() as { data: BaseGroup };
  const navigate = useNavigate();
  const portfoliosServices = new GroupsServices();
  const [dataAllValues, setDataAllValues] = useState<GroupDesc[]>();

  const callSegmentation = (entityType?: string, groupId?: string, workflowId?: string, filterIds?: string[], filterValues?: string[]) => {
    if (groupId && entityType) {
      navigate(`../${dnaConfig.childRoutes.segmentations.baseUrl}?entityType=${entityType}&groupId=${groupId}${workflowId ? `&workflowId=${workflowId}` : ''}${filterIds ? `&filterIds=${filterIds.join(',')}` : ''}${filterValues ? `&filterValues=${filterValues.join(',')}` : ''}`);
    }
  };

  useEffect(() => {
    portfoliosServices.getGroupSubGroupDescs(data.data.baseGroupId)
      .then(res => setDataAllValues(res.data));
  }, []);

  return (
    <Stack spacing={8} pt={4}>
      {dataAllValues ?
        <QuickDashboardContainers  dashboardConfig={dashboardConfig} baseGroupId={data.data.baseGroupId} callSegmentation={callSegmentation} /> :
        <Grid container spacing={2}>
          {[...Array(8)].map((arr, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={`${arr}-${index}`} xs minWidth={200} height={140}>
              <Skeleton
                sx={{ bgcolor: theme.palette.background.default, borderRadius: 2 }}
                animation='wave'
                variant='rectangular'
                width='100%'
                height='100%'
              />
            </Grid>
          ))}
        </Grid>
      }
    </Stack>
  );
}

export default DashboardTabsPortfolios;
