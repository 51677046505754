import React, { ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';
import {
  IconCircleArrowDownFilled,
  IconCircleArrowLeftFilled,
  IconCircleArrowUpFilled,
  IconCircleDotFilled
} from '@tabler/icons-react';
import { currencyFormatter } from '@deecision/common-webapp/utils';
import { useTheme } from '@mui/material/styles';

interface IndicatorProps {
  indicator?: number | string,
  percentage?: number
}

function Indicator(props: IndicatorProps): ReactElement {
  const theme = useTheme();

  return (
    <>
      {props.indicator !== undefined && (
        typeof props.indicator === 'string' ?
          <Tooltip title={props.indicator} arrow placement='right'>
            <IconCircleDotFilled size={16} style={{ color: theme.palette.grey['500'] }}/>
          </Tooltip>
          :
          <Tooltip
            title={
              `${props.percentage && props.indicator !== 0 ? `${props.indicator > 0 ? '+' : ''}${props.percentage.toFixed(2)}% (` : ''}${props.indicator > 0 ? '+' : ''}${props.indicator !== 0 ? currencyFormatter({ value: props.indicator, currency: 'EUR' }) : '='}${props.percentage && props.indicator !== 0 ? ')' : ''}`
            }
            arrow
            placement='right'
          >
            {
              (props.indicator > 0 &&
                <IconCircleArrowUpFilled size={16} style={{ color: theme.palette.success.main }}/> ||
                props.indicator === 0 &&
                <IconCircleArrowLeftFilled size={16} style={{ color: theme.palette.warning.main }}/> ||
                props.indicator < 0 &&
                <IconCircleArrowDownFilled size={16} style={{ color: theme.palette.error.main }}/>) ||
              <></>
            }
          </Tooltip>
      )}
    </>
  );
}

export default Indicator;
