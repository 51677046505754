import { CustomRouteObject } from '@deecision/common-webapp/router';
import { IconFolders } from '@tabler/icons-react';
import React from 'react';
import createElementRoutes from '@deecision/common-webapp/router/utils/create.element.routes';
import { BaseGroup, GroupAllValues } from '@deecision/dna-interfaces';
import { routes as routesCreate } from './create';
import { routes as routesSegmentations } from '../segmentation';
import dnaConfig from '../../../../config/dna.config.json';
import ComponentPortfolios from './components/component.portfolios';
import GroupsServices from './services/portfolios.services';
import IdComponentPortfolios from './components/id.component.portfolios';
import DashboardTabsPortfolios from './components/tabs/dashboard.tabs.portfolios';
import ProxemeeTabsPortfolios from './components/tabs/proxemee.tabs.portfolios';
import ListTabsPortfolios from './components/tabs/list.tabs.portfolios';
import UsecasesTabsPortfolios from './components/tabs/usecases.tabs.portfolios';

const tabs: CustomRouteObject[] = [
  {
    id: dnaConfig.routes.portfolios.tabs.dashboard.label,
    i18nKey: 'Dashboard',
    path: dnaConfig.routes.portfolios.tabs.dashboard.baseUrl,
    element: <DashboardTabsPortfolios />,
    handle: {
      keepParams: true
    }
  },
  {
    id: dnaConfig.routes.portfolios.tabs.proxemee.label,
    i18nKey: dnaConfig.routes.portfolios.tabs.proxemee.i18nKey,
    path: dnaConfig.routes.portfolios.tabs.proxemee.baseUrl,
    element: <ProxemeeTabsPortfolios />,
    handle: {
      keepParams: true
    }
  },
  {
    id: dnaConfig.routes.portfolios.tabs.useCases.label,
    i18nKey: dnaConfig.routes.portfolios.tabs.useCases.i18nKey,
    path: dnaConfig.routes.portfolios.tabs.useCases.baseUrl,
    element: <UsecasesTabsPortfolios />
  },
  {
    id: dnaConfig.routes.portfolios.tabs.persons.label,
    i18nKey: dnaConfig.routes.portfolios.tabs.persons.i18nKey,
    path: dnaConfig.routes.portfolios.tabs.persons.baseUrl,
    element: <ListTabsPortfolios />
  },
  {
    id: dnaConfig.routes.portfolios.tabs.companies.label,
    i18nKey: dnaConfig.routes.portfolios.tabs.companies.i18nKey,
    path: dnaConfig.routes.portfolios.tabs.companies.baseUrl,
    element: <ListTabsPortfolios />
  }
];

const routesPortfolios: CustomRouteObject = createElementRoutes<BaseGroup, GroupAllValues>({
  i18nKey: dnaConfig.routes.portfolios.i18nKey,
  path: dnaConfig.routes.portfolios.baseUrl,
  icon: IconFolders,
  displayIndex: true,
  sectionTitle: 'deetect',
  component: <ComponentPortfolios />,
  idComponent: <IdComponentPortfolios />,
  Service: new GroupsServices(),
  tabs,
  childrens: [
    routesCreate
  ],
  idChildrens: [
    routesSegmentations(dnaConfig.routes.portfolios.label)
  ]
});

export default routesPortfolios;
