import React from 'react';
import { CustomRouteObject } from '@deecision/common-webapp/router';
import { IconFolderPlus } from '@tabler/icons-react';
import CreateComponent from './components/component.create';
import dnaConfig from '../../../../../config/dna.config.json';

const routesCreate: CustomRouteObject = {
  i18nKey: dnaConfig.routes.portfolios.create.i18nKey,
  path: dnaConfig.routes.portfolios.create.baseUrl,
  handle: {
    icon: IconFolderPlus
  },
  element: <CreateComponent />
};

export default routesCreate;
