import { CustomRouteObject } from '@deecision/common-webapp/router';
import { IconFolders } from '@tabler/icons-react';
import React from 'react';
import createElementRoutes from '@deecision/common-webapp/router/utils/create.element.routes';
import { BaseGroup, GroupAllValues } from '@deecision/dna-interfaces';
import { Construction } from '@deecision/common-webapp/components';
import { routes as routesCreate } from './create';
import dnaConfig from '../../../../config/dna.config.json';

const routesFolders: CustomRouteObject = createElementRoutes<BaseGroup, GroupAllValues>({
  i18nKey: dnaConfig.routes.folders.i18nKey,
  path: dnaConfig.routes.folders.baseUrl,
  icon: IconFolders,
  displayIndex: true,
  sectionTitle: 'deetect',
  component: <Construction />,
  idComponent: <Construction />,
  childrens: [
    routesCreate
  ]
});

export default routesFolders;
