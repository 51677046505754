import axios, { AxiosResponse } from 'axios';
import { upperFirst } from 'lodash';
import { BaseEntitiesService, BaseEntitiesServiceProps } from '@deecision/common-webapp/api';
import { CompanyEntity, EntityTag, TagChange, TagChangeType } from '@deecision/dna-interfaces';
import dnaConfig from '../../../../config/dna.config.json';
import axiosInstance from '../../../../api';

export interface BaseDnaEntitiesServiceProps extends Pick<BaseEntitiesServiceProps, 'entityPathUrl'> {
  entityType?: string
}

class BaseDnaRncsEntitiesService<Entity> extends BaseEntitiesService<Entity> {
  entityType: string | undefined;

  aiUrl: string;

  aiBearerToken: string;

  constructor(props: BaseDnaEntitiesServiceProps) {
    super({
      entityPathUrl: props.entityType?.includes('rncs') ? props.entityType : `rncs${upperFirst(props.entityType) || ''}`,
      // entityPathUrl: props.entityType,
      entityPrefixUrl: dnaConfig.api.url.entitiesPrefix,
      axiosInstance, ...props
    });

    this.entityType = props.entityType;
    this.aiUrl = 'https://deecision-dna-completion.meknassi.com/';
    this.aiBearerToken = 'UDHL38tuTWrTXGchCVL3C7p4U4VX77Zk';
  }

  getAiResume(name: string): Promise<AxiosResponse<{ description: string }>> {
    return axios.get(`${this.aiUrl}describe/${this.entityType || ''}/${name}`, {
      headers: {
        'Authorization': `Bearer ${this.aiBearerToken}`
      }
    })
      .then(res => res);
  }

  getAiSanctions(name: string): Promise<AxiosResponse<{ answer: string }>> {
    return axios.get(`${this.aiUrl}question/sanctions/${name}`, {
      headers: {
        'Authorization': `Bearer ${this.aiBearerToken}`
      }
    })
      .then(res => res);
  }

  getAiAcquisitions(name: string): Promise<AxiosResponse<{ answer: string }>> {
    return axios.get(`${this.aiUrl}question/acquisitions/${name}`, {
      headers: {
        'Authorization': `Bearer ${this.aiBearerToken}`
      }
    })
      .then(res => res);
  }

  getCompanyAiResumeWithData(data: CompanyEntity): Promise<AxiosResponse<{ description: string }>> {
    const company = {
      fullName: data.name,
      nationalIdentifierName: 'SIREN',
      nationalIdentifier: data.attributes?.knownIds[0].split(':').at(-1) || 'not specified',
      country: data.dataViews?.data?.company?.address?.country || 'not specified',
      city: data.dataViews?.data?.company?.address?.city || 'not specified',
      address: data.dataViews?.data?.company?.address?.address || 'not specified',
      localCompanyType: 'Société anonyme',
      creationDate: data.dataViews?.data?.company?.identity?.creationDate || 'not specified',
      capital: data.dataViews?.data?.company?.keyFigures?.capital?.value || 'not specified',
      capitalCurrency: data.dataViews?.data?.company?.keyFigures?.capital?.currency || 'not specified'
    };

    return axios.post(`${this.aiUrl}describe/${this.entityType || ''}`, { company }, {
      headers: {
        'Authorization': `Bearer ${this.aiBearerToken}`
      }
    })
      .then(res => res);
  }

  addTag = (id: string, tag: EntityTag) => {
    const tagChanges: Array<TagChange> = [
      {
        tag,
        type: TagChangeType.ADDED
      }
    ];

    return this.axiosInstance.post(`${this.constructUrl(id)}/actions/updateEntityTags`, tagChanges).then(res => this.handleResponse<Entity>(res));
  };

  removeTag = (id: string, tag: EntityTag) => {
    const tagChanges: Array<TagChange> = [
      {
        tag,
        type: TagChangeType.REMOVED
      }
    ];

    return this.axiosInstance.post(`${this.constructUrl(id)}/actions/updateEntityTags`, tagChanges).then(res => this.handleResponse<Entity>(res));
  };
}

export default BaseDnaRncsEntitiesService;
