import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { IconFilter, IconRotate } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ChipFilters from '../../../components/filters/chip.filters';
import { BaseSegmentationProps } from '../types.segmentations';

export interface FiltersSegmentationsProps extends BaseSegmentationProps {
  reset: () => void
}

function FiltersSegmentations(props: FiltersSegmentationsProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const [disableReset, setDisableReset] = useState(true);

  useEffect(() => {
    setDisableReset(false);
  }, [props.filters]);

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid mr={4}>
        <IconFilter />
      </Grid>
      {props.filters.length > 0 ?
        [...props.filters.map(filter => (
          <Grid key={filter.id}>
            <ChipFilters
              color='primary'
              value={
                <Stack direction='row' spacing={2}>
                  <Typography>
                    {filter.label}
                  </Typography>
                  {filter.values?.map(value => (
                    <Chip
                      key={value.toString()}
                      sx={{ bgcolor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                      label={typeof value === 'boolean' ? value ? 'Yes' : 'No' : value.toString()}
                      size='small'
                    />
                  ))}
                  {filter.ranges?.map(range => (
                    <Chip
                      key={`${range.min} - ${range.max}`}
                      sx={{ bgcolor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                      label={`${range.min} - ${range.max}`}
                      size='small'
                    />
                  ))}
                </Stack>
              }
              onDelete={() => filter.id && props.removeFilter(filter.id)}
              onClick={() => undefined}
            />
          </Grid>
        )),
        <Grid>
          <Tooltip title={t('common.utils.reset')} placement='top' arrow>
            <IconButton
              onClick={() => {
                setDisableReset(true);
                props.reset();
              }}
              size='small'
              disabled={disableReset}
            >
              <IconRotate size={20} style={{ transform: 'rotate(180deg)' }} />
            </IconButton>
          </Tooltip>
        </Grid>] :
        <Grid>
          <Typography>
            {t('segmentation.filters.noFilter')}
          </Typography>
        </Grid>
      }
    </Grid>
  );
}

export default FiltersSegmentations;
