import React from 'react';
import { CustomRouteObject } from "@deecision/common-webapp/router";
import createElementRoutes from "@deecision/common-webapp/router/utils/create.element.routes";
import { Construction } from '@deecision/common-webapp/components';
import { IconLayoutList } from '@tabler/icons-react';
import DataBlocksScreen from './dataBlocksList';
import DataBlocksDetailsHeader from './dataBlocksDetailHeader';
import JsonDataBlocksBrutTabs from './tabs/dataBlocks.tabs.jsonBrut';
import dnaConfig from '../../../../config/dna.config.json';

const routeDataBlocks: CustomRouteObject = createElementRoutes({
  id: 'DataBlocks',
  path: 'datablocks',
  icon: IconLayoutList,
  component: <DataBlocksScreen />,
  idComponent: <DataBlocksDetailsHeader />,
  tabs: [{
    id: dnaConfig.routes.admin.tabs.dataElements.tabs.summary.label,
    path: dnaConfig.routes.admin.tabs.dataElements.tabs.summary.baseUrl,
    element: <Construction />
  },
  {
    id: dnaConfig.routes.admin.tabs.dataElements.tabs.json.label,
    path: dnaConfig.routes.admin.tabs.dataElements.tabs.json.baseUrl,
    element: <JsonDataBlocksBrutTabs />
  }],
  displayIndex: true
});

export default routeDataBlocks;
