import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { currencyFormatterProps } from '@deecision/common-webapp/utils/currencyFormatter';
import { useTranslation } from 'react-i18next';
import { PersonEntity } from '@deecision/dna-interfaces';
import { Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { currencyFormatter } from '@deecision/common-webapp/utils';
import Button from '@mui/material/Button';
import { makeRes, PersonGetter } from '../../utils/getter';
import EvenOddTable from '../tables/evenodd/table.evenodd.tables';
import { Column } from '../tables/types.tables';
import dnaConfig from '../../../config/dna.config.json';
import { ListProps } from './types.lists';

function PersonList(props: ListProps<PersonEntity>): ReactElement {
  const { t } = useTranslation();
  // console.log((i18nPossibleLanguages.find(lang => lang.global === (i18n.language || i18nPossibleLanguages[0].global)) || i18nPossibleLanguages[0]).version);

  const columns: Column<currencyFormatterProps>[] = [
    {
      id: 'name',
      label: t('common.identity.name'),
      sort: !props.disableSort && !props.localSort ? 'name' : undefined,
      sortPath: 'props.children',
      minWidth: 240,
      noMaxWidth: true
    },
    {
      id: 'tags',
      label: t('common.utils.tags')
    },
    {
      id: 'nbActiveCompanies',
      label: t('entities.companies.keys.nbActive'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comps_stats_activeCount' : undefined,
      render: value => makeRes(value)
    },
    {
      id: 'nbClosedCompanies',
      label: t('entities.companies.keys.nbClosed'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comps_stats_inactiveCount' : undefined,
      render: value => makeRes(value)
    },
    {
      id: 'nbTotalCompanies',
      label: t('entities.companies.keys.nbTotal'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comps_stats_allCount' : undefined,
      render: value => makeRes(value)
    },
    {
      id: 'nbActiveRelations',
      label: t('entities.relations.keys.nbActive'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_network_stats_activeCount' : undefined,
      render: value => makeRes(value)
    },
    {
      id: 'nbInactiveRelations',
      label: t('entities.relations.keys.nbInactive'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_network_stats_inactiveCount' : undefined,
      render: value => makeRes(value)
    },
    {
      id: 'nbTotalRelations',
      label: t('entities.relations.keys.nbTotal'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_network_stats_allCount' : undefined,
      render: value => makeRes(value)
    },
    {
      id: 'dismemberment',
      label: t('patrimonialComplexity.dismemberment.label'),
      align: 'right',
      render: value => makeRes(value),
      sort: !props.disableSort && !props.localSort ? 'dv_asset_complexity_nb_dismemberment' : undefined
    },
    {
      id: 'turnoverSum',
      label: t('common.financial.turnover.sum'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comps_turnoverSum' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    {
      id: 'ebitdaSum',
      label: t('common.financial.ebitda.sum'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comps_ebitdaSum' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    {
      id: 'capitalSum',
      label: t('common.financial.capital.sum'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comps_capitalSum' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    {
      id: 'valuationSum',
      label: t('common.financial.valuation.sum'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comps_valuationSum' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    {
      id: 'weasse',
      label: t('common.financial.weasse.label'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_person_weasse' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    /*
    {
      id: 'weasseMax',
      label: t('common.financial.weasseMax.label'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_person_weasseMax' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
     */
    {
      id: 'extrapolatedWeasse',
      label: t('common.financial.extrapolatedWeasse.label'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_person_extrapolatedWeasse' : undefined,
      render: value => currencyFormatter({ value: value as number })
    }
  ];

  const rows = props.data.map(person => ({
    id: person.entityId,
    name: (!props.link || typeof props.link === 'string') ?
      <Link
        href={`${dnaConfig.routes.persons.relativeUrl}/${person.entityId as string}`}
        sx={{ whiteSpace: 'nowrap', fontSize: 14 }}
      >
        {person.name}
      </Link> :
      <Button variant='contained' sx={{ mr: '-4px' }} onClick={() => (props.link as (id: string, entityType: string) => void)(person.entityId as string, 'deecPerson')} size='small' fullWidth>{person.name}</Button>,
    tags: <Stack direction='row' spacing={2} maxWidth={340} flexWrap='wrap' useFlexGap>
      {new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getTags().filter(tag => tag.family !== 'batchTag').map(tag =>
        <Tooltip key={`${tag.value}-${tag.family}`} title={tag.family} arrow placement='top'>
          <Chip size='small' label={tag.value} />
        </Tooltip>
      )}
    </Stack>,
    nbActiveCompanies: new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getNbActiveCompanies(true),
    nbClosedCompanies: new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getNbInactiveCompanies(true),
    nbTotalCompanies: new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getNbCompanies(true),
    nbActiveRelations: new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getNbCurrentRelations(true),
    nbInactiveRelations: new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getNbOldRelations(true),
    nbTotalRelations: new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getNbRelations(true),
    dismemberment: new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getIsMemberOfDismemberment() ? new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getNbDismemberment(true) : undefined,
    turnoverSum: new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getTurnoverSum(true),
    ebitdaSum: new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getEbitdaSum(true),
    capitalSum: new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getCapitalSum(true),
    valuationSum: new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getValuationSum(true),
    weasse: new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getWeasse(true),
    // weasseMax: new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getWeasseMax(true),
    extrapolatedWeasse: new PersonGetter({ data: person, errorMsg: t('common.utils.unknown') }).getExtrapolatedWeasse(true)
  }));

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <EvenOddTable<currencyFormatterProps> label='PersonList' totalCount={props.data.length} {...props} entityType='person' columns={columns} rows={rows} disableLocalSort={[...(props.disableLocalSort || []), 'tags']} />
    </Box>
  );
}

export default PersonList;
