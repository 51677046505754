import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { IconPlus } from '@tabler/icons-react';
import { ValuesBuilderSegmentationsProps } from './types.values.builders.segmentations';

function StringValuesBuilderSegmentations(props: ValuesBuilderSegmentationsProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>();

  return (
    <Stack direction='row' spacing={2} alignItems='center'>
      <TextField
        id='outlined-controlled'
        value={value}
        variant='standard'
        placeholder={`${t('segmentation.builder.filter.newValue')}`}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value);
        }}
      />
      <IconButton onClick={value ? () => {
        props.applyValue(value);
        setValue('');
      } : undefined} size='small' disabled={!value || value === ''}>
        <IconPlus size={20} />
      </IconButton>
    </Stack>

  );
}

export default StringValuesBuilderSegmentations;
