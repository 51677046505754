import React, { ReactElement, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { CompanyEntity, EntityLinksAggregation } from '@deecision/dna-interfaces';
import { IconBuildingEstate, IconDownload, IconFolderPlus } from '@tabler/icons-react';
import TitleComponent from '@deecision/common-webapp/components/title.components';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import { upperFirst } from 'lodash';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import HeaderComponents from '../../../../containers/header.containers';
import KeyValueWithCard from '../../../../components/keyvaluewithcard';
import KeyValueList, { KeyValue } from '../../../../components/keyvaluelist';
import { CompanyGetter } from '../../../../utils/getter';
import VeegilenzCard from '../../../../containers/veegilenz/card.veegilenz';
import dnaConfig from '../../../../../config/dna.config.json';
import NewsSmallCards from '../../../../components/smallcards/news.smallcards';
import { EntityLinksQueryAggregatorService } from '../../../../../api/services/query.services';
import CompanyEntitiesChips from '../../../../components/chips/entities/company.entities.chips';
import { VeegilenzCompanyGetter } from '../../../../containers/veegilenz/getter.veegilenz';

function CompanyComponent(): ReactElement {
  const data = useLoaderData() as { data : CompanyEntity };
  const { t } = useTranslation();
  const theme = useTheme();
  const linksServiceToPerson = new EntityLinksQueryAggregatorService<EntityLinksAggregation>({
    entityType: data.data.entityType,
    toEntityType: 'deecPerson'
  }); // todo: remove asap
  const companyGetter = new CompanyGetter({
    data: data.data,
    errorMsg: t('common.utils.unknown')
  });
  const veegilenzGetter = new VeegilenzCompanyGetter({ entity: data.data, linkedEntities: [], yes: t('common.utils.yes'), no: t('common.utils.no') });
  /*
  const completenessArray = [
    parseFloat(companyGetter.getValuationAccuracy().toString()),
    0
  ];
   */
  const [identity, setIdentity] = useState<KeyValue[]>([]);
  const [financial, setFinancial] = useState<KeyValue[]>([]);

  const colorOfCriticity = [
    {
      label: 'opportunity',
      color: theme.palette.success.dark,
      bgColor: theme.palette.success.light
    },
    {
      label: 'neutral',
      color: theme.palette.primary.dark,
      bgColor: theme.palette.primary.light
    },
    {
      label: 'ambiguous',
      color: theme.palette.warning.dark,
      bgColor: theme.palette.warning.light
    },
    {
      label: 'alert',
      color: theme.palette.error.dark,
      bgColor: theme.palette.error.light
    }
  ];

  useEffect(() => {
    setFinancial(data.data.dataViews?.data?.company ? [
      {
        key: t('common.financial.turnover.label'),
        value: companyGetter.getTurnover(),
        completeness: companyGetter.getTurnoverCompleteness(),
        date: companyGetter.getTurnoverDate(),
        align: 'right' as const
      },
      {
        key: t('common.financial.capital.label'),
        value: companyGetter.getCapital(),
        completeness: companyGetter.getCapitalCompleteness(),
        date: companyGetter.getCapitalDate(),
        align: 'right' as const
      },
      {
        key: t('common.financial.ebitda.label'),
        value: companyGetter.getEbitda(),
        completeness: companyGetter.getEbitdaCompleteness(),
        date: companyGetter.getEbitdaDate(),
        align: 'right' as const
      },
      {
        key: t('common.financial.valuation.label'),
        value: companyGetter.getValuation(),
        completeness: companyGetter.getValuationCompleteness(),
        date: companyGetter.getValuationDate(),
        accuracy: companyGetter.getValuationAccuracy(),
        align: 'right' as const
      }
    ] : []);

    const identityTmp = data.data.dataViews?.data?.company?.identity ? [
      { key: t('common.codes.siren'), value: companyGetter.getSiren() },
      { key: t('common.utils.activitySector'), value: companyGetter.getActivitySector() },
      { key: t('common.utils.legalForm'), value: companyGetter.getLegalForm() },
      { key: t('common.localisation.country'), value: companyGetter.getCountry() },
      { key: t('common.dates.creationDate'), value: companyGetter.getCreationDate() }
    ] : [];

    linksServiceToPerson.get(data.data.entityId, 'all', ['has.shareholder'])
      .then((res) => {
        if (res.data) {
          setIdentity([
            ...identityTmp,
            {
              key: t('common.utils.shareHolders'),
              value:
                <Stack spacing={1} alignItems='flex-end'>
                  {res.data?.map(link =>
                    <Link sx={{ textDecoration: 'none' }} key={link.entity2.entityId} href={`/persons/${link.entity2.entityId}`}>
                      <Stack direction='row' alignItems='center' spacing={2}>
                        <Typography sx={{ '&:hover': { textDecoration: 'underline' } }} variant='h5' noWrap>{link.entity2.name}</Typography>
                        <Chip label={`${link.links.find(l => l.type === 'has.shareholder')?.details?.sharesPercentage}%`} size='small' color='primary' />
                      </Stack>
                    </Link>
                  )}
                </Stack>,
              align: 'right'
            }
          ]);
        }
      })
      .catch(() => undefined);
  }, [data.data, t]);

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid>
        <TitleComponent title={data.data.name} icon={IconBuildingEstate} chips={CompanyEntitiesChips({ entity: data.data })} />
      </Grid>
      {/*
      <Grid>
        <Link href={dnaConfig.routes.companies.tabs.origeen.baseUrl}>
          <Completeness
            completeness={completenessArray.reduce((a, b) => (Number.isNaN(a) ? 0 : a) + (Number.isNaN(b) ? 0 : b), 0) / completenessArray.length}
            size='medium'
            icon={IconDatabase}
          />
        </Link>
      </Grid>
      */}
      <Grid ml='auto'>
        <Stack spacing={2} direction='row' alignItems='center'>
          <Button
            variant='text'
            startIcon={<IconDownload size={16} />}
            onClick={() => window.open(`https://data.inpi.fr/export/companies?format=pdf&ids=["${companyGetter.getSiren()}"]`, '_blank', '')}
          >
            {t('common.documents.officialIdentityReport')}
          </Button>
          <Button
            variant='contained'
            startIcon={<IconFolderPlus size={16} />}
            href={dnaConfig.routes.folders.create.steps.choices}
          >
            {t('folder.createFolder')}
          </Button>
        </Stack>
      </Grid>
      <Grid xs={12}>
        <HeaderComponents
          height={174}
          selects={[]}
          keys={[
            '',
            '',
            t('common.financial.label') || '',
            t('entities.companies.company.tabs.officialInformations') || '',
            t('entities.companies.company.tabs.news') || ''
          ]}
          links={[
            '',
            'veegilenz',
            'financial',
            'official_informations',
            'news'
          ]}
        >
          {[
            <KeyValueList key='identity' values={[identity]} />,
            <VeegilenzCard
              key='veegilenz'
              score={2}
              veegilenzDatas={{
                directIndex: veegilenzGetter.getDirectScore(),
                corporateNetwork: veegilenzGetter.getCorporateScore(),
                individualNetwork: veegilenzGetter.getIndividualScore()
              }}
            />,
            <KeyValueWithCard
              key='financial'
              values={[financial]}
              keyValueCard={
                {
                  Key: `${t('common.financial.turnover.label')}`,
                  value: companyGetter.getTurnover(),
                  size: 'big',
                  bgColor: companyGetter.getTurnoverDate() ? Math.abs(new Date(Date.now() - (companyGetter.getTurnoverDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 1 ? theme.palette.success.light : Math.abs(new Date(Date.now() - (companyGetter.getTurnoverDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 2 ? theme.palette.warning.light : theme.palette.error.light : theme.primaryColors?.light || theme.palette.primary.light,
                  color: companyGetter.getTurnoverDate() ? Math.abs(new Date(Date.now() - (companyGetter.getTurnoverDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 1 ? theme.palette.success.dark : Math.abs(new Date(Date.now() - (companyGetter.getTurnoverDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 2 ? theme.palette.warning.dark : theme.palette.error.dark : theme.palette.primary.dark
                }
              }
            />,
            <Box>
              <Grid key='criticity' container spacing={2} minWidth='256px' width='256px'>
                {colorOfCriticity.map(c => (
                  <Grid xs={6} key={c.label}>
                    <KeyValueWithCard
                      keyValueCard={{
                        bgColor: c.bgColor,
                        color: c.color,
                        Key: upperFirst(c.label),
                        width: '100%',
                        height: '42px',
                        padding: '10px 8px',
                        size: 'small',
                        value: data.data.dataViews?.data?.company?.bodacc?.stats?.countsByCriticity[c.label as keyof typeof data.data.dataViews.data.company.bodacc.stats.countsByCriticity] || '0',
                        link: 'official_informations',
                        params: `criticity=${c.label}`
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>,
            <NewsSmallCards key='news' entityType='deecCompany' />
          ]}
        </HeaderComponents>
      </Grid>
    </Grid>
  );
}

export default CompanyComponent;
