import React, { ReactElement } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { BaseQuickDashboardProps, DashboardConfig, dashboardSpacing } from './types.quick.dashboard.containers';
import QuickDashboardGroup from './group.quick.dashboard.containers';
import QuickDashboardDataflow from './dataflow.quick.dashboard.containers';
import QuickDashboardGroupSelect from './groupselect.quick.dashboard.containers';
import QuickDashboardGroupSegmentation from './groupsegmentation.quick.dashboard.containers';
import PatrimonialComplexityQuickDashboardContainers from './patrimonialcomplexity.quick.dashboard.containers';
import QuickDashboardProxemee, { getProxemeeSubGroups } from './proxemee.quick.dashboard.containers';

function QuickDashboardContainers(props: { dashboardConfig: DashboardConfig } & Omit<BaseQuickDashboardProps, 'group'>): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid container alignItems='stretch' spacing={6} mt={2}>
      {props.dashboardConfig.sections.map(section => (
        <Grid xs={section.type === 'compact' ? 12 : section.id === 'alerts' ? 'auto' : true} key={section.id} overflow='auto'>
          <Paper sx={{ bgcolor: 'transparent', width: section.id === 'alerts' ? 'fit-content' : '100%', height: '100%', borderStyle: 'dashed' }}>
            <Grid container spacing={dashboardSpacing} p={1} width={section.id === 'alerts' ? 'min-content' : '100%'}>
              {!section.noLabel &&
                <Grid>
                  <Stack spacing={dashboardSpacing} direction='row' alignItems='center' p={1} mt='-30px !important' bgcolor={theme.palette.grey['50']} width='min-content'>
                    {section.icon && <section.icon />}
                    <Typography variant='h3' whiteSpace='nowrap'>
                      {t(`segmentation.dashboard.sections.${section.id}.label`)}
                    </Typography>
                  </Stack>
                </Grid>
              }
              {section.type === 'compact' && section.groups?.map(group => (
                <Grid xs={12} key={`compact-${group.subGroupId}`} overflow='auto'>
                  <QuickDashboardDataflow {...props} group={group} />
                </Grid>
              ))}
              {section.type === 'full' &&
                ((section.groups && getProxemeeSubGroups({ subGroupId: section.id, subGroups: section.groups } ).length > 0) ?
                  <QuickDashboardProxemee full {...{ baseGroupId: props.baseGroupId, group: { subGroupId: section.id, subGroups: section.groups }, callSegmentation: props.callSegmentation }} />
                  :
                  section.groups?.map(group => (
                    <Grid xs={12} key={`full-${group.subGroupId}`} overflow='auto'>
                      {group.type === 'select' && <QuickDashboardGroupSelect {...props} group={group} />}
                      {group.type === 'segmentation' && <QuickDashboardGroupSegmentation {...props} group={group} />}
                      {(!group.type || group.type === 'parent') && <QuickDashboardGroup {...props} group={group} />}
                      {group.type === 'custom' &&
                      <>
                        {group.subGroupId === 'patrimonialComplexity' && <PatrimonialComplexityQuickDashboardContainers {...props} group={group} />}
                      </>
                      }
                    </Grid>
                  )))
              }
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

export default QuickDashboardContainers;
