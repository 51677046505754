import React, { ReactElement } from 'react';
import GetCountryFlags from '@deecision/common-webapp/components/getCountryFlags';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { Box, Divider } from '@mui/material';
import {
  Icon,
  IconExternalLink,
  IconProps
} from '@tabler/icons-react';
import Tooltip from '@mui/material/Tooltip';
import Indicator from './indicator';
import Status, { StatusProps } from './status';
import Completeness, { CompletenessProps } from './completeness';
import Accuracy, { AccuracyProps } from './accuracy';
import KeyValueDate from './date';

export type KeyValue = StatusProps & {
  id?: string,
  key: string,
  value: string | string[] | number | ReactElement | undefined,
  flags?: boolean,
  tooltip?: string,
  redirectTo?: string,
  wrap?: true,
  width?: string,
  icon?: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>,
  align?: 'left' | 'center' | 'right',
  completeness?: CompletenessProps['completeness'],
  accuracy?: AccuracyProps['accuracy'],
  date?: Date,
  indicator?: number | string,
  percentage?: number,
  selected?: true,
  color?: string,
  keyColor?: string,
  bgcolor?: string
}

export interface KeyValueListProps {
  values: KeyValue[][],
  card?: true,
  alignItems?: string
}

export interface KeyValueListRowProps {
  values: KeyValue[],
  compact?: true,
  selectKey?: (key: string) => void
}

export function KeyValueListRow(props: KeyValueListRowProps) {
  const theme = useTheme();

  return (
    <Grid
      container
      direction='row'
      spacing={0}
      minWidth={!props.compact ? '200px' : undefined}
      width='100%'
      justifyContent='space-between'
      flexWrap='nowrap'
    >
      <Grid xs>
        <Grid container spacing={2} direction='column' pr={0}>
          {props.values.map((value, index) => (value.value !== '' &&
            <React.Fragment key={`keys-${value.key}`}>
              {!props.compact && index !== 0 &&
                <Grid pb={2}><Divider /></Grid>
              }
              <Grid
                height={props.compact ? '22px' : '28px'}
                pr={4}
                onClick={() => (props.selectKey ? props.selectKey(value.id || value.key) : undefined)}
                sx={{ cursor: props.selectKey ? 'pointer' : undefined }}
              >
                {value.icon ?
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <value.icon size={16} />
                    <Typography
                      variant='body1'
                      fontWeight={400}
                      color={value.keyColor || (value.selected ? theme.palette.primary.main : theme.palette.text.secondary)}
                      noWrap
                    >
                      {value.key}
                    </Typography>
                  </Stack>
                  :
                  <Typography
                    variant='body1'
                    fontWeight={400}
                    color={value.keyColor || (value.selected ? theme.palette.primary.main : theme.palette.text.secondary)}
                    noWrap
                  >
                    {value.key}
                  </Typography>
                }
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
      <Grid>
        <Grid container spacing={2} direction='column' mt='-3px !important' pl={0}>
          {props.values.map((value, index) => (value.value !== '' &&
            <React.Fragment key={`values-${value.key}`}>
              {!props.compact && index !== 0 &&
                <Grid pb={2} pl={2} mt='-1px !important' mb='1px !important'><Divider /></Grid>
              }
              <Grid
                height={props.compact ? '22px' : typeof value.value === 'string' ? '28px' : undefined}
                pl={4}
                onClick={() => (props.selectKey ? props.selectKey(value.id || value.key) : undefined)}
                sx={{ cursor: props.selectKey ? 'pointer' : undefined }}
              >
                <Tooltip title={value.tooltip} arrow placement='right'>
                  <Stack direction='row' spacing={2} justifyContent='flex-end' alignItems='center'>
                    {
                      value.flags ?
                        <Stack direction='row' spacing={2} pt={1} pb={1}>
                          {GetCountryFlags({ countries: (value.value as string[]), size: 'small' })}
                        </Stack> :
                        typeof value.value === 'string' ?
                          value.redirectTo ?
                            <Stack
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                window.open(value.redirectTo, '_blank');
                              }}>
                              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography
                                  variant='h5'
                                  fontWeight={props.compact ? 500 : 600}
                                  color={value.selected ? theme.palette.primary.main : value.status ? theme.palette[value.status].main : value.color ||
                                    value.date ? Math.abs(new Date(Date.now() - (value.date?.getTime() || 0)).getUTCFullYear() - 1970) < 1 ? theme.palette.success.dark : Math.abs(new Date(Date.now() - (value.date?.getTime() || 0)).getUTCFullYear() - 1970) < 2 ? theme.palette.warning.dark : theme.palette.error.dark : undefined
                                  }
                                  noWrap={value.wrap ? undefined : true}
                                  width={value.width || 'min-content'}
                                  ml={value.align === 'right' ? 'auto' : undefined}
                                >
                                  {value.value || ''}
                                </Typography>
                                <IconExternalLink size={10} style={{ marginBottom: '1vh' }}/>
                              </Box>
                            </Stack> :
                            <Typography
                              variant='h5'
                              fontWeight={props.compact ? 500 : 600}
                              color={value.selected ? theme.palette.primary.main : value.status ? theme.palette[value.status].main : value.color ||
                                value.date ? Math.abs(new Date(Date.now() - (value.date?.getTime() || 0)).getUTCFullYear() - 1970) < 1 ? theme.palette.success.main : Math.abs(new Date(Date.now() - (value.date?.getTime() || 0)).getUTCFullYear() - 1970) < 2 ? theme.palette.warning.main : theme.palette.error.main : undefined
                              }
                              noWrap={value.wrap ? undefined : true}
                              width={value.width || 'min-content'}
                              ml={value.align === 'right' ? 'auto' : undefined}
                            >
                              {value.value || ''}
                            </Typography> :
                          value.value || ''
                    }
                    <KeyValueDate { ...value } key={`date-${value.key}`} />
                    <Status { ...value } key={`status-${value.key}`} />
                    <Indicator { ...value } key={`indicator-${value.key}`}  />
                    <Completeness { ...value } key={`completeness-${value.key}`}  />
                    <Accuracy { ...value } key={`accuracy-${value.key}`}  />
                  </Stack>
                </Tooltip>

                {/* <Stack direction='row' spacing={2} justifyContent='flex-end' alignItems='center'>
                  {typeof value.value === 'string' ?
                    value.redirectTo ?
                    <Stack
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        window.open(value.redirectTo, '_blank');
                      }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography
                          variant='h5'
                          fontWeight={props.compact ? 500 : 600}
                          color={value.selected ? theme.palette.primary.main : value.status ? theme.palette[value.status].main : undefined}
                          noWrap={value.wrap ? undefined : true}
                          width={value.width || 'min-content'}
                          ml={value.align === 'right' ? 'auto' : undefined}
                        >
                          {value.value || ''}
                        </Typography>
                        <IconExternalLink size={10} style={{marginBottom: '1vh'}}/>
                      </Box>
                    </Stack> :
                    <Typography
                      variant='h5'
                      fontWeight={props.compact ? 500 : 600}
                      color={value.selected ? theme.palette.primary.main : value.status ? theme.palette[value.status].main : undefined}
                      noWrap={value.wrap ? undefined : true}
                      width={value.width || 'min-content'}
                      ml={value.align === 'right' ? 'auto' : undefined}
                    >
                      {value.value || ''}
                    </Typography> :
                    value.value || ''
                  }
                  <Status { ...value } key={`status-${value.key}`} />
                  <Indicator { ...value } key={`indicator-${value.key}`}  />
                  <Completeness { ...value } key={`completeness-${value.key}`}  />
                  <Accuracy { ...value } key={`accuracy-${value.key}`}  />
                </Stack> */}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

function KeyValueList(props: KeyValueListProps): ReactElement {

  return (
    <Stack direction='row' spacing={2} width='100%' alignItems={props.alignItems}>
      {props.values.map(values => (
        <KeyValueListRow key={`keyValueList-${values.map(value => value.key).join('.')}`} values={values} compact />
      ))}
    </Stack>
  );
}

export default KeyValueList;
