import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IconChevronRight } from '@tabler/icons-react';
import { currencyFormatterProps } from '@deecision/common-webapp/utils/currencyFormatter';
import { DisplayJson } from '@deecision/common-webapp/components';
import EvenOddTable from '../../../containers/tables/evenodd/table.evenodd.tables';
import { Column } from '../../../containers/tables/types.tables';
import { DataBlocksQueryService } from '../../../../api/services/query.services';

type Dependencies = {
  type: string,
  entityRef: {
      entityType: string,
      entityId: string,
      name?: string | undefined
  },
  available: boolean,
  dataElementIds: string[],
  lastModified: string,
  featureId: string,
  needAvailableRecalc: boolean
}

function DataBlocksScreen(): ReactElement {
  const dataBlocksService = new DataBlocksQueryService({ entityType: 'deecCompany' });
  const [dataBlocks, setDataBlocks] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    dataBlocksService.findInAllEntities({})
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          // setDataBlocks(res.data);
        }
        setTotalCount(res.totalCount);
      });
  }, []);

  const columns: Column<currencyFormatterProps>[] = [
    {
      id: 'entityType',
      label: 'Entity Type'
    },
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'path',
      label: "Path"
    },
    {
      id: 'dataType',
      label: "DataType"
    },
    {
      id: 'data',
      label: "Data"
    },
    {
      id: 'voirPlus',
      label: "Voir Plus"
    }
  ];

  const rows = dataBlocks?.map(dataBlock => ({
    entityType: dataBlock.entityRef.entityType,
    id: dataBlock._id,
    name: <Link href={`../entities/${dataBlock.entityRef.entityId as string}`}
      onClick={() => {
        localStorage.setItem(`entityType${dataBlock.entityRef.entityId}`, dataBlock.entityRef.entityType);
      }}>
      {dataBlock.entityRef.name}<br/><Typography fontSize={12} fontWeight={300}>({dataBlock.entityRef.entityId})</Typography>
    </Link>,
    path: dataBlock.dataInfo.path,
    dataType: dataBlock.dataInfo.dataType,
    data:
    <Box width='300px'>
      <DisplayJson data={dataBlock.data} />
    </Box>,
    voirPlus:
    <Tooltip title={dataBlock._id} >
      <IconButton onClick={() => {
        localStorage.setItem(`entityType${dataBlock._id}`, dataBlock.entityRef.entityType);
        navigate(`${dataBlock._id}`);
      }}>
        <IconChevronRight />
      </IconButton>
    </Tooltip>
  }));

  return (
    <Grid container spacing={2} mt='1%' sx={{ flexGrow: 1 }}>
      {/* <Filter
        filterEntityTypeName={filterEntityTypeName}
        filterTypeName={filterTypeName}
        filterSchedulingStatusName={filterSchedulingStatusName}
        handleSetters={handleSetters}
        setResetting={setResetting} /> */}
      <Grid xs={12}>
        <EvenOddTable<currencyFormatterProps> label='dataBlocks' columns={columns} rows={rows || []} totalCount={totalCount}/>
      </Grid>
    </Grid>
  );
}

export default DataBlocksScreen;
