import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import SimpleDisplaySort, { SimpleSort } from '@deecision/common-webapp/components/sort.components';
import { TreeLinksProps } from '../../../components/trees/links/types.links.trees';
import TreeLinksLayer from '../../../components/trees/links/layer.links.trees';
import SimpleDisplayFilters, { SimpleFilter } from '../../../components/filters/simpledisplay.filters';

function LinksTrees(props: Omit<TreeLinksProps, 'service'>): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [filters, setFilters] = useState<SimpleFilter[]>([
    {
      label: t('filters.showActive'),
      id: 'showActive',
      active: false
    },
    {
      label: t('filters.showInactive'),
      id: 'showInactive',
      active: false
    }
  ]);
  const [sortList, setSortList] = useState<SimpleSort[]>([
    {
      id: 'name',
      label: t('filters.name'),
      direction: 'asc'
    },
    {
      id: 'shares',
      label: t('filters.shares')
    }
  ]);

  return (
    <Stack spacing={4} width='100%' height='max-content'>
      <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center'>
        <SimpleDisplayFilters filters={filters} setFilters={setFilters} />
        <SimpleDisplaySort sortList={sortList} setSortList={setSortList} />
      </Stack>
      <TreeLinksLayer
        {...props}
        filters={filters}
        sortList={sortList}
        parentEntities={[props.entity.entityId]}
      >
        <Tooltip title={props.entity.name} placement='top' arrow>
          <Paper variant='hoverElevation3' sx={{ boxShadow: 2, bgcolor: theme.palette.primary.main, pr: 4, height: 'min-content', width: 500 }}>
            <Typography variant='h5' color={theme.palette.primary.contrastText} sx={{ textAlign: 'center' }} noWrap>
              {props.entity.name}
            </Typography>
          </Paper>
        </Tooltip>
      </TreeLinksLayer>
    </Stack>
  );
}

export default LinksTrees;
