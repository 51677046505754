import { BaseService, BaseServiceProps } from '@deecision/common-webapp/api';
import { ApiArrayResponse } from '@deecision/deecision-interfaces';
import {
  EntityLinkQueryBuilder,
  EntityLinkTypes,
  EntityRef,
  IDataBlock,
  IDataElement
} from '@deecision/dna-interfaces';
import dnaConfig from '../../config/dna.config.json';
import axiosInstance from '../axios/instance.axios';

interface BaseQueryServiceProps extends Omit<BaseServiceProps, 'axiosInstance'> {
  entityType?: string
}

interface DataBlocksQueryServiceProps extends Omit<BaseQueryServiceProps, 'elementPrefixUrl'> {
  dataPath?: string | string[]
}

abstract class BaseQueryService<Data> extends BaseService<Data> {
  entityType: string | undefined;

  protected constructor(props: BaseQueryServiceProps) {
    super({ ...props, axiosInstance });
    this.entityType = props.entityType;
  }

  findByQuery(queryProps: { entityId: string | Record<string, unknown>, dataPath?: string | string[], others?: Record<string, unknown> }): Promise<ApiArrayResponse<Data>> {
    const query = {
      'entityRef.entityType': this.entityType,
      'entityRef.entityId': queryProps.entityId,
      'dataInfo.path': Array.isArray(queryProps.dataPath) ? { '$in': queryProps.dataPath } : queryProps.dataPath,
      ...queryProps.others
    };

    return this.axiosInstance.post(this.constructUrl('actions/findByQuery'), { query })
      .then(res => this.handleArrayResponse<Data>(res));
  }

  findInAllEntities(queryProps: { _id?: string | string[] | Record<string, unknown>, type?: string[] | Record<string, unknown>, schedulingStatus?: string[] | Record<string, unknown>, dataPath?: string | string[], others?: Record<string, unknown> }): Promise<ApiArrayResponse<Data>> {
    const query = {
      // 'entityRef.entityType': this.entityType,
      '_id': queryProps._id,
      ...('type' in queryProps ? { 'type': { "$in": queryProps.type } } : {}),
      ...('schedulingStatus' in queryProps ? { 'scheduling.status': { "$in": queryProps.schedulingStatus } } : {}),
      'dataInfo.path': Array.isArray(queryProps.dataPath) ? { '$in': queryProps.dataPath } :queryProps.dataPath,
      ...queryProps.others
    };

    return this.axiosInstance.post(this.constructUrl('actions/findByQuery'), { query })
      .then(res => this.handleArrayResponse<Data>(res));
  }

  findByCustomQuery(queryProps: { _id?: string | Record<string, unknown>, type?: string[] | Record<string, unknown>, schedulingStatus?: string[] | Record<string, unknown>, dataPath?: string | string[], dataType?: string | string[], others?: Record<string, unknown> }): Promise<ApiArrayResponse<Data>> {
    const query = {
      // 'entityRef.entityType': this.entityType,
      '_id': queryProps._id,
      ...('type' in queryProps ? { 'type': { "$in": queryProps.type } } : {}),
      ...('schedulingStatus' in queryProps ? { 'scheduling.status': { "$in": queryProps.schedulingStatus } } : {}),
      'dataInfo.path': Array.isArray(queryProps.dataPath) ? { '$in': queryProps.dataPath } : queryProps.dataPath,
      'dataInfo.dataType': Array.isArray(queryProps.dataType) ? { '$in': queryProps.dataType } : queryProps.dataType,
      ...queryProps.others
    };

    return this.axiosInstance.post(this.constructUrl('actions/findByQuery'), { query })
      .then(res => this.handleArrayResponse<Data>(res));
  }

  findLinks(queryProps: { query: { type?: string | Record<string, string | string[]> }, entity1: EntityRef, entity2: EntityRef, expandEntity1?: boolean, expandEntity2?: boolean }): Promise<ApiArrayResponse<Data>> {
    return this.axiosInstance.post(this.constructUrl('actions/aggregateByEntities2'), {
      query: {
        ...queryProps.query,
        ...(queryProps.entity1.entityId !== '' ? {
          'entity1.entityType': queryProps.entity1.entityType,
          'entity1.entityId': queryProps.entity1.entityId,
          'entity2.entityType': queryProps.entity2.entityType,
          'entity2.entityId': queryProps.entity2.entityId === '' ? undefined : queryProps.entity2.entityId
        } : {
          'entity1.entityType': queryProps.entity1.entityType,
          'entity1.entityId': queryProps.entity1.entityId === '' ? undefined : queryProps.entity1.entityId,
          'entity2.entityType': queryProps.entity2.entityType,
          'entity2.entityId': queryProps.entity2.entityId
        })
      },
      entity1: (queryProps.expandEntity1 !== undefined || queryProps.expandEntity2 !== undefined) ? { entityType: queryProps.entity1.entityType, shouldExpand: queryProps.expandEntity1 } : undefined,
      entity2: (queryProps.expandEntity1 !== undefined || queryProps.expandEntity2 !== undefined) ? { entityType: queryProps.entity2.entityType, shouldExpand: queryProps.expandEntity2 } : undefined
    })
      .then(res => this.handleArrayResponse<Data>(res));
  }

  aggregateEntityLinks(query: unknown): Promise<ApiArrayResponse<Data>> {
    return this.axiosInstance.post(this.constructUrl('actions/aggregateByEntities'), { query })
      .then(res => this.handleArrayResponse<Data>(res));
  }
}

export class DataBlocksQueryService<Data> extends BaseQueryService<IDataBlock<Data>> {
  dataPath?: string | string[];

  constructor(props: DataBlocksQueryServiceProps) {
    super({ elementPrefixUrl: dnaConfig.api.url.dataBlocksPrefix, ...props });
    this.dataPath = props.dataPath;
  }

  find = super.findByQuery;

  getAll() {
    return super.findByQuery({ entityId: '', dataPath: this.dataPath });
  }

  get(id: string): Promise<ApiArrayResponse<IDataBlock<Data>>> {
    return super.findByQuery({ entityId: id, dataPath: this.dataPath });
  }

  search() {
    return super.findByQuery({ entityId: '', dataPath: this.dataPath });
  }
}

export class DataElementsQueryService extends BaseQueryService<IDataElement> {
  constructor(props: Omit<BaseQueryServiceProps, 'elementPrefixUrl'>) {
    super({ elementPrefixUrl: dnaConfig.api.url.dataElementsPrefix, ...props });
  }

  getAll() {
    return super.findByQuery({ entityId: '' });
  }

  get(id: string): Promise<ApiArrayResponse<IDataElement>> {
    return super.findByQuery({ entityId: id });
  }

  findByQuery(queryProps: { entityId: string }) {
    return super.findByQuery(queryProps);
  }
}

export class EntityLinksQueryAggregatorService<T> extends BaseQueryService<T> {
  entityType: string | undefined;

  toEntityType: string | undefined;

  constructor(props: Omit<BaseQueryServiceProps, 'elementPrefixUrl'> & { toEntityType?: string }) {
    super({ elementPrefixUrl: dnaConfig.api.url.entityLinksPrefix, ...props });

    this.entityType = props.entityType;
    this.toEntityType = props.toEntityType;
  }

  getAll() {
    return super.aggregateEntityLinks(new EntityLinkQueryBuilder());
  }

  get(id?: string, active?: boolean | 'all', types?: EntityLinkTypes[]) {
    const queryBuilder = new EntityLinkQueryBuilder();
    const possibleTypes: EntityLinkTypes[] = types || [
      'has.providerEntity',
      'has.beneficialOwner',
      'has.shareholder',
      'has.investedCompany',
      'has.representative',
      'has.representedCompany',
      'has.networkPerson'
    ];

    return super.aggregateEntityLinks(id ?
      queryBuilder
        .fromEntity(this.entityType || '', id)
        .toEntityType(this.toEntityType || '')
        .withActive(active !== undefined ? active : true)
        .withTypeIn(possibleTypes)
        .build()
      : queryBuilder.build()
    );
  }

  find(entity: EntityRef, linkType?: EntityLinkTypes | Record<string, EntityLinkTypes | EntityLinkTypes[]>, expandSrc?: boolean, expandDest?: boolean, toEntity?: EntityRef) {
    return super.findLinks({
      query: {
        type: linkType
      },
      entity1: (this.entityType || entity.entityType || 'deecPerson') === 'deecPerson' ? entity : { entityType: (this.toEntityType || toEntity?.entityType || 'deecCompany'), entityId: toEntity?.entityId || '' },
      entity2: (this.entityType || entity.entityType || 'deecCompany') === 'deecCompany' ? entity : { entityType: (this.toEntityType || toEntity?.entityType || 'deecPerson'), entityId: toEntity?.entityId || '' },
      expandEntity1: (this.entityType || entity.entityType) === 'deecPerson' ? expandSrc : expandDest,
      expandEntity2: (this.entityType || entity.entityType) === 'deecCompany' ? expandSrc : expandDest
    });
  }
}
