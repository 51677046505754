import { DataElementDataStatus, IDataElement } from '@deecision/dna-interfaces';
import { newsDataPaths } from './news.tabs.components';

type TabsFunctionsReturnType = ('visible' | 'hidden' | 'disabled');

function findDataBlockForTabDisplay(props: { dataElements: IDataElement[], dataBlockRequired: string[] }): Promise<TabsFunctionsReturnType> {
  const statuses = props.dataElements
    .filter(dataElement => dataElement.provides.dataBlocks.some(dataBlock => props.dataBlockRequired.includes(dataBlock.path)))
    .map(dataElement => dataElement.dataInfo.status);

  return new Promise((res) => {
    if (statuses.includes(DataElementDataStatus.AVAILABLE)) {
      res('visible' as const);
    } else {
      res('disabled' as const);
    }
  });
}

export function bodaccFunction(props: { id: string, data?: unknown }): Promise<TabsFunctionsReturnType> {
  return findDataBlockForTabDisplay({ dataElements: props.data as IDataElement[], dataBlockRequired: ['company.bodacc.publication'], ...props });
}

export function newsFunction(props: { id: string, data?: unknown }): Promise<TabsFunctionsReturnType> {
  return findDataBlockForTabDisplay({ dataElements: props.data as IDataElement[], dataBlockRequired: newsDataPaths, ...props });
}

export function financialFunction(props: { id: string, data?: unknown }): Promise<TabsFunctionsReturnType> {
  return findDataBlockForTabDisplay({ dataElements: props.data as IDataElement[], dataBlockRequired: ['company.financialReportDetails'], ...props });
}
