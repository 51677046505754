import React, { ReactElement, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { IDataBlock, FinancialReportDataBlock } from '@deecision/dna-interfaces';
import Stack from '@mui/material/Stack';
import { LocalDate } from '@js-joda/core';
import { get, uniqWith, upperFirst } from 'lodash';
import { currencyFormatter } from '@deecision/common-webapp/utils';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {
  IconChartHistogram, IconChevronDown, IconCircleX,
  IconDownload,
  IconExternalLink,
  IconFilePlus, IconTable
} from '@tabler/icons-react';
import Tooltip from '@mui/material/Tooltip';
// eslint-disable-next-line no-restricted-imports
import { deepClone } from '@mui/x-data-grid/utils/utils';
import Paper from '@mui/material/Paper';
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Dashboard from '../../../../../containers/dashboard/entity.dashboard.containers';
import KeyValueCardComponent from '../../../../../components/cards/keyvalue.cards';
import Indicator from '../../../../../components/indicator';

function FinancialDataSort( a: IDataBlock<FinancialReportDataBlock>, b: IDataBlock<FinancialReportDataBlock> ) {
  if (!a.data.closingDate) {
    return -1;
  }
  if (!b.data.closingDate) {
    return 1;
  }

  const d1 = LocalDate.parse(a.data.closingDate);
  const d2 = LocalDate.parse(b.data.closingDate);

  if ( d1.isAfter(d2) ) {
    return -1;
  }
  if ( d2.isAfter(d1) ) {
    return 1;
  }

  return 0;
}

function FinancialActions(props: { onlyFolder?: true }): ReactElement {
  const { t } = useTranslation();

  return (
    <Stack spacing={1} direction='row' alignItems='center'>
      {!props.onlyFolder &&
        <>
          <Tooltip title={t('common.utils.openLink')} arrow placement='top'>
            <Button
              variant='icon'
              size='small'
              sx={{
                mr: '-4px',
                transform: 'scale(0.8)'
              }}
            >
              <IconExternalLink size={22} />
            </Button>
          </Tooltip>
          <Tooltip title={t('common.utils.downloadFile')} arrow placement='top'>
            <Button
              variant='icon'
              size='small'
              sx={{
                transform: 'scale(0.8)'
              }}
            >
              <IconDownload size={22} />
            </Button>
          </Tooltip>
        </>
      }
      <Button
        variant='contained'
        sx={{
          ml: '8px !important'
        }}
        startIcon={
          <IconFilePlus size={16} />
        }
      >
        {t('folder.addSelectedElementsToMyFolder')}
      </Button>
    </Stack>
  );
}

function FinancialGrid(props: { years: string[], datas: IDataBlock<FinancialReportDataBlock>[], selectedKey: string[], setSelectedKey: (keyPath: string[]) => void }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const [pathListObj, setPathListObj] = useState<Record<string, string[]>>({});
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    let pathListTmp: typeof pathListObj = {};

    props.datas.forEach((data) => {
      if (data.data.details) {
        Object.keys(data.data.details).forEach((key) => {
          if (!Object.keys(pathListTmp).find(k => k === key)) {
            pathListTmp = { ...pathListTmp, [key]: [] };
          }
          if (get(data.data.details, key)) {
            Object.keys(get(data.data.details, key)).forEach((subKey) => {
              if (!pathListTmp[key].find(sk => sk === `${key}.${subKey}`)) {
                pathListTmp[key] = [ ...pathListTmp[key], `${key}.${subKey}` ];
              }
            });
          }
        });
      }
    });

    setPathListObj(pathListTmp);
  }, [props.datas]);

  useEffect(() => {
    let listTmp: string[] = [];

    Object.keys(pathListObj).forEach((key) => {
      listTmp = [ ...listTmp, ...pathListObj[key] ];
    });

    setSelected(listTmp);
  }, [pathListObj]);

  return (
    <Box sx={{ width: '100%', pl: 0, pr: 0, pt: 1, border: `solid 1px ${theme.palette.grey['500']}`, borderRadius: `${theme.shape.borderRadius}px` }}>
      <Stack spacing={4}>
        <Stack direction='row' spacing={2} width='100%' alignItems='center' pl={2}>
          <IconTable size={20} />
          <Typography variant='h3'>
            {t('common.financial.financialGrid')}
          </Typography>
          <Box ml='auto !important' p={2} pt={1} pb={0}>
            <FinancialActions onlyFolder />
          </Box>
        </Stack>
        {Object.keys(pathListObj).map(key => (
          <TableContainer key={key}>
            <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
              <TableHead>
                <TableRow>
                  <TableCell width='40px' sx={{ pl: 1, pr: 1 }}>
                    <Checkbox
                      checked={pathListObj[key].every(subKey => selected.includes(subKey))}
                      indeterminate={!pathListObj[key].every(subKey => selected.includes(subKey)) && pathListObj[key].some(subKey => selected.includes(subKey))}
                      onChange={() => {
                        if (pathListObj[key].every(subKey => selected.includes(subKey))) {
                          setSelected(prevState => prevState.filter(subKey => !pathListObj[key].includes(subKey)));
                        } else if (pathListObj[key].some(subKey => selected.includes(subKey))) {
                          setSelected(prevState => [...prevState, ...pathListObj[key].filter(subKey => !prevState.includes(subKey))]);
                        } else {
                          setSelected(prevState => [...prevState, ...pathListObj[key]]);
                        }
                      }}
                      size='small'
                    />
                  </TableCell>
                  <TableCell width='340px' sx={{ pl: 1 }}>
                    <Typography variant='h5'>
                      {`${key.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() || ''}${key.replace(/([A-Z])/g, " $1").slice(1) || ''}`}
                    </Typography>
                  </TableCell>
                  {props.years.map(year => (
                    <TableCell key={`columnLabel-${year}`} align='right' width='160px'>
                      <Tooltip title={props.datas.find(d => year === LocalDate.parse(d.data.closingDate).year().toString())?.data.balanceSheetType} arrow placement='right'>
                        <Typography variant='h5'>
                          {year}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {pathListObj[key].map(subKey => (
                  <TableRow
                    key={`row-${subKey}`}
                    sx={{
                      bgcolor: props.selectedKey.includes(subKey) ? theme.palette.primary.light : undefined,
                      '&:last-child td, &:last-child th': {
                        border: 0,
                        borderRadius: 0
                      },
                      '&:hover': {
                        cursor: 'pointer',
                        bgcolor: props.selectedKey.includes(subKey) && theme.primaryColors?.light || theme.palette.grey['200']
                      }
                    }}
                  >
                    <TableCell sx={{ pl: 1, pr: 1 }}>
                      <Checkbox
                        key={`${subKey}-${selected.includes(subKey)}`}
                        checked={selected.includes(subKey)}
                        onChange={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelected(prevState => (prevState.includes(subKey) ? prevState.filter(f => f !== subKey) : [...prevState, subKey]));
                        }}
                        size='small'
                      />
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{ pl: 1 }}
                      onClick={() => props.setSelectedKey(props.selectedKey.find(k => k === subKey) ? props.selectedKey.filter(k => k !== subKey) : [...props.selectedKey, subKey])}
                    >
                      <Typography
                        variant='body1'
                        fontWeight={400}
                        color={theme.palette.text.secondary}
                      >
                        {`${subKey.split('.')[1]?.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() || ''}${subKey.split('.')[1]?.replace(/([A-Z])/g, " $1").slice(1) || ''}`}
                      </Typography>
                    </TableCell>
                    {props.years.map(year => (
                      <TableCell
                        key={`cell-${subKey}-${year}`}
                        align='right'
                        onClick={() => props.setSelectedKey(props.selectedKey.find(k => k === subKey) ? props.selectedKey.filter(k => k !== subKey) : [...props.selectedKey, subKey])}
                      >
                        <Stack direction='row' spacing={2} alignItems='center' justifyContent='flex-end'>
                          <Typography
                            variant='body1'
                          >
                            {
                              currencyFormatter({
                                value: get(props.datas.find(d => year === LocalDate.parse(d.data.closingDate).year().toString())?.data.details, subKey),
                                currency: props.datas.find(d => year === LocalDate.parse(d.data.closingDate).year().toString())?.data.currencyCode
                              })
                            }
                          </Typography>
                          <Indicator
                            indicator={
                              get(props.datas.find(d => year === LocalDate.parse(d.data.closingDate).year().toString())?.data.details, subKey) && get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === LocalDate.parse(d.data.closingDate).year().toString())?.data.details, subKey) ?
                                parseFloat(get(props.datas.find(d => year === LocalDate.parse(d.data.closingDate).year().toString())?.data.details, subKey) || '0') - parseFloat(get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === LocalDate.parse(d.data.closingDate).year().toString())?.data.details, subKey) || '0')
                                : t('common.utils.unknown') || ''
                            }
                            percentage={
                              get(props.datas.find(d => year === LocalDate.parse(d.data.closingDate).year().toString())?.data.details, subKey) && get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === LocalDate.parse(d.data.closingDate).year().toString())?.data.details, subKey) ?
                                (100 / parseFloat(get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === LocalDate.parse(d.data.closingDate).year().toString())?.data.details, subKey) || '0') * parseFloat(get(props.datas.find(d => year === LocalDate.parse(d.data.closingDate).year().toString())?.data.details, subKey) || '0') - 100)
                                : undefined
                            }
                          />
                        </Stack>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ))}
      </Stack>
    </Box>
  );
}

function FinancialYearList(props: { years: string[], datas: IDataBlock<FinancialReportDataBlock>[], selectedKey: string[], setSelectedKey: (keyPath: string[]) => void }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const [selected, setSelected] = useState<Array<{ year: string, elements: string[] }>>(props.years.map(year => ({ year, elements: [] })));

  useEffect(() => {
    const selectedTmp: typeof selected = [];

    props.years.forEach((year) => {
      const data = props.datas.find(d => year === LocalDate.parse(d.data.closingDate).year().toString());

      if (data?.data.details) {
        selectedTmp.push({ year, elements: Object.keys(data.data.details) });
      }
    });

    setSelected(selectedTmp);
  }, [props]);

  return (
    <Dashboard
      elements={
        props.years.map((year) => {
          const data = props.datas.find(d => year === LocalDate.parse(d.data.closingDate).year().toString());
          const month = LocalDate.parse(data?.data.closingDate || '').month().toString().toLowerCase();

          return (data?.data?.details &&
            {
              id: year,
              type: 'block',
              label: `${t(`common.month.${month}`)} ${year} - ${upperFirst(data.data.balanceSheetType)}`,
              actions: <FinancialActions />,
              widgets: Object.keys(data.data.details).map(key => (Object.keys(get(data.data.details, key)).length > 0 &&
                <KeyValueCardComponent
                  id={key}
                  title={key.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + key.replace(/([A-Z])/g, " $1").slice(1)}
                  bgColor={theme.palette.grey['200']}
                  border={theme.palette.grey['500']}
                  select={() => setSelected((prevState) => {
                    const stateTmp: typeof prevState = deepClone(prevState.filter(y => y.year !== year));
                    let elementsTmp: string[] = deepClone(prevState.find(y => y.year === year)?.elements || []);

                    if (elementsTmp.find(element => element === key)) {
                      elementsTmp = elementsTmp.filter(element => element !== key);

                      return [...stateTmp, { year, elements: elementsTmp }];
                    }
                    elementsTmp.push(key);

                    return [...stateTmp, { year, elements: elementsTmp }];
                  })}
                  selected={selected.find(y => y.year === year)?.elements.find(element => element === key)}
                  cardParts={{
                    mainValue: {
                      key: '',
                      value: ''
                    },
                    values: Object.keys(get(data.data.details, key)).map(detailsKey => ({
                      id: detailsKey,
                      key: detailsKey.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + detailsKey.replace(/([A-Z])/g, " $1").slice(1),
                      value: currencyFormatter({ value: get(data.data.details, `${key}.${detailsKey}`), currency: data.data.currencyCode }) || '',
                      align: 'right',
                      indicator: (
                        get(data.data.details, `${key}.${detailsKey}`) && get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === LocalDate.parse(d.data.closingDate).year().toString())?.data.details, `${key}.${detailsKey}`) ?
                          parseFloat(get(data.data.details, `${key}.${detailsKey}`) || '0') - parseFloat(get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === LocalDate.parse(d.data.closingDate).year().toString())?.data.details, `${key}.${detailsKey}`) || '0')
                          : t('common.utils.unknown') || ''
                      ),
                      percentage: (
                        get(data.data.details, `${key}.${detailsKey}`) && get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === LocalDate.parse(d.data.closingDate).year().toString())?.data.details, `${key}.${detailsKey}`) ?
                          (100 / parseFloat(get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === LocalDate.parse(d.data.closingDate).year().toString())?.data.details, `${key}.${detailsKey}`) || '0') * parseFloat(get(data.data.details, `${key}.${detailsKey}`) || '0') - 100)
                          : undefined
                      ),
                      selected: !!props.selectedKey.find(k => k === `${key}.${detailsKey}`) || undefined
                    })),
                    selectKey: (myKey: string) => props.setSelectedKey(props.selectedKey.find(k => k === `${key}.${myKey}`) ? props.selectedKey.filter(k => k !== `${key}.${myKey}`) : [...props.selectedKey, `${key}.${myKey}`])
                  }}
                />
              )).map(widget => (
                widget ? {
                  id: widget.props.id,
                  type: 'widget',
                  component: widget
                } : false
              ))
            }
          );
        })
      }
    />
  );
}

function FinancialChartBar(props: { years: string[], datas: IDataBlock<FinancialReportDataBlock>[], selectedKey: string[], setSelectedKey: (keyPath: string[]) => void  }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const [lineChart, setLineChart] = useState(true);
  const [barChart, setBarChart] = useState(true);
  const [data, setData] = useState(props.years.map((year) => {
    const obj: Record<string, string> = {
      name: year
    };

    props.selectedKey.forEach((selectedKey) => {
      obj[`${selectedKey.split('.')[1]?.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() || ''}${selectedKey.split('.')[1]?.replace(/([A-Z])/g, " $1").slice(1) || ''}`] =
      get(props.datas.find(d => year === LocalDate.parse(d.data.closingDate).year().toString())?.data.details, selectedKey);
    });

    return obj;
  }));

  useEffect(() => {
    setData(props.years.map((year) => {
      const obj: Record<string, string> = {
        name: year
      };

      props.selectedKey.forEach((selectedKey) => {
        obj[`${selectedKey.split('.')[1]?.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() || ''}${selectedKey.split('.')[1]?.replace(/([A-Z])/g, " $1").slice(1) || ''}`] =
          get(props.datas.find(d => year === LocalDate.parse(d.data.closingDate).year().toString())?.data.details, selectedKey);
      });

      return obj;
    }));
  }, [props]);

  return (
    <Paper variant='hoverElevation1' sx={{ width: '100%' }}>
      <Stack spacing={4}>
        <Stack direction='row' spacing={2} p={1} alignItems='center'>
          <IconChartHistogram size={20} />
          <Typography variant='h3'>
            {t('common.utils.preview')}
          </Typography>
          <Stack direction='row' spacing={2} pr={2} ml='auto !important'>
            <FormGroup>
              <FormControlLabel
                labelPlacement='start'
                control={<Switch checked={barChart} onChange={() => setBarChart(prevState => !prevState)} size='small' />}
                label={t('charts.bar')}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                labelPlacement='start'
                control={<Switch checked={lineChart} onChange={() => setLineChart(prevState => !prevState)} size='small' />}
                label={t('charts.line')}
              />
            </FormGroup>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={2} p={2} alignItems='center'>
          {props.selectedKey.map(filter => (
            filter &&
            <Chip
              key={filter}
              label={`${filter.split('.')[1].replace(/([A-Z])/g, " $1").charAt(0).toUpperCase()}${filter.split('.')[1].replace(/([A-Z])/g, " $1").slice(1)}`}
              size='small'
              onDelete={() => props.setSelectedKey(props.selectedKey.filter(f => f !== filter))}
              deleteIcon={<IconCircleX size={18} />}
            />
          ))}
        </Stack>
        <Box p={2} sx={{ minWidth: '720px', width: '100%', height: '400px' }}>
          <ResponsiveContainer width='100%' height='100%'>
            <ComposedChart
              width={600}
              height={400}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis yAxisId='left' orientation='left' tickFormatter={(value, index) => (currencyFormatter({ value, currency: props.datas[index]?.data.currencyCode }) === 'unknown' ? '0' : currencyFormatter({ value, currency: props.datas[index]?.data.currencyCode }) || value)} stroke={theme.palette.primary.main} />
              <YAxis yAxisId='right' orientation='right' tickFormatter={(value, index) => (currencyFormatter({ value, currency: props.datas[index]?.data.currencyCode }) === 'unknown' ? '0' : currencyFormatter({ value, currency: props.datas[index]?.data.currencyCode }) || value)} stroke={theme.palette.secondary.main} />
              <ChartTooltip formatter={value => (currencyFormatter({ value: parseFloat(`${value}`), currency: props.datas[0].data.currencyCode }) === 'unknown' ? '0' : currencyFormatter({ value: parseFloat(`${value}`), currency: props.datas[0].data.currencyCode }) || value)} />
              <Legend />
              {props.selectedKey.map((selectedKey, index) => (
                <React.Fragment key={selectedKey}>
                  {barChart &&
                    <Bar
                      yAxisId={index % 2 ? 'right' : 'left'}
                      dataKey={`${selectedKey.split('.')[1]?.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() || ''}${selectedKey.split('.')[1]?.replace(/([A-Z])/g, " $1").slice(1) || ''}`}
                      barSize={24}
                      fill={index % 2 ? theme.palette.secondary.main : theme.palette.primary.main}
                    />
                  }
                  {lineChart &&
                    <Line
                      yAxisId={index % 2 ? 'right' : 'left'}
                      dataKey={`${selectedKey.split('.')[1]?.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() || ''}${selectedKey.split('.')[1]?.replace(/([A-Z])/g, " $1").slice(1) || ''}`}
                      type='monotone'
                      stroke={index % 2 ? theme.palette.secondary.main : theme.palette.primary.main}
                    />
                  }
                </React.Fragment>
              ))}
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
      </Stack>
    </Paper>
  );
}

function FinancialTabsComponents(): ReactElement {
  const data = useLoaderData() as { data: IDataBlock<FinancialReportDataBlock>[] };
  const [years, setYears] = useState<string[]>([]);
  const [sortedData, setSortedData] = useState<IDataBlock<FinancialReportDataBlock>[]>([]);
  const [reducedData, setReducedData] = useState<IDataBlock<FinancialReportDataBlock>[]>([]);
  const [selectedKey, setSelectedKey] = useState(['profitAndLossAccount.turnover']);
  const [view, setView] = useState('grid');

  const selectChevron = (params: Record<string, unknown>) => <IconChevronDown size={14} {...params} />;

  useEffect(() => {
    setSortedData(uniqWith(data.data, (a, b) => (a.data.documentId === b.data.documentId)).sort(FinancialDataSort));
  }, [data]);

  useEffect(() => {
    const yearsTmp: string[] = [];

    sortedData.forEach((d) => {
      if (d.data.closingDate && !yearsTmp.find(year => d.data.closingDate && year === LocalDate.parse(d.data.closingDate).year().toString())) {
        yearsTmp.push(LocalDate.parse(d.data.closingDate).year().toString());
      }
    });
    setYears(yearsTmp);
  }, [sortedData]);

  useEffect(() => {
    const dataTmp: IDataBlock<FinancialReportDataBlock>[] = [];

    years.forEach((year) => {
      const dataPerYear = sortedData.filter(d => d.data.closingDate && year === LocalDate.parse(d.data.closingDate).year().toString());

      const dataForThisYear = dataPerYear.find(d => d.data.balanceSheetType === 'full') ||
        dataPerYear.find(d => d.data.balanceSheetType === 'simplified') ||
        dataPerYear.find(d => d.data.balanceSheetType === 'consolidated');

      if (dataForThisYear) {
        dataTmp.push(dataForThisYear);
      }
    });

    setReducedData(dataTmp);
  }, [years]);

  return (
    <Stack spacing={4}>
      <FinancialChartBar years={years.sort((a, b) => (parseInt(a) - parseInt(b)))} datas={reducedData} selectedKey={selectedKey} setSelectedKey={setSelectedKey} />
      <Stack direction='row' spacing={2} width='100%'>
        <FormControl size='small' sx={{ ml: 'auto !important' }}>
          <Select
            labelId='financial-view-selector-label'
            id='financial-view-selector'
            IconComponent={selectChevron}
            value={view}
            onChange={event => setView(event.target.value)}
            input={
              <OutlinedInput
                sx={{
                  color: 'text.primary',
                  borderColor: 'grey.500',
                  borderRadius: 2
                }}
              />
            }
          >
            <MenuItem
              key='grid'
              value='grid'
            >
              Grid
            </MenuItem>
            <MenuItem
              key='perYear'
              value='perYear'
            >
              Per Year
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
      {view === 'perYear' &&
        <FinancialYearList years={years} datas={reducedData} selectedKey={selectedKey} setSelectedKey={setSelectedKey} />
      }
      {view === 'grid' &&
        <FinancialGrid years={years} datas={reducedData} selectedKey={selectedKey} setSelectedKey={setSelectedKey} />
      }
    </Stack>
  );
}

export default FinancialTabsComponents;
