import React from 'react';
import { Outlet } from 'react-router-dom';
import { IconSearch } from '@tabler/icons-react';
import { CustomRouteObject } from '@deecision/common-webapp/router';
import { routes as routesPersons } from './persons';
import { routes as routesCompanies } from './companies';
import { Search } from './search';
import { routes as routesRelations } from './relations';

const routesEntities: CustomRouteObject[] = [
  {
    i18nKey: 'search.label',
    path: 'search',
    element: <Outlet />,
    handle: {
      icon: IconSearch,
      sectionTitle: 'entities.label'
    },
    children: [
      {
        index: true,
        element: <Search />
      }
    ]
  },
  routesRelations,
  routesPersons,
  routesCompanies
];

export default routesEntities;
