import React, { ReactElement, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { sortBy } from 'lodash';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import IconButton from '@mui/material/IconButton';
import { IconColumns3, IconDotsVertical, IconRotate } from '@tabler/icons-react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Tooltip } from '@mui/material';
import { StyledTableCell, StyledTableRow } from './styled.evenodd.tables';
// eslint-disable-next-line import/no-named-as-default
import EvenOddHead, { EvenOddHeadProps, LocalSort } from './head.evenodd.tables';
import { BaseValues, Column, Row, Values } from '../types.tables';
import EvenOddFooter from './footer.evenodd.tables';
import { getTableStorage, setTableStorage } from '../localStorage.tables';

export interface ImplementColumn<T> {
  columnIndex?: number,
  column?: Column<T>,
  rows: (Row<T> & {
    id: string,
    columnId: string,
    value: Values<T>
  })[]
}

export type EvenOddTableProps<T> = Omit<EvenOddHeadProps<T>, 'setSort' | 'setColumns' | 'isHovered' | 'setIsHovered' | 'label' | 'setTest'> & {
  label: string,
  basePageSize?: number,
  actions?: ReactElement[],
  hideFooter?: true,
  entityType?: 'person' | 'company' | null,
  implementColumns?: ImplementColumn<T>[],
  actionsPadding?: string,
  labelFound?: string
}

function DndWrapper(props: {children: ReactElement}): ReactElement {
  return (
    <DndProvider
      backend={HTML5Backend}
    >
      {props.children}
    </DndProvider>
  );
}

function RenderCell<T>(props: { column: Column<T>, row: Row<T>, isHovered: {
    hover: boolean,
    hoveredCol: string,
    originHoveredCol: string
  } }): ReactElement {
  return (
    <StyledTableCell
      key={`row-${props.column.id}-${props.row.id}`}
      component='th'
      scope='row'
      align={props.column.align}
      style={{
        backgroundColor: props.isHovered.hover && props.isHovered.hoveredCol === props.column.id && props.isHovered.originHoveredCol !== props.column.id
          ? '#lightgrey'
          : undefined,
        borderRight: props.isHovered.hover && props.isHovered.hoveredCol === props.column.id ? 'dotted': 'none',
        borderLeft: props.isHovered.hover && props.isHovered.hoveredCol === props.column.id ? 'dotted': 'none',
        borderRadius: 1,
        borderColor: props.isHovered.hover && props.isHovered.hoveredCol === props.column.id ? '#E0E0E0': '',
        color: props.isHovered.hover && props.isHovered.hoveredCol === props.column.id ? 'lightgrey': '',
        whiteSpace: 'nowrap',
        maxWidth: !props.column.noMaxWidth ? 320 : undefined,
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }}
    >
      {props.column.tooltip ?<Tooltip title={props.column.format ?
        props.column.format(props.row[props.column.id] as unknown as T) :
        props.column.render ?
          props.column.render(props.row[props.column.id] as BaseValues) as Values<undefined> :
          props.row[props.column.id] as unknown as Values<undefined>
      } arrow placement='top'>
        <Box
          sx={{
            maxWidth: 320,
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {props.column.format ?
            props.column.format(props.row[props.column.id] as unknown as T) :
            props.column.render ?
              props.column.render(props.row[props.column.id] as BaseValues) as Values<undefined> :
              props.row[props.column.id] as unknown as Values<undefined>
          }
        </Box>
      </Tooltip> :
        props.column.format ?
          props.column.format(props.row[props.column.id] as unknown as T) :
          props.column.render ?
          props.column.render(props.row[props.column.id] as BaseValues) as Values<undefined> :
          props.row[props.column.id] as unknown as Values<undefined>
      }
    </StyledTableCell>
  );
}

function EvenOddTable<T>(props: EvenOddTableProps<T>) {
  const { t } = useTranslation();
  const [columns, setColumns] = useState<typeof props.columns>([]);
  const [rows, setRows] = useState(props.rows);
  const [sort, setSort] = useState<LocalSort>({ columnId: undefined, dir: false });
  const [isHovered, setIsHovered] = useState<{
    hover: boolean,
    hoveredCol: string,
    originHoveredCol: string
  }>({
    hover: false,
    hoveredCol: '',
    originHoveredCol: ''
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColumnsChange = (cols: typeof columns) => {
    const tableStorage = getTableStorage();

    delete tableStorage?.[props.label];
    setTableStorage({ ...tableStorage, [props.label]: cols.map(col => col.id) });

    setColumns(cols);
  };

  const initiate = (cols: typeof props.columns) => {
    const rowsTmp: typeof rows = [];
    const tableStorage = getTableStorage();
    const columnsOrder = tableStorage?.[props.label];
    const columnsTmp1: typeof cols = [ ...cols.filter(column => !props.hideColumns?.includes(column.id)) ];
    let columnsTmp2: typeof cols = [];

    props.rows.forEach((row) => {
      let myRow = row;

      props.implementColumns?.forEach((implCol) => {
        if (!columnsTmp1.find(col => col.id === implCol.column?.id)) {
          if (implCol.columnIndex !== undefined && implCol.column) {
            columnsTmp1.splice(implCol.columnIndex, 0, implCol.column);
          } else if (implCol.column) {
            columnsTmp1.push(implCol.column);
          }
        }
        myRow = {
          ...myRow,
          [implCol.rows.find(rowToImpl => rowToImpl.id === row.id)?.columnId || 'other']: implCol.rows.find(rowToImpl => rowToImpl.id === row.id)?.value
        };
      });
      rowsTmp.push(myRow);
    });

    if (columnsOrder && columnsTmp1.every(col => columnsOrder.includes(col.id))) {
      columnsOrder.forEach((colOrderId, index) => {
        columnsTmp2.push(columnsTmp1.find(col => col.id === colOrderId) || columnsTmp1[index]);
      });
    } else {
      columnsTmp2 = [...columnsTmp1];
    }

    setRows(rowsTmp);
    setColumns(columnsTmp2);
  };

  const resetTableColumns = () => {
    const tableStorage = getTableStorage();

    delete tableStorage?.[props.label];

    if (tableStorage) {
      setTableStorage(tableStorage);
    }
    handleClose();
    initiate(props.columns);
  };

  useEffect(() => {
    initiate(props.columns);
  }, [props.rows, props.columns, props.implementColumns]);

  useEffect(() => {
    if (sort.columnId && sort.dir) {
      const rowsTmp = sortBy(rows, `${sort.columnId}${props.columns.find(column => column.id === sort.columnId)?.sortPath ? `.${props.columns.find(column => column.id === sort.columnId)?.sortPath}` : ''}`);

      setRows(sort.dir === 'asc' ? rowsTmp.reverse() : rowsTmp);
    }
  }, [sort]);

  return (
    <Paper elevation={0} sx={{ p: 0, width: '100%', height: '100%', bgcolor: 'transparent', border: 'none', pb: rows.length < 1 ? 4 : 0 }}>
      <Stack spacing={2} height='100%'>
        <Box pl={props.actionsPadding} pr={props.actionsPadding}>
          <Paper elevation={0} sx={{ bgcolor: 'primary.light', borderRadius: 2, width: '100%' }}>
            <Stack direction='row' p={0} alignItems='center' spacing={2}>
              {!props.selected ?
                <Box ml={1} pl={2} pr={4}>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography variant='h4'>{props.totalCount || 0}</Typography>
                    <Typography variant='body1'>
                      {`${props.labelFound ? props.labelFound
                        : props.totalCount && props.totalCount > 1
                          ? props.entityType === 'person' && t('entities.persons.label') || props.entityType === 'company' && t('entities.companies.label') || t('common.utils.elements')
                          : props.entityType === 'person' && t('entities.persons.person.label') || props.entityType === 'company' && t('entities.companies.company.label') || t('common.utils.element')
                      } ${props.totalCount && props.totalCount > 1 ? t('common.utils.founds') : t('common.utils.found')}`}
                    </Typography>
                  </Stack>
                </Box> :
                <Box ml={1} pl={2} pr={4}>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography variant='h4'>{props.selected?.length || 0}</Typography>
                    <Typography variant='body1'>
                      {props.entityType === 'person' && t('entities.persons.selected') || props.entityType === 'company' && t('entities.companies.selected') || t('entities.selected')}
                    </Typography>
                  </Stack>
                </Box>
              }
              {props.actions &&
              <Stack direction='row' spacing={2}>
                {props.actions}
              </Stack>
              }
              <IconButton
                sx={{ ml: 'auto !important' }}
                size='small'
                id='menu-button'
                aria-controls={open ? 'menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <IconDotsVertical size={20} />
              </IconButton>
              <Menu
                id='menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'menu-button'
                }}
              >
                <MenuItem onClick={resetTableColumns} sx={{ p: 2 }}>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <IconColumns3 />
                    <IconRotate size={20} style={{ transform: 'rotate(180deg)' }} />
                    <Typography>
                      {`${t('common.utils.reset')} ${t('common.utils.thes')} ${t('common.utils.columns')}`}
                    </Typography>
                  </Stack>
                </MenuItem>
              </Menu>

            </Stack>
          </Paper>
        </Box>
        {rows.length > 0 &&
          <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)' }}>
            <Table stickyHeader sx={{ minWidth: 700 }} aria-label='even odd table'>
              <DndWrapper>
                <EvenOddHead
                  {...props}
                  columns={columns}
                  setSort={setSort}
                  setColumns={handleColumnsChange}
                  setTest={setColumns}
                  isHovered={isHovered}
                  setIsHovered={setIsHovered}
                />
              </DndWrapper>
              <TableBody>
                {rows.map(row => (
                  <StyledTableRow key={row.id?.toString()}>
                    {props.selected &&
                      <StyledTableCell
                        key={`row-selected-${row.id}`}
                        component='th'
                        scope='row'
                      >
                        <Checkbox
                          sx={{ height: 24, width: 24 }}
                          checked={props.selected.includes(row.id)}
                          onChange={
                            () => (props.setSelected && props.setSelected(
                              props.selected ?
                                props.selected.includes(row.id as string) ?
                                  props.selected.filter(s => s !== row.id)
                                  : [...props.selected, row.id]
                                : [row.id]
                            ))
                          }
                        />
                      </StyledTableCell>
                    }
                    {columns.map(column => (
                      <RenderCell column={column} row={row} isHovered={isHovered} />
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        {rows.length > 0 && !props.hideFooter &&
          <TableContainer sx={{ overflow: 'inherit' }}>
            <Table sx={{ minWidth: 700 }} aria-label='table footer'>
              {props.totalCount &&
                <EvenOddFooter {...props} totalCount={props.totalCount} />
              }
            </Table>
          </TableContainer>
        }
      </Stack>
    </Paper>
  );
}

export default EvenOddTable;
