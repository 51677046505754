import React, { ReactElement } from 'react';
import { PersonEntity } from '@deecision/dna-interfaces';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IconBuildings from '@deecision/common-webapp/assets/custom/IconBuildings';
import { IconIdBadge2, IconUsers } from '@tabler/icons-react';
import Dashboard from '../../../../../containers/dashboard/entity.dashboard.containers';
import CompaniesForPersonWidgets from '../../../../../components/widgets/companiesforperson.widgets';
import IdentityWidgets from '../../../../../components/widgets/identity.widgets';
import PersonsforpersonWidgets from '../../../../../components/widgets/personsforperson.widgets';

function DashboardTabsComponents(): ReactElement {
  const { data } = useOutletContext() as { data: PersonEntity };
  const { t } = useTranslation();

  return (
    <Dashboard
      elements={[
        {
          id: 'identityBlock',
          type: 'block',
          orientation: 'vertical',
          icon: <IconIdBadge2 size={20} />,
          label: t('common.identity.identity'),
          widgets: IdentityWidgets({ data }).map(widget => (
            widget ? {
              id: widget.key || widget.props.id,
              type: 'widget',
              component: widget
            } : false
          ))
        },
        {
          id: 'companiesBlock',
          type: 'block',
          icon: <IconBuildings size={20} />,
          label: t('entities.companies.label'),
          widgets: CompaniesForPersonWidgets({ data }).map(widget => (
            widget ? {
              id: widget.key || widget.props.id,
              largeWidget: widget.key === 'companiesList' || undefined,
              minWidth: widget.key === 'companiesList' ? '500px' : undefined,
              type: 'widget',
              component: widget
            } : false
          ))
        },
        {
          id: 'relationsBlock',
          type: 'block',
          icon: <IconUsers size={20} />,
          label: t('entities.persons.alterLabels.relations'),
          widgets: PersonsforpersonWidgets({ data }).map(widget => (
            widget ? {
              id: widget.key || widget.props.id,
              largeWidget: widget.key === 'personsList' || undefined,
              minWidth: widget.key === 'personsList' ? '500px' : undefined,
              type: 'widget',
              component: widget
            } : false
          ))
        }
      ]}
    />
  );
}

export default DashboardTabsComponents;
