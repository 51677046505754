import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { CompanyEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import { IconDatabase, IconHome, IconRefresh } from '@tabler/icons-react';
import GetCountryFlags from '@deecision/common-webapp/components/getCountryFlags';
import { upperFirst } from 'lodash';
import Typography from '@mui/material/Typography';
import { CompanyGetter } from '../../../utils/getter';

function CompanyEntitiesChips(props: { entity: CompanyEntity, size?: 'small' | 'medium' }): ReactElement[] {
  const { t } = useTranslation();
  const [entity, setEntity] = useState<CompanyEntity>(props.entity as CompanyEntity);
  const [chips, setChips] = useState<ReactElement[]>([]);
  const companyGetter = useMemo(() => new CompanyGetter({
    data: entity,
    errorMsg: t('common.utils.unknown')
  }), [entity]);

  useEffect(() => {
    setEntity(props.entity as CompanyEntity);
  }, [props.entity]);

  useEffect(() => {
    const chipsTmp: typeof chips = [
      <Tooltip
        key='common.identity.country'
        title={`${t('common.identity.country')}: ${companyGetter.getCountry()}`}
        arrow
        placement='top'
      >
        <Chip
          label={
            <Stack direction='row' height='20px' spacing={1} alignItems='center' divider={<Divider orientation='vertical' />} sx={{ mr: 1 }}>
              {GetCountryFlags({ countries: [companyGetter.getCountryCode(true) || ''], size: props.size })}
            </Stack>
          }
          icon={<IconHome size={props.size === 'small' ? 18 : 22} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
          size={props.size}
        />
      </Tooltip>,
      <Chip
        key='state'
        label={upperFirst(companyGetter.getState())}
        color={companyGetter.getState() === 'open' ? 'success' : 'error'}
        size={props.size}
      />,
      <Tooltip title={t('common.utils.completeness')} arrow placement='top'>
        <Chip
          key='completion'
          label={
            <Stack direction='row' spacing={1} alignItems='center'>
              <IconDatabase size={20} />
              <Typography>{`${Math.floor(Math.random() * (55 - 35) + 35)}%`}</Typography>
            </Stack>
          }
          color='primary'
          size={props.size}
        />
      </Tooltip>,
      <Tooltip
        key='common.dates.lastUpdate'
        title={t('common.dates.lastUpdate')}
        arrow
        placement='top'
      >
        <Chip
          label={companyGetter.getDataDate()}
          icon={<IconRefresh size={props.size === 'small' ? 18 : 22} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
        />
      </Tooltip>
    ];

    if (entity.tags?.find(tag => tag.family === 'companyType' && tag.value === 'listedCompany')) {
      chipsTmp.push(
        <Tooltip title={t('entities.tags.listed.company')} placement='top' arrow>
          <Chip
            label='L'
            sx={{
              p: 2,
              '& .MuiChip-label': {
                fontWeight: '600'
              }
            }}
            color='error'
            size={props.size}
          />
        </Tooltip>
      );
    }

    setChips(chipsTmp);
  }, [companyGetter]);

  return (chips);
}

export default CompanyEntitiesChips;
