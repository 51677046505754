import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { IconPlus } from '@tabler/icons-react';
import { Slider } from '@mui/material';
import { ValuesBuilderSegmentationsProps } from './types.values.builders.segmentations';

function AmountValuesBuilderSegmentations(props: ValuesBuilderSegmentationsProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState<number>();
  const [range, setRange] = useState<number[]>([0, 100]);
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(100);

  const handleChange = (event: unknown, newValue: number | number[]) => {
    setRange(newValue as number[]);
    (newValue as number[])[1] >= max && setMax((newValue as number[])[1] + 10);
    (newValue as number[])[0] <= min && setMin((newValue as number[])[0] - 10);
  };

  return (
    <Stack direction='row' spacing={2} alignItems='center' width='100%'>
      {props.valuesOrRanges === 'values' ?
        <TextField
          id='outlined-controlled'
          type='number'
          defaultValue={value}
          value={value}
          variant='standard'
          placeholder={`${t('segmentation.builder.filter.newValue')}`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(parseFloat(event.target.value));
          }}
        /> :
        <Stack direction='row' spacing={4} width='100%' alignItems='center'>
          <TextField
            type='number'
            value={range[0]}
            variant='outlined'
            placeholder={`${t('segmentation.builder.filter.newValue')}`}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(event, [parseFloat(event.target.value), range[1]]);
            }}
            size='small'
          />
          <Slider
            valueLabelDisplay='auto'
            value={range}
            onChange={handleChange}
            min={min}
            max={max}
          />
          <TextField
            type='number'
            value={range[1]}
            variant='outlined'
            placeholder={`${t('segmentation.builder.filter.newValue')}`}
            onChange={(event) => {
              handleChange(event, [range[0], parseFloat(event.target.value)]);
            }}
            size='small'
          />
        </Stack>
      }
      <IconButton
        onClick={() => {
          props.valuesOrRanges === 'values' ? value !== undefined && props.applyValue(value) : props.applyRange({ min: range[0], max: range[1] });
          setValue(undefined);
          setRange([0, 100]);
        }}
        size='small'
        disabled={props.valuesOrRanges === 'values' && value === undefined}
      >
        <IconPlus size={20} />
      </IconButton>
    </Stack>

  );
}

export default AmountValuesBuilderSegmentations;
