import React from 'react';
import createElementRoutes from '@deecision/common-webapp/router/utils/create.element.routes';
import { IconChartInfographic, IconPlus } from '@tabler/icons-react';
import { Navigate } from 'react-router-dom';
import dnaConfig from '../../../../../config/dna.config.json';
import SegmentationsBuilder from '../../../../containers/segmentations/builder/builder.segmentations';
import {
  UserDataCustomSegmentationService
} from '../../../../containers/segmentations/services/segmentations.services';

const routesSegmentationFactory = createElementRoutes({
  id: dnaConfig.routes.admin.factory.segmentation.label,
  path: dnaConfig.routes.admin.factory.segmentation.baseUrl,
  icon: IconChartInfographic,
  component: <Navigate to={dnaConfig.routes.admin.factory.segmentation.create.baseUrl} />,
  idComponent: <SegmentationsBuilder ownerId='admin' ownerType='user' />,
  IdService: new UserDataCustomSegmentationService({ userId: 'admin' }),
  childrens: [
    createElementRoutes({
      id: dnaConfig.routes.admin.factory.segmentation.create.label,
      path: dnaConfig.routes.admin.factory.segmentation.create.baseUrl,
      icon: IconPlus,
      component: <SegmentationsBuilder ownerId='admin' ownerType='user' />
    })
  ]
});

export default routesSegmentationFactory;
