import React, { ReactElement, ReactNode, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { IconCheck, IconEdit, IconX } from '@tabler/icons-react';
import { get, set } from 'lodash';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography, { TypographyOwnProps } from '@mui/material/Typography';
import { BaseItemSegmentationBuilderProps } from '../types.builder.segmentations';

function TextBuilderSegmentations(props: Omit<BaseItemSegmentationBuilderProps, 'workflow'> & { icon?: ReactNode, variant?: TypographyOwnProps['variant'] }): ReactElement {
  const theme = useTheme();
  const [titleValue, setTitleValue] = useState<string | false>(false);

  return (
    <Stack spacing={2} direction='row' alignItems='center' height={42} width='100%'>
      {props.icon}
      <Stack spacing={1} direction='row' alignItems='center'>
        {titleValue !== false ?
          <Stack spacing={1} direction='row' alignItems='center'>
            <Box
              component='form'
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' }
              }}
              noValidate
              autoComplete='off'
            >
              <TextField
                variant='standard'
                hiddenLabel
                value={titleValue}
                onClick={e => e.stopPropagation()}
                sx={{ mt: '-8px !important' }}
                autoFocus
                onChange={e => setTitleValue(e.target.value)}
              />
            </Box>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                props.setData({ ...set(props.data as object, props.path, titleValue) });
                setTitleValue(false);
              }}
            >
              <IconCheck color={theme.palette.success.main} />
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                setTitleValue(false);
              }}
            >
              <IconX />
            </IconButton>
          </Stack> :
          <Button
            endIcon={<IconEdit size={12} />}
            sx={{ cursor: 'text', textDecoration: 'none !important' }}
            onClick={(event) => {
              event.stopPropagation();
              setTitleValue(get(props.data, props.path));
            }}
          >
            <Typography variant={props.variant} textAlign='left'>{get(props.data, props.path)}</Typography>
          </Button>
        }
      </Stack>
    </Stack>
  );
}

export default TextBuilderSegmentations;
