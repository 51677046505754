import React, { ReactElement, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import { DNAFilter } from '../../../../components/form/types.form';
import SearchSubmitBtn, { SearchSubmitBtnProps } from './searchsubmitbtn.components';
import FiltersManager, { FiltersManagerProps } from '../../../../components/filters/manager.filters';

type SearchFiltersProps = Omit<FiltersManagerProps, 'filters' | 'setFilters'> & Omit<SearchSubmitBtnProps, 'btnRef'>;

function SearchFilters(props: SearchFiltersProps): ReactElement {
  const btnRef = useRef<HTMLButtonElement>(null);
  const [filters, setFilters] = useState<DNAFilter[]>([]);

  return (
    <>
      <FiltersManager {...props} filters={filters} setFilters={setFilters} />
      <Box maxWidth='800px' width='100%' alignSelf='center' pl={2} pr={2}>
        <Stack direction='row' spacing={2} justifyContent='space-between' p={2}>
          <div />
          <SearchSubmitBtn
            {...props}
            btnRef={btnRef}
            forceWithoutQuery
            disabled={!filters.some(filter => filter.value) && !props.query}
          />
        </Stack>
      </Box>
    </>
  );
}

export default SearchFilters;
