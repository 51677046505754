import React, { ReactElement, useEffect, useState } from 'react';
import { useLoaderData, useParams, useSearchParams } from 'react-router-dom';
import {
  SegmentationData, SegmentationPossibleOutputEntityTypes,
  SegmentationRequest,
  SegmentationWorkflowIds
} from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import makeFindOptions from '@deecision/common-webapp/utils/findoptions';
import { SegmentationFilter } from '@deecision/dna-interfaces/dist/segmentation/segmentationFilters';
import { cloneDeep, lowerCase } from 'lodash';
import { BaseCustomUserData } from '../../types.segmentations';
import SegmentationsServices from '../../services/segmentations.services';
import getDynamicGroupsRequest from '../../../../modules/deetect/portfolios/utils/dynamicgroups.utils.portfolios';
import EntitiesListsSegmentations from './entities.lists.segmentations';
import CustomAccordion from '../../../../components/accordion';

function SimpleSegmentationList(): ReactElement {
  const userData = useLoaderData() as { data?: BaseCustomUserData };
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const segmentationService = new SegmentationsServices();
  const [data, setData] = useState<BaseCustomUserData | undefined>(userData?.data? cloneDeep(userData?.data) : undefined);
  const [segmentationData, setSegmentationData] = useState<SegmentationData>();
  const [entryCount, setEntryCount] = useState<number>(0);
  const entityType = searchParams.get('entityType');
  const groupId = searchParams.get('groupId');
  const customGroupId = searchParams.get('customGroupId');
  const outputEntitiesFromParams = searchParams.get('outputEntities');
  const workflowIdFromParams = searchParams.get('workflowId');
  const filterIds = searchParams.get('filterIds')?.split(',');
  const filterValues = searchParams.get('filterValues')?.split(',');
  const dataSetId = (lowerCase(entityType || '')?.includes('person') || data?.data.on?.includes('person'))
    ? 'deecPersons'
    : 'deecCompanies';
  const baseFilterGroupMember: SegmentationFilter[] = id ? (customGroupId === 'true' && groupId) ? getDynamicGroupsRequest(groupId, id)?.filters || [] : [
    {
      id: dataSetId === 'deecPersons' ? 'person_groupMember' : 'company_groupMember',
      filterId: dataSetId === 'deecPersons' ? 'person_groupMember' : 'company_groupMember',
      type: 'filter',
      on: dataSetId === 'deecPersons' ? 'person1' : 'company',
      values: groupId && groupId.split(',').length > 1 ? groupId.split(',').map(uniqGroupId => `${id}/${uniqGroupId}`) : [`${id}/${groupId || (dataSetId === 'deecPersons' ? 'persons' : 'companies')}`]
    }
  ] : [];
  const baseFiltersFromUrlParams: SegmentationFilter[] = filterIds && filterValues ? filterIds.map((filterId, index) => ({
    id: filterId,
    filterId,
    type: 'filter',
    on: dataSetId === 'deecPersons' ? 'person1' : 'company',
    values: [filterValues[index] === "true" ? true : filterValues[index] === "false" ? false : filterValues[index]]
  })) : [];

  const performSegmentation = ({ outputEntities, dataUpdated, workflowId }: { outputEntities: SegmentationPossibleOutputEntityTypes, dataUpdated?: typeof data, workflowId: SegmentationWorkflowIds }) => {
    const dataTmp = dataUpdated || data;

    segmentationService.perform({
      dataSetId,
      outputEntities: outputEntitiesFromParams as SegmentationRequest['outputEntities'] || outputEntities,
      workflowId: workflowIdFromParams as SegmentationRequest['workflowId'] || workflowId,
      filters: [
        ...baseFilterGroupMember,
        ...baseFiltersFromUrlParams,
        ...(dataTmp?.data.preFilters as SegmentationRequest['filters'] || [])
      ],
      globalFilteringItems: [],
      segmentationCriterias: [],
      entitiesSettings: {
        includeEntities: true,
        findOptions: makeFindOptions({ searchParams, post: true })
      }
    })
      .then((res) => {
        setSegmentationData(res.data);
        setData(dataTmp);
        if (!entryCount && res.data) {
          setEntryCount(res.data.totalCount);
        }
      });
  };

  useEffect(() => {
    performSegmentation({
      outputEntities: 'person1',
      workflowId: (lowerCase(entityType || '')?.includes('person') || data?.data.on?.includes('person')) ?'person1_link_person2' : 'simple_company'
    });
  }, [id, searchParams]);

  return (
    <CustomAccordion
      title={`${segmentationData?.totalCount || 0} ${(segmentationData?.totalCount || 0) > 1 ? t('entities.persons.label') : t('entities.persons.person.label')}`}
      defaultOpen
      disabled={!segmentationData?.totalCount}
    >
      {segmentationData &&
        <EntitiesListsSegmentations
          data={segmentationData}
          entityType={{ deecPerson: 'person' as const, deecCompany: 'company' as const, p2pNetworkLink: 'person' as const }[segmentationData.objectType]}
          enableSelect />
      }
    </CustomAccordion>
  );
}

export default SimpleSegmentationList;
