import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { get, set } from 'lodash';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { FormHelperText, FormLabel } from '@mui/material';
import Box from '@mui/material/Box';
import {
  IconCheck,
  IconCirclesRelation,
  IconUsers,
  IconX, IconProps, Icon
} from '@tabler/icons-react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useTheme } from '@mui/material/styles';
import IconBuildings from '@deecision/common-webapp/assets/custom/IconBuildings';
// eslint-disable-next-line import/no-cycle
import {
  SegmentationPossibleFilterEntityTypes,
  SegmentationPossibleOutputEntityTypes,
  SegmentationWorkflowIds
} from '@deecision/dna-interfaces';
// eslint-disable-next-line import/no-cycle
import { BaseItemSegmentationBuilderProps } from '../types.builder.segmentations';
import CustomAccordion from '../../../../components/accordion';
import { CustomSegmentation } from '../../types.segmentations';

export type EntitiesIn = 'persons' | 'companies';
export type EntitiesOut = SegmentationPossibleOutputEntityTypes | 'company';

export interface SegmentationBaseWorkflow<T = string> {
  type: T,
  workflowId?: SegmentationWorkflowIds,
  labelId: string,
  icon: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>,
  possibleFilterTypes?: SegmentationPossibleFilterEntityTypes[]
}

export interface SegmentationWorkflow<T> extends SegmentationBaseWorkflow<T> {
  subWorkflows?: SegmentationBaseWorkflow<EntitiesOut>[]
}

export interface SegmentationEntityType<T> extends SegmentationBaseWorkflow<T> {
  workflows?: SegmentationWorkflow<EntitiesOut>[]
}

const subCompaniesWorkflow: SegmentationBaseWorkflow<EntitiesOut> = {
  type: 'company' as const,
  workflowId: 'person1-companies_person2-companies',
  labelId: 'entities.companies.related',
  icon: IconBuildings
};

export const segmentationEntityTypes: SegmentationEntityType<EntitiesIn>[] = [
  {
    type: 'persons' as const,
    labelId: 'entities.persons.label',
    icon: IconUsers,
    workflows: [
      {
        type: 'person1' as const,
        workflowId: 'person1_link_person2',
        labelId: 'entities.persons.label',
        icon: IconUsers,
        possibleFilterTypes: ['person', 'company']
      },
      {
        type: 'person2person' as const,
        workflowId: 'person1_link_person2',
        labelId: 'entities.links',
        icon: IconCirclesRelation,
        possibleFilterTypes: ['person2person']
      },
      {
        type: 'person2' as const,
        workflowId: 'person1_link_person2',
        labelId: 'entities.persons.related',
        icon: IconUsers,
        possibleFilterTypes: ['person', 'company']
      }
    ]
  },
  {
    type: 'companies' as const,
    labelId: 'entities.companies.label',
    icon: IconBuildings,
    workflows: [
      {
        type: 'company' as const,
        workflowId: 'simple_company',
        labelId: 'entities.companies.label',
        icon: IconBuildings,
        possibleFilterTypes: ['company']
      }
    ]
  }
];

function TypeSelectorBuilderSegmentations(props: Omit<BaseItemSegmentationBuilderProps, 'workflow' | 'data'> & { data: CustomSegmentation }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [on, setOn] = useState((props.path === '' ? (props.data as CustomSegmentation).on : get(props.data, `${props.path}.on`)) || '');

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOn((event.target as HTMLInputElement).value);
    props.setData({ ...set(props.data, props.path === '' ? 'on' : `${props.path}.on`, event.target.value) });
    props.setData({ ...set(props.data, props.path === '' ? 'outputEntities' : `${props.path}.outputEntities`, undefined) });
  };

  return (
    <CustomAccordion
      title={
        <Stack spacing={2} direction='row' alignItems='center'>
          <Typography variant='h4'>
            {t('segmentation.builder.steps.initialize.label')}
          </Typography>
          {props.data.on ?
            <Chip
              sx={{ '& > *': { p: 2 } }}
              label={<Box display='flex' justifyContent='center'><IconCheck size={18} /></Box>}
              color='success'
              size='small'
            /> :
            <Chip
              sx={{ '& > *': { p: 2 } }}
              label={<Box display='flex' justifyContent='center'><IconX size={18} /></Box>}
              color='error'
              size='small'
            />
          }
          {get(props.data, props.path) &&
            <Chip label={t(`entities.${get(props.data, props.path)}.unique`)} size='small' />
          }
        </Stack>
      }
      defaultOpen
    >
      <Stack spacing={6} p={6} minWidth='50%' width='fit-content'>
        <FormControl required>
          <FormLabel id='demo-controlled-radio-buttons-group' sx={{ fontSize: '16px', color: on === '' ? theme.palette.error.main : theme.palette.text.primary }}>
            {t('segmentation.builder.steps.initialize.desc')}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby='demo-controlled-radio-buttons-group'
            name='controlled-radio-buttons-group'
            value={on}
            onChange={handleOnChange}
          >
            {segmentationEntityTypes.map(segmentationEntityType => (
              <FormControlLabel
                value={segmentationEntityType.type}
                control={<Radio />}
                label={
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <segmentationEntityType.icon stroke={segmentationEntityType.type === 'companies' ? theme.palette.text.primary : undefined} />
                    <Typography variant='h5'>{t(`${segmentationEntityType.labelId}`)}</Typography>
                  </Stack>
                }
              />
            ))}
          </RadioGroup>
          {!(props.path === '' ? (props.data as CustomSegmentation).on : get(props.data, `${props.path}.on`)) && <FormHelperText>{t('common.utils.required')}</FormHelperText>}
        </FormControl>
      </Stack>
    </CustomAccordion>
  );
}

export default TypeSelectorBuilderSegmentations;
