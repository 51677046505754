import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { currencyFormatterProps } from '@deecision/common-webapp/utils/currencyFormatter';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { IconTrash } from '@tabler/icons-react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { upperFirst } from 'lodash';
import EvenOddTable from '../tables/evenodd/table.evenodd.tables';
import { ListProps } from '../lists/types.lists';
import { CustomUserData } from './types.segmentations';
import { Column } from '../tables/types.tables';
import dnaConfig from '../../../config/dna.config.json';
import ChipViewSegmentations from './render/view/chip.view.segmentations';

function ListSegmentations(props: ListProps<CustomUserData>): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns: Column<currencyFormatterProps>[] = [
    {
      id: 'name',
      label: t('common.identity.name'),
      sortPath: 'props.children'
    },
    {
      id: 'on',
      label: upperFirst(t('common.utils.for') || '')
    },
    {
      id: 'vues',
      label: t('segmentation.builder.views.label')
    },
    {
      id: 'user',
      label: t('common.utils.user')
    },
    {
      id: 'groups',
      label: t('common.utils.groups')
    },
    {
      id: 'actions',
      label: t('common.utils.actions'),
      align: 'right'
    }
  ];

  const rows = props.data.map(customUserData => ({
    id: customUserData._id,
    name: <Link href={`${dnaConfig.routes.admin.factory.segmentation.baseUrl}/${customUserData._id as string}`}>{customUserData.title}</Link>,
    on: t(`entities.${customUserData.data.on}.label`),
    vues: <Grid container spacing={2}>
      {customUserData.data.views?.map(view => (
        <ChipViewSegmentations key={view.id} view={view} />
      ))}
    </Grid>,
    user: customUserData.ownerId,
    groups: '',
    actions: <Stack direction='row' justifyContent='flex-end'>
      <IconButton>
        <IconTrash
          onClick={props.service ?
            () => {
              props.service?.delete?.(customUserData._id)
                .then(() => navigate(0));
            }
            : undefined
          }
        />
      </IconButton>
    </Stack>
  }));

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <EvenOddTable<currencyFormatterProps> label='CustomSegmentation' {...props} columns={columns} rows={rows} />
    </Box>
  );
}

export default ListSegmentations;
