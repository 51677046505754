import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { currencyFormatterProps } from '@deecision/common-webapp/utils/currencyFormatter';
import { useTranslation } from 'react-i18next';
import { CompanyEntity } from '@deecision/dna-interfaces';
import { Stack } from '@mui/material';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { currencyFormatter } from '@deecision/common-webapp/utils';
import Button from '@mui/material/Button';
import { CompanyGetter, makeRes } from '../../utils/getter';
import EvenOddTable from '../tables/evenodd/table.evenodd.tables';
import { Column } from '../tables/types.tables';
import dnaConfig from '../../../config/dna.config.json';
import { ListProps } from './types.lists';

function CompanyList(props: ListProps<CompanyEntity>): ReactElement {
  const { t } = useTranslation();

  const columns: Column<currencyFormatterProps>[] = [
    {
      id: 'name',
      label: t('common.identity.name'),
      sort: !props.disableSort && !props.localSort ? 'name' : undefined,
      sortPath: 'props.children',
      minWidth: 240,
      noMaxWidth: true
    },
    {
      id: 'tags',
      label: t('common.utils.tags')
    },
    {
      id: 'nationalId',
      label: t('common.codes.nationalId')
    },
    {
      id: 'creationDate',
      label: t('common.dates.creationDate')
    },
    {
      id: 'codeApe',
      label: t('common.utils.activitySector'),
      sort: !props.disableSort && !props.localSort ? 'codeApe' : undefined,
      tooltip: true
    },
    {
      id: 'legalStatus',
      label: t('common.utils.legalForm'),
      sort: !props.disableSort && !props.localSort ? 'legalForm' : undefined,
      tooltip: true
    },
    {
      id: 'dismemberment',
      label: t('patrimonialComplexity.nbMemberInDismemberment.label'),
      align: 'right',
      render: value => makeRes(value),
      sort: !props.disableSort && !props.localSort ? 'dv_asset_complexity_nb_member_in_dismemberment' : undefined
    },
    {
      id: 'turnover',
      label: t('common.financial.turnover.label'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comp_turnover' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    {
      id: 'ebitda',
      label: t('common.financial.ebitda.label'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comp_ebitda' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    {
      id: 'capital',
      label: t('common.financial.capital.label'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comp_capital' : undefined,
      render: value => currencyFormatter({ value: value as number })
    },
    {
      id: 'valuation',
      label: t('common.financial.valuation.label'),
      align: 'right',
      sort: !props.disableSort && !props.localSort ? 'dv_comp_valuation' : undefined,
      render: value => currencyFormatter({ value: value as number })
    }
  ];

  const rows = props.data.map(company => ({
    id: company.entityId,
    name: (!props.link || typeof props.link === 'string') ?
      <Link
        href={`${dnaConfig.routes.companies.relativeUrl}/${company.entityId as string}`}
        sx={{ whiteSpace: 'nowrap', fontSize: 14 }}
      >
        {new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getName()}
      </Link> :
      <Button variant='contained' sx={{ mr: '-4px' }} onClick={() => (props.link as (id: string, entityType: string) => void)(company.entityId as string, 'deecCompany')} size='small' fullWidth>{new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getName()}</Button>,
    tags: <Stack direction='row' spacing={2} maxWidth={340} flexWrap='nowrap' useFlexGap>
      {new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getTags().filter(tag => tag.family !== 'batchTag').map(tag =>
        <Tooltip key={`${tag.value}-${tag.family}`} title={tag.family === 'companyType' && tag.value === 'listedCompany' ? t('entities.tags.listed.company') : tag.family} arrow placement='top'>
          {tag.family === 'companyType' && tag.value === 'listedCompany' ?
            <Chip sx={{ '& .MuiChip-labelSmall': { fontWeight: '600' } }} color='error' size='small' label='L' /> :
            <Chip size='small' label={tag.value === 'antiMoneyLaundering' ? 'aml' : tag.value} />
          }

        </Tooltip>
      )}
    </Stack>,
    nationalId: new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getSiren(),
    creationDate: new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getCreationDate(),
    codeApe: new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getActivitySector(),
    legalStatus: `${new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getLegalForm()} (${new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getLegalForm(true)})`,
    dismemberment: new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getIsDismemberment() ? new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getNbMembers(true) : undefined,
    turnover: new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getTurnover(true),
    ebitda: new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getEbitda(true),
    capital: new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getCapital(true),
    valuation: new CompanyGetter({ data: company, errorMsg: t('common.utils.unknown') }).getValuation(true)
  }));

  return (
    <>
      <Box sx={{ width: '100%', height: '100%' }}>
        <EvenOddTable<currencyFormatterProps> totalCount={props.data.length} {...props} entityType='company' label='CompanyList' columns={columns} rows={rows} disableLocalSort={[...(props.disableLocalSort || []), 'tags', 'codeApe', 'legalStatus']} />
      </Box>
    </>
  );
}

export default CompanyList;
