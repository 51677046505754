import React, { ReactElement } from 'react';
import { useOutletContext } from 'react-router-dom';
import { CompanyEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import { VeegilenzCompanyGetter } from '../../../../../containers/veegilenz/getter.veegilenz';
import Veegilenz from '../../../../../containers/veegilenz/veegilenz';

function VeegilenzTabsComponents(): ReactElement {
  const { data } = useOutletContext() as { data: CompanyEntity };
  const { t } = useTranslation();

  return (
    <Veegilenz
      getter={new VeegilenzCompanyGetter({ entity: data, linkedEntities: [], yes: t('common.utils.yes'), no: t('common.utils.no') })}
      entityType='deecCompany'
    />
  );
}

export default VeegilenzTabsComponents;
