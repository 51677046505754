import React from 'react';
import { CustomRouteObject } from '@deecision/common-webapp/router';
import { IconFilterEdit } from '@tabler/icons-react';
import createElementRoutes from '@deecision/common-webapp/router/utils/create.element.routes';
import { PersonEntity, CompanyEntity, SegmentationData } from '@deecision/dna-interfaces';
import { Construction } from '@deecision/common-webapp/components';
import dnaConfig from '../../../../../config/dna.config.json';

function routesCustomSegmentations(id: string): CustomRouteObject {
  return createElementRoutes<unknown, PersonEntity | CompanyEntity, SegmentationData>({
    id: `${id}-${dnaConfig.childRoutes.segmentations.custom.label}`,
    path: dnaConfig.childRoutes.segmentations.custom.baseUrl,
    component: <Construction />,
    idComponent: <Construction />,
    icon: IconFilterEdit
  });
}

export default routesCustomSegmentations;
