import React, { ReactElement } from 'react';
import { useLoaderData } from 'react-router-dom';
import { PersonEntity } from '@deecision/dna-interfaces';
import { ApiArrayResponse } from '@deecision/deecision-interfaces';
import PersonList from '../../../../containers/lists/person.lists';

function PersonsComponent(): ReactElement {
  const data = useLoaderData() as ApiArrayResponse<PersonEntity>;

  return (
    <PersonList data={Array.isArray(data.data) ? data.data : []} totalCount={data.totalCount} />
  );
}

export default PersonsComponent;
