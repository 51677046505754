import React, { ReactElement, useEffect, useState } from 'react';
import { SegmentationData, SegmentationPossibleDisplayTypes } from '@deecision/dna-interfaces';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { get, set } from 'lodash';
import EntitiesListsSegmentations from '../lists/entities.lists.segmentations';
import CustomAccordion from '../../../../components/accordion';
import FiltersSegmentations, {
  FiltersSegmentationsProps
} from '../filters.segmentations';
import {
  BaseCustomUserData,
  BaseSegmentationProps, CustomSegmentation,
  CustomSegmentationView
} from '../../types.segmentations';
import DispatchChartsSegmentations, { baseChartWidth } from '../charts/dispatch.charts.segmentations';
import AddFiltersBuilderSegmentations from '../../builder/filters/add.filters.builder.segmentations';
import { segmentationEntityTypes } from '../../builder/steps/typeselector.builder.segmentations';

export interface AssistedSegmentationStepComponentProps extends BaseSegmentationProps, Pick<FiltersSegmentationsProps, 'reset'> {
  data: BaseCustomUserData,
  setData: (data: BaseCustomUserData) => void,
  path: string,
  segmentationData?: SegmentationData,
  setSegmentationData: (data?: SegmentationData) => void
}

function ViewSegmentationComponent(props: AssistedSegmentationStepComponentProps): ReactElement {
  const { t } = useTranslation();
  const view = get(props.data, props.path) as CustomSegmentationView;
  const handleDelete = (segmentationCriteria: any) => props.setData({ ...set(props.data, `${props.path}.segmentationCriterias[${(get(props.data, `${props.path}.segmentationCriterias`) as CustomSegmentationView['segmentationCriterias'])?.findIndex(sc => sc !== undefined && sc.id === segmentationCriteria.id) || 0}]`, undefined) });
  const handleChangeDisplayType = (displayType: SegmentationPossibleDisplayTypes, segmentationCriteria: any) => props.setData({ ...set(props.data, `${props.path}.segmentationCriterias[${(get(props.data, `${props.path}.segmentationCriterias`) as CustomSegmentationView['segmentationCriterias'])?.findIndex(sc => sc.id === segmentationCriteria.id) || 0}].displayInfo.displayType`, displayType) });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth, windowHeight]);
  
  return (
    <Stack spacing={2}>
      <Stack>
        <Paper
          sx={{
            p: 4,
            bgcolor: 'transparent',
            borderBottom: 'none',
            borderRadius: 0,
            width: '100%',
            '&:first-of-type': {
              borderTopLeftRadius: '12px !important',
              borderTopRightRadius: '12px !important'
            },
            '&:last-of-type': {
              borderBottom: 'solid 1px',
              borderColor: 'rgb(206, 212, 218)',
              borderTopLeftRadius: '12px !important',
              borderTopRightRadius: '12px !important'
            }
          }}
        >
          <FiltersSegmentations {...props} />
        </Paper>
        <CustomAccordion
          title={
            <Stack direction='row' spacing={2} alignItems='center'>
              <Typography variant='h4'>{view.label}</Typography>
              <Chip
                label={`${view.segmentationCriterias?.filter(segmentationCriteria => (segmentationCriteria && !props.filters.map(filter => filter.id).includes(segmentationCriteria.id))).length || 0} ${t('segmentation.builder.views.possibleFilters')}`}
                color='success'
                size='small'
              />
              <Chip
                label={`${t('segmentation.render.views.on')} ${t(`segmentation.builder.filter.${view.on}`)}`}
                color='primary'
                size='small'
              />
            </Stack>
          }
          defaultOpen={!!props.segmentationData}
          disabled={!props.segmentationData?.totalCount}
        >
          <Grid container p={3} spacing={2} minHeight={100}  alignItems='flex-start' >  {/* overflow='auto' */}
            <Grid xs={12}>
              <AddFiltersBuilderSegmentations
                {...props}
                data={props.data as CustomSegmentation}
                setData={(data: CustomSegmentation) => props.setData(data as BaseCustomUserData)}
                ownerId='admin'
                idPrefix='added-'
                workflow={segmentationEntityTypes.find(segmentationEntityType => segmentationEntityType.type === props.data.data.on)?.workflows?.find(workflow => workflow.type === view.on)}
                subWorkflow={segmentationEntityTypes.find(segmentationEntityType => segmentationEntityType.type === props.data.data.on)?.workflows?.find(workflow => workflow.type === view.on)?.subWorkflows?.find(subWorkflow => subWorkflow.workflowId === view.workflowId)}
                outputEntities={view.outputEntities}
                view
              />
            </Grid>
            {view.segmentationCriterias?.filter(segmentationCriteria => (
              segmentationCriteria && !props.filters.map(filter => filter.id).includes(segmentationCriteria.id)
            )).map((segmentationCriteria) => {
              const criteria = props.segmentationData?.segmentationCriterias ? props.segmentationData.segmentationCriterias[segmentationCriteria.id] : undefined;

              if (!criteria) {
                return undefined;
              }

              return (
                <Grid xs minWidth={windowWidth > 1500 ?  '50%' : baseChartWidth} key={segmentationCriteria.id}>
                  <DispatchChartsSegmentations
                    segmentationCriteria={segmentationCriteria}
                    criteria={{
                      ...segmentationCriteria,
                      ...criteria
                    }}
                    criteriaInfo={{
                      ...segmentationCriteria,
                      ...criteria,
                      displayInfo: {
                        displayType: segmentationCriteria.displayInfo?.displayType || 'barChart',
                        label: segmentationCriteria.filterSpec ? segmentationCriteria.filterSpec.label || segmentationCriteria.filterSpec.id : `${segmentationCriteria.label?.split('-')[0] || segmentationCriteria.filterId || ''} - ${segmentationCriteria.filterLabel}`
                      }
                    }}
                    entityType={segmentationCriteria.on?.includes('person') ? 'deecPerson' : 'deecCompany'}
                    totalCount={props.segmentationData?.totalCount || 0}
                    handleDelete={handleDelete}
                    handleChangeDisplayType={handleChangeDisplayType}
                    filters={props.filters}
                    addFilter={props.addFilter}
                    removeFilter={props.removeFilter}
                  />
                </Grid>
              );
            })
            }
            {/* props.assistedSegmentationData.segmentationCriteriasInfo
              .filter(criteria => (data?.segmentationCriterias[criteria.id]?.values || []).length > 0)
              .map(criteria => (
                <Grid xs minWidth={baseChartWidth} key={criteria.id}>
                  {data &&
                    <DispatchChartsSegmentations
                      {...props}
                      criteriaInfo={criteria}
                      criteria={data.segmentationCriterias[criteria.id]}
                      entityType={getEntityTypeEquivalent(entityType)}
                      totalCount={data.totalCount}
                    />
                  }
                </Grid>
              ))
            */}
          </Grid>
        </CustomAccordion>

        <CustomAccordion
          title={`${props.segmentationData?.totalCount || 0} ${!view.on?.includes('person') ? (props.segmentationData?.totalCount || 0) > 1 ? t('entities.companies.label') : t('entities.companies.company.label') : (props.segmentationData?.totalCount || 0) > 1 ? t('entities.persons.label') : t('entities.persons.person.label')}`}
          defaultOpen
          disabled={!props.segmentationData?.totalCount}
        >
          {props.segmentationData &&
            <EntitiesListsSegmentations
              data={props.segmentationData}
              entityType={{ deecPerson: 'person' as const, deecCompany: 'company' as const, p2pNetworkLink: 'person' as const }[props.segmentationData.objectType]} // TODO: replace p2pNetworkLink: 'person'
              enableSelect
            />
          }
        </CustomAccordion>
      </Stack>
    </Stack>
  );
}

export default ViewSegmentationComponent;
