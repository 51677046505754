import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { CompanyEntity, EntityRef, EntityType, PersonEntity } from '@deecision/dna-interfaces';
import CompanyEntitiesCards from './company.entities.cards';
import PersonEntitiesCards from './person.entities.cards';
import { BaseEntitiesCardsProps } from './base.entities.cards';
import { getEntity } from '../../../utils/getter';

function EntitiesCards(props: Omit<BaseEntitiesCardsProps, 'entity'> & Partial<Pick<BaseEntitiesCardsProps, 'entity'>> & { entityRef?: EntityRef, commonRelationsWithMain?: string }): ReactElement {
  const [entity, setEntity] = useState(props.entity);
  const Entities = {
    deecCompany: CompanyEntitiesCards,
    deecPerson: PersonEntitiesCards
  };

  useEffect(() => {
    if (!props.entity && props.entityRef) {
      getEntity<CompanyEntity | PersonEntity>(props.entityRef)
        .then(res => setEntity(res));
    } else {
      setEntity(props.entity);
    }
  }, [props.entity, props.entityRef]);

  const Entity = useMemo(() => (entity ? Entities[(entity.entityType as EntityType)] : Entities.deecPerson), [entity]);

  return (entity ? <Entity entity={entity} {...props} /> : <></>);
}

export default EntitiesCards;
