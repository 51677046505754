import React, { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SortDirection } from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import { useDrag, useDrop } from 'react-dnd';
import { useTheme } from '@mui/material/styles';
import { Column, Row } from '../types.tables';
import { StyledTableCell } from './styled.evenodd.tables';

export interface LocalSort {
  columnId?: string,
  dir: SortDirection
}

export interface EvenOddHeadProps<T> {
  columns: Column<T>[],
  rows: Row<T>[],
  label: string,
  selected?: string[],
  setSelected?: (selected: string[]) => void,
  totalCount?: number,
  hideColumns?: string[],
  setSort: (sort: LocalSort) => void,
  setColumns: (columns: Column<T>[]) => void,
  setTest: React.Dispatch<React.SetStateAction<Column<T>[]>>,
  localSort?: true,
  disableLocalSort?: string[],
  isHovered: {
    hover: boolean,
    hoveredCol: string,
    originHoveredCol: string
  },
  setIsHovered: React.Dispatch<React.SetStateAction<{
    hover: boolean,
    hoveredCol: string,
    originHoveredCol: string
  }>>
}

export interface DraggableTableCellProps<T> {
  label: string,
  columns: Column<T>[],
  column: Column<T>,
  columnIndex: number,
  sort: SortDirection,
  sortType: string,
  localSort?: true,
  columnToSort: string | undefined,
  handleSort: (column: Column<T>) => void,
  handleLocalSort: (column: Column<T>) => void,
  disableLocalSort?: string[],
  setColumns: (columns: Column<T>[]) => void,
  isHovered: {
    hover: boolean,
    hoveredCol: string,
    originHoveredCol: string
  },
  setTest: React.Dispatch<React.SetStateAction<Column<T>[]>>,
  setIsHovered: React.Dispatch<React.SetStateAction<{
    hover: boolean,
    hoveredCol: string,
    originHoveredCol: string
  }>>
}

function DraggableTableCell<T = number>(props: DraggableTableCellProps<T>): ReactElement {
  const theme = useTheme();
  const [showIcon, setShowIcon] = useState(false);

  const handleMove = (originId: string, destId: string) => {
    let newColumns = [...props.columns];

    if (originId !== destId) {
      const originColumnIndex = newColumns.findIndex(col => col.id === originId);
      const destColumnIndex = newColumns.findIndex(col => col.id === destId);
      const columnTmp = newColumns[originColumnIndex];
      const columnsPart1 = newColumns.slice(0, originColumnIndex);
      const columnsPart2 = newColumns.length > originColumnIndex + 1 ? newColumns.slice(originColumnIndex + 1) : [];

      newColumns = columnsPart1.concat(columnsPart2);
      newColumns.splice(destColumnIndex, 0, columnTmp);

      props.setColumns(newColumns);
    }
  };

  const handleHover = (originId: string, destId: string) => {
    props.setIsHovered({ hover: true, hoveredCol: props.column.id, originHoveredCol: originId });
    handleMove(originId, destId);
  };

  const handleDrop = () => {
    props.setIsHovered({ hover: false, hoveredCol: '', originHoveredCol: '' });
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'tableCell',
    item: { id: props.column.id, index: props.column.index ? props.column.index : props.columnIndex  },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  }));

  const drop = useDrop(() => ({
    accept: 'tableCell',
    hover: (item: Column<T>) => handleHover(item.id, props.column.id),
    drop: () => handleDrop(),
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  }), [props.columns])[1];

  return (
    <StyledTableCell
      ref={(node: React.ReactElement) => drag(drop(node))}
      align={props.column.align}
      sortDirection={props.sort}
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
      sx={{
        minWidth: props.column.minWidth,
        border: isDragging ? 'dashed' : props.isHovered.hover && props.isHovered.hoveredCol === props.column.id ? 'dashed': undefined,
        borderColor: isDragging ? theme.palette.grey['500'] : props.isHovered.hover && props.isHovered.hoveredCol === props.column.id ? 'darkgrey': undefined,
        color: isDragging ? '' : props.isHovered.hover && props.isHovered.hoveredCol === props.column.id ? 'lightgrey': undefined
      }}
    >
      <TableSortLabel
        active={props.sortType === 'server' ? props.column.sort === props.columnToSort : props.sortType === 'local' ? props.column.id === props.columnToSort : undefined}
        direction={props.sortType === 'server' && props.column.sort || props.sortType === 'local' ? props.sort || 'asc' : undefined}
        onClick={props.sortType === 'none' ? undefined : () => {
          switch (props.sortType) {
          case 'server':
            props.handleSort(props.column);
            break;
          case 'local':
            props.handleLocalSort(props.column);
            break;
          default:
            props.handleLocalSort(props.column);
            break;
          }
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: props.column.align === 'right' ? 'row-reverse' : 'row',
          color: theme.palette.text.primary,
          '&:hover': {
            color: theme.palette.text.primary
          },
          '& .MuiTableSortLabel-icon': {
            opacity: showIcon ? 0.5 : undefined
          }
        }}
      >
        <span>{props.column.label}</span>
      </TableSortLabel>
    </StyledTableCell>
  );
}

function EvenOddHead<T = number>(props: EvenOddHeadProps<T>): ReactElement {
  const [sort, setSort] = useState<SortDirection>(false);
  const [columnToSort, setColumnToSort] = useState<string | undefined>(undefined);
  // const [triggerEffect, setTriggerEffect] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!props.localSort) {
      searchParams.forEach((value, key) => {
        if (key.startsWith('s_')) {
          const keySort = key.replace('s_', '');

          if (keySort !== columnToSort) {
            setColumnToSort(key.replace('s_', ''));
          }
          setSort(value as SortDirection);
        }
      });
    }
  }, [props.columns]);

  const handleSort = (column: Column<T>) => {
    setSort(prevState =>
      (prevState === 'asc' && column.sort === columnToSort ? 'desc' : 'asc')
    );
    setColumnToSort(column.sort);
    searchParams.forEach((value, key) => {
      if (key.startsWith('s_') && key !== `s_${column.sort}`) {
        searchParams.delete(key);
        setSearchParams(searchParams);
      }
    });
    searchParams.set(
      `s_${column.sort}`,
      sort === 'asc' && column.sort === columnToSort ? 'desc' : 'asc'
    );
    setSearchParams(searchParams);
  };

  const handleLocalSort = (column: Column<T>) => {
    const sortTmp = sort === 'asc' && column.id === columnToSort ? 'desc' : 'asc';

    setColumnToSort(column.id);
    setSort(sortTmp);
    props.setSort({ columnId: column.id, dir: sortTmp });
  };

  return (
    <TableHead>
      <TableRow>
        {props.selected && (
          <StyledTableCell key='row-selected' component='th' scope='row'>
            <Checkbox
              sx={{ height: 24, width: 24 }}
              indeterminate={
                props.selected.length > 0 &&
                props.selected?.length !== props.totalCount
              }
              checked={
                props.selected.length > 0 &&
                props.selected?.length === props.totalCount
              }
              onChange={() =>
                props.setSelected &&
                props.setSelected(
                  props.selected && props.selected.length > 0
                    ? []
                    : props.rows.map(r => r.id)
                )
              }
            />
          </StyledTableCell>
        )}
        {props.columns.map((column, index) => (
          <DraggableTableCell
            {...props}
            key={`column-${column.id}`}
            column={column}
            columnIndex={index}
            sort={sort}
            sortType={
              props.localSort && !props.disableLocalSort?.includes(column.id)
                ? 'local'
                : column.sort
                  ? 'server'
                  : 'none'
            }
            handleSort={handleSort}
            handleLocalSort={handleLocalSort}
            columnToSort={columnToSort}
          />
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EvenOddHead;
