import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { PersonEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import GetCountryFlags from '@deecision/common-webapp/components/getCountryFlags';
import { IconDatabase, IconHome, IconId, IconRefresh } from '@tabler/icons-react';
import { PersonGetter } from '../../../utils/getter';
import Completeness from '../../completeness';

function PersonEntitiesChips(props: { entity: PersonEntity, size?: 'small' | 'medium' }): ReactElement[] {
  const { t } = useTranslation();
  const [entity, setEntity] = useState<PersonEntity>(props.entity as PersonEntity);
  const personGetter = useMemo(() => new PersonGetter({
    data: entity,
    errorMsg: t('common.utils.unknown')
  }), [entity]);

  const completenessArray = [
    parseFloat(personGetter.getWeasseCompleteness().toString()),
    parseFloat(personGetter.getWeasseMaxCompleteness().toString()),
    parseFloat(personGetter.getTurnoverSumCompleteness().toString()),
    parseFloat(personGetter.getCapitalSumCompleteness().toString()),
    parseFloat(personGetter.getEbitdaSumCompleteness().toString()),
    parseFloat(personGetter.getValuationSumCompleteness().toString())
  ];

  useEffect(() => {
    setEntity(props.entity as PersonEntity);
  }, [props.entity]);

  return ([
    <Tooltip
      key='common.identity.nationality'
      title={`${personGetter.getNationalitiesCodes().length < 2 ? t('common.identity.nationality') : t('common.identity.nationalities')}: ${personGetter.getNationalities().join(' / ')}`}
      arrow
      placement='top'
    >
      <Chip
        label={
          <Stack direction='row' height='20px' spacing={1} alignItems='center' divider={<Divider orientation='vertical' />} sx={{ mr: 1 }}>
            {GetCountryFlags({ countries: personGetter.getNationalitiesCodes(), size: props.size })}
          </Stack>
        }
        icon={<IconId size={props.size === 'small' ? 18 : 22} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
        size={props.size}
      />
    </Tooltip>,
    <Tooltip
      key='common.identity.country'
      title={`${personGetter.getCountriesCodes().length < 2 ? t('common.identity.country') : t('common.identity.countries')}: ${personGetter.getCountries().join(' / ')}`}
      arrow
      placement='top'
    >
      <Chip
        label={
          <Stack direction='row' height='20px' spacing={1} alignItems='center' divider={<Divider orientation='vertical' />} sx={{ mr: 1 }}>
            {GetCountryFlags({ countries: personGetter.getCountriesCodes(), size: props.size })}
          </Stack>
        }
        icon={<IconHome size={props.size === 'small' ? 18 : 22} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
        size={props.size}
      />
    </Tooltip>,
    <Completeness
      key='completenessTitleChip'
      completeness={completenessArray.reduce((a, b) => (Number.isNaN(a) ? 0 : a) + (Number.isNaN(b) ? 0 : b), 0) / completenessArray.length}
      size={props.size || 'medium'}
      icon={IconDatabase}
    />,
    <Tooltip
      key='common.dates.lastUpdate'
      title={t('common.dates.lastUpdate')}
      arrow
      placement='top'
    >
      <Chip
        label={personGetter.getDataDate()}
        icon={<IconRefresh size={props.size === 'small' ? 18 : 22} style={{ marginLeft: props.size === 'small' ? '8px' : '12px', marginRight: '-4px' }} />}
      />
    </Tooltip>
  ]
  );
}

export default PersonEntitiesChips;
