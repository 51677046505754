import React, { ReactElement } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Button, Divider, PaletteColor, Paper, Stack, Tooltip, Typography, useTheme  } from '@mui/material';
import TitleComponent from '@deecision/common-webapp/components/title.components';
import { round } from 'lodash';
import { IconExternalLink } from '@tabler/icons-react';
import ProgressBar from './progressBar';
import { WEBAPP_NAME } from '../../../../../env/env';
import ActionPanel from './actionPanel';

export type CountObj = {
  all: number,
  done: number,
  failed: number,
  requested: number,
  waiting_for_dependencies: number
}

interface DataElementCardsProps {
  card?: {
    color: PaletteColor,
    status: string,
    statusCount: number,
    selected: number
  },
  title: string,
  count: number,
  status: string,
  totalCount: CountObj,
  selectedCount: CountObj,
  dataElement? : [{
    entityType: string,
    count: number,
    scheduling_status: string,
    type: string
  }],
  filterSchedulingStatusName: string[],
  filterParams?: URLSearchParams,
  handleSetters: (setter: string, value: string[]) => void
}

export const progressBarColor = {
  done: 'green',
  failed: 'red',
  waiting_for_dependencies: 'orange',
  requested: '#6a1b9a'
};

function DisplayListValue(props: DataElementCardsProps): ReactElement {

  return (
    <Grid container xs={12}>
      {props.dataElement && props.dataElement.map((value, index) => (
        <>
          { index !== 0 &&
              <>
                <Grid container spacing={0} xs={12} direction='row' maxWidth='100%' mt='-3px !important' pl={0} justifyContent='space-between'>
                  <Grid xs={1} style={{ cursor: 'pointer' }}
                  // onClick={()=> { props.handleSetters('filterEntityType', [value.entityType]); }}
                  >
                    {/* Maybe Change this so it filters instead of redirect to entityType */}
                    <Stack
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        window.open(`/${WEBAPP_NAME}/entities?entitie=${value.entityType as string}`, '_blank');
                      }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography
                          variant='body1'
                          fontWeight={400}
                          color='#949494'
                          noWrap
                        >
                          {value.entityType}
                        </Typography>
                        <IconExternalLink size={10} style={{ marginBottom: '1vh' }}/>
                      </Box>
                    </Stack>

                  </Grid>
                  <Grid xs={5} onClick={()=> {
                    props.handleSetters('filterType', [value.type]);
                  }}>
                    <Typography
                      variant='body1'
                      fontWeight={400}
                      color='#949494'
                      noWrap
                      style={{ cursor: 'pointer' }}
                    >
                      {value.type}
                    </Typography>
                  </Grid>
                  <Grid xs={2}>
                    <Typography
                      variant='body1'
                      fontWeight={500}
                      color='#666666'
                      noWrap
                    >
                      {value.scheduling_status}
                    </Typography>
                  </Grid>
                  <Grid xs={2}>
                    <Button
                      variant='textIcon'
                      sx={{ cursor: 'pointer' }}
                      size='small'
                      onClick={() => {
                        window.open(`/${WEBAPP_NAME}/dataelements?type=${value.type as string}&status=${value.scheduling_status as string}`, '_blank');
                      }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography
                          variant='body1'
                          fontWeight={500}
                          color='#666666'
                          noWrap
                        >
                          {value.count}
                        </Typography>
                        <IconExternalLink size={15} style={{ marginBottom: '1vh' }}/>
                      </Box>
                    </Button>
                  </Grid>
                  <ActionPanel />
                </Grid>
                <Grid xs={12} pb={2} mt={2}><Divider /></Grid>
              </>
          }
        </>
      ))}
    </Grid>
  );
}

function DisplaySchedulingStatus(props: DataElementCardsProps): ReactElement {
  return (
    <Grid xs={3} p={2}>
      <Paper
        elevation={0}
        onClick={()=> {
          if (props.card && props.filterParams) {
            const urlStatus = props.filterParams.getAll('status')[0];
            const tmpStatus = (urlStatus ? urlStatus.replace(`'`, '').replace(/"/g, '').split(',') : []);

            tmpStatus.includes(props.card?.status.toLowerCase()) ?
              tmpStatus.splice(tmpStatus.indexOf(props.card.status.toLowerCase()), 1) :
              tmpStatus.push(props.card.status.toLowerCase());
            props.handleSetters('filterSchedulingStatus', tmpStatus);
          }
        }}
        sx={{
          bgcolor: (props.filterSchedulingStatusName.includes(props.card? props.card.status.toLowerCase() : '') ? props.card?.color.main : props.card?.color.light),
          color: props.card?.color.dark,
          width: '100%'
        }}
      >
        <Tooltip title={`Select ${props.card?.status} filter`}>
          <Stack
            sx={{
              width: '100%',
              height: '50px',
              textAlign: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
          >
            <Typography variant='h3' color='primary.dark'>{props.card?.status}: {props.card?.statusCount} ({(props.card ? round(((props.card.statusCount / props.totalCount.all) * 100), 2)  : 'NaN')}%) </Typography>
            <Stack
              sx={{
                width: '100%',
                height: '25px',
                textAlign: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography variant='h3' color='primary.dark'>Selected: {props.card?.selected} </Typography>
            </Stack>
          </Stack>
        </Tooltip>
      </Paper>
    </Grid>
  );
}

function DataElementCards(props: DataElementCardsProps): ReactElement {
  const theme = useTheme();

  return (
    <Paper variant='hoverElevation1'>
      <Grid container spacing={2} width='100%'>
        <Grid xs={12} ml='-8px'>
          <TitleComponent title={props.title} />
        </Grid>
        <Grid container flexDirection='row' xs={12} p={2}>
          <Paper
            elevation={0}
            sx={{
              bgcolor: 'white',
              color: 'black',
              width: '100%'
            }}
          >
            <Grid container flexDirection='row' xs={12}>
              <DisplaySchedulingStatus {...props} card={{ color: theme.palette.success, status: 'Done', statusCount: props.totalCount.done, selected: props.selectedCount.done }} />
              <DisplaySchedulingStatus {...props} card={{ color: theme.palette.error, status: 'Failed', statusCount: props.totalCount.failed, selected: props.selectedCount.failed }} />
              <DisplaySchedulingStatus {...props} card={{ color: theme.palette.secondary, status: 'Waiting_for_dependencies', statusCount: props.totalCount.waiting_for_dependencies, selected: props.selectedCount.waiting_for_dependencies }} />
              <DisplaySchedulingStatus {...props} card={{ color: theme.palette.info, status: 'Requested', statusCount: props.totalCount.requested, selected: props.selectedCount.requested }} />
            </Grid>
            <Grid container flexDirection='row' xs={12} mb='-25px'>
              <Grid xs={9}>
                <ProgressBar
                  done={round(((props.totalCount.done / props.totalCount.all) * 100), 2)}
                  failed={round(((props.totalCount.failed / props.totalCount.all) * 100), 2)}
                  requested={round(((props.totalCount.requested / props.totalCount.all) * 100), 2)}
                  waitingForDependencies={round(((props.totalCount.waiting_for_dependencies / props.totalCount.all) * 100), 2)}
                />
              </Grid>
              <Grid xs={3}>
                <Typography style={{ marginTop: 9, marginLeft: '40%' }} variant='h3'>{props.count} / {props.totalCount.all} </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid xs={12} p={2} pr={4} pl={4}>
          <DisplayListValue {...props} />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default DataElementCards;