import React, { ReactElement, useEffect, useState } from 'react';
import { darken } from '@mui/material/styles';
import { getFlagEmoji } from '@deecision/common-webapp/utils/getFlagEmoji';
import { getCountryISO2 } from '@deecision/common-webapp/utils/getCountryISO2';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import { EChartsOption } from 'echarts-for-react/src/types';
import { isArray, isString } from 'lodash';
import { BaseChartsProps } from './types.charts';
import { colorTableForVeeligenz, DataType, FlagType, formatNumber, getRange, getValue, interpolateColors, SerieDataType, sortVeegilenzColor } from './utils';
import './style.css';
import APE from '../../../config/Liste_des_codes_NAF_et_APE_en_france__2_.json';

const baseTooltipForOption = {
  trigger: 'item',
  axisPointer: {
    type: 'shadow'
  }
};

const baseLegendForOption = {
  show: true,
  top: 'center',
  right: 0,
  orient: 'vertical'
};

const baseSeriesForOption = {
  type: 'bar',
  barCategoryGap: '10%',
  avoidLabelOverlap: false,
  showEmptyCircle: true,
  itemStyle: {
    borderRadius: 5,
    borderColor: '#333333',
    borderWidth: 1,
    color: 'blue'
  },
  label: {
    show: true,
    position: 'top'
  },
  emphasis: {
    label: {
      show: true,
      fontSize: 16,
      fontWeight: 'normal'
    }
  },
  showBackground: true,
  backgroundStyle: {
    color: 'rgba(180, 180, 180, 0.2)'
  },
  data: [120, 200, 150, 80, 70, 110, 130]
};

function BarCharts(props: BaseChartsProps & { yAxisID?: string, flagsData?: Array<FlagType> }): ReactElement {
  const { t } = useTranslation();
  const startColor = '#6C93CF';
  const endColor = '#977599'; // '#6ab3d2';
  const baseColor = interpolateColors(startColor, endColor, props.datas.length);
  const color = props.datas.map((_, index) => baseColor[index % baseColor.length]);
  const total = props.datas.reduce((acc, curr) => acc + (curr.count || 0), 0);
  const [dataClickState, setDataClickState] = useState<{[key: string]: boolean}>({});
  const [option, setOption] = useState<EChartsOption>({
    color,
    title: {
      show: true,
      text: props.on,
      left: 'center',
      textStyle: {
        fontSize: 12,
        fontWeight: 'normal'
      }
    },
    tooltip: baseTooltipForOption,
    xAxis: {
      type: 'category',
      axisLabel: {
        interval: 0,
        rotate: 0
      },
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    },
    yAxis: {
      type: 'value'
    },
    series: baseSeriesForOption
  });

  const getBooleanOrOtherName = (element: SerieDataType) => {
    switch (element.soloValue) {
    case t('common.utils.no'):
      return false;
    case t('common.utils.yes'):
      return true;
    case t('common.utils.notFound'):
      return t('common.utils.notFound');
    default:
      return element.soloValue;
    }
  };

  const getName = (element: DataType) => {
    if (element.value === undefined && element.displayValue === undefined && element.range === undefined) return t('common.utils.notFound');

    if (element.displayValue !== undefined) {
      return `${element.displayValue.substring(0, 10)  }...`;
    }

    if (element.value === undefined) {
      switch (props.dataType) {
      case 'amount':
        return `${formatNumber(element.range?.min, 0, 'EUR')} - ${formatNumber(element.range?.max, 0, 'EUR')}`;
      case 'number':
        return `${element.range.min}-${element.range.max}`;
      default:
        break;
      }
    }
    if (props.dataType === 'countryCode' && element.value !== null) return `${element.value}`;
    if (isArray(element.value) && props.dataType === 'countryCode[]' && element.value !== null) return element.value.length === 0 ? t('common.utils.notFound') : element.value.join(' ');
    const regexCodeAPE = /^[0-9][0-9].[0-9][0-9][A-Z]$/;

    if (regexCodeAPE.test(element.value)) {
      if (Array.isArray(APE.APE) && APE.APE.length > 0) {
        const elementFind = APE.APE.some((ape: { code: string }) => ape.code === element.value) ? APE.APE.find((ape: { code: string }) => ape.code === element.value)?.name : t('common.utils.notFound');

        return elementFind ? `${element.value} - ${elementFind}` : t('common.utils.notFound');
      }
    }

    switch (element.value) {
    case false:
      return t('common.utils.no');
    case true:
      return t('common.utils.yes');
    case '':
      return t('common.utils.notFound');
    case null:
      return t('common.utils.notFound');
    default:
      return element.value;
    }
  };

  const getDisplayValueFullName = (name: string) => Object.values(props.datas).find((data) => {
    if (data.displayValue !== undefined) {
      return data.displayValue.includes(name.replace('...', ''));
    }
  });

  function getFlagLabel(d: DataType) {
    if (!isString(getName(d)) || props.flagsData?.length === 0) return '';

    if (props.dataType === 'countryCode') {
      const flagToFind = getCountryISO2(getName(d));
      if (getName(d) === t('common.utils.notFound')) return '';

      return getFlagEmoji(flagToFind) || '';
    }
    if (props.dataType === 'countryCode[]') {
      const countryCodes = getName(d).split(' ');
      if (getName(d) === t('common.utils.notFound')) return '';
      const emojis = countryCodes.map((code: string) => {
        const flagParsed = getCountryISO2(code);

        return flagParsed ? getFlagEmoji(flagParsed) || '' : '';
      });

      return emojis.join('');
    }

    return '';
  }

  const makeChart = () => {
    setOption((prevState: EChartsOption) => ({
      ...prevState,
      legend: baseLegendForOption,
      xAxis: {
        axisLabel: {
          ...prevState.axisLabel,
          rich: {
            flagEmojiStyle: {
              fontFamily: 'NotoColorEmojiLimited'
            }
          },
          rotate: props.dataType === 'amount' || props.dataType === 'countryCode' || props.dataType === 'countryCode[]' || props.datas.map((d) => {
            const regexCodeAPE = /^[0-9][0-9].[0-9][0-9][A-Z]$/;

            return (regexCodeAPE.test(d.value));
          }).some(bool => bool) ? 25 : 0
        },
        data: props.datas
          .sort(sortVeegilenzColor)
          .map((d) => {
            const regexCodeAPE = /^[0-9][0-9].[0-9][0-9][A-Z]$/;
            const flagEmoji = getFlagLabel(d);
            const name = getName(d);

            return (props.dataType === 'countryCode' || props.dataType === 'countryCode[]') && name !== t('common.utils.notFound') ?
              `{flagEmojiStyle|${flagEmoji}} ${name}` : regexCodeAPE.test(d.value) ? `${name.substring(0, 20)  }...` : name;
          })
      },
      series: [
        {
          ...baseSeriesForOption,
          label: {
            ...baseSeriesForOption.label,
            fontSize: props.datas.length > 6 ?  props.datas.length > 10 ? 8 : 10 : 12,
            rich: {
              flagEmojiStyle: {
                fontFamily: 'NotoColorEmojiLimited'
              }
            },
            formatter: (name: {value: number}) => (`${name.value} (${((name.value / total) * 100).toFixed(2)}%)`)
          },
          tooltip: {
            show: true,
            trigger: 'item',
            formatter(params: {
              data: any, marker: string, name: string, value: string, percent: string
            }) {
              const DisplayValueOrName = getDisplayValueFullName(params.name)?.displayValue || params.name;
              const ParsedFlag = params.name.startsWith('{flagEmojiStyle|') ? params.name.substring('{flagEmojiStyle|'.length).replace('}', '').split(' ') : undefined;
              const flagEmojiStyle = params.name.startsWith('{flagEmojiStyle|') ? 'font-family: NotoColorEmojiLimited;' : '';
              const nameToDisplay = `<span style="${flagEmojiStyle}">${ ParsedFlag ? ParsedFlag[0] : DisplayValueOrName}</span> ${ ParsedFlag ? ParsedFlag.slice(1).join(' ') : ''}`;
              if (params.data.soloValue) {
                const regexCodeAPE = /^[0-9][0-9].[0-9][0-9][A-Z]$/;

                if (regexCodeAPE.test(params.data.soloValue) && Array.isArray(APE.APE) && APE.APE.length > 0) {
                  const elementFind = APE.APE.some((ape: { code: string }) => ape.code === params.data.soloValue) ? APE.APE.find((ape: { code: string }) => ape.code === params.data.soloValue)?.name : t('common.utils.notFound');

                  return (`${params.marker} ${params.data.soloValue} - ${elementFind}<span style="float: right; margin-left: 20px;"><b>${params.value} (${params.data.percentage})%</b></span>`);
                }
              }

              return `${params.marker} ${nameToDisplay}<span style="float: right; margin-left: 20px;"><b>${params.value} (${params.data.percentage})%</b></span>`;
            }
          },
          data: props.datas.map(d => ({
            label: {
              rich: {
                flagEmojiStyle: {
                  fontFamily: 'NotoColorEmojiLimited'
                }
              }
            },
            value: d.count,
            range: getRange(d),
            soloValue: getValue(d),
            percentage: (((d.count || 0) / total) * 100).toFixed(2)
          }))
        }
      ]
    }));
  };

  const onDataClick = (params: {data: {range: string, soloValue: string}}) => {
    const echartsData = { range: params.data.range, value: params.data.soloValue };
    const name = echartsData.range ? echartsData.range : echartsData.value;

    setDataClickState(prev => ({
      [name]: !prev[name]
    }));
    if (name === null || name === undefined) return;
    props.onClick ? props.onClick(undefined, undefined, undefined, true, echartsData) : '';
  };

  const onEvents = {
    click: onDataClick
  };

  useEffect(() => {
    makeChart();
  }, [props.datas]);

  useEffect(() => {
    if (props.selected === undefined && Object.keys(dataClickState).length !== 0) {
      setDataClickState({});
    }
  }, [props.selected]);

  useEffect(() => {
    setOption((prevState: EChartsOption) => ({
      ...prevState,
      series: prevState.series.map((serie: any) => ({
        ...serie,
        data: serie.data.map((serieData: any, index: number) => {
          const legendName = serieData.range ? serieData.range : getBooleanOrOtherName(serieData);
          const currentColorIfDefine: string = serieData.soloValue ? String(serieData.soloValue) : '';
          const colorToDisplay = colorTableForVeeligenz[currentColorIfDefine] !== undefined ? colorTableForVeeligenz[currentColorIfDefine] : prevState.color[index];

          return {
            ...serieData,
            itemStyle: {
              ...serieData.itemStyle,
              borderColor: dataClickState[legendName] ? darken(colorToDisplay, 0.5) : darken(colorToDisplay, 0.2),
              borderWidth: dataClickState[legendName] ? 2 : 1,
              color: dataClickState[legendName] ? darken(colorToDisplay, 0.2) : colorToDisplay
            },
            label: {
              ...serieData.label,
              fontWeight: dataClickState[legendName] ? 900 : 'normal',
              color: dataClickState[legendName] ? darken(colorToDisplay, 0.1) : 'black'
            }
          };
        })
      }))
    }));
  }, [dataClickState]);

  return (
    <ReactECharts
      option={option}
      onEvents={onEvents}
      lazyUpdate
      style={{ height: '100%', width: '100%' }}
    />
  );
}

export default BarCharts;
