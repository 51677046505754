import React, { ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import VEEGILENZ from '../../../assets/VEEGILENZ.svg';
import KeyValueWithCard from '../../components/keyvaluewithcard';
import { VeegilenzScores } from './veegilenz';

interface VeegilenzCardProps {
  score: number,
  veegilenzDatas: VeegilenzScores
}

function VeegilenzCard(props: VeegilenzCardProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();

  const scoreColor = [
    {
      light: theme.palette.grey['300'],
      main: theme.palette.grey['300'],
      dark: theme.palette.grey['800'],
      contrastText: theme.palette.grey['300']
    },
    theme.palette.success,
    theme.palette.warning,
    theme.palette.error,
    {
      light: theme.palette.grey['800'],
      main: theme.palette.grey['800'],
      dark: theme.palette.grey['200'],
      contrastText: theme.palette.grey['300']
    }
  ];

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <img src={VEEGILENZ} alt='Veegilenz' height={24} />
        {
          /*
        <Paper
          elevation={0}
          sx={{
            bgcolor: scoreColor[Math.floor(props.score)].light,
            color: scoreColor[Math.floor(props.score)].dark,
            width: '100%'
          }}
        >
          <Stack
            sx={{
              width: '100%',
              height: '68px',
              textAlign: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography variant='h1'>{props.score}</Typography>
          </Stack>
        </Paper>
        */
        }
      </Stack>
      <Stack direction='row' spacing={2}>
        <Stack spacing={2}>
          <KeyValueWithCard
            keyValueCard={{
              bgColor: scoreColor[Math.floor(props.veegilenzDatas.directIndex || 0)].light,
              color: scoreColor[Math.floor(props.veegilenzDatas.directIndex || 0)].dark,
              Key: t('veegilenz.directIndex'),
              width: '100%',
              height: '28px',
              padding: '4px 8px',
              size: 'small',
              value: props.veegilenzDatas.directIndex || '-'
            }}
          />
          <KeyValueWithCard
            keyValueCard={{
              bgColor: props.veegilenzDatas.corporateNetwork ? scoreColor[Math.floor(props.veegilenzDatas.corporateNetwork)].light : theme.palette.grey['400'],
              color: props.veegilenzDatas.corporateNetwork ? scoreColor[Math.floor(props.veegilenzDatas.corporateNetwork)].dark : theme.palette.grey['700'],
              Key: t('veegilenz.corporateNetwork'),
              width: '100%',
              height: '28px',
              padding: '4px 8px',
              size: 'small',
              value: props.veegilenzDatas.corporateNetwork || '-'
            }}
          />
          <KeyValueWithCard
            keyValueCard={{
              bgColor: props.veegilenzDatas.individualNetwork ? scoreColor[Math.floor(props.veegilenzDatas.individualNetwork)].light : theme.palette.grey['400'],
              color: props.veegilenzDatas.individualNetwork ? scoreColor[Math.floor(props.veegilenzDatas.individualNetwork)].dark : theme.palette.grey['700'],
              Key: t('veegilenz.individualNetwork'),
              width: '100%',
              height: '28px',
              padding: '4px 8px',
              size: 'small',
              value: props.veegilenzDatas.individualNetwork || '-'
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default VeegilenzCard;
