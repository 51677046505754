import React, { ReactElement, useState } from 'react';
import Paper, { PaperProps } from '@mui/material/Paper';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { IconBuilding, IconCopy, IconExternalLink, IconUser } from '@tabler/icons-react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import dnaConfig from '../../../../config/dna.config.json';
import { WEBAPP_NAME } from '../../../../env/env';

export interface BaseEntitiesCardsProps extends PaperProps {
  entity: PersonEntity | CompanyEntity,
  onlyName?: true,
  compact?: true,
  full?: true,
  width?: string,
  height?: string
}

function BaseEntitiesCards(props: BaseEntitiesCardsProps & { children?: React.ReactNode | React.ReactNode[] }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [copied, setCopied] = useState(false);

  return (
    <Paper sx={{ height: props.height, maxWidth: props.width || ((props.compact || props.onlyName) ? undefined : 480), width: props.width || (props.compact || props.onlyName) ? 'max-content' : 480, p: 1 }} {...props}>
      <Stack spacing={1} width='100%'>
        <Stack spacing={2} direction='row' width='100%' alignItems='center' pl={1}>
          <Stack spacing={2} direction='row' width='100%' alignItems='center' overflow='auto'>
            {props.entity.entityType === 'deecPerson' ? <IconUser size={20} /> : <IconBuilding size={20} />}
            <Box overflow='auto' p={1}>
              <Typography variant='h4' whiteSpace='nowrap'>
                {props.entity.name}
              </Typography>
            </Box>
            <Tooltip
              title={copied ? t('common.utils.copied') : `${t('common.utils.copy')}`}
              arrow
              placement='top'
              onOpen={() => setCopied(false)}
            >
              <IconCopy
                style={{
                  marginLeft: '2px',
                  marginRight: '6px',
                  cursor: 'pointer'
                }}
                color={theme.palette.grey['600']}
                size={12}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(props.entity.name || props.entity.entityId)
                    .then(() => {
                      setCopied(true);
                    });
                }}
              />
            </Tooltip>
          </Stack>
          <Button
            variant='textIcon'
            sx={{ ml: 'auto !important' }}
            size='small'
            onClick={() => window.open(`/${WEBAPP_NAME}/${dnaConfig.routes[props.entity.entityType === 'deecCompany' ? 'companies' : 'persons'].baseUrl}/${props.entity.entityId}`)}
          >
            <IconExternalLink size={18} />
          </Button>
        </Stack>
        {!props.onlyName && props.children}
      </Stack>
    </Paper>
  );
}

export default BaseEntitiesCards;
