import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { currencyFormatterProps } from '@deecision/common-webapp/utils';
import { IconChevronRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { IDataBlock } from '@deecision/dna-interfaces';
import { DisplayJson } from '@deecision/common-webapp/components';
import { Column } from '../../../../containers/tables/types.tables';
import EvenOddTable from '../../../../containers/tables/evenodd/table.evenodd.tables';
import { DataBlocksQueryService, DataElementsQueryService } from '../../../../../api/services/query.services';

type RowsType = {
  id: any,
  path: string,
  dataType: string,
  entityType: string,
  provider: string | undefined,
  sourceDataElementId: string | undefined,
  originDataBlockId: string | undefined,
  voirPlus: JSX.Element
}[][]

function DataBlocksTabs(): ReactElement {
  const dataElementId = window.location.pathname.split('/')[4];
  const dataElementService = new DataElementsQueryService({ entityType: 'deecCompany' });
  const dataBlockService = new DataBlocksQueryService({ entityType: 'deecCompany' });
  // const [dataBlocksValue, setDataBlocksValue] = useState<DataBlocksType[]>([]);
  const [dataBlocksQueryList, setDataBlocksQueryList] = useState<IDataBlock[][]>([[]]);
  // const [totalCount, setTotalCount] = useState<number>(0);
  const navigate = useNavigate();
  const [rows, setRows] = useState<RowsType>([[]]);

  console.log(dataElementId);
  const parseDataRow = (dataRow: any/* typeof dataBlocksQueryList */) => {
    const rowsParsed: any /* RowsType */ =
      dataRow.map((dataBlocksInfo: any[]) => (
        dataBlocksInfo.map((dataBlockInfo: { _id: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined, dataInfo: { path: any, dataType: any }, entityRef: { entityType: any }, data: any, _source: { provider: { id: any }, dataElementId: any } }) => (
          {
            id: dataBlockInfo._id,
            path: dataBlockInfo.dataInfo.path,
            dataType: dataBlockInfo.dataInfo.dataType,
            entityType: dataBlockInfo.entityRef.entityType,
            // <Link href={`../entities/${dataElement.entityRef.entityId as string}`}
            //   onClick={() => {
            //     localStorage.setItem(`entityType${dataElement.entityRef.entityId}`, dataElement.entityRef.entityType);
            //   }}>
            //   {dataElement.entityRef.name}<br/><Typography fontSize={12} fontWeight={300}>({dataElement.entityRef.entityId})</Typography>
            // </Link>,
            data:
            <Box width='350px'>
              <DisplayJson data={dataBlockInfo.data} />
            </Box>,
            provider: dataBlockInfo._source?.provider.id,
            sourceDataElementId: dataBlockInfo._source?.dataElementId,
            // originDataBlockId: dataBlockInfo._source?.originDataBlockId,
            voirPlus:
            <Tooltip title={dataBlockInfo._id} >
              <IconButton onClick={() => {
                navigate(`../../../datablocks/${dataBlockInfo._id}`);
              }}>
                <IconChevronRight />
              </IconButton>
            </Tooltip>
          }
        ))
      ));
    setRows(rowsParsed);
    // setTotalCount(rowsParsed.length);

  };

  useEffect(() => {
    dataElementService.findInAllEntities({ _id: dataElementId })
      .then((res) => {
        if (res.data) {
          const tmpDataBlocks = [];
          const tmpDataBlocksQueryList: IDataBlock[][] = [];

          for (const dataBlock of res.data[0].provides.dataBlocks) {
            tmpDataBlocks.push({ key: dataBlock.path, value: dataBlock.type });
          }
          // setDataBlocksValue(tmpDataBlocks);

          tmpDataBlocks.forEach((dataBlock) => {
            dataBlockService.findByCustomQuery({
              dataType: dataBlock.value
            }).then((resDataBlock) => {
              if (resDataBlock.data) {
                tmpDataBlocksQueryList.push(resDataBlock.data);
                parseDataRow(tmpDataBlocksQueryList);
              }
            });
          });
          setDataBlocksQueryList(tmpDataBlocksQueryList);

        }
      });

  }, []);

  const columns: Column<currencyFormatterProps>[] = [
    {
      id: 'path',
      label: 'Path'
    },
    {
      id: 'dataType',
      label: 'Data Type'
    },
    {
      id: 'entityType',
      label: "Entity Type"
    },
    {
      id: 'data',
      label: "Data"
    },
    {
      id: 'provider',
      label: "Provider Id"
    },
    {
      id: 'sourceDataElementId',
      label: "dataElementId"
    },
    // {
    //   id: 'originDataBlockId',
    //   label: "originDataBlockId"
    // },
    {
      id: 'voirPlus',
      label: "Voir Plus"
    }
  ];

  return (
    <Grid xs={12}>
      <EvenOddTable<currencyFormatterProps> label='dataBlocks' columns={columns} rows={rows.flat() || []} totalCount={rows.flat().length}/>
    </Grid>
  );
}

export default DataBlocksTabs;
