import { cloneDeep } from 'lodash';
import { CustomSegmentation, CustomSegmentationFilter, CustomSegmentationView } from '../types.segmentations';

export function sanitiseCustomSegmentationFilter (customSegmentationFilter: CustomSegmentationFilter) {
  const customSegmentationFilterCopy = cloneDeep(customSegmentationFilter);

  customSegmentationFilterCopy.subItemSpecs = customSegmentationFilterCopy.subItemSpecs?.filter(subItemSpec => subItemSpec?.id).map(subItemSpec => sanitiseCustomSegmentationFilter(subItemSpec)) as typeof customSegmentationFilter.subItemSpecs;

  return customSegmentationFilter;
}

export function sanitiseCustomSegmentationCriteria(customSegmentationCriteria: CustomSegmentationFilter) {
  const customSegmentationCriteriaCopy = cloneDeep(customSegmentationCriteria);

  if (customSegmentationCriteriaCopy.filterSpec?.subItemSpecs) {
    customSegmentationCriteriaCopy.filterSpec.subItemSpecs = customSegmentationCriteriaCopy.filterSpec.subItemSpecs?.filter(subItemSpec => subItemSpec?.id).map(subItemSpec => sanitiseCustomSegmentationFilter(subItemSpec)) as typeof customSegmentationCriteriaCopy.filterSpec.subItemSpecs;
  }

  return customSegmentationCriteriaCopy;
}

export function sanitiseCustomSegmentationView(customSegmentationView: CustomSegmentationView) {
  const customSegmentationViewCopy = cloneDeep(customSegmentationView);

  customSegmentationViewCopy.segmentationCriterias = customSegmentationViewCopy.segmentationCriterias?.filter(segmentationCriteria => segmentationCriteria?.id).map(segmentationCriteria => sanitiseCustomSegmentationCriteria(segmentationCriteria)) as typeof customSegmentationViewCopy.segmentationCriterias;

  return customSegmentationViewCopy;
}

export function sanitizeCustomSegmentation(data: CustomSegmentation): CustomSegmentation {
  const dataCopy = cloneDeep(data);

  dataCopy.preFilters = dataCopy.preFilters?.filter(globalFilteringItem => globalFilteringItem?.id).map(globalFilteringItem => sanitiseCustomSegmentationFilter(globalFilteringItem)) || [];
  dataCopy.views = dataCopy.views?.filter(view => view?.id).map(view => sanitiseCustomSegmentationView(view));

  return dataCopy;
}
