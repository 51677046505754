import React, { ReactElement } from 'react';
import {
  IconAlertCircleFilled,
  IconCircleCheckFilled,
  IconCircleXFilled
} from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';

export interface StatusProps {
  status?: 'success' | 'warning' | 'error'
}

function Status(props: StatusProps): ReactElement {
  const theme = useTheme();

  return (
    <>
      {props.status !== undefined &&
        (props.status === 'success' &&
          <IconCircleCheckFilled size={16} style={{ color: theme.palette.success.main }}/> ||
          props.status === 'warning' &&
          <IconAlertCircleFilled size={16} style={{ color: theme.palette.warning.main }}/> ||
          props.status === 'error' &&
          <IconCircleXFilled size={16} style={{ color: theme.palette.error.main }}/>) ||
        <></>
      }
    </>
  );
}

export default Status;
