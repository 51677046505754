import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import CompanySmallCards, { SmallCompanyCardEntity } from './company.smallcards';
import PersonSmallCards, { SmallPersonCardEntity } from './person.smallcards';

interface EntitiesSmallCardsProps<T> {
  bgColor?: string,
  sideColor?: string,
  entityType: string,
  entity: T
}

function EntitiesSmallCards<T>(props: EntitiesSmallCardsProps<T>): ReactElement {
  const { t } = useTranslation();
  let card: ReactElement | undefined;

  if (props.entityType === 'deecCompany') {
    card = <CompanySmallCards entity={props.entity as SmallCompanyCardEntity} />;
  } else if (props.entityType === 'deecPerson') {
    card = <PersonSmallCards entity={props.entity as SmallPersonCardEntity} />;
  }

  return (
    <Paper
      variant='hoverElevation1'
      sx={{
        width: '100%',
        whiteSpace: 'nowrap',
        p: 0,
        backgroundColor: props.sideColor
      }}
    >
      <Paper
        elevation={0}
        sx={{
          backgroundColor: props.bgColor || undefined,
          whiteSpace: 'nowrap',
          width: 'calc(100% - 12px)',
          p: 1,
          pl: 2,
          ml: props.sideColor ? 3 : 0
        }}
      >
        {card || t('error.entityTypeNotFound')}
      </Paper>
    </Paper>
  );
}

export default EntitiesSmallCards;
