import React, { ReactElement, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import makeFindOptions from '@deecision/common-webapp/utils/findoptions';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import PersonList from '../../../../../containers/lists/person.lists';
import CompanyList from '../../../../../containers/lists/company.lists';
import SegmentationServices from '../../../segmentation/services/segmentation.services';
import SimpleDisplayFilters, { SimpleFilter } from '../../../../../components/filters/simpledisplay.filters';

function ListTabsPortfolios(): ReactElement {
  const { t } = useTranslation();
  const { id, tab } = useParams();
  const [searchParams] = useSearchParams();
  const [persons, setPersons] = useState<PersonEntity[]>([]);
  const [companies, setCompanies] = useState<CompanyEntity[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0); // [1
  const segmentationServices = new SegmentationServices();
  const [personFilters, setPersonFilters] = useState<SimpleFilter[]>([
    {
      id: 'withCompanies',
      label: t('folder.dashboard.groups.labels.persons.withCompaniesWithAlerts.filter'),
      active: false
    }
  ]);

  const retrieveList = () => {
    segmentationServices.perform({
      dataSetId: tab === 'persons' ? 'deecPersons' : 'deecCompanies',
      globalFilteringItems: [],
      filters: [
        tab === 'companies' ?
          {
            id: 'company_groupMember',
            filterId: 'company_groupMember',
            type: 'filter',
            on: 'company',
            values: [
              `${id}/companies`
            ]
          } :
          {
            id: 'person_groupMember',
            filterId: 'person_groupMember',
            type: 'filter',
            on: 'person1',
            values: [
              personFilters.find(filter => filter.id === 'withCompanies')?.active ? `${id}/persons.withCompanies` : `${id}/persons`
            ]
          }
      ],
      entitiesSettings: {
        includeEntities: true,
        findOptions: makeFindOptions({ searchParams, post: true })
      },
      outputEntities: tab === 'companies' ? 'company' : 'person1',
      segmentationCriterias: []
    })
      .then((res) => {
        tab === 'persons' ? setPersons((res.data?.entities || []) as PersonEntity[]) : setCompanies((res.data?.entities || []) as CompanyEntity[]);
        setTotalCount(res.data?.totalCount || 0);
      });
  };

  useEffect(() => {
    retrieveList();
  }, [id, tab, searchParams, personFilters]);

  return (tab === 'persons' ?
    <Stack spacing={4}>
      <SimpleDisplayFilters filters={personFilters} setFilters={setPersonFilters} />
      <PersonList totalCount={totalCount} data={persons} />
    </Stack> :
    <CompanyList totalCount={totalCount} data={companies} />
  );
}

export default ListTabsPortfolios;
