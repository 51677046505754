import React, { ReactElement, useEffect, useState } from 'react';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { IconFolderPlus } from '@tabler/icons-react';
import Tooltip from '@mui/material/Tooltip';
import PersonList from '../../../lists/person.lists';
import CompanyList from '../../../lists/company.lists';
import dnaConfig from '../../../../../config/dna.config.json';

interface ListSegmentationsProps<T> {
  data: {
    entities: T[],
    totalCount: number
  },
  entityType: 'person' | 'company',
  enableSelect?: boolean
}

function EntitiesListsSegmentations<T>(props: ListSegmentationsProps<T>): ReactElement {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string[]>(props.data.entities.map(entity => entity['entityId' as keyof typeof entity] as string));

  useEffect(() => {
    setSelected(props.data.entities.map(entity => entity['entityId' as keyof typeof entity] as string));
  }, [props.data.entities]);

  return (
    <>
      {props.entityType === 'person' &&
        <PersonList
          {...props}
          data={props.data.entities as PersonEntity[]}
          totalCount={props.data.totalCount}
          basePageSize={20}
          selected={props.enableSelect ? selected : undefined}
          setSelected={props.enableSelect ? setSelected : undefined}
          actionsPadding='16px'
          actions={[
            <Tooltip title={t('folder.createFolder')} placement='top' arrow>
              <Button variant='textIcon' size='small' href={dnaConfig.routes.folders.create.steps.choices}>
                <IconFolderPlus size={18} />
              </Button>
            </Tooltip>
          ]}
        />
      }
      {props.entityType === 'company' &&
        <CompanyList
          {...props}
          data={props.data.entities as CompanyEntity[]}
          totalCount={props.data.totalCount}
          basePageSize={20}
          selected={selected}
          setSelected={setSelected}
          actionsPadding='16px'
          actions={[
            <Tooltip title={t('folder.createFolder')} placement='top' arrow>
              <Button variant='textIcon' size='small' href={dnaConfig.routes.folders.create.steps.choices}>
                <IconFolderPlus size={18} />
              </Button>
            </Tooltip>
          ]}
        />
      }
    </>
  );
}

export default EntitiesListsSegmentations;
