import React, { ReactElement, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import {
  CompanyEntity,
  EntityLinksAggregation,
  LinksAggregation,
  PersonEntity
} from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { IconLinkOff } from '@tabler/icons-react';
import Grid from '@mui/material/Unstable_Grid2';
import EntitiesCards from '../../../components/cards/entities/dispatch.entities.cards';
import DisplayTypesLinksRelations from './types.links.relations';

function DisplayLinksRelations(props: { data: EntityLinksAggregation  }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [entity1, setEntity1] = useState<CompanyEntity | PersonEntity | undefined>(props.data.entity1Object?.[0]);
  const [entity2, setEntity2] = useState<CompanyEntity | PersonEntity | undefined>(props.data.entity2Object?.[0]);
  const [links, setLinks] = useState<LinksAggregation>(props.data.links);

  useEffect(() => {
    setEntity1(props.data.entity1Object?.[0]);
    setEntity2(props.data.entity2Object?.[0]);
    setLinks(props.data.links);
  }, [props.data]);

  return (
    <Box maxWidth='100%' width='min-content' overflow='auto'>
      {entity1 && entity2 &&
        <Stack direction='row' spacing={0} width='min-content'>
          <EntitiesCards entity={entity1} full width='min-content' height='100%' />
          {links && links?.length > 0 ?
            <Grid container width='max-content' alignItems='center'>
              {DisplayTypesLinksRelations({ entity1, entity2, links: links.filter(link => link.type !== 'is.relatedTo') })}
            </Grid> :
            <Grid xs>
              <Stack direction='row' height='auto' alignItems='center' justifyContent='center'>
                <Box sx={{ bgcolor: theme.palette.grey['500'], width: 50, height: 2 }} />
                <Box p={2} pr={1} height='40px'>
                  <IconLinkOff />
                </Box>
                <Typography p={2} pl={1} whiteSpace='nowrap'>{t('entities.relations.dashboard.noLink')}</Typography>
                <Box sx={{ bgcolor: theme.palette.grey['500'], width: 50, height: 2 }} />
              </Stack>
            </Grid>
          }
          <EntitiesCards entity={entity2} full width='min-content' height='100%' />
        </Stack>
      }
    </Box>
  );
}

export default DisplayLinksRelations;
