import React, { ReactElement, useEffect, useState } from 'react';
import { useLoaderData, useParams, useSearchParams } from 'react-router-dom';
import {
  SegmentationData, SegmentationPossibleOutputEntityTypes,
  SegmentationRequest,
  SegmentationWorkflowIds
} from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import TitleComponent from '@deecision/common-webapp/components/title.components';
import Grid from '@mui/material/Unstable_Grid2';
import { IconClipboardData } from '@tabler/icons-react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import makeFindOptions from '@deecision/common-webapp/utils/findoptions';
import { SegmentationFilter } from '@deecision/dna-interfaces/dist/segmentation/segmentationFilters';
import Chip from '@mui/material/Chip';
import { cloneDeep } from 'lodash';
import { BaseCustomUserData } from '../types.segmentations';
import SegmentationsServices from '../services/segmentations.services';
import ViewSegmentationComponent from './view/view.segmentations';
import ChipViewSegmentations from './view/chip.view.segmentations';
import getDynamicGroupsRequest from '../../../modules/deetect/portfolios/utils/dynamicgroups.utils.portfolios';

function RenderSegmentations(): ReactElement {
  const userData = useLoaderData() as { data?: BaseCustomUserData };
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const segmentationService = new SegmentationsServices();
  const [data, setData] = useState<BaseCustomUserData | undefined>(userData?.data? cloneDeep(userData?.data) : undefined);
  const [segmentationData, setSegmentationData] = useState<SegmentationData>();
  const [currentView, setCurrentView] = useState<string | undefined>(data?.data.views?.[0]?.id);
  const [filters, setFilters] = useState<SegmentationFilter[]>([]);
  const [entryCount, setEntryCount] = useState<number>(0);
  const entityType = searchParams.get('entityType');
  const groupId = searchParams.get('groupId');
  const customGroupId = searchParams.get('customGroupId');
  const outputEntitiesFromParams = searchParams.get('outputEntities');
  const workflowIdFromParams = searchParams.get('workflowId');
  const filterIds = searchParams.get('filterIds')?.split(',');
  const filterValues = searchParams.get('filterValues')?.split(',');
  const dataSetId = (entityType?.includes('person') || data?.data.on?.includes('person'))
    ? 'deecPersons'
    : 'deecCompanies';
  const baseFilterGroupMember: SegmentationFilter[] = id ? (customGroupId === 'true' && groupId) ? getDynamicGroupsRequest(groupId, id)?.filters || [] : [
    {
      id: dataSetId === 'deecPersons' ? 'person_groupMember' : 'company_groupMember',
      filterId: dataSetId === 'deecPersons' ? 'person_groupMember' : 'company_groupMember',
      type: 'filter',
      on: dataSetId === 'deecPersons' ? 'person1' : 'company',
      values: groupId && groupId.split(',').length > 1 ? groupId.split(',').map(uniqGroupId => `${id}/${uniqGroupId}`) : [`${id}/${groupId || (dataSetId === 'deecPersons' ? 'persons' : 'companies')}`]
    }
  ] : [];
  const baseFiltersFromUrlParams: SegmentationFilter[] = filterIds && filterValues ? filterIds.map((filterId, index) => ({
    id: filterId,
    filterId,
    type: 'filter',
    on: dataSetId === 'deecPersons' ? 'person1' : 'company',
    values: [filterValues[index] === "true" ? true : filterValues[index] === "false" ? false : filterValues[index]]
  })) : [];

  const performSegmentation = ({ outputEntities, dataUpdated, workflowId }: { outputEntities: SegmentationPossibleOutputEntityTypes, dataUpdated?: typeof data, workflowId: SegmentationWorkflowIds }) => {
    const dataTmp = dataUpdated || data;

    segmentationService.perform({
      dataSetId,
      outputEntities: outputEntitiesFromParams as SegmentationRequest['outputEntities'] || outputEntities,
      workflowId: workflowIdFromParams as SegmentationRequest['workflowId'] || workflowId,
      filters: [
        ...baseFilterGroupMember,
        ...baseFiltersFromUrlParams,
        ...(dataTmp?.data.preFilters as SegmentationRequest['filters'] || []),
        ...filters
      ],
      globalFilteringItems: [],
      segmentationCriterias: dataTmp?.data.views
        ?.find(view => view?.id === currentView)?.segmentationCriterias
        ?.filter(segmentationCriteria => (
          segmentationCriteria && !filters.map(filter => filter.id).includes(segmentationCriteria.id)
        )) || [],
      entitiesSettings: {
        includeEntities: true,
        findOptions: makeFindOptions({ searchParams, post: true })
      }
    })
      .then((res) => {
        setSegmentationData(res.data);
        setData(dataTmp);
        if (!entryCount && res.data) {
          setEntryCount(res.data.totalCount);
        }
      });
  };

  const addFilter = (filter: SegmentationFilter) => {
    setFilters(prevState => [...prevState, filter]);
  };

  const removeFilter = (filterId: string) => {
    setFilters(prevState => [...prevState.filter(f => f.id !== filterId)]);
  };

  const reset = () => {
    setSegmentationData(undefined);
    performSegmentation({
      outputEntities: data?.data.views?.find(view => view?.id === currentView)?.on === 'person' ? 'person1' : data?.data.views?.find(view => view?.id === currentView)?.on as SegmentationPossibleOutputEntityTypes || 'person1',
      workflowId: data?.data.views?.find(view => view?.id === currentView)?.workflowId || 'person1_link_person2'
    });
  };

  useEffect(() => {
    performSegmentation({
      outputEntities: data?.data.views?.find(view => view?.id === currentView)?.outputEntities || 'person1',
      workflowId: data?.data.views?.find(view => view?.id === currentView)?.workflowId || 'person1_link_person2'
    });
  }, [filters, id, currentView, searchParams]);

  return (
    <Grid container spacing={4}>
      <Grid xs container alignItems='center'>
        <Grid>
          <TitleComponent title={data?.title || ''} icon={IconClipboardData} />
        </Grid>
        <Grid>
          <Chip label={`${entryCount} ${(data?.data.views?.find(view => view?.id === currentView)?.outputEntities || 'person1').includes('person') ? t('entities.persons.label') : t('entities.companies.label')}`} />
        </Grid>
        <Grid>
          <Chip
            label={`${data?.data.views?.length || 0} ${(data?.data.views?.length || 0) > 1 ? t('segmentation.builder.views.label') : t('segmentation.builder.views.unique')}`}
            color='primary'
          />
        </Grid>
      </Grid>
      <Grid>
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id='views-simple-select-autowidth-label'>{t('segmentation.builder.views.label')}</InputLabel>
          <Select
            labelId='views-simple-select-autowidth-label'
            id='views-simple-select-autowidth'
            value={currentView}
            onChange={event => setCurrentView(event.target.value)}
            renderValue={selected => data?.data.views?.find(view => view?.id === selected)?.label}
            autoWidth
            label={t('segmentation.builder.views.label')}
          >
            {data?.data.views?.filter(view => view?.id).map(view => (
              <MenuItem key={view.id} value={view.id} sx={{ minWidth: 80 }} disabled={view.id === currentView}>
                <ChipViewSegmentations view={view} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {data && data?.data.views &&
        <Grid xs={12}>
          <ViewSegmentationComponent
            key={currentView}
            data={data}
            setData={setData}
            path={`data.views[${data?.data.views?.findIndex(view => view?.id === currentView) || 0}]`}
            segmentationData={segmentationData}
            setSegmentationData={setSegmentationData}
            filters={filters}
            addFilter={addFilter}
            removeFilter={removeFilter}
            reset={reset}
          />
        </Grid>
      }
    </Grid>
  );
}

export default RenderSegmentations;
