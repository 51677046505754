import React, { ReactElement, useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';
import { CompanyEntity, EntityLinksAggregation, LinksAggregation, PersonEntity } from '@deecision/dna-interfaces';
import Stack from '@mui/material/Stack';
import { IconBuilding, IconCirclesRelation, IconExchange, IconLinkOff, IconTopologyRing2 } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import IconBuildings from '@deecision/common-webapp/assets/custom/IconBuildings';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { set } from 'lodash';
import EntitiesCards from '../../../../components/cards/entities/dispatch.entities.cards';
import dnaConfig from '../../../../../config/dna.config.json';
import { getProxemeeLevel } from '../../../../utils/getter';

interface RelationProps extends BoxProps {
  data?: {
    entity1: CompanyEntity | PersonEntity | undefined,
    entity2: CompanyEntity | PersonEntity | undefined,
    links: EntityLinksAggregation | undefined
  }
}

function RelationComponent(props?: RelationProps): ReactElement {
  const data = useLoaderData() as { data : EntityLinksAggregation[] };
  const navigate = useNavigate();
  const searchParams = useSearchParams()[0];
  const { t } = useTranslation();
  const theme = useTheme();
  const [entity1, setEntity1] = useState<CompanyEntity | PersonEntity | undefined>(props?.data? props.data.entity1 : data.data[0]?.entity1Object?.[0]);
  const [entity2, setEntity2] = useState<CompanyEntity | PersonEntity | undefined>(props?.data? props.data.entity2 : data.data[0]?.entity2Object?.[0]);
  const [links, setLinks] = useState<LinksAggregation>(props?.data?.links ? props.data.links.links : data.data[0]?.links);

  useEffect(() => {
    if (data) {
      setEntity1(data.data[0]?.entity1Object?.[0]);
      setEntity2(data.data[0]?.entity2Object?.[0]);
      setLinks(data.data[0]?.links);
    }
  }, [data]);

  useEffect(() => {
    if (searchParams.get('entity1') !== entity1?.entityId && !props || searchParams.get('entity2') !== entity2?.entityId && !props) {
      window.location.reload();
    }
  }, [searchParams]);

  useEffect(() => {
    if (props) {
      setEntity1(props.data?.entity1);
      setEntity2(props.data?.entity2);
      setLinks(props.data?.links?.links || []);
    }
  }, [props]);

  return (
    <Box width='100%' overflow='auto' {...props}>
      <Stack direction='row' spacing={0} alignItems='center'>
        {entity1 &&
          <Stack direction='row' spacing={2}>
            {!props &&
              <Tooltip title={t('entities.relations.stepper.step2.changeEntity')} placement='top' arrow>
                <Button variant='textIcon' onClick={() => navigate({ pathname: dnaConfig.routes.relations.relativeUrl, search: `?step=2&entity1=${entity2?.entityId}&entity1Type=${entity2?.entityType}` })}>
                  <IconExchange size={22} />
                </Button>
              </Tooltip>
            }
            <EntitiesCards entity={entity1} compact />
          </Stack>
        }
        {entity2 &&
          <>
            {entity1 && entity2 &&
              <Stack direction='row' height='auto' alignItems='center' justifyContent='center'>
                <Box sx={{ bgcolor: theme.palette.grey['500'], width: 32, height: 2 }}/>
                {links && links.length > 0 ?
                  entity1?.entityType === entity2?.entityType && entity1?.entityType === 'deecPerson' ?
                    <Stack direction='row' height='auto' alignItems='center' justifyContent='center'>
                      <Tooltip title={`${t('proxemee.label')} (${links.find(link => link.details?.prxmee)?.details?.prxmee?.score})`} arrow placement='top'>
                        <Paper
                          sx={{
                            color: getProxemeeLevel((links.find(link => link.details?.prxmee)?.details?.prxmee as any)?.level?.codeAsInt || 0, t, theme)?.color,
                            bgcolor: getProxemeeLevel((links.find(link => link.details?.prxmee)?.details?.prxmee as any)?.level?.codeAsInt || 0, t, theme)?.bgcolor
                          }}
                        >
                          <Stack spacing={2} direction='row' alignItems='center'>
                            <IconCirclesRelation />
                            <IconTopologyRing2 />
                            <Typography variant='h4'>{getProxemeeLevel((links.find(link => link.details?.prxmee)?.details?.prxmee as any)?.level?.codeAsInt || 0, t, theme)?.label || t('error.notFound')}</Typography>
                          </Stack>
                        </Paper>
                      </Tooltip>
                      <Box sx={{ bgcolor: theme.palette.grey['500'], width: 16, height: 2 }}/>
                      <Tooltip title={t('relations.inCommon.companies')} arrow placement='top'>
                        <Paper>
                          <Stack spacing={2} direction='row' alignItems='flex-end'>
                            <Stack direction='row' alignItems='center'>
                              <IconCirclesRelation />
                              {(links.find(link => link.details?.prxmee)?.details?.nbCompaniesInCommon || 0) > 0 ?
                                <IconBuildings stroke={theme.palette.grey['700']} /> : <IconBuilding />
                              }
                            </Stack>
                            <Typography variant='h3'>{links.find(link => link.details?.prxmee)?.details?.nbCompaniesInCommon}</Typography>
                          </Stack>
                        </Paper>
                      </Tooltip>
                    </Stack> :
                    <Box p={2} height='40px'>
                      <IconCirclesRelation />
                    </Box> :
                  [
                    <Box p={2} height='40px'>
                      <IconLinkOff/>
                    </Box>,
                    <Typography p={2} pl={1} whiteSpace='nowrap'>{t('entities.relations.dashboard.noLink')}</Typography>
                  ]
                }
                <Box sx={{ bgcolor: theme.palette.grey['500'], width: 32, height: 2 }}/>
              </Stack>
            }
            <Stack direction='row' spacing={2}>
              <EntitiesCards entity={entity2} compact />
              {!props &&
                <Tooltip title={t('entities.relations.stepper.step2.changeEntity')} placement='top' arrow>
                  <Button variant='textIcon' onClick={() => navigate({ pathname: dnaConfig.routes.relations.relativeUrl, search: `?step=2&entity1=${entity1?.entityId}&entity1Type=${entity1?.entityType}` })}>
                    <IconExchange size={22} />
                  </Button>
                </Tooltip>
              }
            </Stack>
          </>
        }
      </Stack>
    </Box>
  );
}

export default RelationComponent;
