import React, { ReactElement } from 'react';
import { useLoaderData, useLocation } from 'react-router-dom';
import { BaseGroup } from '@deecision/dna-interfaces';
import Grid from '@mui/material/Unstable_Grid2';
import TitleComponent from '@deecision/common-webapp/components/title.components';
import { IconArrowNarrowRight, IconFolder, IconUsers } from '@tabler/icons-react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Chip, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconBuildings from '@deecision/common-webapp/assets/custom/IconBuildings';
import dnaConfig from '../../../../../config/dna.config.json';

function IdComponentPortfolios(): ReactElement {
  const data = useLoaderData() as { data : BaseGroup };
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid xs>
        <TitleComponent
          title={data.data.label}
          icon={IconFolder}
          chips={[
            <Chip
              key='persons'
              label={
                <Stack direction='row' spacing={2} alignItems='flex-end'>
                  <IconUsers color={theme.palette.primary.dark} />
                  <Typography variant='h4' color={theme.palette.primary.dark}>{data.data.nbPersons}</Typography>
                </Stack>
              }
              sx={{ bgcolor: theme.palette.primary.light }}
            />,
            <Chip
              key='companies'
              label={
                <Stack direction='row' spacing={2} alignItems='flex-end'>
                  <IconBuildings stroke={theme.palette.secondary.dark} />
                  <Typography variant='h4' color={theme.palette.secondary.dark}>{data.data.nbCompanies}</Typography>
                </Stack>
              }
              sx={{ bgcolor: theme.palette.secondary.light }}
            />
          ]}
        />
      </Grid>
      {!location.pathname.includes(dnaConfig.childRoutes.segmentations.baseUrl) &&
        <Grid>
          <Button
            variant='contained'
            endIcon={<IconArrowNarrowRight size={24} />}
            href={`${dnaConfig.routes.portfolios.relativeUrl}/${data.data.baseGroupId}/${dnaConfig.childRoutes.segmentations.baseUrl}`}
          >
            {t('portfolios.header.btn')}
          </Button>
        </Grid>
      }
    </Grid>
  );
}

export default IdComponentPortfolios;
