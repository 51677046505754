import React from 'react';
import createElementRoutes from '@deecision/common-webapp/router/utils/create.element.routes';
import { IconBuildingFactory } from '@tabler/icons-react';
import dnaConfig from '../../../../config/dna.config.json';
import ComponentFactory from './components/component.factory';
import routesSegmentationFactory from './segmentation/routes.segmentation.factory';

const routesFactory = createElementRoutes({
  id: dnaConfig.routes.admin.factory.label,
  i18nKey: dnaConfig.routes.admin.factory.i18nKey,
  path: dnaConfig.routes.admin.factory.baseUrl,
  icon: IconBuildingFactory,
  component: <ComponentFactory />,
  idComponent: <></>,
  childrens: [
    routesSegmentationFactory
  ]
});

export default routesFactory;
