import React, { ReactElement } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { CustomSegmentationView } from '../../types.segmentations';

function ChipViewSegmentations(props: { view: CustomSegmentationView }): ReactElement {
  const { t } = useTranslation();

  return (
    <Chip
      key={props.view.id}
      sx={{
        '& .MuiChip-label': {
          pr: 1
        }
      }}
      color='primary'
      label={
        <Stack direction='row' spacing={2} alignItems='center'>
          <Typography>{props.view.label}</Typography>
          <Chip
            sx={{
              '& .MuiChip-label': {
                pr: 3,
                fontWeight: 500
              }
            }}
            color='success'
            label={`${props.view.segmentationCriterias?.filter(segmentationCriteria => segmentationCriteria)?.length || 0} ${(props.view.segmentationCriterias?.filter(segmentationCriteria => segmentationCriteria)?.length || 0) > 1 ? t('filters.label') : t('filters.unique') }`}
            size='small'
          />
          <Chip
            label={`${t('segmentation.render.views.on')} ${t(`segmentation.builder.filter.${props.view.on}`)}`}
            sx={{
              '& .MuiChip-label': {
                pr: 3,
                fontWeight: 500
              }
            }}
            size='small'
          />
        </Stack>
      }
    />
  );
}

export default ChipViewSegmentations;
