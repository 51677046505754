import React, { ReactElement, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { IconBuildingFactory, IconPlus } from '@tabler/icons-react';
import Button from '@mui/material/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dnaConfig from '../../../../../config/dna.config.json';
import { CustomUserData } from '../../../../containers/segmentations/types.segmentations';
import ListSegmentations from '../../../../containers/segmentations/list.segmentations';
import CustomAccordion from '../../../../components/accordion';
import {
  UserDataCustomSegmentationService
} from '../../../../containers/segmentations/services/segmentations.services';

function ComponentFactory(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userDataFactoryService = new UserDataCustomSegmentationService<CustomUserData>({ userId: 'admin' });
  const [userData, setUserData] = useState<CustomUserData[]>();

  useEffect(() => {
    userDataFactoryService.getAll({ filters: [{ id: 'type', scope: 'type', value: 'segmentation' }] })
      .then((res) => {
        setUserData(res.data);
      });
  }, []);

  return (
    <Stack spacing={4} pt={4}>
      <Stack spacing={4} direction='row'>
        <IconBuildingFactory />
        <Typography variant='h2'>{t('admin.factory.label')}</Typography>
      </Stack>
      <Stack>
        <CustomAccordion title={t('admin.factory.dashboard.multiple')} disabled />

        <CustomAccordion title={t('segmentation.multiple')} defaultOpen>
          <ListSegmentations
            data={userData || []}
            totalCount={userData?.length || 0}
            actions={[
              <Button variant='contained' endIcon={<IconPlus />} onClick={() => navigate({ pathname: dnaConfig.routes.admin.factory.segmentation.create.relativeUrl })}>
                {t('segmentation.create')}
              </Button>
            ]}
            actionsPadding='16px'
            service={userDataFactoryService}
          />
        </CustomAccordion>
      </Stack>
    </Stack>
  );
}

export default ComponentFactory;
