import React, { ReactElement, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { IconArrowRight, IconClipboardData } from '@tabler/icons-react';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import dnaConfig from '../../../../../config/dna.config.json';
import CustomAccordion from '../../../../components/accordion';
import {
  UserDataCustomSegmentationService
} from '../../../../containers/segmentations/services/segmentations.services';
import { BaseCustomUserData } from '../../../../containers/segmentations/types.segmentations';
import ChipViewSegmentations from '../../../../containers/segmentations/render/view/chip.view.segmentations';
import { getEntityTypeEquivalent } from '../../../../utils/getter';
import SimpleSegmentations from '../../../../containers/segmentations/render/lists/simple.lists.segmentations';

function Segmentation(props: { segmentation: BaseCustomUserData, disabled?: boolean }): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const redirect = () => navigate({
    pathname: `${dnaConfig.childRoutes.segmentations.assisted.baseUrl}/${props.segmentation._id}`,
    search: location.search
  });

  return (
    <Paper
      key={props.segmentation._id}
      variant={!props.disabled ? 'hoverElevation2' : undefined}
      sx={{
        width: '100%',
        p: 2,
        cursor: !props.disabled ? 'pointer' : undefined,
        opacity: props.disabled ? 0.5 : 1
      }}
      onClick={!props.disabled ? redirect : undefined}
    >
      <Stack direction='row' spacing={4} alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={2} alignItems='center'>
          <IconClipboardData />
          <Typography variant='h4' pr={3}>{props.segmentation.title}</Typography>
          <Chip label={t(`entities.${props.segmentation.data.on}.label`)} />
          {props.segmentation.data.views?.map(view => (
            <ChipViewSegmentations key={view.id} view={view} />
          ))}
        </Stack>
        {!props.disabled &&
          <Button
            variant='containedIcon'
            onClick={redirect}
          >
            <IconArrowRight size={22} />
          </Button>
        }
      </Stack>
    </Paper>
  );
}

function SelectComponentSegmentations(): ReactElement {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [segmentations, setSegmentations] = useState<BaseCustomUserData[]>([]);
  const entityType = searchParams.get('entityType');

  const getSegmentation = ({ userId, groupId }: { userId?: string, groupId?: string }) => {
    if (userId) {
      const userDataService = new UserDataCustomSegmentationService<BaseCustomUserData>({ userId });

      userDataService.getAll({ filters: [{ id: 'type', scope: 'type', value: 'segmentation' }] })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            setSegmentations(prevState => [
              ...prevState.filter(ps => !res.data?.find(d => d._id === ps._id)),
              ...(res.data as BaseCustomUserData[])
            ]);
          }
        });
    }
  };

  useEffect(() => {
    getSegmentation({ userId: 'admin' });
  }, []);

  return (
    <Stack minWidth={600}>
      <CustomAccordion title={t('segmentation.assisted.multiFullLabel')} defaultOpen disabled={segmentations.filter(segmentation => segmentation.ownerId === 'admin').length < 1}>
        <Stack p={4} spacing={2}>
          {segmentations.map(segmentation => (
            <Segmentation key={segmentation._id} segmentation={segmentation} disabled={!!(entityType && getEntityTypeEquivalent(segmentation.data.on) !== entityType)} />
          ))}
        </Stack>
        {/*
        <Stack p={4}>
          {segmentations.map(segmentation => (
            <CustomAccordion
              key={segmentation._id}
              title={
                <Stack spacing={4} direction='row' width='100%' alignItems='center' pr={4}>
                  <Typography variant='h4'>
                    {segmentation.title}
                  </Typography>
                  <Chip
                    color='secondary'
                    label={`${segmentation.phases.length} ${segmentation.phases.length > 1 ? t('segmentation.chips.phases') : t('segmentation.chips.phase')}`}
                    size='small'
                  />
                  <Button
                    variant='contained'
                    sx={{ ml: 'auto !important' }}
                    onClick={() => navigate(`${dnaConfig.routes.portfolios.segmentations.assisted.baseUrl}/${segmentation._id}`)}
                    endIcon={<IconArrowRight />}
                  >
                    {t('segmentation.start')}
                  </Button>
                </Stack>
              }
              bgcolor={theme.palette.background.default}
            >
              <Stack>
                <Divider />
                <Grid container spacing={4} p={6}>
                  {segmentation.phases.map(phase => (
                    <KeyValueCardComponent
                      key={phase.id}
                      grid
                      title={`${phase.label}`}
                      icon={IconFilter}
                      bgColor={theme.palette.grey['300']}
                      cardParts={{
                        mainValue: {
                          key: phase.possibleCriterias.length > 1 ? t('segmentation.criteria.multiple') : t('segmentation.criteria.label'),
                          value: phase.possibleCriterias.length
                        },
                        values: phase.possibleCriterias.map(criteria => ({
                          key: criteria.displayInfo.label,
                          value: `${criteria.displayInfo.displayType === 'barChart' && t('charts.bar') ||
                          criteria.displayInfo.displayType === 'yesNo' && `${t('charts.yesNo.yes.label')}/${t('charts.yesNo.no.label')}` ||
                          criteria.displayInfo.displayType === 'pieChart' && t('charts.pie') ||
                          ''} ${t('charts.chart')}`
                        }))
                      }}
                    />
                  ))}
                </Grid>
              </Stack>
            </CustomAccordion>
          ))}
        </Stack>
        */}
      </CustomAccordion>
      <CustomAccordion title={t('segmentation.augmented.multiCustomLabel')} disabled />
      <SimpleSegmentations />
    </Stack>
  );
}

export default SelectComponentSegmentations;
