import BooleanFilterTypeForm from './boolean.filtertypes.form';
// eslint-disable-next-line import/no-cycle
import ComboFilterTypesForm from './combo.filtertypes.form';
import RadioFilterTypeForm from './radio.filtertypes.form';
import SelectFilterTypeForm from './select.filtertypes.form';
import TextFilterTypeForm from './text.filtertypes.form';

const filterTypesMap = {
  'boolean': BooleanFilterTypeForm,
  'combo': ComboFilterTypesForm,
  'radio': RadioFilterTypeForm,
  'select': SelectFilterTypeForm,
  'text': TextFilterTypeForm
};

export default filterTypesMap;
