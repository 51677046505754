
import { ApiResponse } from '@deecision/deecision-interfaces';
import {
  UserDataCustomSegmentationService
} from '../../../../containers/segmentations/services/segmentations.services';

class SegmentationService<T> extends UserDataCustomSegmentationService<T> {
  constructor() {
    super({ userId: 'admin' });
  }

  get(id: string, params: Record<string, string>): Promise<ApiResponse<T>> {
    return this.axiosInstance.get(this.constructUrl(`${params.segmentationId}`))
      .then(res => this.handleResponse(res));
  }
}

export default SegmentationService;
