import React, { ReactElement, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { cloneDeep, get, set } from 'lodash';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import {
  IconArrowNarrowRight,
  IconCheck,
  IconClipboardData,
  IconFilter,
  IconX
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import {
  SegmentationPossibleOnEntityTypes,
  SegmentationPossibleOutputEntityTypes,
  SegmentationWorkflowIds
} from '@deecision/dna-interfaces';
import Typography from '@mui/material/Typography';
import { FormHelperText, FormLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { BaseItemSegmentationBuilderProps } from '../types.builder.segmentations';
import { CustomSegmentation, CustomSegmentationView } from '../../types.segmentations';
import CustomAccordion from '../../../../components/accordion';
import TextBuilderSegmentations from './text.builder.segmentations';
import FilterBuilderSegmentations, { ActionsBuilderSegmentations } from './filter.builder.segmentations';
import { segmentationEntityTypes } from '../steps/typeselector.builder.segmentations';
import AddFiltersBuilderSegmentations from '../filters/add.filters.builder.segmentations';

function SelectViewOutputEntities(props: BaseItemSegmentationBuilderProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const view = (props.path === '' ? props.data : get(props.data, props.path)) as CustomSegmentationView;
  const [outputEntities, setOutputEntities] = useState<SegmentationPossibleOutputEntityTypes | undefined>(view.outputEntities);

  const handleChange = (on: SegmentationPossibleOnEntityTypes, workflowId?: SegmentationWorkflowIds, output?: SegmentationPossibleOutputEntityTypes) => {
    setOutputEntities(output);
    props.setData({ ...set(props.data, props.path === '' ? 'on' : `${props.path}.on`, on) });
    props.setData({ ...set(props.data, props.path === '' ? 'workflowId' : `${props.path}.workflowId`, workflowId) });
    props.setData({ ...set(props.data, props.path === '' ? 'outputEntities' : `${props.path}.outputEntities`, output) });
    props.setData({ ...set(props.data, props.path === '' ? 'segmentationCriterias' : `${props.path}.segmentationCriterias`, undefined) });
  };

  return (
    <FormControl required sx={{ p: 5, pt: 2 }}>
      <FormLabel id='outputEntities-view-controlled-radio-buttons-group' sx={{ fontSize: '16px', color: !outputEntities ? theme.palette.error.main : theme.palette.text.primary }}>
        {t('segmentation.builder.views.outputEntities')}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby='outputEntities-view-controlled-radio-buttons-group'
        name='outputEntities-view-controlled-radio-buttons-group'
        value={outputEntities || 'company'}
      >
        <Stack direction='row' spacing={4} alignItems='flex-start'>
          {segmentationEntityTypes.find(segmentationEntityType => segmentationEntityType.type === props.data.on)?.workflows?.map((workflow, index) => (
            <Stack spacing={1}>
              <Stack key={workflow.type} direction='row' spacing={2} alignItems='center'>
                {index !== 0 && <IconArrowNarrowRight />}
                <FormControlLabel
                  value={workflow.type}
                  control={
                    <Radio
                      onClick={() => handleChange((workflow.type === 'person1' || workflow.type === 'person2') ? 'person' : workflow.type, workflow.workflowId, !workflow.type.includes('company') ? workflow.type : 'company')}
                      checked={outputEntities === workflow.type}
                    />
                  }
                  label={
                    <Stack direction='row' spacing={2} alignItems='center'>
                      <workflow.icon stroke={workflow.type.includes('company') ? theme.palette.text.primary : undefined} />
                      <Typography variant='h5'>{`${t(`${workflow.labelId}`)} ${workflow.labelId.includes('entities.persons') ? `& ${t('entities.companies.related')}` : ''}`}</Typography>
                    </Stack>
                  }
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
      </RadioGroup>
      {!(props.path === '' ? (props.data as CustomSegmentation).on : get(props.data, `${props.path}.on`)) && <FormHelperText>{t('common.utils.required')}</FormHelperText>}
    </FormControl>
  );
}

function ViewBuilderSegmentations(props: BaseItemSegmentationBuilderProps): ReactElement {
  const { t } = useTranslation();
  const view = (props.path === '' ? props.data : get(props.data, props.path)) as CustomSegmentationView;

  useEffect(() => {
    const viewTmp = cloneDeep(view);
    const ready = viewTmp.segmentationCriterias && viewTmp.segmentationCriterias.filter(segmentationCriteria => segmentationCriteria && segmentationCriteria.id).every(segmentationCriteria => segmentationCriteria.ready || segmentationCriteria.filterSpec?.ready) && viewTmp.segmentationCriterias.filter(segmentationCriteria => segmentationCriteria && segmentationCriteria.id).length > 0;

    if (ready !== viewTmp.ready) {
      viewTmp.ready = ready;
      viewTmp.segmentationCriterias = viewTmp.segmentationCriterias?.filter(segmentationCriteria => segmentationCriteria?.id);

      props.setData(props.path === '' ? viewTmp : { ...set(props.data, props.path, viewTmp) });
    }
  }, [props.data]);

  return (
    view ?
      <CustomAccordion
        title={
          <Stack spacing={6} direction='row' alignItems='center' justifyContent='space-between' width='calc(100% - 16px)'>
            <Stack spacing={2} direction='row' alignItems='center'>
              <TextBuilderSegmentations {...props} variant='h4' icon={<IconClipboardData />} path={props.path === '' ? props.data.label ? 'label' : 'id' : get(props.data, `${props.path}.label`) ? `${props.path}.label` : `${props.path}.id`} />
              {view.ready ?
                <Chip sx={{ '& > *': { p: 2 } }} label={<Box display='flex' justifyContent='center'><IconCheck size={18} /></Box>} color='success' size='small' /> :
                <Chip sx={{ '& > *': { p: 2 } }} label={<Box display='flex' justifyContent='center'><IconX size={18} /></Box>} color='error' size='small' />
              }
              {view.on &&
                <Chip
                  label={t(`segmentation.builder.filter.${view.outputEntities}`)}
                  size='small'
                />
              }
              {(view.segmentationCriterias?.length || 0) > 0 &&
                <Chip
                  label={`${view.segmentationCriterias?.length || 0} ${(view.segmentationCriterias?.length || 0) > 1 ? t('segmentation.builder.views.possibleFilters') : t('segmentation.builder.views.possibleFilter.label')}`}
                  color='primary'
                  size='small'
                />
              }
            </Stack>
            <ActionsBuilderSegmentations {...props} />
          </Stack>
        }
        defaultOpen={!view.ready}
        detectHover
      >
        <Stack spacing={2} width='100%'>
          <SelectViewOutputEntities {...props} />
          {view.on && [
            <Stack spacing={4} p={4} pt={2}>
              <Stack direction='row' spacing={2} alignItems='center'>
                <IconFilter/>
                <Typography variant='h4' pl={2} pr={2}>{t('segmentation.builder.views.possibleFilters')}</Typography>
              </Stack>
              <Stack spacing={0}>
                {view.segmentationCriterias?.map((segmentationCriteria, index) => {
                  const basePath = props.path === '' ? `segmentationCriterias[${index}]` : `${props.path}.segmentationCriterias[${index}]`;

                  if (get(props.data, basePath)?.filterSpec) {
                    return (
                      <FilterBuilderSegmentations key={segmentationCriteria.id} {...props} path={`${basePath}.filterSpec`} parentPath={basePath} />
                    );
                  }

                  return (
                    <FilterBuilderSegmentations key={segmentationCriteria.id} {...props} path={basePath}/>
                  );
                })}
              </Stack>
            </Stack>,
            <AddFiltersBuilderSegmentations
              {...props}
              childPath='segmentationCriterias'
              workflow={segmentationEntityTypes.find(segmentationEntityType => segmentationEntityType.type === props.data.on)?.workflows?.find(workflow => workflow.workflowId === view.workflowId && workflow.type === view.outputEntities)}
              btnProps={{
                variant: 'contained',
                sx: {
                  pt: 1,
                  pb: 1
                }
              }}
              outputEntities={view.outputEntities}
              view
            />
          ]}
        </Stack>
      </CustomAccordion> :
      <></>
  );
}

export default ViewBuilderSegmentations;
