import { DynamicGroupsRequest } from '@deecision/dna-interfaces/dist/groups/dynamicGroups';

function getDynamicGroupsRequest(customGroupName: string, baseGroupId?: string ): DynamicGroupsRequest | undefined {
  console.log(customGroupName, baseGroupId);

  switch (customGroupName) {
  case 'crossSells':
    return {
      dataSetId: 'deecPersons',
      outputEntities: 'person1',
      workflowId: 'person1_link_person2',
      filters: [
        {
          id: 'cross_sells_person_groupMember',
          type: 'and',
          on: 'person1',
          subItemSpecs: [
            {
              id: 'companies_person_groupMember',
              type: 'filter',
              on: 'person1',
              filterId: 'person_groupMember',
              values: [
                `${baseGroupId || 'all'}/companies.persons`
              ]
            },
            {
              id: 'not_person_groupMember',
              type: 'not',
              on: 'person1',
              subItemSpec: {
                id: 'person_groupMember',
                type: 'filter',
                on: 'person1',
                filterId: 'person_groupMember',
                values: [
                  `${baseGroupId || 'all'}/persons`
                ]
              }
            }
          ]
        }
      ],
      globalFilteringItems: [],
      segmentationCriterias: [],
      entitiesSettings: {
        includeEntities: false,
        findOptions: {
          pagingParams: {},
          queryFilters: [],
          sortOptions: []
        }
      }
    };
  case 'familyClients':
    return {
      dataSetId: 'deecPersons',
      outputEntities: 'person1',
      workflowId: 'person1_link_person2',
      filters: [
        {
          id: 'family_notClients_person_groupMember',
          type: 'or',
          on: 'person1',
          subItemSpecs: [
            {
              id: 'personal.notClient.sameFamily_person_groupMember',
              type: 'filter',
              on: 'person1',
              filterId: 'person_groupMember',
              values: [
                `${baseGroupId || 'all'}/persons.network.personal.notClient.sameFamily`
              ]
            },
            {
              id: 'business.notClient.sameFamily_person_groupMember',
              type: 'filter',
              on: 'person1',
              filterId: 'person_groupMember',
              values: [
                `${baseGroupId || 'all'}/persons.network.business.notClient.sameFamily`
              ]
            }
          ]
        }
      ],
      globalFilteringItems: [],
      segmentationCriterias: [],
      entitiesSettings: {
        includeEntities: false,
        findOptions: {
          pagingParams: {},
          queryFilters: [],
          sortOptions: []
        }
      }
    };
  case 'networkClients':
    return {
      dataSetId: 'deecPersons',
      outputEntities: 'person1',
      workflowId: 'person1_link_person2',
      filters: [
        {
          id: 'network_clients_person_groupMember',
          type: 'or',
          on: 'person1',
          subItemSpecs: [
            {
              id: 'personal.client_person_groupMember',
              type: 'filter',
              on: 'person1',
              filterId: 'person_groupMember',
              values: [
                `${baseGroupId || 'all'}/persons.network.personal.client`
              ]
            },
            {
              id: 'business.client_person_groupMember',
              type: 'filter',
              on: 'person1',
              filterId: 'person_groupMember',
              values: [
                `${baseGroupId || 'all'}/persons.network.business.client`
              ]
            },
            {
              id: 'extended.client_person_groupMember',
              type: 'filter',
              on: 'person1',
              filterId: 'person_groupMember',
              values: [
                `${baseGroupId || 'all'}/persons.network.extended.client`
              ]
            }
          ]
        }
      ],
      globalFilteringItems: [],
      segmentationCriterias: [],
      entitiesSettings: {
        includeEntities: false,
        findOptions: {
          pagingParams: {},
          queryFilters: [],
          sortOptions: []
        }
      }
    };
  default:
    return undefined;
  }
}

export default getDynamicGroupsRequest;
