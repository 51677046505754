import React, { ReactElement, ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { IconCirclesRelation, IconUsers } from '@tabler/icons-react';
import IconBuildings from '@deecision/common-webapp/assets/custom/IconBuildings';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BaseQuickDashboardProps, dashboardSpacing } from '../types.quick.dashboard.containers';

function ContainerGroupQuickDashboard(props: BaseQuickDashboardProps & { titleAddon?: ReactNode, customIcon?: ReactNode, children?: ReactNode }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();

  const iconMapper = (key: string) => {
    if (props.customIcon) return props.customIcon;

    switch (key) {
    case 'persons':
      return <IconUsers />;
    case 'companies':
      return <IconBuildings stroke={theme.palette.text.primary} />;
    case 'persons.companies':
      return <IconBuildings stroke={theme.palette.text.primary} />;
    case 'persons.network':
      return <IconCirclesRelation />;
    default:
      return <IconUsers />;
    }
  };

  return (
    <Paper
      variant='hoverElevation2'
      sx={{ minWidth: 'min-content', width: '100%', bgcolor: theme.palette.grey['200'], p: dashboardSpacing }}
    >
      <Grid container spacing={dashboardSpacing} height='fit-content'>
        <Grid xs={12}>
          <Stack direction='row' spacing={dashboardSpacing} alignItems='center' width='100%'>
            <Box height={24}>
              {props.group.icon ? <props.group.icon /> : iconMapper(props.group.subGroupId.split('.').at(0) || '')}
            </Box>
            {props.group.img ?
              <img src={props.group.img} alt={props.group.subGroupId} height={24} width={124} /> :
              <Typography variant='h4' whiteSpace='nowrap' pr={2}>
                {(props.group.subGroupId.split('.').at(-1) && ['sameFamily', 'personal', 'business', 'extended', 'client', 'notClient'].includes(props.group.subGroupId.split('.').at(-1) || 'unknown')) ? t(`folder.dashboard.groups.labels.${props.group.subGroupId.split('.').at(-1)}.label`) : t(`folder.dashboard.groups.labels.${props.group.subGroupId}.label`)}
              </Typography>
            }
            {props.titleAddon}
          </Stack>
        </Grid>
        {props.group.description &&
          <Grid xs={12}>
            <Typography variant='body2'>{t(`folder.dashboard.groups.labels.${props.group.subGroupId}.description`)}</Typography>
          </Grid>
        }
        {props.children}
      </Grid>
    </Paper>
  );
}

export default ContainerGroupQuickDashboard;
