import React, { ReactElement, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { BaseItemFormProps } from '../types.form';
// eslint-disable-next-line import/no-cycle
import filterTypesMap from './mapping.form';

function ComboFilterTypesForm(props: BaseItemFormProps): ReactElement {
  const [values, setValues] = useState<(string | undefined)[]>(props.filter.combo && props.filter.combo.map(() => undefined) || []);

  const handleChange = (index: number, value?: string) => {
    const tmpValues = values.map((v, jIndex) => {
      if (index === jIndex) {
        return value;
      }

      return v;
    });

    setValues(tmpValues);
  };

  useEffect(() => {
    if (values.length > 0) {
      props.onChange(values.filter(value => value !== undefined).join('/'));
    } else {
      props.onChange(undefined);
    }
  }, [values]);

  return (
    <Stack direction='row' spacing={2} alignItems='center' width='100%'>
      {props.filter.combo && props.filter.combo.map((type, index) => {
        const ItemFormComponent = filterTypesMap[type as keyof typeof filterTypesMap];

        return (
          <ItemFormComponent
            key={type}
            {...props}
            filter={{
              ...props.filter,
              label: props.filter.labelCombo ? props.filter.labelCombo[index] : props.filter.label,
              possibleValues: props.filter.possibleComboValues ? props.filter.possibleComboValues[index] : [],
              value: (props.filter.value as string)?.split('/')[index]
            }}
            onChange={value => handleChange(index, value?.toString())}
          />
        );
      })}
    </Stack>
  );
}

export default ComboFilterTypesForm;
