import React, { ReactElement } from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { IconFilePlus, IconFolderPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import VEEGILENZ from '../../../assets/VEEGILENZ.svg';

function VeegilenzHeader(): ReactElement {
  const { t } = useTranslation();

  return (
    <Paper elevation={1} sx={{ width: '100%', overflowX: 'auto' }}>
      <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center'>
        <Grid
          container
          spacing={4}
          direction='row'
          alignItems='center'
          height='100px'
          width='max-content'
          pl={2}
          flexWrap='nowrap'
        >
          <Grid>
            <img src={VEEGILENZ} alt='Veegilenz' height={32} />
          </Grid>
          <Grid height='100%'>
            <Divider orientation='vertical' />
          </Grid>
          <Grid>
            <Typography maxWidth={600} ml='auto !important'>
              Veegilenz indicator is given as an indication. Composite, made from official data available as of today from the resources chosen by our customers.

              It presents an indication of vigilance according to the criteria and 3-typologies of regulations (MIFID2, AML-LCB, CFT-FT, FATCA, ...).
            </Typography>
          </Grid>
        </Grid>
        <Stack height='100px' direction='row' spacing={2} justifyContent='flex-start'>
          <Button
            variant='text'
            sx={{ height: 'min-content' }}
            startIcon={<IconFilePlus />}
          >
            {t('report.createComplianceReport')}
          </Button>
          <Button
            variant='text'
            sx={{ height: 'min-content' }}
            startIcon={<IconFolderPlus />}
          >
            {t('folder.createComplianceFolder')}
          </Button>
        </Stack>
        {
          /*
        <Paper
          elevation={0}
          sx={{
            bgcolor: props.scoreColor[Math.floor(props.score)].light,
            color: props.scoreColor[Math.floor(props.score)].dark,
            width: 96
          }}
        >
          <Stack
            sx={{
              width: '100%',
              height: '68px',
              textAlign: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography variant='h1'>{props.score}</Typography>
          </Stack>
        </Paper>
           */
        }
      </Stack>
    </Paper>
  );
}

export default VeegilenzHeader;
