import React from 'react';
import { CustomRouteObject } from "@deecision/common-webapp/router";
import createElementRoutes from "@deecision/common-webapp/router/utils/create.element.routes";
import { Construction } from '@deecision/common-webapp/components';
import { IconUserScan } from '@tabler/icons-react';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import dnaConfig from '../../../../config/dna.config.json';
import EntitiesScreen from './entitiesList';
import EntitiesDataElementTabs from './tabs/entities.tabs.dataElement';
import BaseDnaEntitiesService from '../../entities/services/entities.services';

const routeEntities: CustomRouteObject = createElementRoutes({
  id: dnaConfig.routes.admin.tabs.entities.i18nKey,
  path: dnaConfig.routes.admin.tabs.entities.baseUrl,
  icon: IconUserScan,
  component: <EntitiesScreen />,
  idComponent: <></>,
  Service: new BaseDnaEntitiesService<CompanyEntity | PersonEntity>({ entityType: 'deecCompany' }),
  displayIndex: true,
  tabs: [{
    id: 'summary',
    i18nKey: 'common.utils.summary',
    path: 'summary',
    element: <Construction />
  },
  {
    id: 'dataElement',
    i18nKey: 'DataElement',
    path: 'dataElement',
    element: <EntitiesDataElementTabs />

  },
  {
    id: 'dataBlock',
    i18nKey: 'DataBlock',
    path: 'dataBlock',
    element: <Construction />
  }]
});

export default routeEntities;
