import { toPng } from "html-to-image";
import React from "react";

export const downloadImageOnClick = (flowRef: React.RefObject<HTMLDivElement>, name: string, category: string) => {
  if (flowRef.current === null) return;
  toPng(flowRef.current, {
    backgroundColor: 'white',
    filter: (node: { classList: { contains: (arg0: string) => any } }) => !(
      node?.classList?.contains('react-flow__minimap') ||
            node?.classList?.contains('react-flow__controls')
    )
  }).then((dataUrl) => {
    const a = document.createElement('a');
    alert(`Downloading ${name} ${category}.png...`);
    a.setAttribute('download', `${name} ${category}.png`);
    a.setAttribute('href', dataUrl);
    a.click();
  }).catch((error) => {
    console.log(error);
  });
};