import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { cloneDeep, get, set, uniqueId, unset, upperFirst } from 'lodash';
import {
  FilterItemSpec,
  SegmentationFilter, SegmentationPossibleFilterOnEntityTypes,
  SegmentationPossibleFilterTypes, SegmentationPossibleOnEntityTypes
} from '@deecision/dna-interfaces';
import Button from '@mui/material/Button';
import { IconCheck, IconChevronDown, IconFilterPlus, IconX } from '@tabler/icons-react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { darken, useTheme } from '@mui/material/styles';
import { ButtonGroup, Grow, MenuList } from '@mui/material';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import CustomAccordion from '../../../../components/accordion';
import { BaseItemSegmentationBuilderProps } from '../types.builder.segmentations';
import FilterBuilderSegmentations, { possibleGroupTypes } from '../items/filter.builder.segmentations';
import { CustomSegmentation, CustomSegmentationFilter } from '../../types.segmentations';
import AnchorTemporaryDrawer from '../filters/custom.filters.builder.segmentations';
import {
  EntitiesOut,
  segmentationEntityTypes,
  SegmentationWorkflow
} from './typeselector.builder.segmentations';

function AddBtns(props: BaseItemSegmentationBuilderProps & { data: CustomSegmentation, workflow: SegmentationWorkflow<EntitiesOut> }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const addNewCompanyFilter = () => {
    const filter: Partial<SegmentationFilter> = {
      id: uniqueId('p1_'),
      type: 'personWithAnyCompany',
      on: 'person1',
      subItemSpecs: [
        {
          id: uniqueId('c_filter_'),
          label: `${upperFirst(t('filters.unique') || 'filter')} ${(get(props.data, props.path)?.filter((element: SegmentationFilter) => element !== null).length || 0) + 1}`,
          type: 'filter',
          on: 'company1'
        }
      ]
    };

    props.setData({ ...set(props.data, `${props.path}[${get(props.data, props.path)?.length || 0}]`, filter) });
  };

  const addNewFilter = (type: SegmentationFilter['type'], on: SegmentationPossibleFilterOnEntityTypes) => {
    const filter: Partial<SegmentationFilter> = {
      id: uniqueId(possibleGroupTypes.includes(type) ? 'group' : 'filter'),
      label: `${upperFirst(possibleGroupTypes.includes(type) ? t('common.utils.group') || 'group' : t('filters.unique') || 'filter')} ${(get(props.data, props.path)?.filter((element: SegmentationFilter) => element !== null).length || 0) + 1}`,
      type,
      on
    };

    props.setData({ ...set(props.data, `${props.path}[${get(props.data, props.path)?.length || 0}]`, filter) });
  };

  const handleToggle = () => {
    setOpen(prevState => !prevState);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Stack spacing={1} alignItems='center' justifyContent='center' p={1}>
      <ButtonGroup
        variant='contained'
        ref={anchorRef}
        aria-label='Button group for adding a filter'
        sx={{ bgcolor: 'transparent', boxShadow: 'none', whiteSpace: 'nowrap' }}
      >
        <Button
          size='small'
          startIcon={<IconFilterPlus />}
          variant='contained'
          onClick={() => addNewFilter('filter', props.workflow.type)}
          sx={{
            borderColor: `${darken(theme.palette.primary.main, 0.2)} !important`
          }}
        >
          {t('segmentation.builder.steps.globalFilteringItems.addFilter')}
        </Button>
        <Button
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
          sx={{
            borderLeft: 'none !important'
          }}
        >
          <IconChevronDown size={20} />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper
              sx={{
                p: 0,
                mt: 2,
                bgcolor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderColor: darken(theme.palette.primary.main, 0.2),
                borderRadius: 2
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem sx={{ p: 0 }}>
                  {props.workflow.type === 'person1' &&
                    <MenuItem onClick={addNewCompanyFilter} sx={{ borderRadius: 2, p: 2 }}>
                      <Stack direction='row' spacing={2} alignItems='center'>
                        <IconFilterPlus />
                        {t('segmentation.builder.steps.globalFilteringItems.createFilterCompany1')}
                      </Stack>
                    </MenuItem>
                  }
                  <AnchorTemporaryDrawer
                    {...props}
                    btnLabel={t('segmentation.builder.views.createCustomFilter')}
                  />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
}

function PreFilteringBuilderSegmentations(props: Omit<BaseItemSegmentationBuilderProps, 'workflow'> & { data: CustomSegmentation }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const globalFilteringItems = get(props.data, props.path) as CustomSegmentationFilter[];

  useEffect(() => {
    props.data.preFilters?.forEach((subItemSpec, index) => {
      if (subItemSpec.type === 'personWithAnyCompany' && subItemSpec.subItemSpecs?.filter(si => si).length === 0) {
        const dataTmp = cloneDeep(props.data);

        unset(dataTmp, `${props.path}[${index}]`);

        props.setData({ ...dataTmp });
      }
    });
  }, [props.data]);

  return (
    <CustomAccordion
      title={
        <Stack direction='row' spacing={2}>
          <Typography variant='h3'>
            {t('segmentation.builder.steps.globalFilteringItems.label')}
          </Typography>
          {props.data?.preFilters && props.data.preFilters.filter(item => item).every(item => item?.ready || item.type === 'personWithAnyCompany' && item.subItemSpecs?.every((subItem: CustomSegmentationFilter) => subItem?.ready)) ?
            <Chip
              sx={{ '& > *': { p: 2 } }}
              label={<Box display='flex' justifyContent='center'><IconCheck size={18} /></Box>}
              color='success'
              size='small'
            /> :
            <Chip
              sx={{ '& > *': { p: 2 } }}
              label={<Box display='flex' justifyContent='center'><IconX size={18} /></Box>}
              color='error'
              size='small'
            />
          }
        </Stack>
      }
      defaultOpen
    >
      <Stack spacing={4} p={2} pt={0}>
        <Grid container spacing={2}>
          {segmentationEntityTypes.find(segmentationEntityType => segmentationEntityType.type === props.data.on)?.workflows?.map(workflow => (
            <Grid key={workflow.type} xs={12 / (segmentationEntityTypes.find(segmentationEntityType => segmentationEntityType.type === props.data.on)?.workflows?.length || 1)}>
              <Stack spacing={1} pt={2}>
                <Paper sx={{ width: '100%', bgcolor: theme.palette.grey['100'], border: 'none' }}>
                  <Stack spacing={2}>
                    <Typography variant='h4' p={1}>{t(`${workflow.labelId}`)}</Typography>
                    <Stack>
                      {globalFilteringItems?.map((filter, index) => (
                        <FilterBuilderSegmentations key={`${(filter as FilterItemSpec)?.id}-${workflow.type}`} {...props} on={filter.on as SegmentationPossibleOnEntityTypes} type={filter.type as (SegmentationPossibleFilterTypes | undefined)} path={`${props.path}[${index}]`} workflow={workflow} />
                      )).filter(filter => filter.key !== 'null' && workflow.type === filter.props.on)}
                    </Stack>
                    <AddBtns {...props} workflow={workflow} />
                  </Stack>
                </Paper>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </CustomAccordion>
  );
}

export default PreFilteringBuilderSegmentations;
