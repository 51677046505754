import React, { ReactElement } from 'react';
import { CompanyEntity, EntityNews, PersonEntity } from '@deecision/dna-interfaces';
import { IconNews } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import MakeWidget, { MakeWidgetProps } from './make.widgets';

function News(props: { news?: EntityNews[] | null }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack spacing={3} p={2}>
      {props.news && props.news.length > 0 ?
        props.news.map((n, index) => (
          <Link
            key={n.title}
            href={n.link}
            target='_blank'
            sx={{
              textDecoration: 'none',
              textDecorationColor: `${theme.palette.text.primary} !important`,
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            {index !== 0 &&
              <Divider sx={{ borderBottomWidth: 1.5, borderRadius: 1, mb: 3 }}/>
            }
            <Typography sx={{ color: 'text.primary' }}>
              {n.title.split(' - ')[0]}
            </Typography>
            <Typography variant='body2' color='primary'>{n.title.split(' - ')[1]}</Typography>
          </Link>
        )) :
        <Typography textAlign='center' mt={4} mb={4}>
          {t('common.news.noNewsFound')}
        </Typography>
      }
    </Stack>
  );
}

function NewsWidget(props: { data: PersonEntity | CompanyEntity } & Pick<MakeWidgetProps, 'link' | 'linkLabel'>): ReactElement {
  const { t } = useTranslation();

  return (
    <MakeWidget
      icon={<IconNews size={18} />}
      title={t('common.news.label')}
      width='320px'
      childHeight={props.data.dataViews?.data?.news && props.data.dataViews?.data?.news.length > 0 ? '400px' : undefined}
      {...props}
    >
      <News news={props.data.dataViews?.data?.news} />
    </MakeWidget>
  );
}

export default NewsWidget;
