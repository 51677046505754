import React from 'react';
import { CustomRouteObject } from '@deecision/common-webapp/router';
import { IDataBlock, PersonEntity, zPersonEntity } from '@deecision/dna-interfaces';
import { IconUsers } from '@tabler/icons-react';
import createElementRoutes from '@deecision/common-webapp/router/utils/create.element.routes';
import { customGetterLoader } from '@deecision/common-webapp/router/components/loader.router';
import { TabFunction } from '@deecision/common-webapp/components/tabs.components';
import dnaConfig from '../../../../config/dna.config.json';
import PersonsComponent from './components/component.persons';
import PersonComponent from './components/id.component.persons';
import BaseDnaEntitiesService from '../services/entities.services';
import DashboardTabsComponents from './components/tabs/dashboard.tabs.components';
import DeveloperTabsComponents from '../components/tabs/developer.tabs.components';
import VeegilenzTabsComponents from './components/tabs/veegilenz.tabs.components';
import CompaniesTabsComponents from './components/tabs/companies.tabs.components';
import NetworkTabsComponents from '../components/tabs/network.tabs.components';
import PrioreezTabsComponents from './components/tabs/prioreez.tabs.components';
import OrigeenTabsComponents from "../companies/components/tabs/origeen.tabs.components";
// import OrigeenTabsComponents from './components/tabs/origeen.tabs.components';
import RelationsTabsComponents from './components/tabs/relations.tabs.components';
import NewsTabsComponents, { newsDataPaths } from '../components/tabs/news.tabs.components';
import { DataBlocksQueryService, DataElementsQueryService } from '../../../../api/services/query.services';
import { newsFunction } from '../components/tabs/tabsfunctions.tabs';
import WeasseTabsComponents, { weasseDataPaths } from './components/tabs/weasse.tabs.components';

const tabsPersons: CustomRouteObject[] = [
  {
    id: dnaConfig.routes.persons.tabs.dashboard.label,
    i18nKey: dnaConfig.routes.persons.tabs.dashboard.i18nKey,
    path: dnaConfig.routes.persons.tabs.dashboard.baseUrl,
    element: <DashboardTabsComponents />
  },
  {
    id: dnaConfig.routes.persons.tabs.network.label,
    i18nKey: dnaConfig.routes.persons.tabs.network.i18nKey,
    path: dnaConfig.routes.persons.tabs.network.baseUrl,
    element: <NetworkTabsComponents<PersonEntity> />
  },
  {
    id: dnaConfig.routes.persons.tabs.companies.label,
    i18nKey: dnaConfig.routes.persons.tabs.companies.i18nKey,
    path: dnaConfig.routes.persons.tabs.companies.baseUrl,
    element: <CompaniesTabsComponents />
  },
  {
    id: dnaConfig.routes.persons.tabs.relations.label,
    i18nKey: dnaConfig.routes.persons.tabs.relations.i18nKey,
    path: dnaConfig.routes.persons.tabs.relations.baseUrl,
    element: <RelationsTabsComponents />
  },
  {
    id: dnaConfig.routes.persons.tabs.news.label,
    i18nKey: dnaConfig.routes.persons.tabs.news.i18nKey,
    path: dnaConfig.routes.persons.tabs.news.baseUrl,
    element: <NewsTabsComponents />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<IDataBlock>({ dataPath: newsDataPaths, entityType: 'deecPerson' }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.persons.tabs.veegilenz.label,
    i18nKey: 'Veegilenz',
    path: dnaConfig.routes.persons.tabs.veegilenz.baseUrl,
    element: <VeegilenzTabsComponents />
  },
  {
    id: dnaConfig.routes.persons.tabs.prioreez.label,
    i18nKey: 'Prioreez',
    path: dnaConfig.routes.persons.tabs.prioreez.baseUrl,
    element: <PrioreezTabsComponents />
  },
  {
    id: dnaConfig.routes.persons.tabs.weasse.label,
    i18nKey: dnaConfig.routes.persons.tabs.weasse.i18nKey,
    path: dnaConfig.routes.persons.tabs.weasse.baseUrl,
    element: <WeasseTabsComponents />,
    loader: ({ request, params }) => customGetterLoader({
      service: new DataBlocksQueryService<IDataBlock>({ dataPath: weasseDataPaths, entityType: 'deecPerson' }),
      params,
      request
    })
  },
  {
    id: dnaConfig.routes.persons.tabs.origeen.label,
    i18nKey: 'Origeen',
    path: dnaConfig.routes.persons.tabs.origeen.baseUrl,
    element: <OrigeenTabsComponents entityType='deecPerson' />
  },
  {
    id: dnaConfig.routes.persons.tabs.developer.label,
    i18nKey: dnaConfig.routes.persons.tabs.developer.i18nKey,
    path: dnaConfig.routes.persons.tabs.developer.baseUrl,
    element: <DeveloperTabsComponents<PersonEntity> zodSchema={zPersonEntity} />
  }
];

const tabsFunctions: Record<string, TabFunction> = {
  [dnaConfig.routes.persons.tabs.news.baseUrl]: newsFunction
};

const routesPersons: CustomRouteObject = createElementRoutes<PersonEntity>({
  i18nKey: dnaConfig.routes.persons.i18nKey,
  path: dnaConfig.routes.persons.baseUrl,
  icon: IconUsers,
  component: <PersonsComponent />,
  idComponent: <PersonComponent />,
  Service: new BaseDnaEntitiesService<PersonEntity>({ entityType: 'person' }),
  displayIndex: true,
  tabs: tabsPersons,
  tabsDisplayService: new DataElementsQueryService({ entityType: 'deecPerson' }),
  tabsFunctions
});

export default routesPersons;
