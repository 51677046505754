import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { IconCheck, IconX } from '@tabler/icons-react';
import Button from '@mui/material/Button';
import { ValuesBuilderSegmentationsProps } from './types.values.builders.segmentations';

function BooleanValuesBuilderSegmentations(props: ValuesBuilderSegmentationsProps) {
  const { t } = useTranslation();

  const handleClick = (v: boolean) => {
    props.applyValue(v);
  };

  return (
    <Stack direction='row' spacing={2} alignItems='center'>
      <Button
        variant='contained'
        endIcon={<IconCheck />}
        onClick={() => handleClick(true)}
      >
        {t('common.utils.yes')}
      </Button>
      <Button
        variant='outlined'
        endIcon={<IconX />}
        onClick={() => handleClick(false)}
      >
        {t('common.utils.no')}
      </Button>
    </Stack>

  );
}

export default BooleanValuesBuilderSegmentations;
