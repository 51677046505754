
import { BaseElementsService } from '@deecision/common-webapp/api';
import { ApiResponse } from '@deecision/deecision-interfaces';
import {
  SegmentationData,
  SegmentationRequest
} from '@deecision/dna-interfaces';
import axiosInstance from '../../../../api';

class SegmentationServices<T> extends BaseElementsService<T> {
  secondUrlPrefix?: string;

  constructor(props?: { elementPrefixUrl?: string }) {
    super({ elementPrefixUrl: `segmentation/${props?.elementPrefixUrl}`, axiosInstance });

    this.secondUrlPrefix = props?.elementPrefixUrl;
  }

  perform(props: SegmentationRequest): Promise<ApiResponse<SegmentationData>> {
    const data: SegmentationRequest = {
      ...props
    };

    return this.axiosInstance.post(this.constructUrl('performSegmentation5').replace(`/${this.secondUrlPrefix}` || '', ''), data)
      .then(res => this.handleResponse(res));
  }
}

export class CustomSegmentationService<T> extends SegmentationServices<T> {
  constructor(props?: { elementPrefixUrl?: string }) {
    super({ elementPrefixUrl: props?.elementPrefixUrl ? `custom/${props.elementPrefixUrl}` : 'custom' });
  }
}

export class CustomSegmentationFiltersService<T> extends CustomSegmentationService<T> {
  constructor(props?: { elementPrefixUrl?: string }) {
    super({ elementPrefixUrl: props?.elementPrefixUrl ? `possibleFilters/${props.elementPrefixUrl}` : 'possibleFilters' });
  }
}

export class UserDataCustomSegmentationService<T> extends CustomSegmentationService<T> {
  constructor(props: { userId: string }) {
    super({ elementPrefixUrl: `userData/${props?.userId}` });
  }
}

export class GroupDataCustomSegmentationService<T> extends CustomSegmentationService<T> {
  constructor(props: { groupId: string }) {
    super({ elementPrefixUrl: `userGroup/${props.groupId}` });
  }
}

export default SegmentationServices;
