import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { cloneDeep, get, set, uniq, uniqueId, unset, upperFirst } from 'lodash';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import {
  IconArrowsSplit2,
  IconChartBar, IconChartPie,
  IconChevronDown,
  IconChevronRight,
  IconExchange,
  IconFilter,
  IconFilterPlus,
  IconStack2,
  IconTrash
} from '@tabler/icons-react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button, { ButtonProps } from '@mui/material/Button';
import {
  SegmentationFilter,
  SegmentationFilterRange,
  SegmentationFilterValue,
  SegmentationPossibleFilter,
  SegmentationPossibleFilterTypes, SegmentationPossibleOnEntityTypes, SegmentationPossibleOutputEntityTypes
} from '@deecision/dna-interfaces';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Collapse, FormHelperText, Grow, List, ListItemButton, MenuList } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { darken, useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { CustomSegmentationFiltersService } from '../../services/segmentations.services';
import { CustomSegmentationFilter } from '../../types.segmentations';
import TextBuilderSegmentations from './text.builder.segmentations';
import { BaseItemSegmentationBuilderProps } from '../types.builder.segmentations';
import CustomAccordion from '../../../../components/accordion';
import StringValuesBuilderSegmentations from './values/string.values.builder.segmentations';
import AmountValuesBuilderSegmentations from './values/amount.values.builder.segmentations';
import BooleanValuesBuilderSegmentations from './values/boolean.values.builder.segmentations';
// eslint-disable-next-line import/no-cycle
import AnchorTemporaryDrawer from '../filters/custom.filters.builder.segmentations';
import { EntitiesOut } from '../steps/typeselector.builder.segmentations';

export const possibleFilterTypes = ['filter', 'personWithAnyCompany', 'ref'];
export const possibleGroupTypes = ['and', 'or', 'not'];
export const categoryFilterToHide = ['hidden'];

function TitleFilterBuilderSegmentations(props: BaseItemSegmentationBuilderProps): ReactElement {
  const { t } = useTranslation();
  const filter = (props.path === '' ? props.data : get(props.data, props.path)) as Partial<CustomSegmentationFilter>;

  return (
    <Grid container spacing={2} alignItems='center' overflow='auto'>
      <Grid>
        <TextBuilderSegmentations
          {...props}
          icon={filter.type && (possibleGroupTypes.includes(filter.type)
            ? <IconStack2 />
            : <IconFilter />
          )}
          variant='h4'
          path={props.path === '' ? filter.label ? 'label' : 'id' : get(props.data, `${props.path}.label`) ? `${props.path}.label` : `${props.path}.id`}
        />
      </Grid>
      {(filter.type === 'segmentationCriteria' && filter.displayInfo?.displayType || filter.subItemSpecs && filter.subItemSpecs.filter(item => item !== null).length > 0 && !filter.valueType ) && !props.userService &&
        <Grid>
          <Tooltip title={t(`segmentation.builder.displayTypes.${filter.displayInfo?.displayType || 'yesNo'}`)} arrow placement='top'>
            <Chip
              sx={{ '& > *': { p: 2 } }}
              label={<Box display='flex' justifyContent='center'>
                {
                  {
                    barChart: <IconChartBar size={18} />,
                    pieChart: <IconChartPie size={18} />,
                    yesNo: <IconArrowsSplit2 size={18} />
                  }[filter.displayInfo?.displayType || 'yesNo']
                }
              </Box>}
              color='primary'
              size='small'
            />
          </Tooltip>
        </Grid>
      }
      {filter.type && (possibleFilterTypes.includes(filter.type) || filter.type === 'segmentationCriteria') && filter.filterId && [
        <Grid><Chip label={filter.filterLabel} size='small' /></Grid>
      ]}
      {filter.type && possibleGroupTypes.includes(filter.type) && [
        <Grid><Chip label={`${filter.subItemSpecs?.length || 0} ${(filter.subItemSpecs?.length || 0) > 1 ? t('filters.label') : t('filters.unique')}`} color='primary' size='small' /></Grid>,
        <Grid><Chip label={filter.type === 'and' && t('segmentation.builder.filter.and') || filter.type === 'or' && t('segmentation.builder.filter.or') || filter.type === 'not' && t('segmentation.builder.filter.not')} size='small' /></Grid>
      ]}
    </Grid>
  );
}

export function ActionsBuilderSegmentations(props: BaseItemSegmentationBuilderProps): ReactElement {
  const { t } = useTranslation();
  const options = [t('segmentation.builder.custom.saveAndUse'), t('segmentation.builder.custom.use'), t('segmentation.builder.custom.delete')];
  const options2 = [t('segmentation.builder.custom.use'), t('segmentation.builder.custom.delete')];
  const filter = props.path === '' ? props.data : get(props.data, props.path) as Partial<CustomSegmentationFilter>;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    props.doAction?.('use');
    props.draft && props.doAction?.('save');
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    event.stopPropagation();
    setOpen(false);
    if (index === 1) {
      props.draft ?
        props.doAction?.('save') :
        props.doAction?.('delete');
    } else {
      props.doAction?.('delete');
    }
  };

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Stack direction='row' spacing={2} alignItems='center'>
      {props.userService && filter.ready ?
        <>
          <ButtonGroup
            variant='contained'
            ref={anchorRef}
            aria-label='Button group with a nested menu'
            sx={{ bgcolor: 'transparent', boxShadow: 'none', whiteSpace: 'nowrap' }}
          >
            <Button onClick={handleClick}>{(props.draft ? options : options2)[0]}</Button>
            <Button
              size='small'
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label='select save or delete'
              aria-haspopup='menu'
              onClick={handleToggle}
            >
              <IconChevronDown />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1
            }}
            open={open}
            anchorEl={anchorRef.current}
            placement='bottom-end'
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id='split-button-menu' autoFocusItem>
                      {(props.draft ? options : options2).filter((option, index) => index !== 0).map((option, index) => (
                        <MenuItem
                          key={option}
                          onClick={event => handleMenuItemClick(event, index + 1)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </> :
        <IconButton onClick={() => {
          if (props.userService) {
            props.doAction?.('delete');
          } else {
            const dataTmp = { ...props.data };
            if (props.path === '') {
              props.setData({});
            } else {
              if (props.parentPath && props.path.includes('filterSpec') && props.path.includes('subItemSpecs')) {
                unset(dataTmp, props.path);
              } else {
                unset(dataTmp, props.parentPath || props.path);
              }
              props.setData({ ...dataTmp });
            }
          }
        }}>
          <IconTrash />
        </IconButton>
      }
    </Stack>
  );
}

function AddFilterOrGroupBuilderSegmentations(props: BaseItemSegmentationBuilderProps & { size?: ButtonProps['size'], on?: EntitiesOut }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const filter = props.path === '' ? props.data : get(props.data, props.path) as Partial<CustomSegmentationFilter>;

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevState => !prevState);
  };

  const addNewFilter = (type: SegmentationFilter['type']) => {
    const subFilter: Partial<SegmentationFilter> = {
      id: uniqueId(`${filter?.id}-${possibleGroupTypes.includes(type) ? 'group' : 'filter'}`),
      label: `${filter?.label} - ${upperFirst(possibleGroupTypes.includes(type) ? t('common.utils.group') || 'group' : t('filters.unique') || 'filter')} ${((props.childPath ? get(props.data, props.childPath) : props.data)?.filter((element: SegmentationFilter) => element !== null).length || 0) + 1}`,
      type,
      on: props.on
    };

    props.setData({ ...set(props.data, `${props.childPath || ''}[${(props.childPath ? get(props.data, props.childPath) : props.data)?.length || 0}]`, subFilter) });
  };

  const addNewCompanyFilter = () => {
    setOpen(false);
    const subFilter: Partial<SegmentationFilter> = {
      id: uniqueId('p1_'),
      type: 'personWithAnyCompany',
      on: 'person1',
      subItemSpecs: [
        {
          id: uniqueId('c_filter_'),
          label: `${upperFirst(t('filters.unique') || 'filter')} ${((props.childPath ? get(props.data, props.childPath) : props.data)?.filter((element: SegmentationFilter) => element !== null).length || 0) + 1}`,
          type: 'filter',
          on: 'company1'
        }
      ]
    };

    props.setData({ ...set(props.data, `${props.childPath || ''}[${(props.childPath ? get(props.data, props.childPath) : props.data)?.length || 0}]`, subFilter) });
  };

  return (
    <Stack spacing={props.size ? 2 : 0} direction='row' alignItems='center' justifyContent='center'>
      <ButtonGroup
        variant='contained'
        ref={anchorRef}
        aria-label='Button group for adding a filter'
        sx={{ bgcolor: 'transparent', boxShadow: 'none', whiteSpace: 'nowrap' }}
      >
        <Button
          size='small'
          startIcon={<IconFilterPlus />}
          variant='text'
          onClick={() => addNewFilter('filter')}
          sx={{
            border: 'none !important'
          }}
        >
          {t('segmentation.builder.steps.globalFilteringItems.addFilter')}
        </Button>
        <Button
          variant='text'
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
          sx={{
            borderLeft: 'none !important'
          }}
        >
          <IconChevronDown size={20} />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper
              sx={{
                p: 0,
                mt: 2,
                bgcolor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderColor: darken(theme.palette.primary.main, 0.2),
                borderRadius: 2
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem sx={{ p: 0 }}>
                  {props.workflow?.type === 'person1' &&
                    <MenuItem onClick={addNewCompanyFilter} sx={{ borderRadius: 2, p: 2 }}>
                      <Stack direction='row' spacing={2} alignItems='center'>
                        <IconFilterPlus />
                        {t('segmentation.builder.steps.globalFilteringItems.createFilterCompany1')}
                      </Stack>
                    </MenuItem>
                  }
                  <AnchorTemporaryDrawer
                    {...props}
                    btnLabel={t('segmentation.builder.views.createCustomFilter')}
                  />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
}

function SelectFilterSpecsCategoryBuilderSegmentations(props: { label: string, children: ReactNode }): ReactElement {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      component='nav'
    >
      <ListItemButton
        onClick={(e) => {
          e.stopPropagation();
          handleClick();
        }}
      >
        <ListItemText
          primary={
            <Typography variant='h4'>{props.label}</Typography>
          }
        />
        {open ? <IconChevronDown /> : <IconChevronRight />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        {Array.isArray(props.children)
          ? props.children.map(children => (
            <ListItemText key={children.key}>
              {children}
            </ListItemText>
          ))
          : props.children}
      </Collapse>
    </List>
  );
}

function SelectFilterSpecsBuilderSegmentations(props: BaseItemSegmentationBuilderProps): ReactElement {
  const { t } = useTranslation();
  const filter = props.path === '' ? props.data : get(props.data, props.path) as Partial<CustomSegmentationFilter>;
  const possibleFiltersService = new CustomSegmentationFiltersService<SegmentationPossibleFilter>();
  const [possibleFilters, setPossibleFilters] = useState<SegmentationPossibleFilter[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    possibleFiltersService.getAll({ filters: filter.on ? [{ id: 'on', scope: 'on', value: (filter.on === 'person1' || filter.on === 'person2') ? 'person' : filter.on.includes('company') ? 'company' : filter.on }] : undefined })
      .then((res) => {
        setPossibleFilters(res.data?.filter(data =>  (filter.on ? (data.on === 'person' ? (filter.on === 'person1' || filter.on === 'person2') : data.on === 'company' ? filter.on.includes('company') : filter.on === data.on) : data.on === 'person' || data.on === 'person2person')) || []);
      });
  }, [filter.on]);

  return (
    <Stack spacing={2}>
      <Typography variant='subtitle1'>{t('segmentation.builder.filter.selected')}</Typography>
      <FormControl key={`filter-select-${filter.id}-${filter.filterLabel}`} hiddenLabel required fullWidth sx={{ minWidth: 200 }} error={!filter.filterId}>
        <Select
          open={open}
          onClose={() => setOpen(false)}
          onClick={() => setOpen(!open)}
          defaultValue={filter.filterId}
          value={filter.filterId}
          size='small'
          autoWidth
        >
          {filter.filterId &&
            <MenuItem value={filter.filterId} disabled>
              {filter.filterLabel}
            </MenuItem>
          }
          {uniq(possibleFilters?.filter(possibleFilter => possibleFilter.canUseForSegmentation)
            ?.map(possibleFilter => possibleFilter.categories).flat(1))
            .filter(category => !categoryFilterToHide.includes(category))
            .map(category => (
              <SelectFilterSpecsCategoryBuilderSegmentations key={category} label={category}>
                {possibleFilters
                  ?.filter(possibleFilter => possibleFilter.categories.includes(category))
                  .map(possibleFilter => (
                    <MenuItem
                      key={possibleFilter.id}
                      value={possibleFilter.id}
                      sx={{ pl: 6, pr: 4 }}
                      disabled={possibleFilter.id === filter.filterId}
                      onClick={() => {
                        const filterTmp = { ...possibleFilter } as CustomSegmentationFilter;

                        delete filterTmp.filterId;
                        delete filterTmp.values;
                        delete filterTmp.ranges;

                        props.setData({
                          ...set(props.data, props.path, {
                            ...possibleFilter,
                            label: filter.label === possibleFilters.find(pf => pf.id === get(props.data, props.path).filterId)?.label ? possibleFilter.label || possibleFilter.id : filter.label,
                            id: filter.id,
                            filterId: possibleFilter.id,
                            filterLabel: possibleFilter.label,
                            on: filter.on,
                            type: filter.type,
                            displayType: filter.type === 'segmentationCriteria' ? {
                              valueType: possibleFilter.valueType
                            } : undefined
                          })
                        });
                      }}
                    >
                      {possibleFilter.label || possibleFilter.id}
                    </MenuItem>
                  ))
                }
              </SelectFilterSpecsCategoryBuilderSegmentations>
            ))
          }
        </Select>
        {!filter.filterId && <FormHelperText>{t('common.utils.required')}</FormHelperText>}
      </FormControl>
    </Stack>
  );
}

function FilterGroupMatchBuilderSegmentations(props: BaseItemSegmentationBuilderProps): ReactElement {
  const { t } = useTranslation();
  const filter = props.path === '' ? props.data : get(props.data, props.path) as Partial<CustomSegmentationFilter>;
  const [open, setOpen] = useState(false);

  return (
    <Stack spacing={2}>
      <Typography variant='subtitle1'>{t('segmentation.builder.filter.correspondTo')}</Typography>
      <FormControl key={`group-match-select-${filter.id}-${filter.type}`} hiddenLabel required fullWidth sx={{ minWidth: 200 }} error={!filter.type}>
        <Select
          open={open}
          onClose={() => setOpen(false)}
          onClick={() => setOpen(!open)}
          onChange={event => props.setData(props.path === '' ? {
            ...filter,
            type: event.target.value
          } : {
            ...set(props.data, props.path, {
              ...filter,
              type: event.target.value
            })
          })
          }
          defaultValue={filter.type}
          value={filter.type}
          size='small'
          autoWidth
        >
          {possibleGroupTypes.map(possibleGroupType => (
            <MenuItem key={possibleGroupType} value={possibleGroupType} disabled={filter.type === possibleGroupType}>
              {t(`segmentation.builder.filter.${possibleGroupType}`)}
            </MenuItem>
          ))}
        </Select>
        {!filter.type && <FormHelperText>{t('common.utils.required')}</FormHelperText>}
      </FormControl>
    </Stack>
  );
}

function FilterValuesSetterBuilderSegmentations(props: BaseItemSegmentationBuilderProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const filter = (props.path === '' ? props.data : get(props.data, props.path)) as Partial<CustomSegmentationFilter>;
  const [values, setValues] = useState<SegmentationFilterValue[] | undefined>(filter.values);
  const [ranges, setRanges] = useState<SegmentationFilterRange[] | undefined>(filter.ranges);
  const [valuesOrRanges, setValuesOrRanges] = useState<string>(filter.valueType === 'amount' || filter.valueType === 'number' ? 'ranges' : 'values');

  const InputComponent = {
    string: StringValuesBuilderSegmentations,
    amount: AmountValuesBuilderSegmentations,
    boolean: BooleanValuesBuilderSegmentations,
    number: AmountValuesBuilderSegmentations,
    countryCode: StringValuesBuilderSegmentations, // CountryCodeValuesBuilderSegmentations,
    'countryCode[]': StringValuesBuilderSegmentations // CountryCodeValuesBuilderSegmentations
  }[filter.valueType || 'string'];

  useEffect(() => {
    if (values) {
      const filterTmp = { ...filter };

      delete filterTmp.values;
      delete filterTmp.ranges;

      if (values && values.length > 0) {
        filterTmp.values = values;
      }

      props.setData({ ...set(props.data, props.path, filterTmp) });
    }
  }, [values]);

  useEffect(() => {
    if (ranges) {
      const filterTmp = { ...filter };

      delete filterTmp.values;
      delete filterTmp.ranges;

      if (ranges && ranges.length > 0) {
        filterTmp.ranges = ranges;
        setValues(undefined);
      }

      props.setData({ ...set(props.data, props.path, filterTmp) });
    }
  }, [ranges]);

  useEffect(() => {
    setValues(filter.values);
    setRanges(filter.ranges);
  }, []);

  return (
    <Paper
      sx={{
        width: '100%',
        p: 2,
        bgcolor: theme.palette.primary.light,
        borderColor: darken(theme.palette.primary.light, 0.2)
      }}
    >
      <Stack spacing={2} p={1}>
        <Stack direction='row' spacing={1} justifyContent='space-between' alignItems='flex-start'>
          <Typography variant='h4'>{t('segmentation.builder.filter.valuesDesc')}</Typography>
          {(filter.valueType === 'amount' || filter.valueType === 'number') &&
            <Stack direction='row' spacing={2} alignItems='center'>
              {valuesOrRanges === 'values' ?
                <Typography>{t('segmentation.builder.filter.values')}</Typography> :
                <Typography>{t('segmentation.builder.filter.ranges')}</Typography>
              }
              <Tooltip title={valuesOrRanges === 'values' ? `${t('common.utils.changeTo')} ${t('segmentation.builder.filter.ranges')}` : `${t('common.utils.changeTo')} ${t('segmentation.builder.filter.values')}`} arrow placement='top'>
                <IconButton onClick={() => setValuesOrRanges(valuesOrRanges === 'values' ? 'ranges' : 'values')}>
                  <IconExchange
                    style={{ transform: valuesOrRanges === 'ranges' ? 'rotate(180deg)' : undefined, transition: '0.2s ease' }}
                    color={theme.palette.text.primary}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          }
        </Stack>
        <Stack spacing={2} p={3}>
          {valuesOrRanges === 'values' && values && values.length > 0 && values.map((value, index) => (
            <Stack key={value.toString()} direction='row' spacing={4} alignItems='center' width='100%'>
              {/* filter.valueType?.includes('countryCode') ?
                <ReactFlagsSelect
                  selected={value.toString()}
                  onSelect={() => undefined}
                  showOptionLabel={false}
                  disabled
                /> : */
                <Typography>{filter.valueType === 'boolean' ? value ? t('common.utils.yes') : t('common.utils.no') : value}</Typography>
              }
              <IconButton size='small' onClick={() => setValues((prevState) => {
                const prevStateTmp = prevState ? [ ...prevState] : undefined;

                prevStateTmp ? prevStateTmp.splice(index, 1) : undefined;

                return prevStateTmp;
              })}>
                <IconTrash size={18} />
              </IconButton>
            </Stack>
          ))}
          {valuesOrRanges === 'ranges' && ranges && ranges.length > 0 && ranges.map((range, index) => (
            <Stack key={range.toString()} direction='row' spacing={4} alignItems='center' width='100%'>
              <Stack direction='row' spacing={2} alignItems='center'>
                <Typography>{`Min: ${range.min}`}</Typography>
                <Typography>-</Typography>
                <Typography>{`Max: ${range.max}`}</Typography>
              </Stack>
              <IconButton size='small' onClick={() => setRanges((prevState) => {
                const prevStateTmp = prevState ? [ ...prevState] : undefined;

                prevStateTmp ? prevStateTmp.splice(index, 1) : undefined;

                return prevStateTmp;
              })}>
                <IconTrash size={18} />
              </IconButton>
            </Stack>
          ))}
          {!(filter.valueType === 'boolean' && values && values.length > 0) &&
            <InputComponent
              valuesOrRanges={valuesOrRanges}
              applyValue={value => setValues(prevState => (prevState ? [ ...prevState, value ] : [ value ]))}
              applyRange={range => setRanges(prevState => (prevState ? [ ...prevState, range ] : [ range ]))}
            />
          }
        </Stack>
      </Stack>
    </Paper>
  );
}

function FilterDisplayTypeBuilderSegmentations(props: BaseItemSegmentationBuilderProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const filter = (props.path === '' ? props.data : get(props.data, props.path)) as Partial<CustomSegmentationFilter>;

  return (
    <Stack spacing={2}>
      <Typography variant='subtitle1'>{t('segmentation.builder.views.displaySelector')}</Typography>
      <Stack direction='row' spacing={1} alignItems='center'>
        {filter?.possibleDisplayTypes?.map((possibleDisplayType) => {
          const PossibleDisplayTypesIcons = {
            barChart: IconChartBar,
            pieChart: IconChartPie,
            yesNo: IconArrowsSplit2
          }[possibleDisplayType];

          return (
            <Tooltip title={t(`segmentation.builder.displayTypes.${possibleDisplayType}`)} arrow placement='top'>
              <Button
                variant={filter.displayInfo?.displayType === possibleDisplayType ? 'contained' : 'outlined'}
                sx={{ bgcolor: filter.displayInfo?.displayType === possibleDisplayType ? `${theme.palette.primary.main} !important` : undefined }}
                onClick={() => props.setData({ ...set(props.data, `${props.path}.displayInfo.displayType`, possibleDisplayType) })}
                disabled={filter.displayInfo?.displayType === possibleDisplayType}
              >
                <PossibleDisplayTypesIcons color={filter.displayInfo?.displayType === possibleDisplayType ? theme.palette.primary.contrastText : theme.palette.text.primary} />
              </Button>
            </Tooltip>
          );
        })}
      </Stack>
    </Stack>
  );
}

function FilterBuilderSegmentations(props: BaseItemSegmentationBuilderProps & { on?: SegmentationPossibleOnEntityTypes, type?: SegmentationPossibleFilterTypes }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const filter = (props.path === '' ? props.data : get(props.data, props.path)) as Partial<CustomSegmentationFilter>;

  useEffect(() => {
    if (filter) {
      const filterTmp = cloneDeep(filter);
      const ready = filter.type === 'personWithAnyCompany' ? !!filter.subItemSpecs?.[0]?.values || !!filter.subItemSpecs?.[0]?.ranges || !!filter.subItemSpecs?.[0]?.displayInfo?.displayType : !!(filter.values || filter.ranges || (filter.type === 'segmentationCriteria' && filter.displayInfo?.displayType) || (filter.type && possibleGroupTypes.includes(filter.type) && (filter.values || filter.ranges || !filter.valueType) && filter.subItemSpecs && filter.subItemSpecs.filter(subItem => subItem?.id).length > 0 && (filter.subItemSpecs as CustomSegmentationFilter[])?.every(subItem => subItem !== null && subItem.ready)));

      if (ready !== filterTmp.ready) {
        filterTmp.ready = ready;
        filterTmp.subItemSpecs = filterTmp.subItemSpecs?.filter(subItemSpec => subItemSpec?.id);

        props.setData(props.path === '' ? filterTmp : { ...set(props.data, props.path, filterTmp) });
      }
    }
  }, [props.data]);

  return (
    filter
      ? <CustomAccordion
        title={
          <Stack spacing={6} direction='row' alignItems='center' justifyContent='space-between' width='calc(100% - 16px)'>
            <TitleFilterBuilderSegmentations {...props} path={filter.type === 'personWithAnyCompany' ? `${props.path}.subItemSpecs[0]` : props.path} />
            <ActionsBuilderSegmentations {...props} />
          </Stack>
        }
        bgcolor={filter.type && (possibleFilterTypes.includes(filter.type) || filter.type === 'segmentationCriteria') ? theme.palette.background.default : theme.palette.grey['50']}
        defaultOpen={!filter.ready}
        detectHover={!props.userService && !!filter.type && (possibleFilterTypes.includes(filter.type) || possibleGroupTypes.includes(filter.type) || filter.type === 'segmentationCriteria')}
        borderColor={filter.ready ? theme.palette.success.main : theme.palette.error.main}
      >
        <Stack spacing={2} width='100%'>
          {filter.type && possibleGroupTypes.includes(filter.type) &&
            <Stack spacing={4} p={2} pt={0} pb={4}>
              <Stack spacing={4} p={4} pt={2}>
                <FilterGroupMatchBuilderSegmentations {...props} path={filter.type === 'personWithAnyCompany' ? `${props.path}.subItemSpecs[0]` : props.path} />
              </Stack>
              <Stack spacing={2}>
                <Stack direction='row' spacing={2} alignItems='center' pb={2}>
                  <Typography variant='h4' pl={4} pr={4}>{t('segmentation.builder.filter.filtersAndGroups')}</Typography>
                </Stack>
                {filter.subItemSpecs && filter.subItemSpecs.filter(item => item !== null).length > 0 &&
                   <Stack p={2} pt={0} pb={0}>
                     {filter.subItemSpecs.map((subItemSpec, index) => (
                       <FilterBuilderSegmentations key={subItemSpec?.id || 'null'} {...props} userService={undefined} path={props.path === '' ? `subItemSpecs[${index}]` : `${props.path}.subItemSpecs[${index}]`} on={filter.on as SegmentationPossibleOnEntityTypes} />
                     )).filter(subItemSpec => subItemSpec.key && subItemSpec.key !== 'null')}
                   </Stack>
                }
                <AddFilterOrGroupBuilderSegmentations {...props} path={filter.type === 'personWithAnyCompany' ? `${props.path}.subItemSpecs[0]` : props.path} childPath={props.path === '' ? 'subItemSpecs' : `${props.path}.subItemSpecs`} size='medium' on={(props.workflow?.type || props.on || filter.on) as EntitiesOut} workflow={props.workflow} />
                {filter.valueType &&
                  <Box p={2}>
                    <FilterValuesSetterBuilderSegmentations {...props} path={filter.type === 'personWithAnyCompany' ? `${props.path}.subItemSpecs[0]` : props.path} />
                  </Box>
                }
              </Stack>
            </Stack>
          }

          {filter.type && (possibleFilterTypes.includes(filter.type) || filter.type === 'segmentationCriteria') && [
            <Stack spacing={4} p={5} pt={2}>
              <SelectFilterSpecsBuilderSegmentations {...props} path={filter.type === 'personWithAnyCompany' ? `${props.path}.subItemSpecs[0]` : props.path} />
            </Stack>,
            (filter.filterId || filter.type === 'personWithAnyCompany' && filter.subItemSpecs?.[0].filterId) &&
            <Box p={5} pt={filter.type === 'segmentationCriteria' ? 0 : 2}>
              {filter.type === 'segmentationCriteria' ?
                <FilterDisplayTypeBuilderSegmentations {...props} /> :
                <FilterValuesSetterBuilderSegmentations {...props} path={filter.type === 'personWithAnyCompany' ? `${props.path}.subItemSpecs[0]` : props.path} />
              }
            </Box>
          ]}
        </Stack>
      </CustomAccordion>
      : <></>
  );
}

export default FilterBuilderSegmentations;
