import React, { ReactElement, useEffect, useState } from "react";
import { useLoaderData, useOutletContext } from "react-router-dom";
import { IDataElement } from "@deecision/dna-interfaces";
import DisplayJsonComponents from "@deecision/common-webapp/components/displayjson.components";
import { DataElementsQueryService } from "../../../../../api/services/query.services";

function EntitiesDataElementTabs(): ReactElement {
  const entityId = window.location.pathname.split('/')[4];
  const localStorageParams = localStorage.getItem(`entityType${entityId}`);
  const entityType = (localStorageParams != null ?  localStorageParams : '');

  const dataElementServices = new DataElementsQueryService({ entityType });
  const [dataElements, setDataElements] = useState<IDataElement[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await dataElementServices.findByQuery({ entityId });
      const res = Array.isArray(data.data) ? data.data : [];
      setDataElements(res);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const schedulingStatusCount = {
      all: 0,
      done: 0,
      failed: 0,
      requested: 0,
      waiting_for_dependencies: 0
    };

    dataElements?.map((entity) => {
      schedulingStatusCount[entity.scheduling.status as keyof typeof schedulingStatusCount] += 1;
      schedulingStatusCount.all += 1;
    });
    localStorage.setItem(`${entityId}statusCount`, JSON.stringify(schedulingStatusCount));
  }, [dataElements]);

  return (
    <>
      <div>
        {
          dataElements?.map(entity => (
            <DisplayJsonComponents<IDataElement> title={`${entity.type} - ${entity.scheduling.status}`} data={entity} />
          ))
        }
      </div>
    </>
  );
}

export default EntitiesDataElementTabs;