import React, { ReactElement } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
// eslint-disable-next-line no-restricted-imports
import { deepClone } from '@mui/x-data-grid/utils/utils';
import { DNAFilter, DNAFilterValue } from './types.form';
import ItemForm from './item.form';

export interface ListFormProps {
  filters: DNAFilter[],
  setFilters: (filters: DNAFilter[]) => void
}

function ListForm(props: ListFormProps): ReactElement {
  const onChange = (filterId: string, value: DNAFilterValue) => {
    const tmpFilters = props.filters.map((filter) => {
      if (filter.id === filterId) {
        const tmpFilter: typeof filter = deepClone(filter);

        tmpFilter.value = value;

        return tmpFilter;
      }

      return filter;
    });

    props.setFilters(tmpFilters);
  };

  return (
    <Grid
      container
      spacing={6}
      width='100%'
      maxWidth='800px'
      justifyContent='center'
      alignItems='top'
      mt={8}
      ml='auto !important'
      mr='auto !important'
    >
      {props.filters.map((filter, index) => (
        <ItemForm
          key={filter.id}
          index={index}
          filter={filter}
          onChange={(value: DNAFilterValue) => onChange(filter.id, value)}
        />
      ))}
    </Grid>
  );
}

export default ListForm;
