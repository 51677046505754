import React, { ReactElement } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
import { IconPlus, IconVariablePlus } from '@tabler/icons-react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import DirectIndexVeegilenz from './directindex';
import CorporateNetworkVeegilenz from './corporatenetwork.veegilenz';
import IndividualNetworkVeegilenz from './individualnetwork.veegilenz';
import { VeegilenzElementsProps } from './types.veegilenz';
import VeegilenzHeader from './header.veegilenz';

export interface VeegilenzScores {
  directIndex?: number,
  individualNetwork?: number,
  corporateNetwork?: number
}

type VeegilenzProps<T, U> = Pick<VeegilenzElementsProps<T, U>, 'getter' | 'entityType'>

function Veegilenz<T, U>(props: VeegilenzProps<T, U>): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();

  const scoreColor = [
    {
      light: theme.palette.grey['300'],
      main: theme.palette.grey['300'],
      dark: theme.palette.grey['800'],
      contrastText: theme.palette.grey['300']
    },
    theme.palette.success,
    theme.palette.warning,
    theme.palette.error,
    {
      light: theme.palette.grey['800'],
      main: theme.palette.grey['800'],
      dark: theme.palette.grey['200'],
      contrastText: theme.palette.grey['300']
    }
  ];

  return (
    <Grid container spacing={2}>
      <Grid xs={12} mb={2}>
        <VeegilenzHeader />
      </Grid>
      <Grid xs={12} textAlign='end'>
        <Button
          variant='contained'
          startIcon={<IconPlus />}
        >
          {t('veegilenz.addCriteria')}
        </Button>
      </Grid>
      <Grid>
        <DirectIndexVeegilenz<T, U>
          score={props.getter.getDirectScore()}
          scoreColor={scoreColor}
          getter={props.getter}
          entityType={props.entityType}
          disabled={props.getter.getDirectScore() === 0}
        />
      </Grid>
      <Grid>
        <CorporateNetworkVeegilenz<T, U>
          score={0}
          scoreColor={scoreColor}
          getter={props.getter}
          entityType={props.entityType}
          disabled
        />
      </Grid>
      <Grid>
        <IndividualNetworkVeegilenz<T, U>
          score={0}
          scoreColor={scoreColor}
          getter={props.getter}
          entityType={props.entityType}
          disabled
        />
      </Grid>
    </Grid>
  );
}

export default Veegilenz;
