import React, { ReactElement, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { EChartsOption } from 'echarts-for-react/src/types';
import { darken, useTheme } from '@mui/material/styles';
import { getFlagEmoji } from '@deecision/common-webapp/utils/getFlagEmoji';
import { getCountryISO2 } from '@deecision/common-webapp/utils/getCountryISO2';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { colorTableForVeeligenz, DataType, FlagType, formatNumber, getRange, getValue, jetBlack, sortVeegilenzColor } from './utils';
import { BaseChartsProps } from './types.charts';
import './style.css';
import APE from '../../../config/Liste_des_codes_NAF_et_APE_en_france__2_.json';

const tooltip = {
  trigger: 'item',
  axisPointer: {
    type: 'shadow'
  },
  position (point: [number, number], params: undefined, dom: undefined, rect: {width: number, height: number}, size: {contentSize: [number, number]}) {
    const tooltipWidth = size.contentSize[0];
    const tooltipHeight = size.contentSize[1];
    let x = point[0];
    let y = point[1];

    if (x + tooltipWidth > rect.width) {
      x = rect.width - tooltipWidth;
    }
    if (y + tooltipHeight > rect.height) {
      y = rect.height - tooltipHeight;
    }
    x = Math.max(0, Math.min(x, rect.width - tooltipWidth));
    y = Math.max(0, Math.min(y, rect.height - tooltipHeight));

    return [x, y];
  }
};

const baseLegendForOption = {
  show: true,
  top: 'center',
  right: 0,
  orient: 'vertical',
  type: 'scroll',
  height: '80%',
  textStyle: {
    overflow: 'truncate',
    rich: {
      b: {
        fontWeight: 'bold'
      },
      flagEmojiStyle: {
        fontFamily: 'NotoColorEmojiLimited'
      }
    }
  },
  data: {
    test: 'test'
  },
  selected: {
    test: true
  }
};

const baseSeriesForOption = {
  type: 'pie',
  width: '100%',
  radius: ['30%', '60%'],
  center: ['40%', '50%'],
  avoidLabelOverlap: false,
  itemStyle: {
    borderRadius: 10,
    borderColor: jetBlack,
    borderWidth: 1
  },
  label: {
    show: true,
    position: 'outside',
    rich: {
      flagEmojiStyle: {
        fontFamily: 'NotoColorEmojiLimited'
      }
    }
  },
  emphasis: {
    label: {
      show: true,
      fontSize: 16,
      backgroundColor: '#E9ECEF',
      padding: 4,
      borderRadius: 10,
      fontWeight: 'normal'
    }
  },
  textStyle: {
    overflow: 'truncate',
    rich: {
      flagEmojiStyle: {
        fontFamily: 'NotoColorEmojiLimited'
      }
    }
  },
  data: [
    { value: 1048, name: 'Initialization value' },
    { value: 735, name: 'Initialization value 2' }
  ]
};

function PieCharts(props: BaseChartsProps & { flagsData?: Array<FlagType>, showDataLabel?: boolean, graphPositionX?: string | number }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const baseColor = [
    theme.palette.primary.main.replace(')', ', 0.75)').replace('rgb', 'rgba'),
    theme.palette.success.main.replace(')', ', 0.75)').replace('rgb', 'rgba'),
    theme.palette.warning.main.replace(')', ', 0.75)').replace('rgb', 'rgba'),
    theme.palette.secondary.main.replace(')', ', 0.75)').replace('rgb', 'rgba'),
    theme.palette.error.main.replace(')', ', 0.75)').replace('rgb', 'rgba')
  ];
  const color = props.datas.map((_, index) => {
    const baseColorIndex = index % baseColor.length;

    return baseColor[baseColorIndex];
  });
  const total = props.datas.reduce((acc, curr) => acc + (curr.count || 0), 0);
  const [legendSelected, setLegendSelected] = useState<{[key: string]: boolean}>({});
  const [dataClickState, setDataClickState] = useState<{[key: string]: boolean}>({});
  const [option, setOption] = useState<EChartsOption>({
    title: {
      show: true,
      text: props.on,
      right: 'center',
      textStyle: {
        fontSize: 12,
        fontWeight: 'normal'
      }
    },
    tooltip,
    color,
    legend: baseLegendForOption,
    series: baseSeriesForOption
  });

  const handleDataClickState = (name: string) => {
    setDataClickState((prev) => {
      const nextState = {
        [name]: !prev[name]
      };

      if (!nextState[name]) setLegendSelected({});

      return nextState;
    });
  };

  const onLegendClick = (params: { name: string, selected: { [key: string]: boolean } }) => {
    const legendName = params.name;
    const indexOfLegend = option.series[0].data.map((e: DataType) => e.name).indexOf(legendName);
    const dataOfLegendClicked = option.series[0].data[indexOfLegend];

    const echartsData = { range: dataOfLegendClicked.range, value: dataOfLegendClicked.soloValue };
    const name = dataOfLegendClicked.range ? dataOfLegendClicked.range : dataOfLegendClicked.soloValue;
    console.log('echartsData1', echartsData);

    setLegendSelected(params.selected);
    handleDataClickState(name);
    if (name === null || name === undefined) return;
    props.onClick ? props.onClick(undefined, undefined, undefined, true, echartsData) : '';
  };

  const onDataClick = (params: {
    data: {
      range: string,
      soloValue: string | boolean
    }
  }) => {
    const echartsData = { range: params.data.range, value: params.data.soloValue as any };
    const name = echartsData.range ? echartsData.range : echartsData.value ? echartsData.value : '';

    handleDataClickState(String(name));
    if (name === null || name === undefined) return;
    props.onClick ? props.onClick(undefined, undefined, undefined, true, echartsData) : '';
  };

  const onEvents = {
    legendselectchanged: onLegendClick,
    click: onDataClick
  };

  const getBooleanOrOtherName = (element?: {soloValue: string | boolean}, value?: string | boolean) => {
    switch (element ? element?.soloValue : value) {
    case false || 'false':
      return false;
    case true || 'true':
      return true;
    case '':
      return t('common.utils.notFound');
    case null:
      return t('common.utils.notFound');
    default:
      return element? element.soloValue : value;
    }
  };

  function getFlagLabel(value: string) {
    if (props.flagsData?.length === 0 || value === null || value === undefined) return '';

    if (props.dataType === 'countryCode') {
      const flagToFind = getCountryISO2(value);

      return `${getFlagEmoji(flagToFind)}` || '';
    }
    if (props.dataType === 'countryCode[]') {
      const countryCodes = value.split(' ');
      const emojis = countryCodes.map((code: string) => {
        const flagParsed = getCountryISO2(code);

        return getFlagEmoji(flagParsed) || '';
      });

      return emojis.join('');
    }

    return '';
  }

  const getName = (element: DataType) => {
    if (element.value === undefined && element.displayValue === undefined && element.range === undefined) return t('common.utils.notFound');
    if (element.displayValue !== undefined) {
      return `${element.displayValue.substring(0, 15)  }...`;
    }
    if (element.value === undefined && element.displayValue === undefined) {
      switch (props.dataType) {
      case 'amount':
        return `${formatNumber(element.range?.min, 0, props.currency || 'EUR')} - ${formatNumber(element.range?.max, 0, props.currency || 'EUR')}`;
      case 'number':
        return `${element.range.min}-${element.range.max}`;
      default:
        break;
      }
    }
    if (element.value === null) return t('common.utils.notFound');
    if (props.dataType === 'boolean') return (element.value ?  t('common.utils.yes') : t('common.utils.no'));
    if (props.dataType === 'countryCode') {
      const insecableSpace = '\u00a0'.repeat(element.value.length + 1);

      return `{flagEmojiStyle|${getFlagLabel(element.value)}}${insecableSpace}${element.value}`;
    }
    if (isArray(element.value) && props.dataType === 'countryCode[]' && element.value !== null) {
      const insecableSpace = '\u00a0'.repeat(element.value.length + 1);

      return element.value.length === 0 ? t('common.utils.notFound') : `{flagEmojiStyle|${getFlagLabel(element.value.join(' '))}}${insecableSpace}${element.value.join(' ')} `;
    }

    const regexCodeAPE = /^[0-9][0-9].[0-9][0-9][A-Z]$/;

    if (regexCodeAPE.test(element.value)) {
      if (Array.isArray(APE.APE) && APE.APE.length > 0) {
        const elementFind = APE.APE.some((ape: { code: string }) => ape.code === element.value) ? APE.APE.find((ape: { code: string }) => ape.code === element.value)?.name : t('common.utils.notFound');

        return elementFind ? `${element.value} - ${elementFind}` : t('common.utils.notFound');
      }
    }

    return element.value?.toString();
  };

  const getDisplayValueFullName = (name: string) => Object.values(props.datas).find((data) => {
    if (data.displayValue !== undefined) {
      return data.displayValue.includes(name.replace('\...', ''));
    }
  });

  const tooltipFormatter = (params: {name: string}) => {
    let result = getDisplayValueFullName(params.name);
    let display = 'displayValue';

    if (result === undefined) {
      result = props.datas.find(item => (getName(item) === params.name));
      display = (result === undefined ? 'displayValue' : 'RangeOrValue');
    }
    const dataPercentageAndCount = `${result && (result.count || result.count === 0) ? `${props.countType === 'amount' ? formatNumber(result.count || 0, 0, props.currency || 'EUR') : result.count} (${(((result.count || 0) / total) * 100).toFixed(2)}%)` : t('common.utils.notFound')}`;

    const parsedFlag = params.name.startsWith('{flagEmojiStyle|') ? params.name.substring('{flagEmojiStyle|'.length).replace('}', ' ').split(' ') : undefined;
    const flagEmojiStyle = params.name.startsWith('{flagEmojiStyle|') ? 'font-family: NotoColorEmojiLimited;' : '';
    const nameToDisplay = `<span style="${flagEmojiStyle}">${ parsedFlag ? parsedFlag[0] : result}</span> ${ parsedFlag ? parsedFlag.slice(1).join(' ') : ''}`;

    return (display === 'displayValue' && result ?
      `${result.displayValue}\n<span style="float: right; margin-left: 20px"><b>${dataPercentageAndCount}</b></span>` :
      `${parsedFlag !== undefined ? nameToDisplay : params.name}\n<span style="float: right; margin-left: 20px"><b>${dataPercentageAndCount}</b></span>`);
  };

  const legendFormatter = (name: string) => {
    const regexCodeAPE = /^[0-9][0-9].[0-9][0-9][A-Z]/;
    const value = props.datas.find(item => (getName(item) === name));
    const dataPercentageAndCount = `${value && (value.count || value.count === 0) ? `${props.countType === 'amount' ? formatNumber(value.count || 0, 0, props.currency || 'EUR') : value.count} (${(((value.count || 0) / total) * 100).toFixed(2)}%)` : t('common.utils.notFound')}`;
    const isCountry = props.dataType === 'countryCode' || props.dataType === 'countryCode[]';
    let result;
    const displayedname = regexCodeAPE.test(name) ? `${name.substring(0, 14)}...` : isCountry ? name : name.substring(0, 14);

    Object.keys(legendSelected).forEach((key) => {
      if (!legendSelected[key] && key === name) result = key;
    });

    return name === '' || name === null ?
      `${t('common.utils.notFound')}\n${dataPercentageAndCount}`:
      `${result && !isCountry ? `{b|` : ''} ${displayedname}${result && !isCountry ? '}' : ''}\n{b|${dataPercentageAndCount}}`;
  };

  const makeChart = () => {
    const buildData = props.datas.sort(sortVeegilenzColor).map((element, index) => {
      const legendName: any = element.range ? element.range : getBooleanOrOtherName(element.value);
      const colorAssociated = color[index] || '#fff';
      const currentColorIfDefine: string = getValue(element) ? String(getValue(element)) : '';
      const colorToDisplay = colorTableForVeeligenz[currentColorIfDefine] !== undefined ? colorTableForVeeligenz[currentColorIfDefine] : colorAssociated;

      return ({
        value: String(element.count),
        name: getName(element),
        range: getRange(element),
        soloValue: getValue(element),
        label: {
          formatter: (params: {name: string, value: number}) => {
            const regexCodeAPE = /^[0-9][0-9].[0-9][0-9][A-Z]/;

            return (`${regexCodeAPE.test(params.name) ? `${params.name.substring(0, 7)}...` : params.name}\n ${((params.value / total) * 100).toFixed(2)}%`);
          },
          fontWeight: dataClickState[legendName] ? 'bold' : 'normal',
          color: dataClickState[legendName] ? darken(colorToDisplay, 0.3) : colorToDisplay
        },
        itemStyle: {
          borderColor: dataClickState[legendName] ? darken(colorToDisplay, 0.7) : darken(colorToDisplay, 0.2),
          color: dataClickState[legendName] ? darken(colorToDisplay, 0.3) : colorToDisplay
        }
      });
    });
    const legendProps = {
      ...baseLegendForOption,
      right: props.halfPieChart ? 'center' : props.graphPositionX ? undefined : 20,
      width: props.halfPieChart ? '96%' : '120%',
      left: props.legendPosition === 'bottom' ? 0 : props.graphPositionX ? '50%' : undefined,
      orient: props.halfPieChart || props.legendPosition === 'bottom' ? 'horizontal' : 'vertical',
      textStyle: {
        ...baseLegendForOption.textStyle,
        fontSize: props.legendPosition === 'bottom' ? 10 : 12
      },
      tooltip: {
        show: true,
        trigger: 'item',
        formatter: tooltipFormatter
      },
      ...(props.halfPieChart || props.legendPosition === 'bottom' ? { bottom: 50, top: undefined } : { top: 'center', bottom: undefined }),
      formatter: legendFormatter,
      data: buildData
    };
    const seriesProps = {
      ...baseSeriesForOption,
      label: props.legendPosition === 'bottom' ? { show: false } : { ...baseSeriesForOption.label, show: props.showDataLabel !== false, position: 'outside' },
      emphasis: props.legendPosition === 'bottom' ||  props.showDataLabel === false ? null : baseSeriesForOption.emphasis,
      ...(props.legendPosition === "bottom" ? { center: ['50%', '35%'] } : { center: [ props.graphPositionX ? props.graphPositionX : '40%', '50%'] }),
      ...(props.halfPieChart ? {
        startAngle: 180,
        endAngle: 360,
        center: ['center', '50%']
      } : {}),
      tooltip: {
        show: true,
        trigger: 'item',
        formatter(params: {
          data: any, marker: string, name: string, value: string, percent: string
        }) {
          const displayValueOrName = getDisplayValueFullName(params.name)?.displayValue || params.name;
          const parsedFlag = params.name.startsWith('{flagEmojiStyle|') ? params.name.substring('{flagEmojiStyle|'.length).replace('}', ' ').split(' ') : undefined;
          const flagEmojiStyle = params.name.startsWith('{flagEmojiStyle|') ? 'font-family: NotoColorEmojiLimited;' : '';
          const nameToDisplay = `<span style="${flagEmojiStyle}">${ parsedFlag ? parsedFlag[0] : displayValueOrName}</span> ${ parsedFlag ? parsedFlag.slice(1).join(' ') : ''}`;

          return `${params.marker} ${nameToDisplay}<span style="float: right; margin-left: 20px;"><b>${props.countType === 'amount' ? formatNumber(parseFloat(params.value), 0, props.currency || 'EUR') : params.value} (${params.percent}%)</b></span>`;
        }
      },
      data: buildData
    };
    const newColors = props.datas.map((serieData: any, index) => {
      const currentColorIfDefine: string = serieData.value ? String(serieData.value) : '';
      const colorToDisplay = colorTableForVeeligenz[currentColorIfDefine] !== undefined ? colorTableForVeeligenz[currentColorIfDefine] : color[index];

      return colorToDisplay;
    });

    setOption((prevState: EChartsOption) => ({
      ...prevState,
      color: newColors,
      legend: { ...legendProps },
      series: [{ ...seriesProps }]
    }));
  };

  useEffect(() => {
    setDataClickState({});
    setLegendSelected({});
    makeChart();
  }, [props.datas]);

  useEffect(() => {
    setOption((prevState: EChartsOption) => {
      const updatedLegend = {
        ...prevState.legend,
        formatter: legendFormatter
      };
      const updatedSeries = prevState.series.map((serie: {data: any}) => ({
        ...serie,
        data: serie.data.map((serieData: any, index: number) => {
          const legendName = serieData.range ? serieData.range : getBooleanOrOtherName(serieData);
          const colorAssociated = option.color[index] || jetBlack;
          const currentColorIfDefine = serieData.soloValue ? String(serieData.soloValue) : '';
          const colorToDisplay = colorTableForVeeligenz[currentColorIfDefine] !== undefined ? colorTableForVeeligenz[currentColorIfDefine] : colorAssociated;

          return {
            ...serieData,
            itemStyle: {
              ...serieData.itemStyle,
              borderColor: dataClickState[legendName] ? darken(colorToDisplay, 0.7) : darken(colorToDisplay, 0.2),
              color: dataClickState[legendName] ? darken(colorToDisplay, 0.3) : colorToDisplay
            },
            label: {
              ...serieData.label,
              fontWeight: dataClickState[legendName] ? 'bold' : 'normal',
              color: dataClickState[legendName] ? darken(colorToDisplay, 0.3) : 'black'
            }
          };
        })
      }));

      return {
        ...prevState,
        legend: updatedLegend,
        series: updatedSeries
      };
    });
  }, [dataClickState, legendSelected]);

  useEffect(() => {
    if (props.selected === undefined) {
      setLegendSelected({});
      setDataClickState({});
    }
  }, [props.selected]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth, windowHeight]);

  const chartWidth = Math.min(windowWidth * 0.53, 650);
  const chartHeight = Math.min(windowHeight * 0.7, 435);

  return (
    <div
      className='flex flex-col bg-primary p-[15px] bg-[yellow] '
      style={{ width: `${chartWidth}px`, height: `${chartHeight}px` }}
    >
      <ReactECharts
        className='flex flex-1 bg-[transparent] h-full w-full'
        option={option}
        onEvents={onEvents}
        style={{
          minWidth: '100%',
          minHeight: '95%'
        }}
      />
    </div>
  );
}

export default PieCharts;
