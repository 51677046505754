import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { IconCheck, IconLayoutDashboard, IconPlus, IconX } from '@tabler/icons-react';
import Button from '@mui/material/Button';
import { get, set, uniqueId, upperFirst } from 'lodash';
import CustomAccordion from '../../../../components/accordion';
import { BaseItemSegmentationBuilderProps } from '../types.builder.segmentations';
import { CustomSegmentation, CustomSegmentationView } from '../../types.segmentations';
import ViewBuilderSegmentations from '../items/view.builder.segmentations';
import { EntitiesOut, segmentationEntityTypes, SegmentationWorkflow } from './typeselector.builder.segmentations';

function SegmentationBuilderSegmentations(props: Omit<BaseItemSegmentationBuilderProps, 'workflow'> & { data: CustomSegmentation }): ReactElement {
  const { t } = useTranslation();

  const addView = () => {
    const view: CustomSegmentationView = {
      id: uniqueId(`view-${(get(props.data, props.path)?.filter((element: CustomSegmentationView) => element !== null).length || 0) + 1}`),
      label: `${upperFirst(t('segmentation.builder.views.unique') || 'view')} ${(get(props.data, props.path)?.filter((element: CustomSegmentationView) => element !== null).length || 0) + 1}`,
      ready: false
    };

    props.setData({ ...set(props.data, `${props.path}[${get(props.data, props.path)?.length || 0}]`, view) });
  };

  return (
    <CustomAccordion
      title={
        <Stack direction='row' spacing={2}>
          <Typography variant='h4'>
            {t('segmentation.builder.steps.segmentationViews.label')}
          </Typography>
          {props.data?.views && props.data.views.filter(item => item).length > 0 && props.data.views.filter(item => item).every(item => item?.ready) ?
            <Chip
              sx={{ '& > *': { p: 2 } }}
              label={<Box display='flex' justifyContent='center'><IconCheck size={18} /></Box>}
              color='success'
              size='small'
            /> :
            <Chip
              sx={{ '& > *': { p: 2 } }}
              label={<Box display='flex' justifyContent='center'><IconX size={18} /></Box>}
              color='error'
              size='small'
            />
          }
        </Stack>
      }
      defaultOpen
    >
      <Stack spacing={4} p={4} alignItems='center'>
        <Stack width='100%'>
          {(get(props.data, props.path) as CustomSegmentationView[])?.map((view, index) => (
            <ViewBuilderSegmentations key={(view as CustomSegmentationView)?.id || 'null'} {...props} path={`${props.path}[${index}]`} workflow={(segmentationEntityTypes?.find(segmentationEntityType => segmentationEntityType.type === props.data.on)?.workflows as SegmentationWorkflow<EntitiesOut>[])?.find(workflow => view.on === workflow.type)} />
          )).filter(view => view.key !== 'null')}
        </Stack>
        <Button
          variant='contained'
          sx={{ width: 'fit-content' }}
          onClick={addView}
          startIcon={<IconLayoutDashboard />}
          endIcon={<IconPlus />}
          disabled={!props.data.on}
          aria-disabled={!props.data.on}
        >
          {t('segmentation.builder.views.create')}
        </Button>
      </Stack>
    </CustomAccordion>
  );
}

export default SegmentationBuilderSegmentations;
