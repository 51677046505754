import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  IconCheck,
  IconCirclesRelation,
  IconTargetArrow
} from '@tabler/icons-react';
import Grid from '@mui/material/Unstable_Grid2';
import CustomizedSteppers from '../../../../components/steppers/horizontal.steppers';
import SearchEngineComponents from '../../search/components/searchengine.components';
import Step2Relations from './steps/step2.relations';
import Step3Relations from './steps/step3.relations';

function RelationsComponent(): ReactElement {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: `${t('common.utils.step')} 1`,
      desc: t('entities.relations.stepper.step1.label')
    },
    {
      label: `${t('common.utils.step')} 2`,
      desc: t('entities.relations.stepper.step2.label')
    },
    {
      label: `${t('common.utils.step')} 3`,
      desc: t('entities.relations.stepper.step3.label')
    }
  ];

  const icons: { [index: string]: React.ReactElement } = {
    1: <IconTargetArrow size={20} />,
    2: <IconCirclesRelation size={20} />,
    3: <IconCheck size={20} />
  };

  const stepComponents = useMemo(() => [
    <SearchEngineComponents link={(id: string, entityType: string) => navigate({ pathname, search: `?step=2&entity1=${id}&entity1Type=${entityType}` })} noActions />,
    <Step2Relations />,
    <Step3Relations />
  ], []);

  useEffect(() => {
    const activeStepTmp = searchParams.get('step');

    if (activeStepTmp) {
      setActiveStep(parseInt(activeStepTmp, 10) - 1);
    }
  }, [searchParams]);

  return (
    <Grid container spacing={4} pt={2}>
      <Grid xs={12} mb={8}>
        <CustomizedSteppers activeStep={activeStep} icons={icons} steps={steps} />
      </Grid>
      <Grid xs={12} p={2}>
        {stepComponents[activeStep]}
      </Grid>
    </Grid>
  );
}

export default RelationsComponent;
