import React, { ReactElement, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import TitleComponent from '@deecision/common-webapp/components/title.components';
import { IconChartPie, IconCircleX } from '@tabler/icons-react';
import { Button, Chip, Stack } from '@mui/material';
import { CompanyEntity, PersonEntity, SegmentationCriteria } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import PieCharts from '../../../../components/charts/pie.charts';
import { ChartRange, ChartsSegmentationProps } from './types.charts.segmentation';
import { getApeLabel, getRncsLabel } from '../../../../../config/getter';

function PersonPieChartsSegmentation<T extends PersonEntity>(props: ChartsSegmentationProps<T>): ReactElement {
  const { t } = useTranslation();

  const dataCharts = (rng?: { apeCodeRanges: ChartRange[], rncsCodeRanges: ChartRange[]}) =>  [
    {
      id: 'activitySector',
      label: t('common.utils.activitySector'),
      filters: rng?.apeCodeRanges || [],
      values: (prop: { data: T[], filter?: string }) => prop.data.filter(d => (
        prop.filter && d.dataViews?.data?.companies?.stats?.all?.groupByApeCode?.[prop.filter]
      )).length,
      rawValues: (prop: { data: T[], filter?: string }) => prop.data.filter(d => (
        prop.filter && d.dataViews?.data?.companies?.stats?.all?.groupByApeCode?.[prop.filter]
      )).length,
      filterValues: (prop: { data: T[], filter?: string }) => prop.data.filter(d => (
        prop.filter && d.dataViews?.data?.companies?.stats?.all?.groupByApeCode?.[prop.filter]
      )),
      getter: getApeLabel
    },
    {
      id: 'legalForm',
      label: t('common.utils.legalForm'),
      filters: rng?.rncsCodeRanges || [],
      values: (prop: { data: T[], filter?: string }) => prop.data.filter(d => (
        prop.filter && d.dataViews?.data?.companies?.stats?.all?.groupByLegalFormCode?.[prop.filter]
      )).length,
      rawValues: (prop: { data: T[], filter?: string }) => prop.data.filter(d => (
        prop.filter && d.dataViews?.data?.companies?.stats?.all?.groupByLegalFormCode?.[prop.filter]
      )).length,
      filterValues: (prop: { data: T[], filter?: string }) => prop.data.filter(d => (
        prop.filter && d.dataViews?.data?.companies?.stats?.all?.groupByLegalFormCode?.[prop.filter]
      )),
      getter: getRncsLabel
    }
  ];

  const [chartId, setChartId] = useState(dataCharts()[0].id);
  const [apeCodeRanges, setApeCodeRanges] = useState<ChartRange[]>([]);
  const [rncsCodeRanges, setRncsCodeRanges] = useState<ChartRange[]>([]);
  const [piesDatas, setPiesDatas] = useState<Required<SegmentationCriteria>['values'][]>([]);

  const action = (filter?: string) => {
    if (!filter) {
      props.setFilters(props.filters.filter(f => f.id !== chartId));
    } else {
      props.setFilters([...props.filters.filter(f => !dataCharts().map(c => c.id).includes(f.id)), {
        id: chartId,
        label: dataCharts().find(dataChart => dataChart.id === chartId)?.label || chartId,
        value: filter,
        valueParsed: dataCharts().find(dataChart => dataChart.id === chartId)?.getter(filter)
      }]);
    }
    props.setBatchDatas(
      dataCharts({ apeCodeRanges, rncsCodeRanges })?.find(dataChart => dataChart.id === chartId)?.filterValues({
        data: props.batchDatas,
        filter
      }) as T[] || []
    );
  };

  useEffect(() => {
    const apeCodeRangesTemp: ChartRange[] = [];
    const rncsCodeRangesTemp: ChartRange[]  = [];

    props.batchDatas.forEach((d) => {
      const apeCode = Object.keys(d.dataViews?.data?.companies?.stats?.all?.groupByApeCode || {});
      const rncsCode = Object.keys(d.dataViews?.data?.companies?.stats?.all?.groupByLegalFormCode || {});

      apeCode.forEach((aC) => {
        if (aC && !apeCodeRangesTemp.find(apeCodeRange => apeCodeRange.code === aC)) {
          apeCodeRangesTemp.push({ label: aC, code: aC });
        }
      });

      rncsCode.forEach((rncsC) => {
        if (rncsC && !rncsCodeRangesTemp.find(rncsCodeRange => rncsCodeRange.code === rncsC)) {
          rncsCodeRangesTemp.push({ label: rncsC, code: rncsC });
        }
      });
    });
    setApeCodeRanges(apeCodeRangesTemp);
    setRncsCodeRanges(rncsCodeRangesTemp);
    setPiesDatas(
      dataCharts({ apeCodeRanges: apeCodeRangesTemp, rncsCodeRanges: rncsCodeRangesTemp })
        .map(dataChart => (
          dataChart.filters.map(f => ({
            count: dataChart.values({ data: props.batchDatas, filter: f.code }),
            value: `${f.label} - ${dataChart.getter(f.code || f.label) || f.code || ''}`,
            range: {
              min: 0,
              max: 0
            }
          }))
        ))
    );
  }, [props.batchDatas]);

  useEffect(() => {
    const filter = props.filters.find(f => f.id === chartId);

    if (filter) {
      props.setBatchDatas(
        dataCharts({ apeCodeRanges, rncsCodeRanges })?.find(dataChart => dataChart.id === chartId)?.filterValues({
          data: props.batchDatas,
          filter: filter.value
        }) as T[] || []
      );
    } else {
      props.setBatchDatas(props.batchDatas);
    }
  }, [props.filters, piesDatas]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid xs={12} textAlign='center'>
          <TitleComponent icon={IconChartPie} title={props.label} small />
        </Grid>
        <Grid xs={12}>
          <Stack direction='row' spacing={2}>
            {dataCharts({ apeCodeRanges, rncsCodeRanges }).map(dataChart => (
              <Button
                key={dataChart.id}
                variant={dataChart.id === chartId ? 'tabContained' : 'tabText'} size='small'
                onClick={() => setChartId(dataChart.id)}
              >
                {dataChart.label}
              </Button>
            ))}
          </Stack>
        </Grid>
        {props.filters.filter(f => !dataCharts().map(c => c.id).includes(f.id)).length > 0 &&
          <Grid xs={12}>
            {props.filters.filter(f => !dataCharts().map(c => c.id).includes(f.id)).map(filter => (
              filter.value && filter.value !== '' &&
              <Chip
                key={filter.id}
                label={`${filter.label}: ${filter.valueParsed || filter.value}`}
                size='small'
                onDelete={() => (
                  props.setFilters ?
                    props.setFilters(props.filters?.filter(f => f.id !== filter.id) || []) :
                    undefined
                )}
                deleteIcon={<IconCircleX size={18} />}
              />
            ))}
          </Grid>
        }
        <Grid xs={12}>
          <PieCharts
            datas={piesDatas[dataCharts({ apeCodeRanges, rncsCodeRanges }).findIndex(dataChart => dataChart.id === chartId)] || []}
            totalCount={props.totalCount}
            showDataLabel={false}
            graphPositionX='20%'
            // legendPosition='bottom'
            dataType='number'
          />
        </Grid>
      </Grid>
    </>
  );
}

function CompanyPieChartsSegmentation<T extends CompanyEntity>(props: ChartsSegmentationProps<T>): ReactElement {
  const { t } = useTranslation();

  const dataCharts = (rng?: { apeCodeRanges: ChartRange[], rncsCodeRanges: ChartRange[]}) =>  [
    {
      id: 'activitySector',
      label: t('common.utils.activitySector'),
      filters: rng?.apeCodeRanges || [],
      values: (prop: { data: T[], filter?: string }) => prop.data.filter(d => (
        prop.filter === d.dataViews?.data?.company?.identity?.codeApe
      )).length,
      rawValues: (prop: { data: T[], filter?: string }) => prop.data.filter(d => (
        prop.filter === d.dataViews?.data?.company?.identity?.codeApe
      )).length,
      filterValues: (prop: { data: T[], filter?: string }) => prop.data.filter(d => (
        prop.filter === d.dataViews?.data?.company?.identity?.codeApe
      )),
      getter: getApeLabel
    },
    {
      id: 'legalForm',
      label: t('common.utils.legalForm'),
      filters: rng?.rncsCodeRanges || [],
      values: (prop: { data: T[], filter?: string }) => prop.data.filter(d => (
        prop.filter === d.dataViews?.data?.company?.identity?.legalStatus.code
      )).length,
      rawValues: (prop: { data: T[], filter?: string }) => prop.data.filter(d => (
        prop.filter === d.dataViews?.data?.company?.identity?.legalStatus.code
      )).length,
      filterValues: (prop: { data: T[], filter?: string }) => prop.data.filter(d => (
        prop.filter === d.dataViews?.data?.company?.identity?.legalStatus.code
      )),
      getter: getRncsLabel
    }
  ];

  const [chartId, setChartId] = useState(dataCharts()[0].id);
  const [apeCodeRanges, setApeCodeRanges] = useState<ChartRange[]>([]);
  const [rncsCodeRanges, setRncsCodeRanges] = useState<ChartRange[]>([]);
  const [piesDatas, setPiesDatas] = useState<Required<SegmentationCriteria>['values'][]>([]);

  const action = (filter?: string) => {
    if (!filter) {
      props.setFilters(props.filters.filter(f => f.id !== chartId));
    } else {
      props.setFilters([...props.filters.filter(f => !dataCharts().map(c => c.id).includes(f.id)), {
        id: chartId,
        label: dataCharts().find(dataChart => dataChart.id === chartId)?.label || chartId,
        value: filter,
        valueParsed: dataCharts().find(dataChart => dataChart.id === chartId)?.getter(filter)
      }]);
    }
    if (props.label !== '') {
      props.setBatchDatas(
        dataCharts({ apeCodeRanges, rncsCodeRanges })?.find(dataChart => dataChart.id === chartId)?.filterValues({
          data: props.batchDatas,
          filter
        }) as T[] || props.batchDatas
      );
    }
  };

  useEffect(() => {
    const apeCodeRangesTemp: ChartRange[] = [];
    const rncsCodeRangesTemp: ChartRange[]  = [];

    const apeCode = props.batchDatas.map(company => company.dataViews?.data?.company?.identity?.codeApe);
    const rncsCode = props.batchDatas.map(company => company.dataViews?.data?.company?.identity?.legalStatus.code);

    apeCode.forEach((aC) => {
      if (aC && !apeCodeRangesTemp.find(apeCodeRange => apeCodeRange.code === aC)) {
        apeCodeRangesTemp.push({ label: aC, code: aC });
      }
    });

    rncsCode.forEach((rncsC) => {
      if (rncsC && !rncsCodeRangesTemp.find(rncsCodeRange => rncsCodeRange.code === rncsC)) {
        rncsCodeRangesTemp.push({ label: rncsC, code: rncsC });
      }
    });
    setApeCodeRanges(apeCodeRangesTemp);
    setRncsCodeRanges(rncsCodeRangesTemp);
    setPiesDatas(
      dataCharts({ apeCodeRanges: apeCodeRangesTemp, rncsCodeRanges: rncsCodeRangesTemp })
        .map(dataChart => (
          dataChart.filters.map(f => ({
            count: dataChart.values({ data: props.batchDatas, filter: f.code }),
            value: `${f.label} - ${dataChart.getter(f.code || f.label) || f.code || ''}`,
            range: {
              min: 0,
              max: 0
            }
          }))
        ))
    );
  }, [props.batchDatas]);

  useEffect(() => {
    const filter = props.filters.find(f => f.id === chartId);

    if (filter) {
      props.setBatchDatas(
        dataCharts({ apeCodeRanges, rncsCodeRanges })?.find(dataChart => dataChart.id === chartId)?.filterValues({
          data: props.batchDatas,
          filter: filter.value
        }) as T[] || []
      );
    } else {
      props.setBatchDatas(props.batchDatas);
    }
  }, [props.filters, piesDatas]);

  return (
    <>
      <Grid container spacing={4} width='min-content'>
        {props.label !== '' &&
          <Grid xs={12} textAlign='center'>
            <TitleComponent icon={IconChartPie} title={props.label} small />
          </Grid>
        }
        <Grid xs={12}>
          <Stack direction='row' spacing={2}>
            {dataCharts({ apeCodeRanges, rncsCodeRanges }).map(dataChart => (
              <Button
                key={dataChart.id}
                variant={dataChart.id === chartId ? 'tabContained' : 'tabText'} size='small'
                onClick={() => setChartId(dataChart.id)}
                fullWidth={props.label === ''}
              >
                {dataChart.label}
              </Button>
            ))}
          </Stack>
        </Grid>
        {props.filters.filter(f => !dataCharts().map(c => c.id).includes(f.id)).length > 0 &&
          <Grid xs={12}>
            {props.filters.filter(f => !dataCharts().map(c => c.id).includes(f.id)).map(filter => (
              filter.value && filter.value !== '' &&
              <Chip
                key={filter.id}
                label={`${filter.label}: ${filter.valueParsed || filter.value}`}
                size='small'
                onDelete={() => (
                  props.setFilters ?
                    props.setFilters(props.filters?.filter(f => f.id !== filter.id) || []) :
                    undefined
                )}
                deleteIcon={<IconCircleX size={18} />}
              />
            ))}
          </Grid>
        }
        <Grid xs={12}>
          {piesDatas[dataCharts({ apeCodeRanges, rncsCodeRanges }).findIndex(dataChart => dataChart.id === chartId)]?.length > 0 &&
            <PieCharts
              datas={piesDatas[dataCharts({ apeCodeRanges, rncsCodeRanges }).findIndex(dataChart => dataChart.id === chartId)]}
              totalCount={props.totalCount}
              title={props.label}
              showDataLabel={false}
              graphPositionX='20%'
              // legendPosition='bottom'
              dataType='number'
            />
          }
        </Grid>
      </Grid>
    </>
  );
}

function PieChartsSegmentation<T extends PersonEntity | CompanyEntity>(props: ChartsSegmentationProps<T>): ReactElement {

  const component = (
    props.batchDatas[0].entityType === 'deecPerson' ?
      <PersonPieChartsSegmentation {...(props as unknown as ChartsSegmentationProps<PersonEntity>)} /> :
      <CompanyPieChartsSegmentation {...(props as unknown as ChartsSegmentationProps<CompanyEntity>)} />
  );

  return (
    props.label === '' ?
      <Box>{component}</Box> :
      <Paper variant='hoverElevation2' sx={{ width: '100%', p: 4 }}>{component}</Paper>
  );
}

export default PieChartsSegmentation;
