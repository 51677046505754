import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  IconCheck,
  IconClipboardData,
  IconEdit,
  IconFolderCheck,
  IconTargetArrow
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import { useSearchParams } from 'react-router-dom';
import CustomizedSteppers from '../../../../../components/steppers/horizontal.steppers';
import TargetStepsComponentsCreatePortfolios from './steps/target.steps.components.create.portfolios';
import ChoicesStepsComponentsCreatePortfolios from './steps/choices.steps.components.create.portfolios';
import PreparationStepsComponentsCreatePortfolios from './steps/preparation.steps.components.create.portfolios';
import AnalyseStepsComponentsCreatePortfolios from './steps/analyse.steps.components.create.portfolios';
import ValidationStepsComponentsCreatePortfolios from './steps/validation.steps.components.create.portfolios';

function CreateComponent(): ReactElement {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: `${t('common.utils.step')} 1`,
      desc: t('common.utils.target')
    },
    {
      label: `${t('common.utils.step')} 2`,
      desc: t('common.utils.choices')
    },
    {
      label: `${t('common.utils.step')} 3`,
      desc: t('common.utils.preparation')
    },
    {
      label: `${t('common.utils.step')} 4`,
      desc: t('common.utils.analyse')
    },
    {
      label: `${t('common.utils.step')} 5`,
      desc: t('common.utils.validation')
    }
  ];

  const icons: { [index: string]: React.ReactElement } = {
    1: <IconTargetArrow size={20} />,
    2: <IconFolderCheck size={20} />,
    3: <IconEdit size={20} />,
    4: <IconClipboardData size={20} />,
    5: <IconCheck size={20} />
  };

  const stepComponents = useMemo(() => [
    <TargetStepsComponentsCreatePortfolios />,
    <ChoicesStepsComponentsCreatePortfolios />,
    <PreparationStepsComponentsCreatePortfolios />,
    <AnalyseStepsComponentsCreatePortfolios />,
    <ValidationStepsComponentsCreatePortfolios />
  ], []);

  useEffect(() => {
    const activeStepTmp = searchParams.get('step');

    if (activeStepTmp) {
      setActiveStep(parseInt(activeStepTmp, 10) - 1);
    }
  }, [searchParams]);

  return (
    <Grid container spacing={2} pt={2}>
      <Grid xs={12}>
        <CustomizedSteppers activeStep={activeStep} icons={icons} steps={steps} />
      </Grid>
      <Grid xs={12} mt={8} p={2}>
        {stepComponents[activeStep]}
      </Grid>
    </Grid>
  );
}

export default CreateComponent;
