import React, { ReactElement, useEffect, useState } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import {
  SegmentationPossibleDisplayTypes,
  SegmentationPossibleFilter, SegmentationPossibleOnEntityTypes, SegmentationPossibleOutputEntityTypes
} from '@deecision/dna-interfaces';
import { IconChartBar, IconChartPie, IconCheck, IconFilter, IconMinus, IconPlus, IconX } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { get, set, uniq, uniqueId, upperFirst } from 'lodash';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { darken, useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { Chip, IconButton, Modal } from '@mui/material';
import { CustomSegmentationFiltersService } from '../../services/segmentations.services';
import { BaseItemSegmentationBuilderProps } from '../types.builder.segmentations';
import { CustomSegmentationFilter } from '../../types.segmentations';

export const categoryFilterToHide = ['hidden'];

function SelectFiltersToAddModal(
  props: {
    modalOpen: boolean,
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    possibleFilters: SegmentationPossibleFilter[],
    selectedFilters: {id: string, displayType: SegmentationPossibleDisplayTypes}[],
    setSelectedFilters: React.Dispatch<React.SetStateAction<{id: string, displayType: SegmentationPossibleDisplayTypes}[]>>,
    handleAdd: (id: string) => void,
    addFilters(): void,
    defaultDisplay: SegmentationPossibleDisplayTypes,
    setDefaultDisplay: React.Dispatch<React.SetStateAction<"barChart" | "pieChart" | "yesNo">>
  }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const FilterChipSx =  {
    cursor: 'pointer',
    '&:hover': {
      bgcolor: theme.palette.primary.light
    },
    '& .MuiChip-label': {
      pr: 1
    },
    '& .MuiChip-deleteIcon': {
      height: '100%',
      borderBottomRightRadius: 24,
      borderTopRightRadius: 24,
      width: 28,
      mr: 0,
      pl: 1,
      pr: 2,
      '&:hover': {
        bgcolor: theme.primaryColors?.lightest
      }
    },
    '& .iconBar': {
      height: '105%',
      width: 28,
      mr: 0,
      pl: 1,
      pr: 2,
      '&:hover': {
        bgcolor: theme.primaryColors?.lightest
      }
    },
    '& .iconPie': {
      height: '105%',
      width: 28,
      mr: 0,
      pl: 1,
      pr: 2,
      '&:hover': {
        bgcolor: theme.primaryColors?.lightest
      }
    }
  };

  const onDisplayTypeClick = (possibleFilter: {
    valueType: "string" | "number" | "boolean" | "countryCode" | "amount" | "countryCode[]",
    id: string,
    on: "person2person" | "company" | "person",
    possibleDisplayTypes: ("barChart" | "pieChart" | "yesNo")[],
    categories: string[],
    canUseForSegmentation: boolean,
    label?: string | undefined
  }, value: SegmentationPossibleDisplayTypes) => {
    const selectedFilter = props.selectedFilters.find(filter => filter.id === possibleFilter.id);

    if (selectedFilter) {
      selectedFilter.displayType = value;
      props.setSelectedFilters([...props.selectedFilters.filter(filter => filter.id !== possibleFilter.id), selectedFilter]);

      return selectedFilter.displayType;
    }

    return props.defaultDisplay;
  };

  return (
    <Modal
      open={props.modalOpen}
      onClose={() => {
        props.setModalOpen(false);
      }}
    >
      <Paper
        variant='hoverElevation2'
        sx={{
          boxShadow: 1,
          height: '90vh',
          width: '80vw',
          p: 4,
          marginLeft: '10vw',
          marginTop: '5vh',
          backgroundColor: 'white',
          border: 1,
          borderColor: 'grey.400',
          borderRadius: 2
        }}
      >
        <Grid container xs={12} height='100%' width='100%'>
          {/* MODAL HEADER */}
          <Grid xs={12} height='5%' display='flex' flexDirection='row' justifyContent='space-between'>
            <Stack display='flex' flexDirection='row' alignItems='center'>
              <IconFilter />
              <Typography ml={2} fontSize={16} fontWeight={600} color='grey.700'>Choose your filters to add</Typography>
              <Stack display='flex' alignItems='center' alignContent='center'>
                <Tooltip title={t('segmentation.builder.views.displaySelector')} arrow placement='top'>
                  <Box
                    width='min-content'
                    ml='8px'
                    border='1px solid'
                    borderRadius={2}
                    borderColor={darken(theme.palette.background.default, 0.2)}
                  >
                    <Stack display='flex' flexDirection='row' alignItems='center'>
                      <Stack
                        p={1}
                        className='iconBar'
                        onClick={() => {
                          props.setDefaultDisplay('barChart');
                        }}
                      >
                        <IconChartBar size={20} color={props.defaultDisplay === 'barChart' ? '#163562' : 'grey'} />
                      </Stack>
                      <Stack
                        p={1}
                        pl={0}
                        className='iconPie'
                        onClick={() => {
                          props.setDefaultDisplay('pieChart');
                        }}
                      >
                        <IconChartPie size={20} color={props.defaultDisplay === 'pieChart' ? '#163562' : 'grey'}/>
                      </Stack>
                    </Stack>
                  </Box>
                </Tooltip>
              </Stack>
            </Stack>
            <IconButton size='medium' onClick={() => props.setModalOpen(false)}
              sx={{ borderRadius: 50, height: '35px', width: '35px' }}
            >
              <IconX color='grey'/>
            </IconButton>
          </Grid>

          {/* MODAL BODY */}
          <Stack height='83%' spacing={4} p={2} pl={3} pr={3} pb={0} overflow='scroll'>
            {uniq(props.possibleFilters?.filter(possibleFilter => possibleFilter.canUseForSegmentation)
              ?.map(possibleFilter => possibleFilter.categories).flat(1))
              .filter(category => !categoryFilterToHide.includes(category))
              .map(category => ([
                <Stack sx={{ px: 4, pt: 0, width: '100%' }}>
                  <Divider orientation='horizontal' />
                  <Stack width='100%' pt={2}>
                    {/* CARD HEADER */}
                    <Stack direction='row' alignItems='center' width={140}>
                      <Typography variant='h4'>{upperFirst(category)}</Typography>
                      <Button
                        variant='outlined'
                        endIcon={
                          props.possibleFilters?.filter(possibleFilter => possibleFilter.categories.includes(category)).some(possibleFilter => props.selectedFilters.some(filter => filter.id === possibleFilter.id)) && !props.possibleFilters?.filter(possibleFilter => possibleFilter.categories.includes(category)).every(possibleFilter => props.selectedFilters.some(filter => filter.id === possibleFilter.id)) ?
                            <IconMinus size={15} color='#666666'/> :
                            <IconPlus size={15} color='#666666'/>
                        }
                        onClick={() => {
                          if ((props.possibleFilters
                            ?.filter(possibleFilter => possibleFilter.categories.includes(category))
                            .every(possibleFilter => props.selectedFilters.some(filter => filter.id === possibleFilter.id)) ||
                            (props.possibleFilters
                              ?.filter(possibleFilter => possibleFilter.categories.includes(category))
                              .some(possibleFilter => props.selectedFilters.some(filter => filter.id === possibleFilter.id)) &&
                            !props.possibleFilters?.filter(possibleFilter => possibleFilter.categories.includes(category))
                              .every(possibleFilter => props.selectedFilters.some(filter => filter.id === possibleFilter.id))))) {
                            // Bug when unselecting one category, remove all categories
                            props.setSelectedFilters(prevState =>
                              prevState.filter(filter =>
                                !props.possibleFilters.find(possibleFilter => possibleFilter.id === filter.id)
                              )
                            );
                          } else {
                            props.setSelectedFilters(prevState => [
                              ...prevState.filter(filter =>
                                props.possibleFilters.find(possibleFilter => possibleFilter.id === filter.id)
                              ),
                              ...props.possibleFilters
                                .filter(possibleFilter => possibleFilter.categories.includes(category))
                                .map(possibleFilter => ({ id: possibleFilter.id, displayType: props.defaultDisplay }))
                            ]);
                          }
                        }}
                        sx={{
                          maxWidth: 'max-content', maxHeight: '25px', minWidth: 'max-content', minHeight: '25px', marginLeft: '10px', border: 1,
                          '&:hover': {
                            border: 1, borderColor: '#666666', backgroundColor: theme.palette.grey[100]
                          }
                        }}>
                        <Typography fontSize={14} color='#666666'>
                          {props.possibleFilters?.filter(possibleFilter => possibleFilter.categories.includes(category)).every(possibleFilter => props.selectedFilters.some(filter => filter.id === possibleFilter.id)) ||
                            props.possibleFilters?.filter(possibleFilter => possibleFilter.categories.includes(category)).some(possibleFilter => props.selectedFilters.some(filter => filter.id === possibleFilter.id)) && !props.possibleFilters?.filter(possibleFilter => possibleFilter.categories.includes(category)).every(possibleFilter => props.selectedFilters.some(filter => filter.id === possibleFilter.id)) ?
                            t('common.utils.removeAll') :  t('common.utils.addAll')}
                        </Typography>
                      </Button>
                    </Stack>
                  </Stack>

                  {/* CARD BODY */}
                  <Grid container direction='row' display='flex' alignItems='center' borderRadius={1} pt={2}>
                    {props.possibleFilters
                      ?.filter(possibleFilter => possibleFilter.categories.includes(category))
                      .map((possibleFilter) => {
                        const displaySelected = props.selectedFilters.find(filter => filter.id === possibleFilter.id)?.displayType || props.defaultDisplay;

                        return (
                          <Grid
                            container
                            p={2}
                            spacing={2}
                          >
                            <Chip
                              color={props.selectedFilters.some(filter => filter.id === possibleFilter.id) ? 'primary' : undefined}
                              label={
                                <Stack display='flex' flexDirection='row' alignItems='center'>
                                  {possibleFilter.label}
                                  {props.selectedFilters.some(filter => filter.id === possibleFilter.id) &&
                                    <Stack display='flex' flexDirection='row' alignItems='center' ml={1}>
                                      <Divider flexItem orientation='vertical' />
                                      <Stack
                                        p={1}
                                        className='iconBar'
                                        onClick={() => {
                                          onDisplayTypeClick(possibleFilter, 'barChart');
                                        }}
                                      >
                                        <IconChartBar size={20} color={displaySelected === 'barChart' ? '#163562' : 'grey'} />
                                      </Stack>
                                      <Stack
                                        p={1}
                                        pl={0}
                                        className='iconPie'
                                        onClick={() => {
                                          onDisplayTypeClick(possibleFilter, 'pieChart');
                                        }}
                                      >
                                        <IconChartPie size={20} color={displaySelected === 'pieChart' ? '#163562' : 'grey'}/>
                                      </Stack>
                                      <Divider flexItem orientation='vertical' />
                                    </Stack>
                                  }
                                </Stack>
                              }
                              deleteIcon={props.selectedFilters.some(filter => filter.id === possibleFilter.id) ?
                                <IconMinus size={16} color={theme.palette.grey[800]} /> :
                                <IconPlus size={16} color={theme.palette.grey[800]} />
                              }
                              onDelete={() => props.handleAdd(possibleFilter.id)}
                              onClick={!props.selectedFilters.some(filter => filter.id === possibleFilter.id) ? () => props.handleAdd(possibleFilter.id) : undefined}
                              sx={props.selectedFilters.some(filter => filter.id === possibleFilter.id) ?
                                FilterChipSx :
                                { cursor: 'pointer' }
                              }
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </Stack>
              ]))}
          </Stack>

          {/* MODAL FOOTER */}
          <Grid xs={12} pt={2} height='5%' display='flex' flexDirection='row' justifyContent='flex-end' alignItems='center'>
            <Button variant='text' sx={{ marginRight: '8px' }} onClick={() => {
              props.setModalOpen(false);
            }}>
              {t('segmentation.filters.cancel')}
            </Button>
            <Button variant='contained' disabled={props.selectedFilters.length < 1} onClick={props.addFilters}>
              {t('segmentation.filters.add')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}

function AddFiltersBuilderSegmentations(props: BaseItemSegmentationBuilderProps & { btnProps?: ButtonProps, view?: boolean, idPrefix?: string, outputEntities?: SegmentationPossibleOutputEntityTypes }): ReactElement {
  const { t } = useTranslation();
  const possibleFiltersService = new CustomSegmentationFiltersService<SegmentationPossibleFilter>();
  const [possibleFilters, setPossibleFilters] = useState<SegmentationPossibleFilter[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<{id: string, displayType: SegmentationPossibleDisplayTypes}[]>([]);
  const [defaultDisplay, setDefaultDisplay] = useState<SegmentationPossibleDisplayTypes>('barChart');
  const [modalOpen, setModalOpen] = useState(false);

  const handleAdd = (id: string) => {
    setSelectedFilters((prevState) => {
      const exists = prevState.find(selectedFilter => selectedFilter.id === id);
      if (exists) {
        return prevState.filter(selectedFilter => selectedFilter.id !== id);
      }
      
      return [...prevState, { id, displayType: defaultDisplay }];
    });
  };

  const handleClickAddFilters = () => setModalOpen(previousState => !previousState);

  const addFilter = (filterId: string, displayType: SegmentationPossibleDisplayTypes) => {
    const selectedFilter = possibleFilters.find(possibleFilter => possibleFilter.id === filterId);

    if (selectedFilter) {
      const filter: CustomSegmentationFilter = {
        ...selectedFilter,
        id: uniqueId(`${props.idPrefix || ''}${props.view ? 'segmentationCriteria' : 'filter'}`),
        label: props.view ?
          `${upperFirst(t('segmentation.builder.views.possibleFilter.label') || 'possible filter')} ${((props.path === '' ? props.data : get(props.data, props.path))?.segmentationCriterias?.filter((element: CustomSegmentationFilter) => element !== null).length || 0) + 1} - ${selectedFilter.label}` :
          '',
        filterId,
        filterLabel: possibleFilters.find(possibleFilter => possibleFilter.id === filterId)?.label,
        type: props.view ? 'segmentationCriteria' : 'filter',
        on: selectedFilter.on === 'person' && props.outputEntities?.includes('person') ? props.outputEntities : selectedFilter.on
      };
      if (props.view) {
        filter.displayType = { valueType: selectedFilter.valueType };
        filter.displayInfo = { displayType };
        filter.ready = true;
        props.setData({ ...set(props.data, `${props.path}.segmentationCriterias.[${(props.path === '' ? props.data : get(props.data, props.path))?.segmentationCriterias?.filter((element: CustomSegmentationFilter) => element !== null).length || 0}]`, filter) });
      }
    }
    setModalOpen(false);
  };

  const addFilters = () => {
    selectedFilters.forEach(selectedFilter => addFilter(selectedFilter.id, selectedFilter.displayType));
    setSelectedFilters([]);
  };

  const getPossibleFilters = (ons?: SegmentationPossibleOnEntityTypes[]) => {
    setPossibleFilters([]);
    ons?.forEach(on => possibleFiltersService.getAll({ filters: [{ scope: 'on', id: 'on', value: on }] })
      .then(res => setPossibleFilters(prevState => (res.data ? [...prevState.filter(possibleFilter => !res.data?.find(dataFilter => dataFilter.id === possibleFilter.id)), ...res.data] : prevState))));
  };

  useEffect(() => {
    getPossibleFilters(props.workflow?.possibleFilterTypes);
  }, [props.workflow]);

  useEffect(() => {
    console.log('selectedFilters updated', selectedFilters);
  }, [selectedFilters]);

  return (
    <Grid container spacing={4} alignItems='flex-start' p={2}>
      <SelectFiltersToAddModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        possibleFilters={possibleFilters}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        handleAdd={handleAdd}
        addFilters={addFilters}
        defaultDisplay={defaultDisplay}
        setDefaultDisplay={setDefaultDisplay}
      />
      <Grid xs display='flex' justifyContent='center'>
        <Button variant='contained' onClick={handleClickAddFilters} endIcon={<IconFilter />}>
          {t('segmentation.filters.choose')}
        </Button>
      </Grid>
      {/* <Grid container spacing={4} alignItems='center'>
        <Grid>
          <Typography variant='body2'>
            {t('common.utils.or')}
          </Typography>
        </Grid>
        <Grid>
          <AnchorTemporaryDrawer
            {...props}
            btnLabel={t('segmentation.builder.views.createCustomFilter')}
            variant='contained'
          />
        </Grid>
      </Grid>
        */}
    </Grid>
  );
}

export default AddFiltersBuilderSegmentations;
