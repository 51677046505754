import React from 'react';
import { CustomRouteObject } from '@deecision/common-webapp/router';
import { IconChartInfographic } from '@tabler/icons-react';
import createElementRoutes from '@deecision/common-webapp/router/utils/create.element.routes';
import dnaConfig from '../../../../config/dna.config.json';
import { routes as routesAssistedSegmentations } from './assisted';
import { routes as routesCustomSegmentations } from './custom';
import SelectComponentSegmentations from './components/select.component.segmentations';

function routesSegmentations(id: string): CustomRouteObject {
  return createElementRoutes({
    id: `${id}-${dnaConfig.childRoutes.segmentations.label}`,
    path: dnaConfig.childRoutes.segmentations.baseUrl,
    icon: IconChartInfographic,
    displayIndex: true,
    component: <SelectComponentSegmentations />,
    childrens: [
      routesAssistedSegmentations(id),
      routesCustomSegmentations(id)
    ]
  });
}

export default routesSegmentations;
