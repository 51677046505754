import React, { ReactElement } from 'react';
import { useOutletContext } from 'react-router-dom';
import { PersonEntity } from '@deecision/dna-interfaces';
import DisplayRelationsComponents from '../../../../../containers/relations/display.relations';

function RelationsTabsComponents(): ReactElement {
  const { data } = useOutletContext() as { data: PersonEntity };

  return (<DisplayRelationsComponents data={data} to='deecPerson' />);
}

export default RelationsTabsComponents;
