import React, { ReactElement } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import TitleComponent from '@deecision/common-webapp/components/title.components';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { VeegilenzElementsProps } from './types.veegilenz';
import { CardPart } from '../../components/cards/keyvalue.cards';
import { VeegilenzCompanyGetter, VeegilenzPersonGetter } from './getter.veegilenz';

function DirectIndexVeegilenz<T, U>(props: VeegilenzElementsProps<T, U>): ReactElement {
  const { t } = useTranslation();

  const keyValues: { key: string, score: number, flags?: boolean, value: string | string[], tooltip?: string }[] = [
    {
      key: t('veegilenz.keys.mandatesCurrent'),
      ...props.getter.getMandatesCurrent(t('veegilenz.errorResponse.notFound') || undefined)
    },
    {
      key: t('veegilenz.keys.mandatesCurrentAsBO'),
      ...props.getter.getMandatesCurrentAsBO(t('veegilenz.errorResponse.notFound') || undefined)
    },
    {
      key: t('veegilenz.keys.mandatesPast'),
      ...props.getter.getMandatesPast(t('veegilenz.errorResponse.notFound') || undefined)
    },
    {
      key: t('veegilenz.keys.mandatesCurrentWithAML'),
      ...props.getter.getMandatesCurrentWithAML(t('veegilenz.errorResponse.notFound') || undefined)
    },
    {
      key: t('veegilenz.keys.mandatesPastWithAML'),
      ...props.getter.getMandatesPastWithAML(t('veegilenz.errorResponse.notFound') || undefined)
    },
    {
      key: t('veegilenz.keys.holdingCompany'),
      ...props.getter.getHoldingCompany(t('veegilenz.errorResponse.notAvailable') || undefined)
    },
    {
      key: t('veegilenz.keys.mandatesCurrentWithListedCompanies'),
      ...props.getter.getMandatesCurrentWithListedCompanies(t('veegilenz.errorResponse.notFound') || undefined)
    },
    {
      key: t('veegilenz.keys.mandatesPastWithListedCompanies'),
      ...props.getter.getMandatesPastWithListedCompanies(t('veegilenz.errorResponse.notFound') || undefined)
    },
    {
      key: props.entityType === 'deecPerson' && t('veegilenz.keys.politicallyExposedPerson') || props.entityType === 'deecCompany' && t('veegilenz.keys.politicallyExposedDirectStakeholders') || '',
      value: t('veegilenz.errorResponse.notFound') || '',
      score: 0
    },
    {
      key: props.entityType === 'deecPerson' && t('veegilenz.keys.politicallyExposedRelations') || props.entityType === 'deecCompany' && t('veegilenz.keys.politicallyExposedIndirectStakeholders') || '',
      value: t('veegilenz.errorResponse.notFound') || '',
      score: 0
    }
  ];

  if (props.entityType === 'deecPerson') {
    keyValues.unshift(
      {
        key: (props.getter as unknown as VeegilenzPersonGetter).getNationalities(t('veegilenz.errorResponse.notFound') || undefined) ? t('veegilenz.keys.nationalities') : t('veegilenz.keys.nationality'),
        ...(props.getter as unknown as VeegilenzPersonGetter).getNationalities(t('veegilenz.errorResponse.notFound') || undefined),
        flags: true
      },
      {
        key: (props.getter as unknown as VeegilenzPersonGetter).getCountries(t('veegilenz.errorResponse.notFound') || undefined) ? t('veegilenz.keys.countries') : t('veegilenz.keys.country'),
        ...(props.getter as unknown as VeegilenzPersonGetter).getCountries(t('veegilenz.errorResponse.notFound') || undefined),
        flags: true
      }
    );
  }

  if (props.entityType === 'deecCompany') {
    keyValues.unshift(
      {
        key: t('veegilenz.keys.country'),
        ...(props.getter as unknown as VeegilenzCompanyGetter).getCountry(t('veegilenz.errorResponse.notFound') || undefined),
        flags: true
      }
    );
  }

  return (
    <Paper variant='hoverElevation1'>
      <Grid container spacing={2} width='min-content'>
        <Grid xs={12} ml='-8px'>
          <TitleComponent title={t('veegilenz.directIndex')} />
        </Grid>
        <Grid xs={12} p={2}>
          <Paper
            elevation={0}
            sx={{
              bgcolor: props.scoreColor[Math.floor(props.score || 0)].light,
              color: props.scoreColor[Math.floor(props.score || 0)].dark,
              width: '100%'
            }}
          >
            <Stack
              sx={{
                width: '100%',
                height: '32px',
                textAlign: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography variant='h1'>{props.score}</Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid xs={12} p={2} pr={4} pl={4}>
          <CardPart
            values={
              keyValues.filter(keyValue => keyValue.value !== '').map(keyValue => ({
                key: keyValue.key,
                value: keyValue.value,
                flags: keyValue.flags,
                tooltip: keyValue.tooltip,
                align: 'right',
                status: keyValue.score < 2 ? 'success' : keyValue.score < 3 ? 'warning' : keyValue.score < 4 ? 'error' : 'error'
              }))
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default DirectIndexVeegilenz;
