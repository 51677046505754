import React, { ReactElement, useState } from 'react';
import { Icon, IconArrowRight, IconDotsVertical, IconProps } from '@tabler/icons-react';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { SimpleEntityType } from '@deecision/dna-interfaces';
import { lighten, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CardContainer from './container.cards';

export interface SimpleCard {
  id: string,
  name: string,
  status?: string,
  icon?: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>,
  info?: string,
  element?: ReactElement,
  components?: Array<ReactElement>,
  entityType?: SimpleEntityType,
  nbItems?: number,
  color?: 'primary' | 'secondary',
  bgcolor?: string,
  tags?: Array<ReactElement>,
  link?: string,
  actions?: {
    id: string,
    label: string,
    action: () => void
  }[]
}

type ItemCardsComponentsProps = SimpleCard & {
  grid?: true
}

type ItemCardsProps = Omit<ItemCardsComponentsProps, 'grid'>;

function ItemCards(props: ItemCardsProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [disabled, setDisabled] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <CardContainer
      key={`paper-${props.name}`}
      color={props.color}
    >
      <Grid container spacing={3} minHeight='80px' minWidth='276px' width='min-content'>
        <Grid xs={12}>
          <Stack direction='row' spacing={2} alignItems='top'>
            {props.icon &&
              <Box
                height={48}
                p={2}
                borderRadius={1}
                bgcolor={lighten(theme.palette.primary.main, 0.3)}
              >
                <props.icon
                  size={32}
                  color={theme.palette.primary.dark}
                />
              </Box>
            }
            <Stack direction='row' spacing={2} alignItems='center' height='min-content'>
              <Typography
                width='fit-content'
                variant='h3'
                whiteSpace='pre-line'
                color={theme.palette.primary.dark}
              >
                {props.name}
              </Typography>
              {props.status &&
                <Chip
                  label={props.status} color={props.status === 'ready' ? 'success' : 'error'}
                  size='small'
                  sx={{
                    bgcolor: props.status === 'ready' ? theme.palette.success.main : theme.palette.error.main,
                    color: theme.palette.primary.contrastText,
                    fontWeight: 600,
                    ml: 'auto !important'
                  }}
                />
              }
              <Button
                variant='textIcon'
                sx={{
                  alignSelf: 'flex-start',
                  '&:hover': {
                    color: theme.palette.grey['800'],
                    bgcolor: lighten(theme.palette.primary.main, 0.3)
                  }
                }}
                onClick={handleClick}
                disabled={!props.actions || props.actions.length === 0}
              >
                <IconDotsVertical />
              </Button>
            </Stack>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {props.actions?.map(action =>
                <MenuItem key={action.id} onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  action.action();
                }}>
                  {action.label}
                </MenuItem>
              )}
            </Menu>
          </Stack>
        </Grid>
        {props.tags &&
          <Grid xs={12}>
            <Stack direction='row' alignItems='center' spacing={2}>
              {props.tags}
            </Stack>
          </Grid>
        }
        {props.components && props.components.length > 0 &&
          props.components.map(component => (
            <React.Fragment key={component.key}>
              <Grid xs={12}>
                <Divider />
              </Grid>
              <Grid xs={12}>
                {component}
              </Grid>
            </React.Fragment>
          ))
        }
        {props.info &&
            <Grid textAlign='center'>
              <Typography variant='body1'>{props.info}</Typography>
            </Grid>
        }
        {props.element &&
          <Grid xs={12}>
            {props.element}
          </Grid>
        }
        <Stack
          direction='row'
          spacing={2}
          p={2}
          pl={3}
          pr={3}
          width='100%'
          alignItems='baseline'
        >
          {props.nbItems && [
            <Typography variant='h1' fontSize={40} fontWeight={400}>{props.nbItems}</Typography>,
            <Typography>
              {props.entityType === 'company' ?
                `${props.nbItems > 1 ? t('entities.companies.label') : t('entities.companies.company.label')}` :
                `${props.nbItems > 1 ? t('entities.persons.label') : t('entities.persons.person.label')}`
              }
            </Typography>
          ]}
          <Button
            variant='outlinedIcon'
            sx={{
              alignSelf: 'flex-end',
              marginLeft: 'auto !important',
              border: 'solid 1.5px',
              '&:hover': {
                color: theme.palette.grey['800'],
                border: 'solid 1.5px transparent',
                bgcolor: lighten(theme.palette.primary.main, 0.3)
              }
            }}
            onClick={() => {
              setDisabled(true);
              (props.link ? navigate(props.link) : props.status ? props.status === 'ready' ? navigate(`${props.entityType ? `${props.entityType}/` : ''}${props.id}`) : undefined : navigate(`${props.entityType ? `${props.entityType}/` : ''}${props.id}`));
            }}
            disabled={disabled}
            aria-disabled={disabled}
          >
            <IconArrowRight size={22} />
          </Button>
        </Stack>
      </Grid>
    </CardContainer>
  );
}

function ItemCardsComponents(props: ItemCardsComponentsProps): ReactElement {
  return props.grid ? <Grid key={props.id}><ItemCards {...props} /></Grid> : <ItemCards {...props} />;
}

export default ItemCardsComponents;
