import { RNCS_FORME_JURIDIQUE_MAPPING } from './capfi-api-client.constants';
import listApeCode from './Liste_des_codes_NAF_et_APE_en_france__2_.json';

export function getRncsLabel(code?: string) {
  const parsedCode = code?.replace('rncs:', '');

  return code ? RNCS_FORME_JURIDIQUE_MAPPING[parsedCode as keyof typeof RNCS_FORME_JURIDIQUE_MAPPING] : 'unknown';
}

export function getApeLabel(code?: string) {
  return code ? listApeCode.APE.find(apeCode => apeCode.code === code)?.name : 'unknown';
}
