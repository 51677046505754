import React from 'react';
import { CustomRouteObject } from "@deecision/common-webapp/router";
import { IconDashboard } from "@tabler/icons-react";
import createElementRoutes from "@deecision/common-webapp/router/utils/create.element.routes";
import AdminDashboard from "./adminDashboard";

const routeDashboard: CustomRouteObject = createElementRoutes({
  id: 'Dashboard',
  path: 'dashboard',
  icon: IconDashboard,
  component: <AdminDashboard />,
  idComponent: <AdminDashboard />,
  displayIndex: true
});

export default routeDashboard;
