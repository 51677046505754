import { CustomRouteObject } from '@deecision/common-webapp/router';
import { routes as routesPortfolios } from './portfolios';
import { routes as routesFolders } from './folders';

const routesDeetect: CustomRouteObject[] = [
  routesPortfolios,
  routesFolders
];

export default routesDeetect;
