import React, { ReactElement, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { IconFolderPlus, IconUser } from '@tabler/icons-react';
import { CompanyEntity, CompanyInfo, PersonEntity } from '@deecision/dna-interfaces';
import TitleComponent from '@deecision/common-webapp/components/title.components';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import HeaderComponents from '../../../../containers/header.containers';
import KeyValueList, { KeyValue } from '../../../../components/keyvaluelist';
import KeyValueWithCard from '../../../../components/keyvaluewithcard';
import { getEntity, PersonGetter } from '../../../../utils/getter';
import VeegilenzCard from '../../../../containers/veegilenz/card.veegilenz';
import dnaConfig from '../../../../../config/dna.config.json';
import NewsSmallCards from '../../../../components/smallcards/news.smallcards';
import PersonEntitiesChips from '../../../../components/chips/entities/person.entities.chips';
import { VeegilenzPersonGetter } from '../../../../containers/veegilenz/getter.veegilenz';

function PersonComponent(): ReactElement {
  const data = useLoaderData() as { data: PersonEntity };
  const { t } = useTranslation();
  const theme = useTheme();
  const [companies, setCompanies] = useState<CompanyEntity[]>([]);
  const personGetter = new PersonGetter({
    data: data.data,
    errorMsg: t('common.utils.unknown')
  });
  const veegilenzGetter = new VeegilenzPersonGetter({ entity: data.data, linkedEntities: companies, yes: t('common.utils.yes'), no: t('common.utils.no') });

  const [identity, setIdentity] = useState<KeyValue[]>([]);

  const [financial, setFinancial] = useState<KeyValue[]>([]);

  const [companiesFinancial, setCompaniesFinancial] = useState<KeyValue[]>([]);

  const [relationsFinancial, setRelationFinancial] = useState<KeyValue[]>([]);

  useEffect(() => {
    setIdentity(data.data.attributes ? [
      { key: t('common.identity.firstName'), value: personGetter.getFirstName() },
      { key: t('common.identity.lastName'), value: personGetter.getLastName() },
      { key: t('common.identity.age'), value: personGetter.getAge() },
      { key: t('common.identity.birthDate'), value: personGetter.getBirthDate() },
      { key: personGetter.getCountries().length < 2 ? t('common.identity.country') : t('common.identity.countries'), value: personGetter.getCountries().join(' / ') },
      { key: personGetter.getNationalities().length < 2 ? t('common.identity.nationality') : t('common.identity.nationalities'), value: personGetter.getNationalities().join(' / ') }
    ] : []);
    setFinancial(data.data.dataViews?.data?.companies ? [
      {
        key: t('common.financial.turnover.sum'),
        value: personGetter.getTurnoverSum(),
        completeness: personGetter.getTurnoverSumCompleteness(),
        date: personGetter.getTurnoverSumDate(),
        align: 'right' as const
      },
      {
        key: t('common.financial.weasse.label'),
        value: personGetter.getWeasse(),
        completeness: personGetter.getWeasseCompleteness(),
        date: personGetter.getWeasseDate(),
        align: 'right' as const
      },
      {
        key: t('common.financial.weasseMax.label'),
        value: personGetter.getWeasseMax(),
        completeness: personGetter.getWeasseMaxCompleteness(),
        date: personGetter.getWeasseMaxDate(),
        align: 'right' as const
      },
      {
        key: t('common.financial.extrapolatedWeasse.label'),
        value: personGetter.getExtrapolatedWeasse(),
        completeness: personGetter.getExtrapolatedWeasseCompleteness(),
        accuracy: personGetter.getExtrapolatedWeasseAccuracy(),
        align: 'right' as const
      },
      {
        key: t('common.financial.fortune.label'),
        value: personGetter.getFortune(),
        align: 'right' as const
      }
    ] : []);
    setCompaniesFinancial(data.data.dataViews?.data?.companies ? [
      {
        key: t('common.financial.turnover.sum'),
        value: personGetter.getTurnoverSum(),
        completeness: personGetter.getTurnoverSumCompleteness(),
        date: personGetter.getTurnoverSumDate(),
        align: 'right' as const
      },
      {
        key: t('common.financial.capital.sum'),
        value: personGetter.getCapitalSum(),
        completeness: personGetter.getCapitalSumCompleteness(),
        date: personGetter.getCapitalSumDate(),
        align: 'right' as const
      },
      {
        key: t('common.financial.ebitda.sum'),
        value: personGetter.getEbitdaSum(),
        completeness: personGetter.getEbitdaSumCompleteness(),
        date: personGetter.getEbitdaSumDate(),
        align: 'right' as const
      },
      {
        key: t('common.financial.valuation.sum'),
        value: personGetter.getValuationSum(),
        completeness: personGetter.getValuationSumCompleteness(),
        date: personGetter.getValuationSumDate(),
        align: 'right' as const
      }
    ] : []);
    setRelationFinancial(data.data.dataViews?.data?.companies ? [
      {
        key: t('common.financial.turnover.sum'),
        value: personGetter.getRelationsTurnoverSum(),
        completeness: personGetter.getRelationsTurnoverSumCompleteness(),
        date: personGetter.getRelationsTurnoverSumDate(),
        align: 'right' as const
      },
      {
        key: t('common.financial.capital.sum'),
        value: personGetter.getRelationsCapitalSum(),
        completeness: personGetter.getRelationsCapitalSumCompleteness(),
        date: personGetter.getRelationsCapitalSumDate(),
        align: 'right' as const
      },
      {
        key: t('common.financial.ebitda.sumOfSum'),
        value: personGetter.getRelationsEbitdaSumOfSum(),
        completeness: personGetter.getRelationsEbitdaSumOfSumCompleteness(),
        date: personGetter.getRelationsEbitdaSumOfSumDate(),
        align: 'right' as const
      },
      {
        key: t('common.financial.valuation.sumOfSum'),
        value: personGetter.getRelationsValuationSumOfSum(),
        completeness: personGetter.getRelationsValuationSumOfSumCompleteness(),
        date: personGetter.getRelationsValuationSumOfSumDate(),
        align: 'right' as const
      },
      {
        key: t('common.financial.weasse.sum'),
        value: personGetter.getRelationsWeasseSum(),
        completeness: personGetter.getRelationsWeasseSumCompleteness(),
        date: personGetter.getRelationsWeasseSumDate(),
        align: 'right' as const
      },
      {
        key: t('common.financial.weasseMax.sum'),
        value: personGetter.getRelationsWeasseMaxSum(),
        completeness: personGetter.getRelationsWeasseMaxSumCompleteness(),
        date: personGetter.getRelationsWeasseMaxSumDate(),
        align: 'right' as const
      }
    ] : []);

    const companiesInfoTemp: Array<{ status: string, companyInfo: CompanyInfo}> = [];
    setCompanies([]);

    personGetter.getActiveCompaniesInfo().forEach((companyInfo) => {
      companiesInfoTemp.push({ status: 'active', companyInfo });
    });
    personGetter.getInactiveCompaniesInfo().forEach((companyInfo) => {
      companiesInfoTemp.push({ status: 'inactive', companyInfo });
    });

    companiesInfoTemp.forEach((companyInfoTemp) => {
      getEntity<CompanyEntity>(companyInfoTemp.companyInfo.entityRef).then((res) => {
        setCompanies(prevState => ([...prevState, res]));
      });
    });
  }, [data.data, t]);

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid>
        <TitleComponent title={data.data.name} icon={IconUser} chips={PersonEntitiesChips({ entity: data.data })} />
      </Grid>
      <Grid ml='auto'>
        <Stack spacing={2} direction='row' alignItems='center'>
          <Button
            variant='contained'
            startIcon={<IconFolderPlus size={16} />}
            href={dnaConfig.routes.folders.create.steps.choices}
          >
            {t('folder.createFolder')}
          </Button>
        </Stack>
      </Grid>
      <Grid xs={12}>
        <HeaderComponents
          height={180}
          selects={[]}
          keys={[
            '',
            '',
            [
              t('common.financial.label') || '',
              t('entities.companies.label') || '',
              t('entities.persons.alterLabels.relations') || ''
            ],
            t('entities.companies.company.tabs.news') || ''
          ]}
          links={[
            '',
            'veegilenz',
            [''],
            'news'
          ]}
        >
          {[
            <Box key='identityKeyValueList' sx={{ p: 2 }}><KeyValueList values={[identity]} /></Box>,
            <VeegilenzCard
              key='veegilenzCard'
              score={2}
              veegilenzDatas={{
                directIndex: veegilenzGetter.getDirectScore(),
                corporateNetwork: veegilenzGetter.getCorporateScore(),
                individualNetwork: veegilenzGetter.getIndividualScore()
              }}
            />,
            [<KeyValueWithCard
              key='financialCard'
              values={[financial]}
              keyValueCard={
                {
                  Key: `${t('common.financial.turnover.sum')}`,
                  value: personGetter.getTurnoverSum(),
                  size: 'big',
                  bgColor: personGetter.getTurnoverSumDate() ? Math.abs(new Date(Date.now() - (personGetter.getTurnoverSumDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 1 ? theme.palette.success.light : Math.abs(new Date(Date.now() - (personGetter.getTurnoverSumDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 2 ? theme.palette.warning.light : theme.palette.error.light : theme.primaryColors?.light || theme.palette.primary.light,
                  color: personGetter.getTurnoverSumDate() ? Math.abs(new Date(Date.now() - (personGetter.getTurnoverSumDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 1 ? theme.palette.success.dark : Math.abs(new Date(Date.now() - (personGetter.getTurnoverSumDate()?.getTime() || 0)).getUTCFullYear() - 1970) < 2 ? theme.palette.warning.dark : theme.palette.error.dark : theme.palette.primary.dark
                }
              }
            />,
            <KeyValueWithCard
              key='companiesFinancialCard'
              values={[companiesFinancial]}
              keyValueCard={
                {
                  Key: `${t('common.utils.active')} / ${t('common.utils.total')}`,
                  value: `${personGetter.getNbActiveCompanies(true)}/${personGetter.getNbCompanies(true)}`,
                  size: 'big',
                  bgColor: ((personGetter.getNbCompanies(true) as number)
                    - (personGetter.getNbActiveCompanies(true) as number))
                  < (personGetter.getNbActiveCompanies(true) as number) ?
                    theme.palette.success.light : theme.palette.error.light,
                  color: ((personGetter.getNbCompanies(true) as number)
                    - (personGetter.getNbActiveCompanies(true) as number))
                  < (personGetter.getNbActiveCompanies(true) as number) ?
                    theme.palette.success.dark : theme.palette.error.dark
                }
              }
            />,
            <KeyValueWithCard
              key='relationsFinancialCard'
              values={[relationsFinancial]}
              keyValueCard={
                {
                  Key: `${t('common.utils.current')} / ${t('common.utils.total')}`,
                  value: `${personGetter.getNbCurrentRelations()}/${personGetter.getNbRelations()}`,
                  size: 'big',
                  bgColor: ((personGetter.getNbRelations() as number)
                    - (personGetter.getNbCurrentRelations() as number))
                  < (personGetter.getNbCurrentRelations() as number) ?
                    theme.palette.success.light : theme.palette.error.light,
                  color: ((personGetter.getNbRelations() as number)
                    - (personGetter.getNbCurrentRelations() as number))
                  < (personGetter.getNbCurrentRelations() as number) ?
                    theme.palette.success.dark : theme.palette.error.dark
                }
              }
            />],
            <NewsSmallCards key='news' entityType='deecPerson' />
          ]}
        </HeaderComponents>
      </Grid>
    </Grid>
  );
}

export default PersonComponent;
