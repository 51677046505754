import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { CompanyEntity, CompanyInfo, PersonEntity } from '@deecision/dna-interfaces';
import { getEntity, PersonGetter } from '../../../../../utils/getter';
import { VeegilenzPersonGetter } from '../../../../../containers/veegilenz/getter.veegilenz';
import Veegilenz from '../../../../../containers/veegilenz/veegilenz';

function VeegilenzTabsComponents(): ReactElement {
  const { t } = useTranslation();
  const { data } = useOutletContext() as { data: PersonEntity };
  const [companies, setCompanies] = useState<CompanyEntity[]>([]);
  const getterProps = new PersonGetter({
    data,
    errorMsg: t('common.utils.unknown')
  });

  useEffect(() => {
    const companiesInfoTemp: Array<{ status: string, companyInfo: CompanyInfo}> = [];
    setCompanies([]);

    getterProps.getActiveCompaniesInfo().forEach((companyInfo) => {
      companiesInfoTemp.push({ status: 'active', companyInfo });
    });
    getterProps.getInactiveCompaniesInfo().forEach((companyInfo) => {
      companiesInfoTemp.push({ status: 'inactive', companyInfo });
    });

    companiesInfoTemp.forEach((companyInfoTemp) => {
      getEntity<CompanyEntity>(companyInfoTemp.companyInfo.entityRef).then((res) => {
        setCompanies(prevState => ([...prevState, res]));
      });
    });
  }, [data]);

  return (
    <Veegilenz
      getter={new VeegilenzPersonGetter({ entity: data, linkedEntities: companies, yes: t('common.utils.yes'), no: t('common.utils.no') })}
      entityType='deecPerson'
    />
  );
}

export default VeegilenzTabsComponents;
