import React, { ReactElement, useMemo, useState } from 'react';
import {
  SegmentationCriteriaInfo,
  SegmentationCriteria,
  EntityType,
  ValuesOrRanges, SegmentationCriteriaSpec, SegmentationPossibleDisplayTypes
} from '@deecision/dna-interfaces';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { getElementAtEvent } from 'react-chartjs-2';
import type { Chart } from 'chart.js';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { darken, useTheme } from '@mui/material/styles';
import { Collapse, ListItemIcon } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { IconArrowsSplit2, IconChartBar, IconChartPie, IconChartPpf, IconDotsVertical, IconTrash } from '@tabler/icons-react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { lowerFirst } from 'lodash';
import { SegmentationFilter } from '@deecision/dna-interfaces/dist/segmentation/segmentationFilters';
import { BaseChartsProps } from '../../../../components/charts/types.charts';
import PieCharts from '../../../../components/charts/pie.charts';
import YesNoCharts from '../../../../components/charts/yesno.charts';
import BarCharts from '../../../../components/charts/bar.charts';
import { BaseSegmentationProps, CustomSegmentationFilter } from '../../types.segmentations';

export interface ChartDispatchSegmentationsProps extends BaseSegmentationProps {
  segmentationCriteria: any,
  criteriaInfo: SegmentationCriteriaInfo,
  criteria?: Omit<CustomSegmentationFilter, 'values' | 'ranges'> & SegmentationCriteriaSpec & SegmentationCriteria,
  handleDelete?: (segmentationCriteria: any) => void,
  handleChangeDisplayType?: (displayType: SegmentationPossibleDisplayTypes, segmentationCriteria: any) => void,
  entityType: EntityType,
  totalCount: number
}

export const baseChartWidth = 480;

const multiRangesEnable = false;
const multiValuesEnable = false;

function DispatchChartsSegmentations(props: ChartDispatchSegmentationsProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selected, setSelected] = useState<ValuesOrRanges>();
  const [halfPieChart, setHalfPieChart] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const chartHeight = {
    pieChart: 1.3,
    barChart: 1.7,
    yesNo: 2.5
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setValuesOrRanges = (valuesOrRanges: ValuesOrRanges) => {
    if (valuesOrRanges && (valuesOrRanges.values && valuesOrRanges.values.length > 0 || valuesOrRanges.ranges && valuesOrRanges.ranges.length > 0)) {
      // if (props.criteria?.valueType === 'countryCode[]' && valuesOrRanges.values && isArray(valuesOrRanges.values) && valuesOrRanges.values.length > 0) {
      //   valuesOrRanges.values = valuesOrRanges.values[0] as unknown as string[];
      // }
      props.addFilter({
        id: (props.criteria as CustomSegmentationFilter).id || 'unknown',
        filterId: (props.criteria?.filterSpec ? undefined : (props.criteria as CustomSegmentationFilter).filterId) || 'unknown',
        label: props.criteriaInfo.displayInfo.label,
        on: (props.criteria?.filterSpec ? props.criteria.filterSpec.on : props.criteria?.on) || 'person1',
        values: undefined,
        ranges: undefined,
        type: props.criteria?.filterSpec ? props.criteria.filterSpec.type : 'filter' as const,
        subItemSpecs: props.criteria?.filterSpec ? props.criteria.filterSpec.subItemSpecs : undefined,
        ...valuesOrRanges
      } as SegmentationFilter);
    } else {
      props.removeFilter(props.criteriaInfo.id);
    }
  };

  const setValues = (baseValue: boolean | string | number, unique?: boolean) => {
    setSelected({
      values: selected?.values?.find(value => value === baseValue) !== undefined ?
        selected?.values?.filter(value => value !== baseValue) :
        selected?.values && !unique && multiValuesEnable ? [...selected.values, baseValue] : [baseValue]
    });
  };

  const setRanges = (baseRange: { min: number, max: number }) => {
    setSelected({
      ranges: selected?.ranges?.find(range => range.max === baseRange.max && range.min === baseRange.min) ?
        selected?.ranges?.filter(range => !(range.max === baseRange.max && range.min === baseRange.min)) :
        selected?.ranges && multiRangesEnable ? [...selected.ranges, baseRange] : [baseRange]
    });
  };

  const onClick = (event?: React.MouseEvent<HTMLCanvasElement, MouseEvent>, chart?: Chart, baseValue?: boolean, echarts?: boolean, echartsData? : {range: string, value: string}) => {
    // REFACTO THIS FUNCTION TO USE ECHARTS //
    if (echarts && echartsData) {
      const selectedRangeTmp = echartsData.range;
      const selectedValueTmp = echartsData.value;

      if (selectedRangeTmp !== undefined) {
        const [min, max] = selectedRangeTmp.split('-').map(Number);
        setRanges({ min, max });
      } else if (selectedValueTmp !== undefined) {
        setValues(selectedValueTmp);
      } else if (baseValue !== undefined) {
        setValues(baseValue, true);
      }
    }

    if (chart && event && props.criteria?.values && getElementAtEvent(chart, event)[0]) {
      const selectedRangeTmp = props.criteria?.values[getElementAtEvent(chart, event)[0].index].range;
      const selectedValueTmp = props.criteria?.values[getElementAtEvent(chart, event)[0].index].value;

      if (selectedRangeTmp !== undefined) {
        setRanges(selectedRangeTmp);
      } else if (selectedValueTmp !== undefined) {
        setValues(selectedValueTmp);
      }
    } else if (baseValue !== undefined) {
      setValues(baseValue, true);
    }
  };

  const Charts: Record<SegmentationCriteriaInfo['displayInfo']['displayType'], (props: BaseChartsProps) => ReactElement> = {
    pieChart: elementProps => PieCharts({ ...elementProps, halfPieChart }),
    yesNo: elementProps => YesNoCharts(elementProps),
    barChart: elementProps => BarCharts({ ...elementProps })
  };

  const Chart = useMemo(() => Charts[props.criteriaInfo.displayInfo.displayType], [props.criteriaInfo]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('https://fastly.jsdelivr.net/npm/emoji-flags@1.3.0/data.json');
  //       const data = await response.json();
  //       setFlagsData(data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <Paper
      sx={{
        // maxWidth: '800px',
        minWidth: baseChartWidth,
        width: '100%'
      }}
    >
      <Stack spacing={2}>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
          <Typography variant='h5' p={1}>{props.criteriaInfo.displayInfo.label}</Typography>
          <IconButton
            size='small'
            id='chart-menu-button'
            onClick={handleClick}
            aria-controls={open ? 'chart-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <IconDotsVertical size={20} />
          </IconButton>
          <Menu
            id='chart-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'chart-menu-button'
            }}
          >
            {props.criteria?.displayInfo?.displayType !== 'barChart' && props.criteria?.possibleDisplayTypes?.includes('barChart') &&
              <MenuItem
                onClick={() => {
                  props.handleChangeDisplayType?.('barChart', props.segmentationCriteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconChartBar color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.barChart') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {(props.criteria?.displayInfo?.displayType !== 'pieChart' || halfPieChart) && props.criteria?.possibleDisplayTypes?.includes('pieChart') &&
              <MenuItem
                onClick={() => {
                  setHalfPieChart(false);
                  props.handleChangeDisplayType?.('pieChart', props.segmentationCriteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconChartPie color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.pieChart') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {(props.criteria?.displayInfo?.displayType !== 'pieChart' || !halfPieChart) && props.criteria?.possibleDisplayTypes?.includes('pieChart') &&
              <MenuItem
                onClick={() => {
                  setHalfPieChart(true);
                  props.handleChangeDisplayType?.('pieChart', props.segmentationCriteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconChartPpf color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.donut') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {props.criteria?.displayInfo?.displayType !== 'yesNo' && props.criteria?.possibleDisplayTypes?.includes('yesNo') &&
              <MenuItem
                onClick={() => {
                  props.handleChangeDisplayType?.('yesNo', props.segmentationCriteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconArrowsSplit2 color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.yesNo') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {props.handleDelete &&
              <MenuItem
                onClick={() => {
                  props.handleDelete?.(props.segmentationCriteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconTrash color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText sx={{ pr: 4 }}>
                  {t('segmentation.builder.custom.delete')}
                </ListItemText>
              </MenuItem>
            }
          </Menu>
        </Stack>
        <Box
          sx={{
            height: baseChartWidth / chartHeight[props.criteriaInfo.displayInfo.displayType],
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Chart
            {...props}
            datas={props.criteria?.values || []}
            on={props.entityType === 'deecPerson' ? t('entities.persons.label') : props.entityType === 'deecCompany' ? t('entities.companies.label') : undefined}
            selected={selected}
            dataType={props.criteria?.valueType}
            onClick={onClick}
          />
        </Box>
        <Collapse in={selected && (selected.values || selected.ranges || []).length > 0}>
          <Grid container spacing={2} p={2}>
            <Grid xs>
              <Button
                onClick={() => setSelected(undefined)}
                sx={{
                  '&:hover': {
                    color: theme.palette.error.main
                  }
                }}
                fullWidth
              >
                {t('segmentation.filters.cancel')}
              </Button>
            </Grid>
            <Grid xs>
              <Button
                variant='contained'
                onClick={() => (selected ? setValuesOrRanges(selected) : undefined)}
                sx={{
                  bgcolor: theme.palette.success.main,
                  borderColor: theme.palette.success.dark,
                  '&:hover': {
                    bgcolor: darken(theme.palette.success.main, 0.3),
                    borderColor: theme.palette.success.main
                  }
                }}
                fullWidth
              >
                {t('segmentation.filters.apply')}
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </Stack>
    </Paper>
  );
}

export default DispatchChartsSegmentations;
