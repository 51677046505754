import React, { ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import { GroupAllValues } from '@deecision/dna-interfaces';
import { Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { IconArrowNarrowRight, IconCloudComputing, IconUsers } from '@tabler/icons-react';
import IconBuildings from '@deecision/common-webapp/assets/custom/IconBuildings';
import Box from '@mui/material/Box';
import GroupsServices from '../../../modules/deetect/portfolios/services/portfolios.services';
import { BaseQuickDashboardProps, dashboardSpacing } from './types.quick.dashboard.containers';
import { ReactComponent as DEECISION } from '../../../../assets/DEECISION.svg';

function QuickDashboardDataflow(props: BaseQuickDashboardProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const groupsServices = new GroupsServices();
  const [values, setValues] = useState<GroupAllValues>();

  useEffect(() => {
    if (props.group.subGroupId) {
      groupsServices.getGroupValues(props.baseGroupId, props.group.subGroupId)
        .then(res => setValues(res.data));
    }
  }, []);

  return (
    <Stack direction='row' width='100%' spacing={dashboardSpacing} alignItems='center'>
      {props.group.augmented && [
        <Paper sx={{ minWidth: 'min-content', p: dashboardSpacing }}>
          <Stack direction='row' spacing={dashboardSpacing} alignItems='center'>
            <IconCloudComputing />
            <Stack alignItems='center'>
              <DEECISION width={128} height={24} />
              <Typography variant='body1' fontSize={18}>
                {t('segmentation.dashboard.sections.entry.augmentation')}
              </Typography>
            </Stack>
          </Stack>
        </Paper>,
        <Box>
          <IconArrowNarrowRight size={28}/>
        </Box>
      ]}
      {values ?
        <Paper
          variant='hoverElevation2'
          sx={{
            minWidth: 'min-content',
            bgcolor: values.groupDesc.objectType === 'deecPerson' ? theme.palette.primary.light : theme.palette.secondary.light,
            color: values.groupDesc.objectType === 'deecPerson' ? theme.palette.primary.dark : theme.palette.secondary.dark,
            p: dashboardSpacing,
            cursor: 'pointer'
          }}
          onClick={() => props.callSegmentation?.(values.groupDesc.objectType, props.group.subGroupId)}
        >
          <Grid container spacing={dashboardSpacing} height='fit-content' width='min-content'>
            <Grid xs={12}>
              <Stack direction='row' spacing={dashboardSpacing} alignItems='center'>
                {(props.group?.subGroupId || props.group.subGroupId.split('.').at(-1) || '').includes('persons') ?
                  <IconUsers/> : <IconBuildings stroke={theme.palette.secondary.dark}/>
                }
                <Typography variant='h4' whiteSpace='nowrap' pr={2}>
                  {(props.group.subGroupId.split('.').at(-1) && ['sameFamily', 'personal', 'business', 'extended', 'client', 'notClient'].includes(props.group.subGroupId.split('.').at(-1) || 'unknown')) ? t(`folder.dashboard.groups.labels.${props.group.subGroupId.split('.').at(-1)}.label`) : t(`folder.dashboard.groups.labels.${props.group.subGroupId}.label`)}
                </Typography>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Typography variant='h2' textAlign='center'>
                {values.values.totalCount}
              </Typography>
            </Grid>
          </Grid>
        </Paper> :
        <Skeleton variant='rounded' width={180} height={80}/>
      }
      {props.group.subGroups &&
        <Box>
          <IconArrowNarrowRight size={28}/>
        </Box>
      }
      {props.group.subGroups && props.group.subGroups.map(subGroup => (
        <QuickDashboardDataflow
          key={`sub-${subGroup.subGroupId}`}
          {...props}
          group={subGroup}
        />
      ))}
    </Stack>
  );
}

export default QuickDashboardDataflow;
