import React, { ReactElement, useEffect, useState } from 'react';
import { darken, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { useParams } from 'react-router-dom';
import { SegmentationData } from '@deecision/dna-interfaces';
import { basePaperSx, BaseQuickDashboardProps } from './types.quick.dashboard.containers';
import SegmentationServices from '../../segmentations/services/segmentations.services';
import KeyValue from '../../../components/keyvalue';
import ContainerGroupQuickDashboard from './group/container.group.quick.dashboard.containers';

function QuickDashboardGroupSegmentation(props: BaseQuickDashboardProps): ReactElement {
  const theme = useTheme();
  const segmentationServices = new SegmentationServices();
  const { id } = useParams();
  const [segmentationData, setSegmentationData] = useState<SegmentationData>();

  const possibleValues = [
    {
      id: 'green',
      color: theme.palette.success.dark,
      bgcolor: theme.palette.success.light,
      borderColor: darken(theme.palette.success.light, 0.2)
    },
    {
      id: 'orange',
      color: theme.palette.warning.dark,
      bgcolor: theme.palette.warning.light,
      borderColor: darken(theme.palette.warning.light, 0.2)
    },
    {
      id: 'red',
      color: theme.palette.error.dark,
      bgcolor: theme.palette.error.light,
      borderColor: darken(theme.palette.error.light, 0.2)
    }
  ];

  useEffect(() => {
    segmentationServices.perform({
      dataSetId: 'deecPersons',
      globalFilteringItems: [],
      filters: [
        props.group.subGroupId.split('.').at(-1) === 'companies' ?
          {
            id: 'company_groupMember',
            filterId: 'company_groupMember',
            type: 'filter',
            on: 'company',
            values: [
              `${id}/persons.companies`
            ]
          } :
          {
            id: 'person_groupMember',
            filterId: 'person_groupMember',
            type: 'filter',
            on: 'person1',
            values: [
              `${id}/persons.withCompanies`
            ]
          }
      ],
      entitiesSettings: {
        includeEntities: true,
        findOptions: {}
      },
      outputEntities: props.group.subGroupId.split('.').at(-1) === 'companies' ? 'company' : 'person1',
      segmentationCriterias: props.group.segmentationCriterias || []
    })
      .then(res => setSegmentationData(res.data));
  }, [props.group.segmentationCriterias]);

  return (
    <ContainerGroupQuickDashboard {...props}>
      {props.group.segmentationCriterias?.map(criteria => (
        <Grid key={criteria.id} xs={12} container>
          {segmentationData?.segmentationCriterias[criteria.filterId || criteria.id].values?.map(value => (
            possibleValues.find(possibleValue => possibleValue.id === value.value) &&
            <Grid key={value.value} xs>
              <Paper
                variant='hoverElevation1'
                sx={{
                  ...basePaperSx,
                  color: possibleValues.find(possibleValue => possibleValue.id === value.value)?.color,
                  bgcolor: possibleValues.find(possibleValue => possibleValue.id === value.value)?.bgcolor,
                  borderColor: possibleValues.find(possibleValue => possibleValue.id === value.value)?.borderColor,
                  cursor: 'pointer',
                  '&:hover': {
                    borderColor: possibleValues.find(possibleValue => possibleValue.id === value.value)?.color,
                    color: 'black'
                  }
                }}
                onClick={() => props.callSegmentation(props.group.subGroupId.split('.').at(-1) === 'companies' ? 'deecCompany' : 'deecPerson', props.group.subGroupId.split('.').at(-1) === 'companies' ? 'companies' : 'persons.withCompanies', props.group.subGroupId.split('.').at(-1) === 'companies' ? 'person1-companies_person2-companies' : 'person1_link_person2', [criteria.filterId || criteria.id], [value.value])}
              >
                <KeyValue value={value.count} size='big' valueFontSize='20px' />
              </Paper>
            </Grid>
          ))?.sort((a, b) => possibleValues.findIndex(possibleValue => possibleValue.id === a?.key) - possibleValues.findIndex(possibleValue => possibleValue.id === b?.key)) || <></>}
        </Grid>
      ))}
      {props.group.subGroups?.map(subgroup => (
        <Grid xs={12} key={subgroup.subGroupId}>
          <QuickDashboardGroupSegmentation {...props} group={subgroup} />
        </Grid>
      ))}
    </ContainerGroupQuickDashboard>
  );
}

export default QuickDashboardGroupSegmentation;
