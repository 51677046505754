import React, { ReactElement, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { IconChartInfographic, IconCheck, IconDeviceFloppy, IconX } from '@tabler/icons-react';
import { cloneDeep, isEqual } from 'lodash';
import { CircularProgress } from '@mui/material';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import TextBuilderSegmentations from './items/text.builder.segmentations';
import {
  BaseCustomUserData, CustomSegmentationFilter,
  CustomUserData
} from '../types.segmentations';
import { UserDataCustomSegmentationService } from '../services/segmentations.services';
import StepperBuilderSegmentations from './stepper.builder.segmentations';
import PreFilteringBuilderSegmentations from './steps/prefiltering.builder.segmentations';
import SegmentationBuilderSegmentations from './steps/segmentation.builder.segmentations';
import TypeSelectorBuilderSegmentations from './steps/typeselector.builder.segmentations';
import { sanitizeCustomSegmentation } from './sanitizedata.builder';

const defaultNewCustomSegmentationTitle = 'My Segmentation';

interface SegmentationsBuilderProps {
  ownerId: string,
  ownerType: 'user' | 'group'
}

function SegmentationsBuilder(props: SegmentationsBuilderProps): ReactElement {
  const { t } = useTranslation();
  const data = useLoaderData() as { data: CustomUserData } | undefined;
  const navigate = useNavigate();
  const [baseCustomSegmentation, setBaseCustomSegmentation] = useState<BaseCustomUserData>();
  const [customSegmentation, setCustomSegmentation] = useState<BaseCustomUserData>();
  const dataService = new UserDataCustomSegmentationService<BaseCustomUserData>({ userId: props.ownerId });
  const [isReady, setIsReady] = useState<boolean[]>([]);
  const initialize = (dataToInstantiate?: BaseCustomUserData) => {
    setBaseCustomSegmentation(dataToInstantiate ? cloneDeep(dataToInstantiate) : undefined);
    setCustomSegmentation(dataToInstantiate ? cloneDeep(dataToInstantiate) : undefined);
  };

  const getIsReady = () => [
    !!customSegmentation?.data?.on,
    !!customSegmentation?.data?.preFilters?.filter(item => item)?.every(item => item?.ready || item.type === 'personWithAnyCompany' && item.subItemSpecs?.every((subItem: CustomSegmentationFilter) => subItem?.ready)),
    !!customSegmentation?.data?.views?.filter(item => item)?.every(item => item?.ready)
  ];

  const save = () => {
    if (customSegmentation) {
      data?.data
        ? dataService.put?.(data.data._id, customSegmentation)
          .then((res) => {
            setBaseCustomSegmentation(res.data);
          })
        : dataService.post?.(customSegmentation)
          .then((res) => {
            navigate({ pathname: `../${(res.data as CustomUserData)._id}` });
          });
    }
  };

  useEffect(() => {
    initialize(data?.data || {
      ...props,
      title: defaultNewCustomSegmentationTitle,
      type: 'segmentation',
      data: {}
    });
  }, [data]);

  useEffect(() => {
    const ready = getIsReady().every(status => status);

    if (customSegmentation) {
      setIsReady(getIsReady());
      if (customSegmentation.ready !== ready) {
        setCustomSegmentation({ ...customSegmentation, ready });
      }
    }
  }, [customSegmentation]);

  return (
    customSegmentation && isReady.length > 0 ?
      <Stack spacing={6} pt={4}>
        <Stack spacing={4} direction='row' alignItems='center' justifyContent='space-between'>
          <Stack spacing={4} direction='row' alignItems='center'>
            <TextBuilderSegmentations
              variant='h2'
              data={customSegmentation}
              path='title'
              setData={d => setCustomSegmentation(prevState => (prevState ? { ...prevState, ...d } : undefined))}
              icon={<IconChartInfographic />}
              ownerId={props.ownerId}
            />
            {customSegmentation.ready ?
              <Chip sx={{ '& > *': { p: 2 } }} label={<Box display='flex' justifyContent='center'><IconCheck size={18} /></Box>} color='success' size='small' /> :
              <Chip sx={{ '& > *': { p: 2 } }} label={<Box display='flex' justifyContent='center'><IconX size={18} /></Box>} color='error' size='small' />
            }
          </Stack>
          <Button
            variant='contained'
            startIcon={<IconDeviceFloppy />}
            onClick={save}
            style= {{
              position: data?.data ? isEqual(baseCustomSegmentation, customSegmentation) ? undefined : Object.keys(customSegmentation.data).length < 1 ? undefined : 'absolute' : 'absolute',
              right: data?.data ? isEqual(baseCustomSegmentation, customSegmentation) ? undefined : Object.keys(customSegmentation.data).length < 1 ? undefined : 35 : 35,
              bottom: data?.data ? isEqual(baseCustomSegmentation, customSegmentation) ? undefined : Object.keys(customSegmentation.data).length < 1 ? undefined : 35 : 35,
              zIndex: data?.data ? isEqual(baseCustomSegmentation, customSegmentation) ? undefined : Object.keys(customSegmentation.data).length < 1 ? undefined : 1 : 1
            }}
            disabled={data?.data ? isEqual(baseCustomSegmentation, customSegmentation) : Object.keys(customSegmentation.data).length < 1}
          >
            {t('common.utils.save')}
          </Button>
        </Stack>
        <StepperBuilderSegmentations
          data={customSegmentation.data}
          path=''
          isReady={isReady}
          setData={d => setCustomSegmentation(prevState => (prevState ? { ...prevState, data: sanitizeCustomSegmentation(d) } : undefined))}
          ownerId={props.ownerId}
          steps={[
            <TypeSelectorBuilderSegmentations
              key={t('segmentation.builder.steps.initialize.label')}
              data={customSegmentation.data}
              path=''
              setData={d => setCustomSegmentation(prevState => (prevState ? { ...prevState, data: sanitizeCustomSegmentation(d) } : undefined))}
              ownerId={props.ownerId}
            />,
            <PreFilteringBuilderSegmentations
              key={t('segmentation.builder.steps.globalFilteringItems.label')}
              data={customSegmentation.data}
              path='preFilters'
              setData={d => setCustomSegmentation(prevState => (prevState ? { ...prevState, data: sanitizeCustomSegmentation(d) } : undefined))}
              ownerId={props.ownerId}
            />,
            <SegmentationBuilderSegmentations
              key={t('segmentation.builder.steps.segmentationViews.label')}
              data={customSegmentation.data}
              path='views'
              setData={d => setCustomSegmentation(prevState => (prevState ? { ...prevState, data: sanitizeCustomSegmentation(d) } : undefined))}
              ownerId={props.ownerId}
            />
          ]}
        />
      </Stack> :
      <CircularProgress />
  );
}

export default SegmentationsBuilder;
