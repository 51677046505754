import React, { ReactElement, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button, { ButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { IconFilterEdit, IconFilterPlus, IconStack2, IconX } from '@tabler/icons-react';
import { cloneDeep, get, isEqual, set, uniqueId } from 'lodash';
import { SegmentationCriteriaSpec } from '@deecision/dna-interfaces';
import MenuItem from '@mui/material/MenuItem';
// eslint-disable-next-line import/no-cycle
import FilterBuilderSegmentations from '../items/filter.builder.segmentations';
import { BaseItemSegmentationBuilderProps } from '../types.builder.segmentations';
import { UserDataCustomSegmentationService } from '../../services/segmentations.services';
import { BaseCustomUserData, CustomSegmentationFilter } from '../../types.segmentations';

function CustomFilter(props: BaseItemSegmentationBuilderProps & { filter: BaseCustomUserData<CustomSegmentationFilter>, updateFilter: (filter: BaseCustomUserData<CustomSegmentationFilter>) => void, update: () => void, view?: boolean, closeDrawer: () => void }): ReactElement {
  const [filter, setFilter] = useState<BaseCustomUserData<CustomSegmentationFilter> | undefined>(cloneDeep(props.filter));

  const save = () => {
    if (filter) {
      if (filter._id) {
        props.userService?.put?.(filter._id, filter)
          .then(() => {
            props.update?.();
          });
      } else {
        props.userService?.post?.(filter)
          .then(() => {
            props.update?.();
          });
      }
    }
  };

  const remove = () => {
    if (filter?._id) {
      props.userService?.delete?.(filter?._id)
        .then(() => {
          props.update?.();
        });
    } else {
      setFilter(undefined);
    }
  };

  const doAction = (action: string) => {
    const pathToUse = props.path !== '' && props.childPath ? `${props.path}.${props.childPath}` : props.path === '' ? props.childPath || props.path : props.path;
    switch (action) {
    case 'save':
      save();
      break;
    case 'delete':
      remove();
      break;
    case 'use':
      if (props.view) {
        const myCriteria: SegmentationCriteriaSpec = {
          id: `criteria-${filter?.data.id}`,
          type: 'segmentationCriteria',
          on: filter?.data.on as unknown as SegmentationCriteriaSpec['on'] || 'person1',
          filterSpec: {
            ...filter?.data,
            id: filter?.data.id || uniqueId(),
            filterId: filter?.data.filterId || uniqueId(),
            on: filter?.data.on as unknown as SegmentationCriteriaSpec['on'] || 'person1',
            type: filter?.data.type as SegmentationCriteriaSpec['type'] || 'and' as const
          }
        };

        props.setData({ ...set(props.data, `${pathToUse}[${(get(props.data, pathToUse)?.length || 0)}]`, {
          ...myCriteria,
          displayInfo: {
            label: myCriteria.label,
            displayType: 'yesNo',
            valueType: 'boolean'
          }
        }) });
      } else {
        props.setData({ ...set(props.data, `${pathToUse}[${(get(props.data, pathToUse).length || 0)}]`, {
          ...filter?.data,
          valueType: 'boolean'
        }) });
      }
      props.closeDrawer();
      break;
    default:
      break;
    }
  };

  return (
    filter && filter.data ?
      <FilterBuilderSegmentations
        workflow={props.workflow}
        userService={props.userService}
        ownerId={props.ownerId}
        data={filter.data}
        path=''
        setData={data => setFilter(prevState => (prevState && data && Object.keys(data).length > 0 ? { ...prevState, data } : undefined))}
        draft={!isEqual(filter, props.filter)}
        doAction={doAction}
      /> :
      <></>
  );
}

function AnchorTemporaryDrawer(props: BaseItemSegmentationBuilderProps & { btnLabel: string, view?: boolean, onClick?: () => void, variant?: ButtonProps['variant'] }) {
  const anchor = 'right';
  const { t } = useTranslation();
  const [state, setState] = useState(false);
  const dataService = new UserDataCustomSegmentationService<BaseCustomUserData<CustomSegmentationFilter>>({ userId: props.ownerId });
  const [customFilters, setCustomFilters] = useState<BaseCustomUserData<CustomSegmentationFilter>[]>();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setState(open);
  };

  const addFilter = () => {
    const newFilter: BaseCustomUserData<CustomSegmentationFilter> = {
      ownerId: props.ownerId,
      ownerType: 'user',
      title: `${t('segmentation.builder.filter.myCustomFilter')} ${(customFilters?.length || 0) + 1}`,
      type: 'customFilters',
      data: {
        id: uniqueId(`filter-${(customFilters?.length || 0) + 1}-`),
        label: `${t('segmentation.builder.filter.myCustomFilter')} ${(customFilters?.length || 0) + 1}`,
        type: 'and',
        on: props.workflow?.type
      }
    };

    setCustomFilters(customFilters ? [...customFilters, newFilter] : [newFilter]);
  };

  const update = () => {
    dataService.getAll({ filters: [{ id: 'type', scope: 'type', value: 'customFilters' }] })
      .then(res => setCustomFilters(res.data));
  };

  useEffect(() => {
    update();
  }, []);

  const component = () => (
    <Box
      sx={{ minWidth: '600px', width: '55vw' }}
      role='presentation'
      onKeyDown={e => e.code === 'Escape' && toggleDrawer(false)}
    >
      <Stack spacing={4} p={2} width='100%'>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
          <Stack direction='row' spacing={2} alignItems='center'>
            <IconFilterEdit />
            <Typography variant='h3'>{t('segmentation.builder.filter.customFilters')}</Typography>
          </Stack>
          <IconButton onClick={toggleDrawer(false)}>
            <IconX />
          </IconButton>
        </Stack>
        <Stack width='100%'>
          {customFilters?.map((customFilter, index) => (
            <CustomFilter
              key={customFilter._id || customFilter.title}
              {...props}
              workflow={props.workflow}
              update={update}
              filter={customFilter}
              updateFilter={(filter) => {
                const customFiltersTmp = [...customFilters];

                customFiltersTmp.splice(index, 1, filter);

                setCustomFilters(customFiltersTmp.filter(cf => cf.data));
              }}
              userService={dataService}
              closeDrawer={() => setState(false)}
            />
          ))}
        </Stack>
        <Button variant='contained' startIcon={<IconFilterPlus />} onClick={addFilter}>
          {t('segmentation.builder.filter.createFilter')}
        </Button>
      </Stack>
    </Box>
  );

  return (
    <Stack alignItems='center'>
      {props.variant ?
        <Button
          key={anchor}
          onClick={toggleDrawer(true)}
          startIcon={<IconStack2 />}
          variant={props.variant}
        >
          {props.btnLabel}
        </Button> :
        <MenuItem
          onClick={toggleDrawer(true)}
          sx={{
            width: '100%',
            borderRadius: 2,
            p: 2
          }}
        >
          <Stack direction='row' spacing={2} alignItems='center'>
            <IconStack2 />
            {props.btnLabel}
          </Stack>
        </MenuItem>
      }
      <Drawer
        anchor={anchor}
        open={state}
        onClose={toggleDrawer(false)}
      >
        {component()}
      </Drawer>
    </Stack>
  );
}

export default AnchorTemporaryDrawer;
