import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyEntity, CompanyInfo, PersonEntity } from '@deecision/dna-interfaces';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { IconChartPie, IconLayoutList } from '@tabler/icons-react';
import KeyValueCardComponent from '../cards/keyvalue.cards';
import { getRelations, PersonGetter } from '../../utils/getter';
import SmallEntityCards from '../smallcards/entities.smallcards';
import { SmallCompanyCardEntity } from '../smallcards/company.smallcards';
import PieChartsSegmentation from '../../containers/segmentations/legacy/charts/pie.charts.segmentation';
import { CriteriaFilter } from '../../containers/segmentations/legacy/charts/types.charts.segmentation';

function CompaniesForPersonWidgets(props: { data: PersonEntity }): (React.JSX.Element | false)[] {
  const { t } = useTranslation();
  const theme = useTheme();
  const [companies, setCompanies] = useState<CompanyEntity[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<CompanyEntity[]>([]);
  const [entities, setEntities] = useState<Array<SmallCompanyCardEntity>>([]);
  const [filters, setFilters] = useState<CriteriaFilter[]>([]);
  const getterProps = new PersonGetter({
    data: props.data,
    errorMsg: t('common.utils.unknown')
  });

  useEffect(() => {
    const companiesInfoTemp: Array<{ status: string, companyInfo: CompanyInfo}> = [];
    setCompanies([]);
    setEntities([]);

    getterProps.getActiveCompaniesInfo().forEach((companyInfo) => {
      companiesInfoTemp.push({ status: 'active', companyInfo });
    });
    getterProps.getInactiveCompaniesInfo().forEach((companyInfo) => {
      companiesInfoTemp.push({ status: 'inactive', companyInfo });
    });

    getRelations(props.data, 'deecCompany')
      .then((res) => {
        res.forEach((augmentedEntity) => {
          setCompanies(prevState => ([...prevState, augmentedEntity.entity as CompanyEntity]));
          setEntities(prevState => (
            [...prevState, { status: companiesInfoTemp.find(company => company.companyInfo.entityRef.entityId === augmentedEntity.entity.entityId)?.status || 'unknown', company: augmentedEntity.entity as CompanyEntity, companyInfo: companiesInfoTemp.find(company => company.companyInfo.entityRef.entityId === augmentedEntity.entity.entityId)?.companyInfo }]
          ));
        });
      });
  }, [props.data]);

  useEffect(() => {
    if (filters.length === 0) {
      setSelectedCompanies(companies);
    }
  }, [filters, companies]);

  return ([
    entities.length > 0 &&
    <KeyValueCardComponent
      key='companiesSegmentation'
      icon={IconChartPie}
      title={t('segmentation.label')}
      hideFilters
      filters={filters}
      setFilters={f => setFilters(f)}
      cardElements={companies.length > 0 ? [
        <PieChartsSegmentation
          key='pieChart'
          label=''
          batchDatas={companies}
          setBatchDatas={datas => setSelectedCompanies(datas)}
          filters={filters}
          setFilters={f => setFilters(f)}
          totalCount={companies.length}
        />
      ] : undefined}
    />,
    entities.length > 0 &&
    <KeyValueCardComponent
      key='companiesList'
      icon={IconLayoutList}
      title={t('common.utils.list')}
      filters={filters}
      setFilters={f => setFilters(f)}
      cardList={
        entities.filter(entity => selectedCompanies.find(company => company.entityId === entity.company.entityId)).map(company => (
          <SmallEntityCards
            key={company.company.entityId}
            entityType={company.company.entityType}
            entity={company}
            sideColor={company.status === 'active' ? theme.palette.success.light : theme.palette.error.light}
            bgColor={theme.palette.background.paper}
          />
        ))
      }
      link={
        <Link href='../companies'>
          <Typography noWrap>
            {t('entities.companies.all')}
          </Typography>
        </Link>
      }
    />
  ]);
}

export default CompaniesForPersonWidgets;
