import React from 'react';
import { CustomRouteObject } from '@deecision/common-webapp/router';
import { IconArrowGuide } from '@tabler/icons-react';
import createElementRoutes from '@deecision/common-webapp/router/utils/create.element.routes';
import dnaConfig from '../../../../../config/dna.config.json';
import RenderSegmentations from '../../../../containers/segmentations/render/render.segmentations';
import SegmentationService from '../services/segmentation.services';
import { BaseCustomUserData } from '../../../../containers/segmentations/types.segmentations';

function routesAssistedSegmentations(id: string): CustomRouteObject {
  return createElementRoutes<BaseCustomUserData>({
    id: `${id}-${dnaConfig.childRoutes.segmentations.assisted.label}`,
    path: dnaConfig.childRoutes.segmentations.assisted.baseUrl,
    slug: 'segmentationId',
    slugSuffix: true,
    component: <RenderSegmentations />,
    Service: new SegmentationService<BaseCustomUserData>(),
    icon: IconArrowGuide
  });
}

export default routesAssistedSegmentations;
