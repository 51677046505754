import React from 'react';
import { Outlet } from 'react-router-dom';
import { IconHome, IconShovel } from '@tabler/icons-react';
import { CustomRouteObject } from '@deecision/common-webapp/router';
import { Construction } from '@deecision/common-webapp/components';
import { entitiesRoutes } from '../modules/entities';
import { deetectRoutes } from '../modules/deetect';
import { adminRoutes } from '../modules/admin';
import HomePageDnaComponent from '../containers/dnaHomePage/dnaHomePage';

export const deegerRoutes: CustomRouteObject[] = [
  {
    path: 'deeger',
    element: <Outlet />,
    handle: {
      icon: IconShovel,
      sectionTitle: 'deeger'
    },
    children: [
      {
        index: true,
        element: <Construction />
      }
    ]
  }
];

const dnaRoutes: CustomRouteObject[][] = [
  [
    {
      index: true,
      i18nKey: 'common.utils.home',
      element: <HomePageDnaComponent />,
      handle: {
        icon: IconHome
      }
    }
  ],
  entitiesRoutes,
  deetectRoutes,
  adminRoutes
];

export default dnaRoutes;
